import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer
      className="footer footer-center p-10 mt-16 bg-primary text-zinc-200 relative"
      style={{ backgroundColor: "#384E79" }}
    >
      <aside>
        <img
          src={require("../../assets/recipecircle_alt.png")}
          alt="recipecircle.world Logo"
          className="inline-block fill-current"
          style={{ width: "150px", height: "150px" }}
        />
        <p className="font-bold text-zinc-200">
          recipecircle.world
          <br />
          {t("footer.slogan")}
        </p>
        <p>{t("footer.copyright")}</p>
        <div className="flex justify-center mt-2">
          <a
            href="https://recipecircle.world/category/blog/?v=f24485ae434a"
            target="_blank"
            rel="noopener noreferrer"
            className="mx-4 no-underline text-blue-300 hover:text-blue-400"
          >
            {t("footer.blog")}
          </a>
          <a
            href="https://recipecircle.world/sign-up-for-our-newsletter/?v=f24485ae434a"
            target="_blank"
            rel="noopener noreferrer"
            className="mx-4 no-underline text-blue-300 hover:text-blue-400"
          >
            {t("footer.newsletter")}
          </a>
          <a
            href="https://recipecircle.world/chefgnome-service-agreement/?v=f24485ae434a"
            target="_blank"
            rel="noopener noreferrer"
            className="mx-4 no-underline text-blue-300 hover:text-blue-400"
          >
            {t("footer.privacy")}
          </a>
          <a
            href="https://recipecircle.world/chefgnome-service-agreement/?v=f24485ae434a"
            target="_blank"
            rel="noopener noreferrer"
            className="mx-4 no-underline text-blue-300 hover:text-blue-400"
          >
            {t("footer.tos")}
          </a>
        </div>
      </aside>
    </footer>
  );
};

export default Footer;
