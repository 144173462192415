import React, { useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../context/DataContext";
import { MdArrowBackIosNew, MdOutlineRemoveRedEye } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { supabase } from "../supabaseClient";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

const NotificationsPage = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const { t } = useTranslation();
  const { state, actions } = useContext(DataContext);
  const [groupedNotifications, setGroupedNotifications] = useState({});
  const [groupPlanNotifications, setGroupPlanNotifications] = useState([]);
  const { user } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(true);
  dayjs.extend(utc);

  const fetchNotifications = async () => {
    let { data: notifications, error } = await supabase
      .from("notifications")
      .select(
        `
          id,
          created_at,
          read_status,
          comment:comments(
            id, text, created_at,
            user:profiles!inner(id, first_name, last_name, email),
            recipe:recipes(id, name, url),
            circle:circles(id, name)
          )
        `
      )
      .eq("read_status", false)
      .order("created_at", { ascending: false });

    if (error) {
      console.error("Error fetching notifications:", error.message);
      return;
    }

    const processedNotifications = notifications.map((notification) => {
      if (!notification.comment) {
        return {
          ...notification,
          commenterDisplayName: "Unknown",
          type: "unknown",
        };
      }

      const { first_name, last_name, email } = notification.comment.user || {
        first_name: "",
        last_name: "",
        email: "Unknown",
      };
      const displayName =
        first_name && last_name ? `${first_name} ${last_name}` : email;

      return {
        ...notification,
        commenterDisplayName: displayName,
        type: notification.comment.recipe ? "recipe" : "circle",
      };
    });

    const groupedNotifications = processedNotifications.reduce(
      (acc, notification) => {
        if (!notification.comment) return acc;

        let key =
          notification.type === "recipe"
            ? notification.comment.recipe?.id
            : notification.comment.circle?.id;
        if (!acc[key]) {
          acc[key] = {
            name:
              notification.type === "recipe"
                ? notification.comment.recipe?.name
                : notification.comment.circle?.name,
            url:
              notification.type === "recipe"
                ? notification.comment.recipe?.url
                : undefined,
            comments: [],
            type: notification.type,
          };
        }

        acc[key].comments.push(notification);
        return acc;
      },
      {}
    );

    setGroupedNotifications(groupedNotifications);
  };

  const fetchGroupPlanNotifications = async () => {
    let { data: notifications, error } = await supabase
      .from("notifications_group_plans")
      .select(
        `
        id,
        created_at,
        read_status,
        group_planned_meals:group_planned_meals!group_planned_meals_id (
          id,
          event_date_time,
          event_description,
          recipe:recipes (id, name, url),
          group_plan:group_plans!group_plans_id (
            id,
            event_name,
            circle_id,
            circle:circles (id, name)
          )
        )
      `
      )
      .eq("read_status", false)
      .order("created_at", { ascending: false });

    if (error) {
      console.error("Error fetching group plan notifications:", error);
      setGroupPlanNotifications([]);
      return;
    }

    const organizedNotifications = notifications.map((notification) => {
      const plan = notification.group_planned_meals.group_plan;
      const meal = notification.group_planned_meals;

      return {
        id: notification.id,
        created_at: notification.created_at,
        eventName: plan.event_name,
        circleName: plan.circle.name,
        circleId: plan.circle_id,
        eventDescription: meal.event_description,
        eventDateTime: meal.event_date_time,
        recipes: [{ name: meal.recipe.name, url: meal.recipe.url }],
      };
    });

    setGroupPlanNotifications(organizedNotifications || []);
  };

  const handleGroupPlanNotificationClick = async (notification) => {
    const { error: updateError } = await supabase
      .from("notifications_group_plans")
      .update({ read_status: true })
      .match({ id: notification.id });

    if (updateError) {
      console.error("Error updating group plan notification:", updateError);
    } else {
      navigate(`/circles/${notification.circleId}`);
    }
  };

  useEffect(() => {
    fetchNotifications();
    fetchGroupPlanNotifications();
  }, []);

  const handleNotificationClick = async (notification, type) => {
    const { error: updateError } = await supabase
      .from("notifications")
      .update({ read_status: true })
      .match({ id: notification.id });

    if (updateError) {
      console.error("Error updating notification:", updateError);
    } else {
      if (type === "recipe") {
        navigate(`/recipes-view/${notification.comment.recipe.url}`);
      } else if (type === "circle") {
        navigate(`/circles/${notification.comment.circle.id}`);
      }
    }
  };

  const markAllAsRead = async () => {
    const { error } = await supabase
      .from("notifications")
      .update({ read_status: true })
      .eq("user_id", state?.user?.id)
      .eq("read_status", false);

    if (error) {
      console.error("Error marking all notifications as read:", error);
    } else {
      fetchNotifications();
    }
  };

  return (
    <div className="w-100 container mx-auto">
      <div>
        <Helmet>
          <title>RecipeCircle - Notifications</title>
          <meta
            name="description"
            content="View and manage your notifications on RecipeCircle."
          />
          <meta property="og:title" content="RecipeCircle - Notifications" />
          <meta
            property="og:description"
            content="View and manage your notifications on RecipeCircle."
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content="https://app.recipecircle.world/notifications"
          />
        </Helmet>

        <div className="w-full overflow-x-hidden">
          <div className="text-center">
            <h1 className="text-4xl font-bold py-4 text-[#384E79]">
              {t("notifications.notifications")}
            </h1>
            <div className="breadcrumbs flex justify-center mb-4">
              <ul>
                <li>
                  <a
                    onClick={() => {
                      navigate("/");
                    }}
                    className="text-[#384E79] hover:text-blue-400"
                  >
                    {t("profile.home")}
                  </a>
                </li>
                <li className="text-stone-700">
                  {t("notifications.notifications")}
                </li>
              </ul>
            </div>
          </div>

          <div className="flex justify-between items-center mb-4">
            <button
              className="btn btn-outline"
              onClick={() => {
                navigate("/");
              }}
            >
              <MdArrowBackIosNew />
            </button>
            <button
              type="button"
              onClick={markAllAsRead}
              className="btn btn-outline hover:bg-blue-400 text-stone-900 inline-flex items-center"
            >
              <span>{t("notifications.readall")}</span>
              <MdOutlineRemoveRedEye className="w-5 h-5 ml-1" />
            </button>
          </div>
          <div className="divider"></div>
          <div className="container mx-auto px-4 py-8">
            {Object.keys(groupedNotifications).length > 0 ||
            groupPlanNotifications.length > 0 ? (
              <>
                {Object.entries(groupedNotifications).map(
                  ([key, { name, url, comments, type }]) => (
                    <div key={key} className="mb-6">
                      <h3 className="text-xl font-bold text-gray-700 mb-4">
                        {name}
                      </h3>
                      {comments.map((notification) => (
                        <div
                          key={notification.id}
                          className="p-4 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300 ease-in-out cursor-pointer mb-4"
                          onClick={() =>
                            handleNotificationClick(notification, type)
                          }
                        >
                          <div className="flex justify-between items-center">
                            <div>
                              <h4 className="text-lg font-semibold">
                                {notification.commenterDisplayName}
                              </h4>
                              <p className="text-gray-600 mt-1">
                                {notification.comment.text}
                              </p>
                            </div>
                            <span className="text-sm text-gray-400">
                              {new Date(
                                notification.created_at
                              ).toLocaleDateString()}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  )
                )}
                {groupPlanNotifications.map((notification) => (
                  <div
                    key={notification.id}
                    className="mb-6 p-4 bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300 ease-in-out cursor-pointer"
                    onClick={() =>
                      handleGroupPlanNotificationClick(notification)
                    }
                  >
                    <h3 className="text-xl font-bold text-gray-700 mb-4">
                      {notification.eventName} on{" "}
                      <span className="text-blue-500 hover:text-blue-700">
                        {notification.circleName}
                      </span>
                    </h3>
                    <p className="text-sm italic mb-2">
                      {t("notifications.assigned")}
                    </p>
                    <p className="text-lg mb-2 italic">
                      {notification.eventDescription} -{" "}
                      {dayjs
                        .utc(notification.eventDateTime)
                        .format("M/D/YYYY, h:mm A")}
                    </p>
                    {notification.recipes.map((recipe, index) => (
                      <div key={index} className="mb-2">
                        <p
                          className="text-md font-semibold text-blue-500 hover:text-blue-700 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/recipes-view/${recipe.url}`);
                          }}
                        >
                          {recipe.name}
                        </p>
                      </div>
                    ))}
                  </div>
                ))}
              </>
            ) : (
              <p className="text-center text-gray-500">
                {t("notifications.nonotifications")}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationsPage;
