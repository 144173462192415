import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useTranslation } from "react-i18next";
import { supabase } from "../../supabaseClient";
import { MdNavigateNext } from "react-icons/md";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const GroupPlanMealsDisplay = ({ user }) => {
  const { state, actions } = useContext(DataContext);
  const navigate = useNavigate();
  const [groupPlannedMeals, setGroupPlannedMeals] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  dayjs.extend(utc);

  const formatDate = (utcDate) => {
    const dayjsDate = dayjs.utc(utcDate);
    const day = t(`days.${dayjsDate.format("dddd")}`);
    const month = t(`months.${dayjsDate.format("MMMM")}`);
    const atSymbol = t("misc.atSymbol") || "[at]";
    return `${day}, ${month} ${dayjsDate.format("D")}, ${dayjsDate.format(
      "YYYY"
    )} ${atSymbol} ${dayjsDate.format("h:mm A")}`;
  };

  const fetchGroupPlans = async () => {
    if (!user) {
      return;
    }

    setIsLoading(true);

    try {
      const ownedMealsResponse = await supabase
        .from("other_group_meals")
        .select("id")
        .eq("owner_id", user);

      const assignedMealsResponse = await supabase
        .from("other_group_meal_assignments")
        .select("other_group_meals_id")
        .eq("user_id", user);

      if (ownedMealsResponse.error || assignedMealsResponse.error) {
        console.error(
          "Error fetching meals: ",
          ownedMealsResponse.error || assignedMealsResponse.error
        );
        setIsLoading(false);
        return;
      }

      const ownedMealIds = ownedMealsResponse.data.map((meal) => meal.id);
      const assignedMealIds = assignedMealsResponse.data.map(
        (assignment) => assignment.other_group_meals_id
      );
      const uniqueMealIds = Array.from(
        new Set([...ownedMealIds, ...assignedMealIds])
      );

      const detailedAssignments = await supabase
        .from("other_group_meal_assignments")
        .select(
          `
          id, 
          other_group_meals_id, 
          event_date_time, 
          event_description, 
          accepted, 
          user_id, 
          other_group_meals!inner(
            id, 
            event_name, 
            owner_id
          ),
          recipes (
            name, 
            url
          )
        `
        )
        .in("other_group_meals_id", uniqueMealIds)
        .order("event_date_time", { ascending: true });

      if (detailedAssignments.error) {
        console.error(
          "Error fetching detailed assignments: ",
          detailedAssignments.error
        );
        setIsLoading(false);
        return;
      }

      const aggregatedMeals = detailedAssignments.data.reduce((acc, meal) => {
        const mealGroupKey = meal.other_group_meals_id;
        const eventKey = `${meal.event_date_time}_${meal.event_description}`;

        if (!acc[mealGroupKey]) {
          acc[mealGroupKey] = {
            other_group_meals: {
              id: meal.other_group_meals_id,
              event_name: meal.other_group_meals.event_name,
            },
            events: {},
          };
        }

        if (!acc[mealGroupKey].events[eventKey]) {
          acc[mealGroupKey].events[eventKey] = {
            event_description: meal.event_description,
            event_date_time: meal.event_date_time,
            recipe_count: 0,
            recipes: [],
            formattedDate: formatDate(meal.event_date_time, t),
            userSpecificRecipe: null,
          };
        }

        acc[mealGroupKey].events[eventKey].recipe_count += 1;
        acc[mealGroupKey].events[eventKey].recipes.push({
          id: meal.id,
          name: meal.recipes.name,
          url: meal.recipes.url,
          user_id: meal.user_id,
          accepted: meal.accepted,
        });

        if (meal.user_id === user && meal.accepted !== null) {
          acc[mealGroupKey].events[eventKey].userSpecificRecipe = {
            id: meal.id,
            name: meal.recipes.name,
            url: meal.recipes.url,
            accepted: meal.accepted,
          };
        }

        return acc;
      }, {});

      const mealsWithRecipeCounts = Object.values(aggregatedMeals).map(
        (group) => ({
          ...group.other_group_meals,
          events: Object.values(group.events),
        })
      );

      setGroupPlannedMeals(mealsWithRecipeCounts);
      setIsLoading(false);
    } catch (error) {
      console.error("An unexpected error occurred: ", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchGroupPlans();
  }, [user]);

  const updateAssignmentStatus = async (assignmentId, status) => {
    const { data, error } = await supabase
      .from("other_group_meal_assignments")
      .update({ accepted: status })
      .eq("id", assignmentId);

    if (error) {
      console.error("Error updating status: ", error);
    } else {
      fetchGroupPlans();
    }
  };

  const handleView = (url) => {
    navigate(`/recipes-view/${url}`);
  };

  const navigateToGroupPlan = (groupId) => {
    navigate(`/group-meals/${groupId}`);
  };

  return (
    <>
      {groupPlannedMeals.length > 0 && (
        <>
          <h2 className="text-xl font-semibold mb-4 mt-10">
            {t("groupmeals.plannedmeals")}
          </h2>
          <div className="divider"></div>
        </>
      )}
      {isLoading ? (
        <span className="loading loading-spinner loading-lg"></span>
      ) : (
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 1, 750: 3, 900: 4, 1200: 5 }}
        >
          <Masonry gutter="20px">
            {groupPlannedMeals.map((mealGroup, index) => (
              <div
                key={index}
                className="bg-white rounded-lg shadow-sm hover:shadow-lg transition-shadow border border-gray-200 overflow-hidden flex flex-col relative"
              >
                <div className="px-5 pt-5 pb-3 relative">
                  <h2
                    className="text-xl font-semibold text-blue-600 mb-1 cursor-pointer tracking-wide"
                    onClick={() => {
                      const isOwner = mealGroup.owner_id === user.id;
                      const isParticipant = mealGroup.events.some((event) =>
                        event.recipes.some(
                          (recipe) => recipe.user_id === user.id
                        )
                      );
                      if (isOwner || isParticipant) {
                        navigateToGroupPlan(mealGroup.id);
                      }
                    }}
                  >
                    {mealGroup.event_name}
                  </h2>

                  <div className="divider"></div>
                  {mealGroup.events.map((event, eventIndex) => {
                    const userRecipes = event.recipes.filter(
                      (recipe) => recipe.user_id === state.user?.id
                    );
                    return (
                      <div key={eventIndex} className="mb-4">
                        <div className="text-xs text-gray-500 mb-2">
                          <strong>{t("groupmeals.date")}</strong>{" "}
                          {formatDate(event.event_date_time)}
                          <br />
                          <strong>{t("groupmeals.description")}</strong>{" "}
                          {event.event_description}
                          <br />
                          <strong>{t("groupmeals.recipesfor")}</strong>{" "}
                          {event.recipes.length}{" "}
                          {event.recipes.length === 1 ? "recipe" : "recipes"}{" "}
                          {t("groupmeals.assigned")}
                        </div>
                        {userRecipes.length > 0 && (
                          <>
                            <h4 className="text-md font-semibold text-gray-600 mb-1">
                              {t("groupmeals.recipetime")}
                            </h4>
                            {userRecipes.map((recipe, recipeIndex) => (
                              <div
                                key={recipeIndex}
                                className="border-b last:border-b-0 p-5 mb-1 hover:bg-blue-100 rounded-lg transition-colors flex justify-between items-center cursor-pointer group"
                                onClick={() => handleView(recipe.url)}
                              >
                                <div>
                                  <h4 className="text-lg font-semibold text-blue-500 mb-1">
                                    {recipe.name}
                                  </h4>
                                  <p className="text-sm text-gray-600">
                                    {t("groupmeals.yourrecipes")}
                                  </p>
                                  <div className="flex space-x-2 mt-2">
                                    {recipe.accepted === 0 && (
                                      <>
                                        <button
                                          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            updateAssignmentStatus(
                                              recipe.id,
                                              1
                                            );
                                          }}
                                        >
                                          {t("groupmeals.accept")}
                                        </button>
                                        <button
                                          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            updateAssignmentStatus(
                                              recipe.id,
                                              2
                                            );
                                          }}
                                        >
                                          {t("groupmeals.decline")}
                                        </button>
                                      </>
                                    )}
                                    {recipe.accepted !== 0 && (
                                      <>
                                        <button
                                          className={`font-bold py-2 px-4 rounded text-white ${
                                            recipe.accepted === 1
                                              ? "bg-green-700"
                                              : "bg-red-700"
                                          }`}
                                          style={{ cursor: "default" }}
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          {recipe.accepted === 1
                                            ? t("groupmeals.accepted")
                                            : t("groupmeals.declined")}
                                        </button>
                                        <button
                                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            updateAssignmentStatus(
                                              recipe.id,
                                              0
                                            );
                                          }}
                                        >
                                          {t("groupmeals.reset")}
                                        </button>
                                      </>
                                    )}
                                  </div>
                                </div>
                                <MdNavigateNext className="text-2xl transition-opacity duration-150 ease-in-out opacity-0 group-hover:opacity-100 md:opacity-100" />
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      )}
    </>
  );
};

export default GroupPlanMealsDisplay;
