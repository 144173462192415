import React, { useContext, useEffect, useState, useRef } from "react";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../context/DataContext";
import { usePluralizeMeasure } from "../components/utilities/PluralizeMeasurement";
import ParseQuantity from "../components/utilities/ParseQuantity";
import DecimalToFraction from "../components/utilities/DecimalToFraction";
import { MdArrowBackIosNew } from "react-icons/md";
import { Helmet } from "react-helmet-async";
import Swal from "sweetalert2";
import convert, { MeasureKind } from "convert";
import { useTranslation } from "react-i18next";

const YourCart = () => {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState({});
  const [priceUpdates, setPriceUpdates] = useState({});
  const [isSetUnitPriceModalOpen, setIsSetUnitPriceModalOpen] = useState(false);
  const [currentItemId, setCurrentItemId] = useState(null);
  const [currentItemDetails, setCurrentItemDetails] = useState(null);
  const { t } = useTranslation();
  const pluralizeMeasure = usePluralizeMeasure();

  const ensureValidUnit = (unit) => {
    if (!unit) return unit;

    const unitConversionMap = {
      teaspoons: "teaspoon",
      tablespoons: "tablespoon",
      cups: "cup",
      ounces: "ounce",
      pounds: "pound",
      fluid_ounces: "fluid_ounce",
      pints: "pint",
      quarts: "quart",
      gallons: "gallon",
      milligrams: "milligram",
      gs: "g",
      kgs: "kg",
      mls: "ml",
      liters: "liter",
      teaspoon: "teaspoon",
      tablespoon: "tablespoon",
      cup: "cup",
      ounce: "ounce",
      pound: "pound",
      fluid_ounce: "fluid_ounce",
      pint: "pint",
      quart: "quart",
      gallon: "gallon",
      milligram: "milligram",
      g: "g",
      kg: "kg",
      ml: "ml",
      liter: "liter",
    };

    return unitConversionMap[unit.toLowerCase()] || unit;
  };

  const calculateCostPerServing = (
    unitPrice,
    bulkQuantity,
    bulkMeasure,
    recipeQuantity,
    recipeMeasure
  ) => {
    if (
      !unitPrice ||
      !bulkQuantity ||
      !bulkMeasure ||
      !recipeQuantity ||
      !recipeMeasure
    ) {
      return "Missing one or more required inputs";
    }

    bulkMeasure = bulkMeasure.toLowerCase();
    recipeMeasure = recipeMeasure.toLowerCase();

    if (bulkMeasure === "pinch" || recipeMeasure === "pinch") {
      return t("yourcart.pinch");
    }

    if (
      (bulkMeasure === "unit" && recipeMeasure !== "unit") ||
      (bulkMeasure !== "unit" && recipeMeasure === "unit")
    ) {
      return t("yourcart.unit");
    }

    const standardizedBulkMeasure = ensureValidUnit(bulkMeasure);
    const standardizedRecipeMeasure = ensureValidUnit(recipeMeasure);
    if (!standardizedBulkMeasure || !standardizedRecipeMeasure) {
      console.error("Invalid unit:", bulkMeasure, "or", recipeMeasure);
      return "Error: Invalid unit";
    }

    const numericBulkQuantity = ParseQuantity(bulkQuantity.toString());
    const numericRecipeQuantity = ParseQuantity(recipeQuantity.toString());
    if (numericRecipeQuantity === 0) {
      console.error(
        "Recipe quantity is zero, cannot calculate cost per serving."
      );
      return "Error: Recipe quantity is zero";
    }

    let convertedQuantity = numericBulkQuantity;

    if (standardizedBulkMeasure !== standardizedRecipeMeasure) {
      try {
        convertedQuantity = convert(
          numericBulkQuantity,
          standardizedBulkMeasure
        ).to(standardizedRecipeMeasure);
      } catch (error) {
        console.error("Error converting units:", error);
        return "Error during unit conversion";
      }
    }

    const totalCostForRecipe =
      (unitPrice / convertedQuantity) * numericRecipeQuantity;
    return totalCostForRecipe.toFixed(2);
  };

  const renderCostOrMessage = (costPerServing) => {
    const isNumeric =
      !isNaN(parseFloat(costPerServing)) && isFinite(costPerServing);

    if (isNumeric) {
      return `$${parseFloat(costPerServing).toFixed(2)} per serving`;
    } else {
      return costPerServing;
    }
  };

  const openSetUnitPriceModal = (itemId) => {
    const allItems = Object.values(cartItems).flat();
    const itemDetails = allItems.find((item) => item.id === itemId);

    if (itemDetails) {
      setCurrentItemDetails(itemDetails);
      setIsSetUnitPriceModalOpen(true);
      setCurrentItemId(itemId);
    } else {
      console.error("Item details not found for ID:", itemId);
    }
  };

  const closeSetUnitPriceModal = () => {
    setIsSetUnitPriceModalOpen(false);
    setCurrentItemId(null);
  };

  function SetUnitPriceModal({ isOpen, onClose, itemId, itemDetails }) {
    const [unitPrice, setUnitPrice] = useState("");
    const [unitMeasure, setUnitMeasure] = useState("");
    const [unitQuantity, setUnitQuantity] = useState("");
    const [measurementSystem, setMeasurementSystem] = useState("imperial");

    const handlePriceChange = (e) => setUnitPrice(e.target.value);
    const handleMeasureChange = (e) => setUnitMeasure(e.target.value);
    const handleQuantityChange = (e) => setUnitQuantity(e.target.value);

    const handleSubmit = async () => {
      if (unitPrice && unitMeasure && unitQuantity) {
        const { data, error } = await supabase
          .from("cart_items")
          .update({
            unit_price: unitPrice,
            unit_measure: unitMeasure,
            unit_quantity: unitQuantity,
          })
          .match({ id: itemId });

        if (error) {
          console.error("Error updating cart item: ", error.message);
        } else {
          fetchCartItems();
          onClose();
        }
      }
    };

    const handleClose = (e) => {
      e.stopPropagation();
      onClose();
    };

    return (
      <div
        className={`modal ${
          isOpen ? "modal-open" : ""
        } fixed w-full h-full top-0 left-0 flex items-center justify-center`}
      >
        <div
          className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
          onClick={handleClose}
        ></div>
        <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-xl z-50 overflow-y-auto">
          <div className="modal-content p-4">
            <div className="flex flex-col items-center">
              <h3 className="text-xl font-normal">
                {t("yourcart.setprice")}{" "}
                <span className="font-bold">
                  {itemDetails?.ingredient_name}
                </span>
              </h3>
              <p className="mt-2 text-sm text-center">
                {t("yourcart.input")}{" "}
                <span className="font-bold">
                  {itemDetails?.ingredient_name}
                </span>
                {t("yourcart.inputtext")}
              </p>
              <div className="w-full mt-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="quantity"
                >
                  {t("yourcart.quant")}
                </label>
                <input
                  id="quantity"
                  type="number"
                  className="input border border-gray-300 w-full p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
                  placeholder={t("yourcart.quant")}
                  value={unitQuantity}
                  onChange={handleQuantityChange}
                />
              </div>
              <div className="w-full mt-4 flex justify-center items-center">
                <label
                  htmlFor="measurement-system-toggle"
                  className="mr-2 font-bold text-gray-700"
                >
                  {t("yourcart.imperial")}
                </label>
                <input
                  type="checkbox"
                  id="measurement-system-toggle"
                  className="toggle toggle-warning"
                  checked={measurementSystem === "metric"}
                  onChange={() =>
                    setMeasurementSystem(
                      measurementSystem === "imperial" ? "metric" : "imperial"
                    )
                  }
                />
                <label
                  htmlFor="measurement-system-toggle"
                  className="ml-2 font-bold text-gray-700"
                >
                  {t("yourcart.metric")}
                </label>

                <select
                  className="input border border-gray-300 ml-2 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
                  value={unitMeasure}
                  onChange={handleMeasureChange}
                  style={{ flex: 1 }}
                >
                  {measurementSystem === "imperial" ? (
                    <>
                      <option value="teaspoon">{t("measurements.tsp")}</option>
                      <option value="tablespoon">
                        {t("measurements.tbsp")}
                      </option>
                      <option value="cup">{t("measurements.cup")}</option>
                      <option value="ounce">{t("measurements.oz")}</option>
                      <option value="pound">{t("measurements.lb")}</option>
                      <option value="fluid_ounce">
                        {t("measurements.floz")}
                      </option>
                      <option value="pint">{t("measurements.pint")}</option>
                      <option value="quart">{t("measurements.quart")}</option>
                      <option value="gallon">{t("measurements.gal")}</option>
                      <option value="unit">{t("measurements.unit")}</option>
                      <option value="pinch">{t("measurements.pinch")}</option>
                    </>
                  ) : (
                    <>
                      <option value="milligram">{t("measurements.mg")}</option>
                      <option value="g">{t("measurements.g")}</option>
                      <option value="kg">{t("measurements.kg")}</option>
                      <option value="ml">{t("measurements.ml")}</option>
                      <option value="liter">{t("measurements.l")}</option>
                      <option value="unit">{t("measurements.unit")}</option>
                      <option value="pinch">{t("measurements.pinch")}</option>
                    </>
                  )}
                </select>
              </div>
              <div className="w-full mt-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="unitPrice"
                >
                  {t("yourcart.setprice")} {itemDetails?.ingredient_name}
                </label>
                <input
                  id="unitPrice"
                  type="number"
                  className="input border border-gray-300 w-full p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
                  placeholder={t("yourcart.unitpri")}
                  value={unitPrice}
                  onChange={handlePriceChange}
                />
              </div>
              <button
                className="mt-4 btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={handleSubmit}
              >
                {t("yourcart.update")}
              </button>
              <button
                className="mt-2 px-4 bg-transparent p-3 rounded-lg text-blue-700 hover:bg-gray-200"
                onClick={handleClose}
              >
                {t("yourcart.close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const fetchCartItems = async () => {
    const {
      data: { user },
      error: userError,
    } = await supabase.auth.getUser();

    if (userError) {
      console.error("Error fetching user:", userError);
      return;
    }

    if (!user) {
      console.error("No user found");
      return;
    }

    let {
      data: cartData,
      error: cartError,
      status: cartStatus,
    } = await supabase
      .from("cart_items")
      .select(
        "id, recipe_id, recipes(name), quantity, measure, ingredient_name, price, selected_servings, unit_price, unit_quantity, unit_measure"
      )
      .eq("user_id", user.id)
      .order("recipe_id", { ascending: true });

    if (cartError && cartStatus !== 406) {
      console.error("Error fetching cart items:", cartError);
      return;
    }

    const groupedItems = cartData.reduce((acc, item) => {
      const recipeName = item.recipes.name;
      if (!acc[recipeName]) acc[recipeName] = [];
      acc[recipeName].push(item);
      return acc;
    }, {});

    setCartItems(groupedItems);
  };

  useEffect(() => {
    fetchCartItems();
  }, []);

  const removeItemFromCart = async (itemId) => {
    Swal.fire({
      title: t("yourcart.confirm"),
      text: t("yourcart.confirmtext"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc2626",
      cancelButtonColor: "#3b82f6",
      confirmButtonText: t("yourcart.delete"),
      cancelButtonText: t("yourcart.cancel"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { error } = await supabase
          .from("cart_items")
          .delete()
          .match({ id: itemId });

        if (error) {
          console.error("Error deleting item:", error);
          Swal.fire(t("yourcart.error"), t("yourcart.errortext"), "error");
        } else {
          Swal.fire(
            t("yourcart.deleted"),
            t("yourcart.deletedtext"),
            "success"
          ).then(() => {
            fetchCartItems();
          });
        }
      }
    });
  };

  return (
    <div className="w-100 container mx-auto">
      <div>
        <SetUnitPriceModal
          isOpen={isSetUnitPriceModalOpen}
          onClose={closeSetUnitPriceModal}
          itemId={currentItemId}
          itemDetails={currentItemDetails}
        />
        <Helmet>
          <title>RecipeCircle - Your Cart</title>
          <meta
            name="description"
            content="Manage your cart on RecipeCircle. Price ingredients from various recipes and keep track of your shopping list for meal planning."
          />
          <meta property="og:title" content="RecipeCircle - Your Cart" />
          <meta
            property="og:description"
            content="Manage your cart on RecipeCircle. Price ingredients from various recipes and keep track of your shopping list for meal planning."
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content="https://app.recipecircle.world/your-cart"
          />
        </Helmet>
        <div className="w-full overflow-x-hidden">
          <div className="text-center">
            <h1 className="text-4xl font-bold py-4 text-[#384E79]">
              {t("yourcart.cart")}
            </h1>
            <div className="breadcrumbs flex justify-center mb-1">
              <ul>
                <li>
                  <a
                    onClick={() => {
                      navigate("/");
                    }}
                    className="text-[#384E79] hover:text-blue-400"
                  >
                    {t("yourcart.home")}
                  </a>
                </li>
                <li className="text-stone-700">{t("yourcart.cart")}</li>
              </ul>
            </div>
          </div>

          <div className="flex justify-between mb-1">
            <div className="flex-1">
              <button
                className="btn btn-outline"
                onClick={() => {
                  navigate("/");
                }}
              >
                <MdArrowBackIosNew />
              </button>
            </div>
          </div>

          <div className="divider"></div>
        </div>

        <div className="flex justify-center">
          <div className="card w-full md:w-2/3 bg-white shadow-xl mb-8 rounded-lg overflow-hidden">
            <div className="card-body p-4 md:p-6 lg:p-10">
              <h2 className="font-bold text-2xl md:text-3xl lg:text-4xl mb-4 text-gray-900">
                {t("yourcart.cart")}
              </h2>
              <div className="border border-gray-300 bg-blue-100 rounded-lg shadow p-4 md:p-6 mb-4">
                <p className="text-gray-600">{t("yourcart.manage")}</p>
              </div>
              {Object.keys(cartItems).length ? (
                Object.entries(cartItems).map(
                  ([recipeName, items], recipeIndex) => {
                    const totalPrice = items.reduce(
                      (acc, item) => acc + Number(item.unit_price),
                      0
                    );
                    const totalCostPerServing = items
                      .filter(
                        (item) =>
                          item.unit_price &&
                          item.unit_quantity &&
                          item.unit_measure
                      )
                      .reduce((acc, item) => {
                        const costPerServing = calculateCostPerServing(
                          item.unit_price,
                          item.unit_quantity,
                          item.unit_measure,
                          item.quantity,
                          item.measure
                        );
                        return !isNaN(parseFloat(costPerServing)) &&
                          isFinite(costPerServing)
                          ? acc + Number(costPerServing)
                          : acc;
                      }, 0);
                    return (
                      <div
                        key={`recipe-${recipeIndex}`}
                        className="bg-white p-4 mt-4 rounded-lg shadow"
                      >
                        <h3 className="font-bold text-lg mb-4 text-gray-900">
                          {recipeName}
                        </h3>
                        {items.map((item, index) => {
                          const costPerServing = calculateCostPerServing(
                            item.unit_price,
                            item.unit_quantity,
                            item.unit_measure,
                            item.quantity,
                            item.measure
                          );
                          const unitPriceDetails =
                            item.unit_price &&
                            item.unit_quantity &&
                            item.unit_measure
                              ? `${t("yourcart.unitprice")}${
                                  item.unit_price
                                } / ${item.unit_quantity} ${pluralizeMeasure(
                                  item.unit_quantity,
                                  item.unit_measure
                                )}`
                              : "";
                          return (
                            <div
                              key={`item-${index}`}
                              className="flex flex-col md:flex-row justify-between items-center border-b border-gray-300 last:border-b-0 py-3"
                            >
                              <div className="flex flex-col md:flex-row md:items-center space-y-2 md:space-y-0 md:space-x-4 flex-grow">
                                <span className="text-gray-900 font-medium">
                                  {item.ingredient_name}
                                </span>
                                <span className="text-gray-700">
                                  {t("yourcart.quantity")} {item.quantity}{" "}
                                  {pluralizeMeasure(
                                    item.quantity,
                                    item.measure
                                      ? item.measure.replace(/_/g, " ")
                                      : ""
                                  )}
                                </span>
                                {unitPriceDetails && (
                                  <span className="text-sm text-gray-700">
                                    {renderCostOrMessage(costPerServing)}
                                  </span>
                                )}
                                {unitPriceDetails && (
                                  <span className="text-sm text-gray-700">
                                    {unitPriceDetails}
                                  </span>
                                )}
                              </div>
                              <div className="flex items-center space-x-2 mt-2 md:mt-0">
                                <button
                                  onClick={() => openSetUnitPriceModal(item.id)}
                                  className="text-sm bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out w-36"
                                >
                                  {t("yourcart.setunitprice")}
                                </button>
                                <button
                                  onClick={() => removeItemFromCart(item.id)}
                                  className="text-sm bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-150 ease-in-out w-36"
                                >
                                  {t("yourcart.remove")}
                                </button>
                              </div>
                            </div>
                          );
                        })}
                        <div className="flex flex-col md:flex-row justify-between items-center mt-4">
                          <span className="text-lg font-bold mt-4 md:mt-0 text-gray-900">
                            {t("yourcart.total")} {totalPrice.toFixed(2)}
                          </span>
                          <span className="text-lg font-bold mt-4 md:mt-0 text-gray-900">
                            {t("yourcart.totalserving")}{" "}
                            {totalCostPerServing.toFixed(2)}
                          </span>
                        </div>
                      </div>
                    );
                  }
                )
              ) : (
                <div className="text-center p-4">
                  <p className="text-gray-800">Your cart is currently empty.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourCart;
