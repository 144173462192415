import React, { useState, useEffect, useContext } from "react";
import "./App.css";
import Router from "./routes";
import RouterAuth from "./routesAuth";
import { supabase } from "./supabaseClient";
import { DataContext } from "./context/DataContext";
import { HelmetProvider } from "react-helmet-async";
import { I18nextProvider } from "react-i18next";
import i18n from "./ii8n.js";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

function App() {
  const [session, setSession] = useState(null);
  const [user, setUser] = useState(null);
  const { state, actions } = useContext(DataContext);

  const setLanguage = async (currentSession) => {
    let language = "en";
    if (currentSession?.user) {
      const response = await supabase
        .from("profiles")
        .select("language")
        .eq("id", currentSession.user.id)
        .single();

      if (response.error) {
        console.error("Error fetching language:", response.error);
      } else {
        language = response.data?.language || language;
      }
    } else {
      const storedLanguage = localStorage.getItem("i18nextLng");
      if (storedLanguage) {
        language = storedLanguage;
      }
    }
    i18n.changeLanguage(language);
  };

  const init = async () => {
    const {
      data: { session: currentSession },
    } = await supabase.auth.getSession();
    setSession(currentSession);
    setUser(currentSession?.user || null);
    await setLanguage(currentSession);
  };

  useEffect(() => {
    init();

    const { data: subscription } = supabase.auth.onAuthStateChange(
      async (_event, session) => {
        setSession(session);
        setUser(session?.user || null);
        await setLanguage(session);
      }
    );

    return () => {
      if (subscription && typeof subscription.unsubscribe === "function") {
        subscription.unsubscribe();
      }
    };
  }, []);
  return (
    <HelmetProvider>
      <I18nextProvider i18n={i18n}>
        <ScrollToTop />
        <div>{!session ? <RouterAuth /> : <Router />}</div>
      </I18nextProvider>
    </HelmetProvider>
  );
}

export default App;
