import { useRoutes } from "react-router-dom";
// layouts
import MainLayoutGuest from "./components/layout/MainLayoutGuest";
// pages
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import SignUp from "./pages/Signup";
import Landing from "./pages/Landing";
import PublicRecipes from "./pages/PublicRecipes";
import ViewPublicRecipe from "./pages/recipes/ViewPublicRecipe";
import ViewInvitationCircle from "./pages/circles/ViewInvitationCircle";
import NotFound from "./pages/Page404";
import GroupPlanInvites from "./pages/Group-meals-invitation";
import GroupMeals from "./pages/Group-meals";
export default function Router() {
  return useRoutes([
    {
      element: <MainLayoutGuest />,
      children: [
        { path: "/", element: <Landing /> },
        { path: "public-recipes", element: <PublicRecipes /> },
        { path: "log-in", element: <Login /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "reset-password", element: <ResetPassword /> },
        { path: "recipes-view/:url", element: <ViewPublicRecipe /> },
        { path: "circles-invitation/:id", element: <ViewInvitationCircle /> },
        {
          path: "circles-invitation/:id/:status",
          element: <ViewInvitationCircle />,
        },
        { path: "group-meals/:mealId", element: <GroupMeals /> },

        {
          path: "group-plan-invites/:mealId/:assignmentId",
          element: <GroupPlanInvites />,
        },
        { path: "sign-up", element: <SignUp /> },
        { path: "404", element: <NotFound /> },
      ],
    },
  ]);
}
