import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../components/common/registration.css";
import Footer from "../components/common/footer";
import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';


const MySwal = withReactContent(Swal);

const ForgotPassword = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation();  

  const [justifyClass, setJustifyClass] = useState("justify-start");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      const isMobileWidth = window.innerWidth < 768;
      setIsMobile(isMobileWidth);
      setJustifyClass(isMobileWidth ? "justify-start" : "justify-center");
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (error) {
      setError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && email !== "") {
      submitForgot(event);
    }
  };

  const submitForgot = async () => {
    if (email === "") {
      showError(t('forgotPW.enteremail'));
      return;
    }

    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${process.env.REACT_APP_URL}reset-password`,
    });

    if (error) {
      showError(error.message);
    } else {
      showSuccess();
      setSuccess(true);
    }
  };

  const showError = (message) => {
    MySwal.fire({
      icon: "error",
      title: "Error",
      text: message,
    });
  };

  const showSuccess = () => {
    MySwal.fire({
      icon: "success",
      title: t('forgotPW.success'), 
      text: t('forgotPW.successtext'),
      showConfirmButton: true,
    }).then(() => {
      navigate("/");
    });
  };
  

  return (
    <React.Fragment>
      <Helmet>
        <title>Forgot Password - RecipeCircle</title>
        <meta
          name="description"
          content="Forgot your RecipeCircle account password? Reset it here."
        />
        <meta property="og:title" content="Forgot Password - RecipeCircle" />
        <meta
          property="og:description"
          content="Forgot your RecipeCircle account password? Reset it here."
        />
        <meta property="og:type" content="website" />
      </Helmet>

      {!success ? (
        <div
          className={`flex flex-col items-center ${justifyClass} min-h-screen`}
        >
          <div className="flex flex-col-reverse md:flex-row w-full max-w-3xl mx-auto overflow-hidden shadow-xl rc mb-6">
            <div
              className="md:w-1/2 bg-center hidden md:block bg-cover md:pt-0"
              style={{
                backgroundImage: `url(${require("../assets/logo-recipe-circle.png")})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            ></div>
            <div className="w-full md:w-1/2 px-4 py-4 text-center bg-white rounded-b-lg relative">
              <h1 className="text-4xl font-bold">{t('forgotPW.forgotPW')}</h1>
              <h6 className="mt-3">
              {t('forgotPW.enterForm')}
              </h6>
              {error ? <span>{error}</span> : null}
              <div className="mb-4">
                <label
                  className="block text-left ml-1 text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  {t('forgotPW.email')}
                </label>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onKeyDown={handleKeyDown}
                  className="w-full shadow-inner appearance-none border rounded py-2 px-3 bg-gray-200 text-gray-700 leading-normal focus:outline-none focus:shadow-outline"
                  type="email"
                  name="email"
                  placeholder={t('forgotPW.email')}
                  required
                />
              </div>



              <div className="flex items-center justify-start">
                <div className="mt-4 mx-auto w-full">
                  <button
                    className="w-full btn btn-outline hover:bg-blue-500 onclick:bg-blue-500 focus:bg-blue-500 text-black hover:text-black focus:text-black"
                    type="submit"
                    onClick={submitForgot}
                  >
                    {t('forgotPW.reset')}
                  </button>
                </div>
              </div>

              <div className="divider"></div>
              <h6 className="mt-4">
              {t('forgotPW.remember')}{" "}
                <a
                  className="text-blue-500 cursor-pointer"
                  onClick={() => navigate("/log-in")}
                >
                  {t('forgotPW.login')}
                </a>
              </h6>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center min-h-screen"></div>
      )}
    </React.Fragment>
  );
};

export default ForgotPassword;
