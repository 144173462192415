import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

function CookieConsent() {
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleConsent = () => {
    localStorage.setItem("cookieConsent", "true");
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <section className=" sticky inset-x-0 bottom-0 z-50  bg-blue-600 text-zinc-100">
      <div className="container mx-auto flex flex-col items-center justify-center p-2 md:p-6 md:px-24 xl:px-48">
        <h1 className="text-3xl font-bold leading-none text-center mt-2">
          {t("cookie.consent")}
        </h1>
        <p className="pt-2 pb-4 mt-3 text-xl font-medium text-center">
          {t("cookie.continuing")}{" "}
          <a
            href="https://recipecircle.world/chefgnome-service-agreement/?v=f24485ae434a"
            target="_blank"
            rel="noopener noreferrer"
            className="text-sky-300 hover:text-sky-500 no-underline"
          >
            {t("cookie.policy")}
          </a>
          .
        </p>
        <button
          onClick={handleConsent}
          className="px-8 py-3 text-lg font-semibold rounded bg-gray-700 text-zinc-100 mb-2"
        >
          {t("cookie.confirm")}
        </button>
      </div>
    </section>
  );
}

export default CookieConsent;
