import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { GiExitDoor } from "react-icons/gi";
import { supabase } from "../../supabaseClient";
const LeaveGroupInvite = ({ assignmentId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLeave = async () => {
    Swal.fire({
      title: t("groupmeals.leave"),
      text: t("groupmeals.leaveconfirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc2626",
      cancelButtonColor: "#3b82f6",
      confirmButtonText: t("groupmeals.leaveyes"),
      cancelButtonText: t("groupmeals.cancel"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const {
          data: assignmentData,
          error: assignmentError,
        } = await supabase
          .from("other_group_meal_assignments")
          .select("email")
          .eq("id", assignmentId)
          .single();

        if (assignmentError) {
          Swal.fire(t("groupmeals.error"), t("groupmeals.error2"), "error");
        } else {
          const { error } = await supabase
            .from("other_group_meal_assignments")
            .delete()
            .match({ email: assignmentData.email });

          if (error) {
            Swal.fire(t("groupmeals.error"), t("groupmeals.error2"), "error");
          } else {
            Swal.fire(
              t("groupmeals.leavesuccess"),
              t("groupmeals.leavecont"),
              "success"
            ).then(() => {
              navigate("/");
            });
          }
        }
      }
    });
  };

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        handleLeave();
      }}
      className="btn btn-outline hover:bg-red-500"
    >
      <GiExitDoor className="w-7 h-7" />
    </button>
  );
};

export default LeaveGroupInvite;
