/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  BiLogoTiktok,
  BiLogoYoutube,
  BiLogoTwitter,
  BiLogoFacebook,
  BiLogoLinkedin,
  BiLogoMediumOld,
} from "react-icons/bi";
import dayjs from "dayjs";
import mainLogo from "../assets/universecircles.png";
import mainBannerRecipes from "../assets/banner-recipe-1.jpeg";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";
import { categoriesMain, categoriesDietary } from "../constants";
import { Helmet } from "react-helmet-async";
import { MdOutlinePublic, MdOutlinePrivateConnectivity } from "react-icons/md";
import ResponsiveNavbar from "../components/common/ResponsiveNavbar";
import { useTranslation } from "react-i18next";

const getCategoryLabel = (categoryKey) => {
  const predefinedCategory = categoriesMain.find(
    (cMain) => cMain.value === categoryKey
  );

  return predefinedCategory?.label || categoryKey;
};

const Landing = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [dataDisplay, setDataDisplay] = useState([]);
  const [session, setSession] = useState(null);
  const { t } = useTranslation();
  const iconStyle = { fontSize: "30px" };

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  useEffect(() => {
    const loadRecipes = async () => {
      let { data: dataRecipes, error: errorR, status: statusR } = await supabase
        .from("recipes")
        .select(
          `id, name, author, description, type, duration, servings, steps, image_url, created_at, public, owner_id, url,
        recipe_ingredients (
          name
        ),recipe_categories (
          category_key,
          type
        ),profiles (first_name, last_name)`
        )
        .eq("public", 1)
        .limit(4)
        .order("created_at", { ascending: false });

      if (errorR && statusR !== 406) {
        throw errorR;
      }
      setData(dataRecipes);
      setDataDisplay(dataRecipes);
    };

    loadRecipes();
  }, []);

  const handleView = (url) => {
    navigate(`/recipes-view/${url}`);
  };

  return (
    <>
      <Helmet>
        <title>RecipeCircle</title>
        <meta
          name="description"
          content="RecipeCircle.world is your go-to platform for saving, sharing recipes, and meal planning with friends and family. Create groups to plan meals together and discover new favorite recipes."
        />

        <meta
          property="og:title"
          content="RecipeCircle.world - Share and Plan Meals with Loved Ones"
        />
        <meta
          property="og:description"
          content="Discover, save, and share recipes. Plan meals with friends and family on RecipeCircle.world. Join now to start building your recipe collection and meal plans."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://app.recipecircle.world" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="RecipeCircle.world - Share and Plan Meals with Loved Ones"
        />
        <meta
          name="twitter:description"
          content="Join RecipeCircle.world to save, share recipes, and plan your meals with groups. Perfect for families and friends looking to explore new culinary delights together."
        />

        <meta name="twitter:url" content="https://app.recipecircle.world" />

        <link rel="canonical" href="https://app.recipecircle.world" />
        <meta
          name="keywords"
          content="recipe sharing, meal planning, recipe book, group meal planning, family recipes, recipe discovery"
        />
      </Helmet>
      <div className="">
        <div className="hero relative" style={{ height: 400 }}>
          <div
            className="absolute inset-0 bg-cover bg-center blur-sm overflow-hidden"
            style={{
              backgroundImage: `url(${process.env.REACT_APP_SUPABASE_BUCKET_URL}public/recipe-book-tablet.jpeg)`,
            }}
          >
            <div className="hero-overlay"></div>
          </div>

          <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-neutral-content">
            <div className="max-w-md">
              <h1 className="mb-5 text-6xl font-bold">RecipeCircle</h1>
              <p className="mb-5 text-2xl text-primary">
                {t("landingPage.slogan")}
              </p>
            </div>
          </div>
        </div>

        <div className="container mx-auto">
          <div className="mx-auto px-4 py-8 mt-4">
            <div className="flex max-lg:block gap-8">
              <div className="flex-1 bg-secondary text-neutral-content card shadow-xl p-4 mb-4 md:mb-0 lg:mb-0">
                <img
                  className="rounded mx-auto"
                  src={`${process.env.REACT_APP_SUPABASE_BUCKET_URL}public/recipe-book-pasta.jpeg`}
                  alt="recipe book"
                  style={{ height: 240 }}
                />

                <h1 className="text-center text-xl md:text-3xl lg:text-3xl mb-2 mt-2 text-accent">
                  {t("landingPage.tradition")}
                </h1>
                <div>
                  {t("landingPage.tranditiontext")} <b>recipecircle.world</b>.
                </div>
              </div>
              <div className="flex-1 bg-accent  text-neutral-content card shadow-xl p-4 mb-4 md:mb-0 lg:mb-0">
                <img
                  className="rounded mx-auto"
                  src={`${process.env.REACT_APP_SUPABASE_BUCKET_URL}public/relations.jpeg`}
                  alt="recipe book"
                  style={{ height: 240 }}
                />

                <h1 className="text-center text-xl md:text-3xl lg:text-3xl mb-2 mt-2 text-primary">
                  {t("landingPage.relationships")}
                </h1>
                <div>
                  {t("landingPage.relationshipstext1")} <b>recipecircle</b>{" "}
                  {t("landingPage.relationshipstext2")}.
                </div>
              </div>
              <div className="flex-1 bg-primary card shadow-xl p-4 mb-4 md:mb-0 lg:mb-0">
                <img
                  className="rounded mx-auto"
                  src={`${process.env.REACT_APP_SUPABASE_BUCKET_URL}public/recipe-book-digitizerecipes.jpg`}
                  alt="recipe book"
                  style={{ height: 240 }}
                />

                <h1 className="text-center text-xl md:text-3xl lg:text-3xl mb-2 mt-2 text-secondary">
                  {t("landingPage.privacy")}
                </h1>
                <div>
                  {t("landingPage.privacytext1")}
                  <b> recipecircle.world</b>. {t("landingPage.privacytext2")}.
                </div>
              </div>
            </div>
          </div>
          <h1 className="text-center text-2xl md:text-4xl lg:text-4xl mb-2 mt-2 pl-2 pr-2 text-accent">
            {t("landingPage.description")}
          </h1>
          <div className="mx-auto px-4 py-4">
            <div className="flex max-lg:block gap-8">
              <div className="flex-1 bg-base-200 card shadow-xl p-4">
                <img
                  className="rounded mx-auto"
                  src={`${process.env.REACT_APP_SUPABASE_BUCKET_URL}public/recipe-book-computer-recipe.jpeg`}
                  alt="recipe book"
                  style={{ height: 240 }}
                />

                <h1 className="text-center text-xl md:text-2xl lg:text-2xl mb-2 mt-2 text-secondary">
                  {t("landingPage.form")}
                </h1>
                <div>{t("landingPage.formtext")}</div>
              </div>
              <div className="flex-1 bg-base-200 card shadow-xl p-4">
                <img
                  className="rounded mx-auto"
                  src={`${process.env.REACT_APP_SUPABASE_BUCKET_URL}public/recipe-book-tablet.jpeg`}
                  alt="recipe book"
                  style={{ height: 240 }}
                />

                <h1 className="text-center text-xl md:text-2xl lg:text-2xl mb-2 mt-2 text-secondary">
                  {t("landingPage.link")}
                </h1>
                <div>
                  {t("landingPage.linktext1")} <b>recipecircle</b>{" "}
                  {t("landingPage.linktext2")}.
                </div>
              </div>
              <div className="flex-1 bg-base-200 card shadow-xl p-4">
                <img
                  className="rounded mx-auto"
                  src={`${process.env.REACT_APP_SUPABASE_BUCKET_URL}public/recipe-book-handwriting.jpeg`}
                  alt="recipe book"
                  style={{ height: 240 }}
                />

                <h1 className="text-center text-xl md:text-2xl lg:text-2xl mb-2 mt-2 text-secondary">
                  {t("landingPage.photo")}
                </h1>
                <div>
                  {t("landingPage.phototext")} <b>recipecircle.world</b>.
                </div>
              </div>
            </div>
          </div>
          <div className="mx-auto px-4 py-8">
            <h1 className="text-3xl mb-2 mt-8">{t("landingPage.explore")}</h1>
            <div className="divider"></div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              {dataDisplay.map((item) => (
                <div
                  className="card bg-white rounded-xl drop-shadow-xl border border-gray-300 overflow-hidden flex flex-col"
                  key={item.id}
                  onClick={(e) => handleView(item.url)}
                  style={{ cursor: "pointer" }}
                >
                  <figure
                    className="h-40 bg-cover bg-center"
                    style={{
                      backgroundImage: `url("${
                        item?.image_url
                          ? `${process.env.REACT_APP_SUPABASE_BUCKET_URL}${item.image_url}`
                          : mainBannerRecipes
                      }")`,
                    }}
                  ></figure>
                  <div className="card-body p-4 flex-grow">
                    <h2 className="card-title text-[#384E79]">{item.name}</h2>
                    <div className="text-xs text-gray-600">
                      {t("landingPage.recipeby")}{" "}
                      {item.author ||
                        `${item.profiles.first_name} ${item.profiles.last_name}`}
                    </div>
                    <div className="text-xs text-stone-800 pt-2 pb-2">
                      {t("landingPage.CreatedOn")}{" "}
                      {dayjs(item.created_at).format("MM/DD/YYYY")}
                    </div>
                    {item.type === 1 && (
                      <p className="text-sm text-gray-800">
                        {t("landingPage.duration")}{" "}
                        {item?.duration ? item?.duration : "-"}
                      </p>
                    )}
                    <div className="flex flex-wrap gap-2 my-2">
                      {item.recipe_categories
                        .filter((cItem) => cItem.type === 1)
                        .map((cItem2, index, array) => (
                          <span
                            className={`text-sm mr-1 ${
                              index < array.length - 1
                                ? "border-r border-gray-500 pr-2"
                                : ""
                            }`}
                            key={cItem2.category_key}
                          >
                            {getCategoryLabel(cItem2.category_key)}
                          </span>
                        ))}
                    </div>
                    <div className="flex flex-wrap gap-2 mb-2">
                      {item.recipe_categories
                        .filter((cItem) => cItem.type === 2)
                        .map((cItem2, index, array) => (
                          <span
                            className={`text-sm mr-1 ${
                              index < array.length - 1
                                ? "border-r border-gray-500 pr-2"
                                : ""
                            }`}
                            key={cItem2.category_key}
                          >
                            {
                              categoriesDietary.find(
                                (cMain) => cMain.value === cItem2.category_key
                              )?.label
                            }
                          </span>
                        ))}
                    </div>
                  </div>
                  <div className="absolute bottom-2 right-2 flex items-center">
                    {item.public ? (
                      <>
                        <MdOutlinePublic className="text-green-500" />
                        <span className="ml-1 text-xs text-green-500">
                          {t("landingPage.public")}
                        </span>
                      </>
                    ) : (
                      <>
                        <MdOutlinePrivateConnectivity className="text-stone-800" />
                        <span className="ml-1 text-xs text-stone-800">
                          {t("landingPage.private")}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
       
      </div>
    </>
  );
};

export default Landing;
