import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../context/DataContext";
import { MdArrowBackIosNew, MdAdd } from "react-icons/md";
import { Helmet } from "react-helmet-async";
import PlannedMealModal from "../components/utilities/PlannedMealModal";
import EditMealModal from "../components/utilities/EditMealModal";
import ScheduleMealModal from "../components/utilities/ScheduleMealModal";
import FinalizeMealModal from "../components/utilities/FinalizeMealModal.jsx";
import PlannedMealsDisplay from "../components/utilities/PlannedMealsDisplay";
import PastPlannedMealsDisplay from "../components/utilities/PastPlannedMealsDisplay";
import { useTranslation } from "react-i18next";
import { supabase } from "../supabaseClient.js";
import EditScheduleModal from "../components/utilities/EditScheduleMealModal.jsx";
import EditFinalizeMealModal from "../components/utilities/EditFinalizeMealModal.jsx";
import GroupPlannedMealsDisplay from "../components/utilities/GroupPlannedMealsDisplay.jsx";
import { MdOutlinePersonOutline, MdOutlineGroup } from "react-icons/md";
import GroupPlanModal from "../components/utilities/GroupPlanRecipesModal.jsx";
import ScheduleGroupMealModal from "../components/utilities/GroupPlanScheduleModal.jsx";
import FinalizeGroupMealModal from "../components/utilities/GroupPlanFinalizeModal.jsx";
import GroupPlanMealsDisplay from "../components/utilities/GroupPlanMealDisplay.jsx";
const PlanMeals = () => {
  const navigate = useNavigate();
  const { state } = useContext(DataContext);
  const { t } = useTranslation();

  const [addedRecipes, setAddedRecipes] = useState([]);
  const [plannedRecipes, setPlannedRecipes] = useState([]);
  const [currentPlannedMealsId, setCurrentPlannedMealsId] = useState(null);
  const [eventName, setEventName] = useState("");
  const [planName, setPlanName] = useState("");

  const [isSelectPlanOpen, setIsSelectPlanOpen] = useState(false);
  const openSelectPlanModal = () => {
    setIsSelectPlanOpen(true);
  };
  const closeSelectPlanModal = () => {
    setIsSelectPlanOpen(false);
  };

  const SelectPlanMeal = ({ isSelectPlanOpen, onSelectPlanClose }) => {
    return (
      <div
        className={`modal ${
          isSelectPlanOpen ? "modal-open" : ""
        } fixed w-full h-full top-0 left-0 flex items-center justify-center`}
      >
        <div
          className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
          onClick={onSelectPlanClose}
        ></div>

        <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-2xl  z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
            <div className="flex flex-col items-center pb-3">
              <p className="text-2xl font-bold text-center">{t("misc.plan")}</p>
            </div>

            <div className="mt-5">
              <div
                className="flex items-center rounded-lg hover:bg-gray-200 transition-colors cursor-pointer p-4"
                onClick={openModal}
              >
                <MdOutlinePersonOutline className="text-3xl text-[#384E79] mr-5" />
                <div className="flex-grow">
                  <p className="font-semibold">{t("misc.solo")}</p>
                  <p className="text-sm">
                  {t("misc.solotext")}
                  </p>
                </div>
              </div>
              <div
                className="flex items-center rounded-lg hover:bg-gray-200 transition-colors cursor-pointer p-4"
                onClick={openGroupModal}
              >
                <MdOutlineGroup className="text-3xl text-[#384E79] mr-5" />
                <div className="flex-grow">
                  <p className="font-semibold">{t("misc.group")}</p>
                  <p className="text-sm">
                  {t("misc.grouptext")}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex justify-end pt-2">
              <button
                className="modal-close px-4 bg-transparent p-3 rounded-lg text-stone-700 hover:bg-gray-300"
                onClick={onSelectPlanClose}
              >
                {t("recipe.close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const openScheduleModal = () => {
    setIsScheduleModalOpen(true);
  };
  const closeScheduleModal = () => {
    setIsScheduleModalOpen(false);
  };

  const [
    isScheduleGroupMealModalOpen,
    setIsScheduleGroupMealModalOpen,
  ] = useState(false);
  const openGroupScheduleModal = () => {
    setIsScheduleGroupMealModalOpen(true);
  };
  const closeScheduleGroupMealModal = () => {
    setIsScheduleGroupMealModalOpen(false);
  };

  const openModal = () => {
    setIsSelectPlanOpen(false);
    setIsModalOpen(true);
  };

  const [isGroupRecipeModalOpen, setIsGroupRecipeModalOpen] = useState(false);

  const openGroupModal = () => {
    setIsSelectPlanOpen(false);
    setIsGroupRecipeModalOpen(true);
  };
  const closeGroupModal = () => setIsGroupRecipeModalOpen(false);
  const closeModal = () => setIsModalOpen(false);

  const [
    isEditSchedulePlanModalOpen,
    setIsEditSchedulePlanModalOpen,
  ] = useState(false);

  const openEditSchedulePlanModal = async (plannedMealsId) => {
    let { data, error } = await supabase.rpc("get_plan_meal_details", {
      planned_meals_id_input: plannedMealsId,
    });

    if (error) {
      console.error("Error loading plan details:", error.message);
      return;
    }

    const planDetails = data.map((detail) => ({
      datePlanned: detail.plan_date_time.split("T")[0],
      timePlanned: detail.plan_date_time.split("T")[1].substring(0, 5),
      planDescription: detail.plan_description,
      recipes: detail.recipe_details,
    }));

    setScheduleDetails(planDetails);
    closeEditModal();
    setIsEditSchedulePlanModalOpen(true);
  };

  const closeEditSchedulePlanModal = () => {
    setIsEditSchedulePlanModalOpen(false);
  };

  const openEditModal = (plannedMealsId) => {
    setCurrentPlannedMealsId(plannedMealsId);
    setIsEditModalOpen(true);
  };
  const closeEditModal = () => setIsEditModalOpen(false);

  const [isFinalizeModalOpen, setIsFinalizeModalOpen] = useState(false);
  const openFinalizeModal = () => setIsFinalizeModalOpen(true);
  const closeFinalizeModal = () => {
    setIsFinalizeModalOpen(false);
    setPlannedRecipes([]);
    setAddedRecipes([]);
    setEventName("");
    setPlanName("");
  }

  const [
    isFinalizeGroupMealModalOpen,
    setIsFinalizeGroupMealModalOpen,
  ] = useState(false);

  const openFinalizeGroupMealModal = () =>
    setIsFinalizeGroupMealModalOpen(true);
  const closeFinalizeGroupMealModal = () => {
    setIsFinalizeGroupMealModalOpen(false);
    setPlannedRecipes([]);
    setAddedRecipes([]);
    setEventName("");
    setPlanName("");
  }



  const [scheduleDetails, setScheduleDetails] = useState([
    {
      datePlanned: "",
      timePlanned: "",
      eventDescription: "",
      planName: "",
      eventName: "",
      recipes: [{ recipeId: "" }],
    },
  ]);
  const [isEditFinalizeModalOpen, setIsEditFinalizeModalOpen] = useState(false);

  const normalizeRecipeIds = (details) => {
    return details.map((day) => ({
      ...day,
      recipes: day.recipes
        ? day.recipes.map((recipe) => ({
            ...recipe,
            recipeId: recipe.recipeId || recipe.id,
          }))
        : [],
    }));
  };

  const openEditFinalizeModal = (
    scheduleDetails,
    currentPlannedMealsId,
    newEventName
  ) => {
    const normalizedDetails = normalizeRecipeIds(scheduleDetails);
    setScheduleDetails(normalizedDetails);
    setCurrentPlannedMealsId(currentPlannedMealsId);
    setEventName(newEventName);
    setPlannedRecipes(plannedRecipes);
    setIsEditSchedulePlanModalOpen(false);
    setIsEditFinalizeModalOpen(true);
  };

  const closeEditFinalizeModal = () => {
    setIsEditFinalizeModalOpen(false);
    setPlannedRecipes([]);
    setAddedRecipes([]);
    setEventName("");
    setPlanName("");
  };

  return (
    <div>
      <Helmet>
        <title>RecipeCircle - Plan Meals</title>
        <meta
          name="description"
          content="View and manage your planned meals on RecipeCircle. Stay organized and prepared for your upcoming meals."
        />
        <meta property="og:title" content="RecipeCircle - Plan Meals" />
        <meta
          property="og:description"
          content="View and manage your planned meals on RecipeCircle. Stay organized and prepared for your upcoming meals."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://app.recipecircle.world/plan-meals"
        />
      </Helmet>
      <div className="w-100 container mx-auto">
        <div className="w-full overflow-x-hidden">
          <div className="text-center">
            <h1 className="text-4xl font-bold py-4 text-[#384E79]">
              {t("planmeals.planmeals")}
            </h1>
            <div className="breadcrumbs flex justify-center mb-1">
              <ul>
                <li>
                  <a
                    onClick={() => {
                      navigate("/");
                    }}
                    className="text-[#384E79] hover:text-blue-400"
                  >
                    {t("planmeals.home")}
                  </a>
                </li>
                <li className="text-stone-700">{t("planmeals.planmeals")}</li>
              </ul>
            </div>
          </div>

          <div className="flex justify-between items-center mb-4">
            <button
              className="btn btn-outline"
              onClick={() => {
                navigate("/");
              }}
            >
              <MdArrowBackIosNew />
            </button>

            <button
              className="btn btn-outline hover:bg-blue-400 text-stone-900"
              onClick={openSelectPlanModal}
            >
              {t("planmeals.planmeals")}
              <MdAdd className="w-5 h-5" />
            </button>
          </div>

          <div className="divider"></div>
        </div>

        <SelectPlanMeal
          isSelectPlanOpen={isSelectPlanOpen}
          onSelectPlanClose={closeSelectPlanModal}
        />

        <PlannedMealModal
          isOpen={isModalOpen}
          onClose={closeModal}
          user={state.user}
          addedRecipes={addedRecipes}
          setAddedRecipes={setAddedRecipes}
          onOpenScheduleModal={openScheduleModal}
        />

        <ScheduleMealModal
          isOpen={isScheduleModalOpen}
          onClose={closeScheduleModal}
          openFinalizeModal={openFinalizeModal}
          scheduleDetails={
            scheduleDetails.length > 0
              ? scheduleDetails
              : [
                  {
                    datePlanned: "",
                    timePlanned: "",
                    eventDescription: "",
                    recipes: [{ recipeId: "" }],
                  },
                ]
          }
          selectedRecipes={addedRecipes}
          setSelectedRecipes={setAddedRecipes}
          setScheduleDetails={setScheduleDetails}
          user={state.user}
        />

        <FinalizeMealModal
          isOpen={isFinalizeModalOpen}
          onClose={closeFinalizeModal}
          user={state.user}
          addedRecipes={addedRecipes}
          setAddedRecipes={setAddedRecipes}
          scheduleDetails={scheduleDetails}
          setScheduleDetails={setScheduleDetails}
        />

        <EditMealModal
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
          user={state.user}
          plannedMealsId={currentPlannedMealsId}
          plannedRecipes={plannedRecipes}
          setPlannedRecipes={setPlannedRecipes}
          onContinue={() => openEditSchedulePlanModal(currentPlannedMealsId)}
        />
        <EditScheduleModal
          isOpen={isEditSchedulePlanModalOpen}
          onClose={closeEditSchedulePlanModal}
          scheduleDetails={scheduleDetails}
          plannedMealsId={currentPlannedMealsId}
          setScheduleDetails={setScheduleDetails}
          openFinalizeEditModal={(
            localScheduleDetails,
            plannedMealsId,
            eventName
          ) =>
            openEditFinalizeModal(
              localScheduleDetails,
              plannedMealsId,
              eventName
            )
          }
          user={state.user}
          plannedRecipes={plannedRecipes}
          setPlannedRecipes={setPlannedRecipes}
        />

        <EditFinalizeMealModal
          isOpen={isEditFinalizeModalOpen}
          onClose={closeEditFinalizeModal}
          scheduleDetails={scheduleDetails}
          setScheduleDetails={setScheduleDetails}
          plannedMealsId={currentPlannedMealsId}
          user={state.user}
          eventName={eventName}
          plannedRecipes={plannedRecipes}
          setPlannedRecipes={setPlannedRecipes}
        />

        <GroupPlanModal
          isOpen={isGroupRecipeModalOpen}
          onClose={closeGroupModal}
          user={state.user}
          addedRecipes={addedRecipes}
          setAddedRecipes={setAddedRecipes}
          onOpenGroupScheduleModal={openGroupScheduleModal}
        />
        <ScheduleGroupMealModal
          isOpen={isScheduleGroupMealModalOpen}
          onClose={closeScheduleGroupMealModal}
          openFinalizeGroupMealModal={openFinalizeGroupMealModal}
          scheduleDetails={
            scheduleDetails.length > 0
              ? scheduleDetails
              : [
                  {
                    datePlanned: "",
                    timePlanned: "",
                    eventDescription: "",
                    recipes: [{ recipeId: "" }],
                  },
                ]
          }
          selectedRecipes={addedRecipes}
          setSelectedRecipes={setAddedRecipes}
          setScheduleDetails={setScheduleDetails}
          user={state.user}
        />

        <FinalizeGroupMealModal
          isOpen={isFinalizeGroupMealModalOpen}
          onClose={closeFinalizeGroupMealModal}
          user={state.user}
          addedRecipes={addedRecipes}
          setAddedRecipes={setAddedRecipes}
          scheduleDetails={scheduleDetails}
          setScheduleDetails={setScheduleDetails}
        />

        <PlannedMealsDisplay
          user={state.user}
          onOpenModal={openSelectPlanModal}
          onEditClick={openEditModal}
        />

        <GroupPlanMealsDisplay user={state.user?.id} />

        <GroupPlannedMealsDisplay user={state.user} />

        <PastPlannedMealsDisplay user={state.user} />
      </div>
    </div>
  );
};

export default PlanMeals;
