import React, { useContext, useEffect, useState, useRef } from "react";
import { supabase } from "../../supabaseClient";
import { useForm } from "react-hook-form";
import PhotoUploaderRecipe from "../../components/common/PhotoUploaderRecipe";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { DataContext } from "../../context/DataContext";
import DesktopMenu from "../../components/layout/DesktopLinkMenu";
import MobileMenu from "../../components/layout/MobileLinkMenu";
import { categoriesMain, categoriesDietary } from "../../constants";
import Swal from "sweetalert2";
import { BackIcon } from "../../components/common/Icons";
import "sweetalert2/dist/sweetalert2.min.css";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import {
  MdDelete,
  MdArrowCircleDown,
  MdArrowCircleUp,
  MdArrowBackIosNew,
  MdLightbulbOutline,
} from "react-icons/md";
import FormattingTips from "../../components/utilities/formattingtips";

const orderList = (list, value) => {
  return list.sort((a, b) => {
    if (a[value] < b[value]) {
      return -1;
    }
    if (a[value] > b[value]) {
      return 1;
    }
    return 0;
  });
};

const convertToUrlFormat = (str) => {
  return str.toLowerCase().replace(/ /g, "-").replace(/['"]/g, "");
};

const PreventEnterSubmit = () => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return null;
};

const NewRecipeLink = (props) => {
  const navigate = useNavigate();
  const { state } = useContext(DataContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [circles, setCircles] = useState([]);
  const [data, setData] = useState("");
  const [dataPublic, setDataPublic] = useState(false);
  const [dataSteps, setDataSteps] = useState([]);
  const [dataIngredients, setDataIngredients] = useState([]);
  const [dataBookmarks, setDataBookmarks] = useState([]);
  const [dataCategories, setDataCategories] = useState([]);
  const [dataCategoriesDietary, setDataCategoriesDietary] = useState([]);
  const [dataCircle, setDataCircle] = useState([]);
  const [dataType, setDataType] = useState(3);
  const [dataFile, setDataFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [unit, setUnit] = useState("imperial");
  const { t } = useTranslation();

  const [searchInput, setSearchInput] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [suggestedCategories, setSuggestedCategories] = useState([]);

  const allCategories = [...categoriesMain, ...categoriesDietary];

  const searchResultsRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        searchResultsRef.current &&
        !searchResultsRef.current.contains(event.target)
      ) {
        setSuggestedCategories([]);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleSearchInputChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);

    const filteredCategories = input
      ? allCategories.filter(
          (cat) =>
            cat.label.toLowerCase().includes(input.toLowerCase()) &&
            !selectedCategories.includes(cat.label)
        )
      : [];

    if (filteredCategories.length === 0 && input.trim() !== "") {
      setSuggestedCategories([{ label: input }]);
    } else {
      setSuggestedCategories(filteredCategories);
    }
  };

  const handleAddButtonClick = () => {
    if (searchInput.trim() !== "") {
      handleAddCategory(searchInput);
    }
  };

  const handleAddCategory = (category) => {
    if (!selectedCategories.includes(category)) {
      setSelectedCategories([...selectedCategories, category]);
      setSearchInput("");
      setSuggestedCategories([]);
    }
  };

  const handleRemoveCategory = (category) => {
    const updatedCategories = selectedCategories.filter(
      (cat) => cat !== category
    );
    setSelectedCategories(updatedCategories);
  };

  useEffect(() => {
    setSuggestedCategories([]);
  }, [selectedCategories]);

  useEffect(() => {
    const loadCircles = async () => {
      let {
        data: dataCircles,
        error: errorC,
        status: statusC,
      } = await supabase
        .from("circles")
        .select(`id,name, description, image_url`)
        .eq("owner_id", state?.user?.id);

      setCircles(dataCircles);
      if (errorC && statusC !== 406) {
        throw errorC;
      }
    };
    if (state?.user?.id) {
      loadCircles();
    }
  }, [state.user]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const processSubmit = async (dataInput) => {
    if (!state?.user?.id) {
      return false;
    }

    setData({ ...data, ...dataInput });
    setIsLoading(true);

    const {
      data: { user },
    } = await supabase.auth.getUser();

    let filePath = null;

    const dataInputBookmarks = dataBookmarks
      .filter((item) => item.value !== "")
      .map((item) => {
        return { value: item.value, position: item.position };
      });

    const dataInputSteps = dataSteps
      .filter((item) => item.value !== "")
      .map((item) => {
        return { value: item.value, position: item.position };
      });

    const date = new Date();

    const insert = {
      owner_id: user.id,
      name: dataInput.name,
      author: dataInput.author,
      description: dataInput.description,
      hack: dataInput.hack,
      duration: dataInput.duration,
      servings: dataInput.servings,
      steps: dataInputSteps,
      image_url: data.image_url,
      public: dataPublic,
      bookmarks: dataInputBookmarks,
      type: dataType,
      file: filePath,
      url:
        convertToUrlFormat(dataInput.name) +
        "-" +
        date.getFullYear() +
        date.getMonth(),
    };

    let { data: dataInsert, error, status } = await supabase
      .from("recipes")
      .insert(insert)
      .select();

    if (error && status !== 406) {
      throw error;
    }

    if (dataIngredients && dataIngredients.length) {
      const insertIngredients = dataIngredients
        .filter((item) => item.value !== "")
        .map((item) => {
          return {
            recipe_id: dataInsert[0].id,
            name: item.value,
            measure: item.measure,
            position: item.position,
            quantity: item.quantity,
          };
        });

      let { error: errorI, status: statusI } = await supabase
        .from("recipe_ingredients")
        .insert(insertIngredients);

      if (errorI && statusI !== 406) {
        console.error("Error inserting ingredients:", errorI);
        throw errorI;
      }
    }

    if (selectedCategories?.length) {
      const insertCategories = selectedCategories.map((item) => {
        return {
          recipe_id: dataInsert[0].id,
          category_key: item,
          type: 1,
        };
      });

      let { error: errorCat, status: statusCat } = await supabase
        .from("recipe_categories")
        .insert(insertCategories);

      if (errorCat && statusCat !== 406) {
        console.error("Error inserting categories:", errorCat);
        throw errorCat;
      }
    }

    if (dataCircle?.length) {
      // Insert Circles
      const insertCircles = dataCircle.map((item) => {
        return {
          recipe_id: dataInsert[0].id,
          circle_id: item,
        };
      });

      let { error: errorC, status: statusC } = await supabase
        .from("circle_recipes")
        .insert(insertCircles);
      if (errorC && statusC !== 406) {
        throw errorC;
      }
    }

    setSuccess(true);
    setIsLoading(false);
    setTimeout(() => {
      navigate("/recipes");
    }, 3000);
  };

  const handleFileSelect = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setDataFile(selectedFile);
    }
  };

  const handleCategory = (e, value) => {
    let updateCategories = selectedCategories;
    if (e.target.checked) {
      updateCategories = [...updateCategories, value];
    } else {
      updateCategories = updateCategories.filter((itemC) => itemC !== value);
    }
    setDataCategories(updateCategories);
  };

  const handleCategoryDietry = (e, value) => {
    let updateCategories = dataCategoriesDietary;
    if (e.target.checked) {
      updateCategories = [...updateCategories, value];
    } else {
      updateCategories = updateCategories.filter((itemC) => itemC !== value);
    }
    setDataCategoriesDietary(updateCategories);
  };

  const handleCircle = (e, value) => {
    let updateCircles = dataCircle;
    if (e.target.checked) {
      updateCircles = [...updateCircles, value];
    } else {
      updateCircles = updateCircles.filter((itemC) => itemC !== value);
    }
    setDataCircle(updateCircles);
  };

  const handleAddIngredient = () => {
    setDataIngredients([
      ...dataIngredients,
      { id: Date.now(), value: "", position: dataIngredients.length + 1 },
    ]);
  };

  const handleChangeIngredient = (e, id, field) => {
    e.preventDefault();
    const updateIngredients = dataIngredients.map((item) => {
      if (item.id === id) {
        return { ...item, [field]: e.target.value };
      }
      return item;
    });
    setDataIngredients(updateIngredients);
  };

  const handlePublicToggle = () => {
    setDataPublic((prev) => !prev);
  };

  const handleIngredientFieldChange = (e, id, field) => {
    e.preventDefault();
    const updatedIngredients = dataIngredients.map((ingredient) => {
      if (ingredient.id === id) {
        return { ...ingredient, [field]: e.target.value };
      }
      return ingredient;
    });
    setDataIngredients(updatedIngredients);
  };

  const handleUnitToggle = () => {
    setUnit((prevUnit) => (prevUnit === "imperial" ? "metric" : "imperial"));
  };

  const handleIngredientRemove = (e, id) => {
    e.preventDefault();
    const item = dataIngredients.find((item) => item.id === id);
    const pos = item.position;
    const updateIngredients = dataIngredients
      .filter((item) => item.id !== id)
      .map((item) => {
        if (item.position > pos) {
          return { ...item, position: item.position - 1 };
        }
        return item;
      });

    setDataIngredients(updateIngredients);
  };

  const handleIngredientMove = (e, id, direction) => {
    e.preventDefault();
    const item = dataIngredients.find((item) => item.id === id);
    const pos = item.position;
    let toPos = null;
    if (direction === "up") {
      toPos = pos - 1;
    } else {
      toPos = pos + 1;
    }

    const updateIngredients = dataIngredients.map((item) => {
      if (item.position === pos) {
        return { ...item, position: toPos };
      } else if (item.position === toPos) {
        return { ...item, position: pos };
      }
      return item;
    });
    setDataIngredients(updateIngredients);
  };

  const handleChangeStep = (e, id, value) => {
    e.preventDefault();
    const updateSteps = dataSteps.map((item) => {
      if (item.id === id) {
        return { ...item, value: value };
      }
      return item;
    });
    setDataSteps(updateSteps);
  };

  const handleStepRemove = (e, id) => {
    e.preventDefault();
    const item = dataSteps.find((item) => item.id === id);
    const pos = item.position;
    const updateSteps = dataSteps
      .filter((item) => item.id !== id)
      .map((item) => {
        if (item.position > pos) {
          return { ...item, position: item.position - 1 };
        }
        return item;
      });
    setDataSteps(updateSteps);
  };

  const handleDeleteLinkConfirmation = (e, bookmarkID) => {
    e.preventDefault();

    Swal.fire({
      title: t("createrecipes.deleteprompt"),
      text: t("createrecipes.deletelink"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc2626",
      cancelButtonColor: "#1e293b",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-outline",
        cancelButton: "btn btn-secondary",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleBookmarkRemove(e, bookmarkID);
        Swal.fire(t("yourcart.deleted"), t("yourcart.deletedtext"), "success");
      }
    });
  };

  const handleStepMove = (e, id, direction) => {
    e.preventDefault();
    const item = dataSteps.find((item) => item.id === id);
    const pos = item.position;
    let toPos = null;
    if (direction === "up") {
      toPos = pos - 1;
    } else {
      toPos = pos + 1;
    }

    const updateSteps = dataSteps.map((item) => {
      if (item.position === pos) {
        return { ...item, position: toPos };
      } else if (item.position === toPos) {
        return { ...item, position: pos };
      }
      return item;
    });
    setDataSteps(updateSteps);
  };

  const handleChangeBookmark = (e, id, value) => {
    e.preventDefault();
    const updateBookmarks = dataBookmarks.map((item) => {
      if (item.id === id) {
        return { ...item, value: value };
      }
      return item;
    });
    setDataBookmarks(updateBookmarks);
  };

  const handleBookmarkRemove = (e, id) => {
    e.preventDefault();
    const item = dataBookmarks.find((item) => item.id === id);
    const pos = item.position;
    const updateBookmarks = dataBookmarks
      .filter((item) => item.id !== id)
      .map((item) => {
        if (item.position > pos) {
          return { ...item, position: item.position - 1 };
        }
        return item;
      });
    setDataBookmarks(updateBookmarks);
  };

  const handleBookmarkMove = (e, id, direction) => {
    e.preventDefault();
    const item = dataBookmarks.find((item) => item.id === id);
    const pos = item.position;
    let toPos = null;
    if (direction === "up") {
      toPos = pos - 1;
    } else {
      toPos = pos + 1;
    }

    const updateBookmarks = dataBookmarks.map((item) => {
      if (item.position === pos) {
        return { ...item, position: toPos };
      } else if (item.position === toPos) {
        return { ...item, position: pos };
      }
      return item;
    });
    setDataBookmarks(updateBookmarks);
  };

  const onChangeImage = (image) => {
    setData({ ...data, image_url: image });
  };

  const handlePublic = () => {
    setDataPublic(!dataPublic);
  };

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1050);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="w-100 container mx-auto">
      <div>
        <Helmet>
          <title>RecipeCircle - Create Link Recipe</title>
          <meta
            name="description"
            content="Connect your recipe with external sources on RecipeCircle. Create a recipe that links to other pages for detailed instructions, videos, or related content."
          />
          <meta
            property="og:title"
            content="RecipeCircle - Create Linked Recipe"
          />
          <meta
            property="og:description"
            content="Connect your recipe with external sources on RecipeCircle. Create a recipe that links to other pages for detailed instructions, videos, or related content."
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content="https://app.recipecircle.world/recipes/newLink"
          />
        </Helmet>
        <div className="w-full overflow-x-hidden">
          <div className="text-center">
            <h1 className="text-4xl font-bold py-4 text-[#384E79] capitalize">
              {t("createrecipes.linkrecipe")}
            </h1>

            <div className="breadcrumbs flex justify-center mb-4">
              <ul>
                <li>
                  <a
                    onClick={() => {
                      navigate("/");
                    }}
                    className="text-[#384E79] hover:text-blue-400"
                  >
                    {t("profile.home")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/recipes");
                    }}
                    className="text-[#384E79] hover:text-blue-400"
                  >
                    {t("recipe.recipes")}
                  </a>
                </li>
                <li className="text-stone-700 capitalize">
                  {t("createrecipes.linkrecipe")}
                </li>
              </ul>
            </div>
          </div>
          <div className="flex-1">
            <button
              className="btn btn-outline"
              onClick={() => navigate(`/recipes`)}
            >
              <MdArrowBackIosNew />
            </button>
          </div>
          <div className="divider"></div>
        </div>
        <PreventEnterSubmit />
        <form
          className=""
          style={{ position: "relative" }}
          onSubmit={handleSubmit((data) => processSubmit(data))}
        >
          <div className="container w-full flex flex-wrap mx-auto px-2 pt-8 lg:pt-16 mt-16">
            {isMobileView ? <MobileMenu /> : <DesktopMenu />}

            <section className="w-full lg:w-4/5">
              <h1 className="capitalize flex items-center font-sans font-bold break-normal text-gray-700 px-2 text-xl mt-12 lg:mt-0 md:text-2xl">
                {t("createrecipes.linkrecipe")}
              </h1>

              <hr className="bg-gray-300 my-12" />
              <section id="overview" />
              <h2 className="font-sans font-bold break-normal text-gray-700 px-2 pb-8 text-xl">
                {t("createrecipes.overview")}
              </h2>

              <div className="p-8 mt-6 lg:mt-0 leading-normal rounded shadow bg-white">
                <FormattingTips />

                <div className="flex justify-start">
                  <div className="form-control">
                    <label className="label cursor-pointer">
                      <span className="label-text mr-4">
                        {dataPublic
                          ? t("createrecipes.publicrec")
                          : t("createrecipes.privaterec")}
                      </span>
                      <input
                        type="checkbox"
                        className="toggle"
                        checked={dataPublic}
                        onChange={handlePublicToggle}
                      />
                    </label>
                  </div>
                </div>
                <div class="grid gap-6 mb-6 md:grid-cols-2 mt-6">
                  <div>
                    <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                      {t("createrecipes.name")}
                    </label>
                    <input
                      type="text"
                      {...register("name", { required: true })}
                      className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                      required
                    />
                    {errors.name && (
                      <span className="text-accent text-sm mt-1">
                        {t("createrecipes.required")}
                      </span>
                    )}
                  </div>

                  <div>
                    <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                      {t("createrecipes.author")}
                    </label>
                    <input
                      type="text"
                      {...register("author", { required: true })}
                      className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                    />
                    {errors.author && (
                      <span className="text-accent text-sm mt-1">
                        {t("createrecipes.required")}
                      </span>
                    )}
                    {errors.name && (
                      <span className="text-accent text-sm mt-1">
                        {t("createrecipes.required")}
                      </span>
                    )}
                  </div>
                </div>

                <div>
                <label class=" mb-2 text-sm font-medium text-gray-900 dark:text-gray-900 flex items-center">
                    {t("createrecipes.description")}{" "}
                    <span
                      class="h-4 w-4 text-blue-500 ml-1 tooltip"
                      data-tip={t("markdown.enabled")}
                    >
                      <MdLightbulbOutline />
                    </span>
                  </label>
                  <textarea
                    {...register("description")}
                    className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                  />
                  {errors.description && (
                    <span className="text-accent text-sm mt-1">
                      {t("createrecipes.required")}
                    </span>
                  )}
                </div>

                <div class="grid gap-6 mb-6 md:grid-cols-2 mt-6">
                  <div>
                    <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                      {t("createrecipes.duration")}
                    </label>
                    <input
                      type="text"
                      {...register("duration")}
                      className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                    />
                  </div>
                  <div>
                    <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                      {t("createrecipes.servings")}
                    </label>
                    <input
                      type="text"
                      {...register("servings")}
                      className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                    />
                  </div>
                </div>
                <div>
                  <label class="flex mb-2 text-sm font-medium text-gray-900 dark:text-gray-900 items-center">
                    {t("createrecipes.hack")}{" "}
                    <span
                      class="h-4 w-4 text-blue-500 ml-1 tooltip"
                      data-tip="Markup enabled"
                    >
                      <MdLightbulbOutline />
                    </span>
                  </label>
                  <input
                    type="text"
                    {...register("hack")}
                    className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                  />
                  {errors.hack && (
                    <span className="text-accent text-sm mt-1">
                      {t("createrecipes.required")}
                    </span>
                  )}
                </div>
                <PhotoUploaderRecipe
                  onChangeImage={onChangeImage}
                  image={onChangeImage}
                />
              </div>
              <section id="links" />
              <h2 className="mt-4 mb-4 font-sans font-bold break-normal text-gray-700 px-2 pb- text-xl">
                {t("viewrecipe.links")}
              </h2>
              <div className="mt-6 flex flex-wrap rounded bg-white p-8 shadow lg:mt-0">
                {orderList(dataBookmarks, "position").map((bookmark, index) => (
                  <div
                    className="mb-6 grid grid-cols-1 gap-2 md:grid-cols-8 w-full"
                    key={`links-${bookmark.id}`}
                  >
                    {/* Mobile Layout */}
                    <div className="col-span-2 md:hidden">
                      <input
                        onChange={(e) =>
                          handleChangeBookmark(e, bookmark.id, e.target.value)
                        }
                        value={bookmark.value}
                        type="text"
                        placeholder=""
                        className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                      />
                    </div>
                    <div className="col-span-2 md:hidden mt-2">
                      <div className="flex gap-2">
                        <button
                          className="btn btn-outline flex-1 hover:bg-emerald-300 hover:text-black mb-2"
                          onClick={(e) => handleStepMove(e, bookmark.id, "up")}
                          disabled={bookmark.position === 1}
                        >
                          <MdArrowCircleUp className="text-4xl" />
                        </button>
                        <button
                          className="btn btn-outline flex-1 hover:bg-red-400 hover:text-black"
                          onClick={(e) =>
                            handleStepMove(e, bookmark.id, "down")
                          }
                          disabled={bookmark.position === dataSteps.length}
                        >
                          <MdArrowCircleDown className="text-4xl" />
                        </button>
                      </div>
                    </div>
                    <div className="col-span-2 md:hidden">
                      <button
                        className="btn btn-outline w-full mt-2 hover:bg-red-500"
                        onClick={(e) =>
                          handleDeleteLinkConfirmation(e, bookmark.id)
                        }
                      >
                        <MdDelete className="text-2xl" />
                        {t("createrecipes.remove")}
                      </button>
                    </div>

                    {/* Desktop Layout */}
                    <div className="md:col-span-1 hidden md:block">
                      <button
                        className="btn btn-outline w-full hover:bg-emerald-300 hover:text-black"
                        onClick={(e) =>
                          handleBookmarkMove(e, bookmark.id, "up")
                        }
                        disabled={bookmark.position === 1}
                      >
                        <MdArrowCircleUp className="text-4xl" />
                      </button>
                    </div>
                    <div className="col-span-2 md:col-span-3 lg:col-span-4 hidden md:block">
                      <input
                        onChange={(e) =>
                          handleChangeBookmark(e, bookmark.id, e.target.value)
                        }
                        value={bookmark.value}
                        type="text"
                        placeholder="Add a link from another site"
                        className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                      />
                    </div>
                    <div className="md:col-span-1 hidden md:block">
                      <button
                        className="btn btn-outline w-full hover:bg-red-400 hover:text-black"
                        onClick={(e) =>
                          handleBookmarkMove(e, bookmark.id, "down")
                        }
                        disabled={bookmark.position === dataBookmarks.length}
                      >
                        <MdArrowCircleDown className="text-4xl" />
                      </button>
                    </div>
                    <div className="md:col-span-2 md:block flex items-end">
                      <button
                        className="tracking-widest btn btn-outline hover:bg-red-500 mr-4 w-full"
                        onClick={(e) =>
                          handleDeleteLinkConfirmation(e, bookmark.id)
                        }
                      >
                        <MdDelete className="text-2xl " />
                        {t("createrecipes.remove")}
                      </button>
                    </div>
                  </div>
                ))}

                <div className="w-full flex justify-center">
                  <button
                    className="btn btn-outline w-full mt-6 tracking-widest"
                    type="button"
                    onClick={() =>
                      setDataBookmarks([
                        ...dataBookmarks,
                        {
                          id: Date.now(),
                          value: "",
                          position: dataBookmarks.length + 1,
                        },
                      ])
                    }
                  >
                    {t("createrecipes.addlink")}
                  </button>
                </div>
              </div>
              {/* link container end */}

              {/* category container start */}
              <section id="categories" />
              <h2 className="mt-4 mb-4 font-sans font-bold break-normal text-gray-700 px-2 pb- text-xl">
                {t("createrecipes.categories")}
              </h2>
              <div className="mt-6 flex flex-wrap rounded bg-white p-8 shadow lg:mt-0">
                <div className="relative mb-4 w-full">
                  <div className="flex items-center border border-gray-900 rounded-lg p-1 relative">
                    <input
                      type="text"
                      id="category-search"
                      value={searchInput}
                      onChange={handleSearchInputChange}
                      className="flex-1 w-full p-4 text-sm text-gray-900 rounded-lg dark:placeholder-gray-400 dark:text-gray-900 focus:outline-none focus:border-blue-700 focus:ring-1 focus:ring-blue-300"
                      placeholder={t("createrecipes.categoriestext")}
                    />
                    <button
                      type="button"
                      onClick={handleAddButtonClick}
                      className="btn-outline btn text-black focus:outline-none font-medium rounded-lg text-sm px-4 py-2 ml-2"
                    >
                      {t("createrecipes.add")}
                    </button>
                  </div>

                  {/* Shown Categories Display */}
                  {searchInput && suggestedCategories.length > 0 && (
                    <div
                      ref={searchResultsRef}
                      className="absolute mt-1 p-2 bg-white border border-gray-300 rounded-lg shadow-lg w-full z-10 max-h-44 overflow-y-auto"
                    >
                      {suggestedCategories.map((category) => (
                        <div
                          key={category.label}
                          className="cursor-pointer py-2 px-2 hover:bg-emerald-300 rounded-lg"
                          onClick={() => handleAddCategory(category.label)}
                        >
                          {category.label}
                        </div>
                      ))}
                    </div>
                  )}

                  {/* Selected Categories Display */}
                  {selectedCategories.length > 0 && (
                    <div className="mt-10 flex flex-wrap">
                      {selectedCategories.map((category) => (
                        <div
                          className="rounded-lg mr-2 mb-2 text-black p-3  border border-gray-700"
                          key={category}
                        >
                          {category}
                          <input
                            className="hidden"
                            type="checkbox"
                            readOnly
                            checked={selectedCategories.includes(
                              category.value
                            )}
                            defaultChecked
                          ></input>
                          <button
                            onClick={() => handleRemoveCategory(category)}
                            className="ml-2 text-s text-zinc-500 hover:text-red-500"
                          >
                            {t("createrecipes.remove")}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              {/* category container end */}
              {/* circles container start */}
              <section id="circles" />
              <h2 className="mt-4 mb-4 font-sans font-bold break-normal text-gray-700 px-2 pb- text-xl">
                {t("createrecipes.circles")}
              </h2>
              <div className="p-8 mt-6 lg:mt-0 rounded shadow bg-white flex flex-wrap">
                {circles.map((cir) => (
                  <div className="form-control mr-4" key={cir.id}>
                    <label className="label cursor-pointer">
                      <input
                        type="checkbox"
                        checked={dataCircle.includes(cir.id)}
                        className="h-5 w-5 accent-emerald-300 bg-emerald-300 rounded-lg cursor-pointer"
                        onChange={(e) => handleCircle(e, cir.id)}
                      />
                      <span className="ml-2 label-text ">{cir.name}</span>
                    </label>
                  </div>
                ))}
                <button className="w-full mt-8">
                  <input
                    type="submit"
                    className="btn btn-outline w-full tracking-widest"
                    disabled={isLoading || success}
                    value={t("createrecipes.saverec")}
                  />
                </button>
              </div>
              {/* category container end */}
            </section>
            {/*document end*/}
          </div>
        </form>
        {success ? (
          <div className="toast toast-top toast-end">
            <div className="alert alert-success">
              <div>
                <span>{t("createrecipes.recipecreated")}</span>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default NewRecipeLink;
