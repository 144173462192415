import React, { useState, useEffect, useCallback } from "react";
import { MdAdd, MdCheck } from "react-icons/md";
import { supabase } from "../../supabaseClient";
import { useTranslation } from "react-i18next";

const EditMealModal = ({
  isOpen,
  onClose,
  user,
  plannedMealsId,
  plannedRecipes,
  setPlannedRecipes,
  onContinue,
  recipesUpdateCounter,
}) => {
  const [recipes, setRecipes] = useState([]);
  const [selectedRecipeIds, setSelectedRecipeIds] = useState(
    new Set(plannedRecipes.map((recipe) => recipe.id))
  );
  const [isLoading, setIsLoading] = useState(true);
  const [eventName, setEventName] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const { t } = useTranslation();

  const handleAddRecipe = useCallback(
    (recipe) => {
      setPlannedRecipes((prevAddedRecipes) => {
        const isCurrentlySelected = prevAddedRecipes.some(
          (r) => r.id === recipe.id
        );

        setSelectedRecipeIds((prevSelectedIds) => {
          const newSelectedIds = new Set(prevSelectedIds);
          if (isCurrentlySelected) {
            newSelectedIds.delete(recipe.id);
          } else {
            newSelectedIds.add(recipe.id);
          }
          return newSelectedIds;
        });

        if (isCurrentlySelected) {
          return prevAddedRecipes.filter((r) => r.id !== recipe.id);
        } else {
          return [...prevAddedRecipes, recipe];
        }
      });
    },
    [setPlannedRecipes, setSelectedRecipeIds]
  );

  const fetchPreSelectedRecipes = async () => {
    if (!plannedMealsId) return;
    setIsLoading(true);
    try {
      const {
        data: mealRecipesData,
        error: mealRecipesError,
      } = await supabase
        .from("planned_meal_recipes")
        .select("recipe_id")
        .eq("planned_meals_id", plannedMealsId);

      if (mealRecipesError) throw new Error(mealRecipesError.message);

      const uniqueRecipeIds = [
        ...new Set(mealRecipesData.map((meal) => meal.recipe_id)),
      ];

      const { data: recipesData, error: recipesError } = await supabase
        .from("recipes")
        .select("id, name, author, owner_id")
        .in("id", uniqueRecipeIds);

      if (recipesError) throw new Error(recipesError.message);

      setPlannedRecipes(recipesData);
      setSelectedRecipeIds(new Set(recipesData.map((recipe) => recipe.id)));
    } catch (error) {
      console.error("Error fetching pre-selected recipes:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && plannedMealsId) {
      fetchPreSelectedRecipes();
    }
  }, [isOpen, plannedMealsId, recipesUpdateCounter]);

  useEffect(() => {
    const debounceTimeout = setTimeout(async () => {
      setIsLoading(true);
      if (user) {
        let { data: recipesData, error: recipesError } = await supabase
          .from("recipes")
          .select("id, name, author, owner_id")
          .eq("owner_id", user.id);

        if (searchInput) {
          const { data: categoryMatchData } = await supabase
            .from("recipe_categories")
            .select("recipe_id")
            .ilike("category_key", `%${searchInput}%`);

          const categoryMatchRecipeIds = categoryMatchData.map(
            (c) => c.recipe_id
          );
          recipesData = recipesData.filter(
            (recipe) =>
              recipe.name.toLowerCase().includes(searchInput.toLowerCase()) ||
              categoryMatchRecipeIds.includes(recipe.id)
          );
        }

        if (recipesError) {
          console.error("Error fetching user recipes:", recipesError);
        } else {
          setRecipes(recipesData);
        }
      }
      setIsLoading(false);
    }, 300);
    return () => clearTimeout(debounceTimeout);
  }, [user, searchInput, recipesUpdateCounter]);

  useEffect(() => {
    const fetchEventName = async () => {
      if (!plannedMealsId) return;
      setIsLoading(true);
      try {
        const { data, error } = await supabase
          .from("planned_meals")
          .select("plan_name")
          .eq("id", plannedMealsId)
          .single();

        if (error) {
          throw new Error(error.message);
        }

        if (data) {
          setEventName(data.plan_name);
        }
      } catch (error) {
        console.error("Error fetching event name:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchEventName();
  }, [plannedMealsId]);

  return (
    <div
      className={`modal ${
        isOpen ? "modal-open" : ""
      } fixed w-full h-full top-0 left-0 flex items-center justify-center`}
    >
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={onClose}
      ></div>
      <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-xl z-50 overflow-y-auto">
        <div className="flex flex-col items-center pb-3">
          <p className="text-2xl font-bold text-center mt-5">
            {t("viewcircle.edit")} {eventName ? `${eventName}` : ""}{" "}
            {t("dashboard.plannedmeals")}
          </p>
        </div>
        <div className="modal-content overflow-y-auto max-h-[calc(100vh-200px)] px-4 mt-5">
          <div className="w-full flex justify-center mb-3">
            <input
              type="text"
              className="input border border-gray-900 focus:outline-none focus:border-blue-500 w-full max-w-xs"
              placeholder={t("groupplans.search")}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
          {isLoading ? (
            <div className="flex justify-center">
              <span className="loading loading-spinner loading-lg"></span>
            </div>
          ) : recipes.length > 0 ? (
            recipes.map((recipe) => (
              <div
                key={recipe.id}
                className="flex justify-between items-center p-4 border-b border-gray-200"
              >
                <div>
                  <p className="text-lg font-semibold">{recipe.name}</p>
                  <p className="text-sm text-gray-500 italic">
                    {recipe.author}
                  </p>
                </div>
                <button
                  className={`btn ${
                    plannedRecipes.some((r) => r.id === recipe.id)
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "btn-outline hover:bg-blue-400"
                  }`}
                  onClick={() => handleAddRecipe(recipe)}
                >
                  {plannedRecipes.some((r) => r.id === recipe.id) ? (
                    <>
                      <MdCheck /> {t("groupplans.added")}
                    </>
                  ) : (
                    <>
                      <MdAdd /> {t("groupplans.add")}
                    </>
                  )}
                </button>
              </div>
            ))
          ) : (
            <div className="text-center text-gray-700">
              {t("groupplans.norec")}
            </div>
          )}
        </div>
        <div className="flex justify-between items-center p-4">
          <button
            className="modal-close px-4 bg-transparent p-3 rounded-lg text-stone-700 hover:bg-gray-300"
            onClick={onClose}
          >
            {t("groupplans.close")}
          </button>
          <button
            className="btn bg-green-600 text-white hover:bg-green-700"
            disabled={plannedRecipes.length === 0}
            onClick={onContinue}
          >
            {t("groupplans.continue")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditMealModal;
