import React, { createContext, useReducer, useCallback } from "react";

// Define the initial state
const initialState = {
  user: null,
  lastMealUpdate: null,
  lastCommentUpdate: null,
  session: null,
  access_token: null,
};

// Define the reducer function
const reducer = (state, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload.user,
        access_token: action.payload.access_token,
      };
      case "SET_SESSION":
        return {
          ...state,
          session: action.payload,
          access_token: action.payload.access_token,
        };
    case "REFRESH_MEALS":
      return { ...state, lastMealUpdate: action.payload };
    case "REFRESH_COMMENTS":
      return { ...state, lastCommentUpdate: action.payload };
    default:
      return state;
  }
};

// Create the context
export const DataContext = createContext();

// Create a provider component
export const DataContextProvider = ({ children }) => {
  // Use the reducer hook to manage state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Actions
  const setUser = useCallback(
    (user, access_token) => {
      dispatch({ type: "SET_USER", payload: { user, access_token } });
    },
    [dispatch]
  );

  const refreshMeals = useCallback(() => {
    dispatch({ type: "REFRESH_MEALS", payload: Date.now() });
  }, [dispatch]);

  const refreshComments = useCallback(() => {
    dispatch({ type: "REFRESH_COMMENTS", payload: Date.now() });
  }, [dispatch]);

  const setSession = useCallback((session) => {
    dispatch({
      type: "SET_SESSION",
      payload: session,
    });
    if (session) {
      dispatch({
        type: "SET_USER",
        payload: { user: session.user },
      });
    }
  }, [dispatch]);

  const contextValue = React.useMemo(
    () => ({
      state,
      actions: { setUser, setSession, refreshMeals, refreshComments },
    }),
    [state, setSession, setUser, refreshMeals, refreshComments]
  );

  return (
    <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>
  );
};
