import React, { useContext } from "react";
import { DataContext } from "../../context/DataContext";
import { supabase } from "../../supabaseClient";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const DeletePlannedMeal = ({ plannedMealsId }) => {
  const { actions } = useContext(DataContext);
  const { t } = useTranslation();
  const handleDelete = async () => {
    Swal.fire({
      title: t("planmeals.deleteplan"),
      text: t("planmeals.deleteplantext"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3b82f6",
      cancelButtonColor: "#dc2626",
      confirmButtonText: t("planmeals.proceed"),
      cancelButtonText: t("planmeals.cancel"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { data, error } = await supabase
          .from("planned_meals")
          .delete()
          .match({ id: plannedMealsId });

        if (error) {
          console.error("Error deleting planned meal:", error);
          Swal.fire(t("planmeals.failure"), t("planmeals.failuretext"), "error");
        } else {
          Swal.fire(
            t("planmeals.deleted"),
            t("planmeals.deletedtext"),
            "success"
          ).then(() => {
            actions.refreshMeals();
          });
        }
      }
    });
  };

  return (
    <li className="hover:bg-sky-100">
      <button
        onClick={(e) => {
          e.preventDefault();
          handleDelete();
        }}
      >
        {t("planmeals.delete")}
      </button>
    </li>
  );
};


export default DeletePlannedMeal;
