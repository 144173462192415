import React, { useState, useRef } from "react";
import { supabase } from "../../supabaseClient";
import mainLogo from "../../assets/logo-recipe-book.png";

const PhotoUploader = ({ placeholderUrl, image, onChangeImage }) => {
  const [previewUrl, setPreviewUrl] = useState(
    placeholderUrl ?? image
      ? `${process.env.REACT_APP_SUPABASE_BUCKET_URL}${image}`
      : mainLogo
  );
  const fileInputRef = useRef(null);

  const handlePhotoSelect = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const selectedPhoto = event.target.files[0];
      const extension = selectedPhoto.name.split(".").pop();

      const { data: dataUpload, error } = await supabase.storage
        .from("recipe-files")
        .upload(
          `private/profile-logo/avatar-${Date.now()}${
            extension ? `.${extension}` : ""
          }`,
          selectedPhoto
        );

      if (error) {
        throw error;
      }

      onChangeImage(dataUpload.path);

      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(selectedPhoto);
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div
      style={{
        position: "relative",
        margin: "0 auto",
      }}
    >
      <div className="avatar mt-4">
        <div className="w-24 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
          <img
            src={previewUrl}
            alt="Preview"
            style={{
              maxHeight: "100%",
              cursor: "pointer",
            }}
            onClick={handleClick}
          />
        </div>
      </div>
      <input
        type="file"
        accept="image/*"
        onChange={handlePhotoSelect}
        style={{ display: "none" }}
        ref={fileInputRef}
      />
      <button
        className="bg-base-200 btn btn-circle btn-outline btn-xs"
        style={{
          position: "absolute",
          right: 0,
          top: 10,
          cursor: "pointer",
          padding: 4,
        }}
        onClick={handleClick}
      >
        <svg fill="none" viewBox="0 0 15 15">
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M1.903 7.297c0 3.044 2.207 5.118 4.686 5.547a.521.521 0 11-.178 1.027C3.5 13.367.861 10.913.861 7.297c0-1.537.699-2.745 1.515-3.663.585-.658 1.254-1.193 1.792-1.602H2.532a.5.5 0 010-1h3a.5.5 0 01.5.5v3a.5.5 0 01-1 0V2.686l-.001.002c-.572.43-1.27.957-1.875 1.638-.715.804-1.253 1.776-1.253 2.97zm11.108.406c0-3.012-2.16-5.073-4.607-5.533a.521.521 0 11.192-1.024c2.874.54 5.457 2.98 5.457 6.557 0 1.537-.699 2.744-1.515 3.663-.585.658-1.254 1.193-1.792 1.602h1.636a.5.5 0 110 1h-3a.5.5 0 01-.5-.5v-3a.5.5 0 111 0v1.845h.002c.571-.432 1.27-.958 1.874-1.64.715-.803 1.253-1.775 1.253-2.97z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    </div>
  );
};

export default PhotoUploader;
