import React, { useState, useEffect } from "react";
import { supabase } from "../../supabaseClient";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { MdOutlineRemove } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const EditFinalizeMealModal = ({
  isOpen,
  onClose,
  circleId,
  addedRecipes,
  setAddedRecipes,
  scheduleDetails,
  reloadTrigger,
  groupPlansId,
  setReloadTrigger,
}) => {
  const [circleMembers, setCircleMembers] = useState([]);
  const [existingAssignments, setExistingAssignments] = useState([]);
  const [assignments, setAssignments] = useState(
    scheduleDetails.map((detail) => ({
      datePlanned: detail.datePlanned,
      timePlanned: detail.timePlanned,
      eventDescription: detail.eventDescription,
      recipes: [],
    }))
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handlePlanMealsClick = () => {
    navigate("/plan-meals");
  };
  useEffect(() => {
    setAssignments(
      scheduleDetails.map((detail) => ({
        datePlanned: detail.datePlanned,
        timePlanned: detail.timePlanned,
        eventDescription: detail.eventDescription,
        recipes: [{ recipeId: "", memberId: "" }],
      }))
    );
  }, [scheduleDetails]);

  useEffect(() => {
    const fetchExistingAssignments = async () => {
      if (!isOpen) return;
      try {
        let { data, error } = await supabase
          .from("group_planned_meals")
          .select("id, user_id, recipe_id, event_date_time, event_description")
          .eq("circle_id", circleId);

        if (error) throw error;
        setExistingAssignments(data);
      } catch (error) {
        console.error("Error fetching existing assignments:", error.message);
      }
    };

    fetchExistingAssignments();
  }, [isOpen, circleId]);

  useEffect(() => {
    const matchedAssignments = scheduleDetails.map((detail) => {
      const dateTimeKey = `${detail.datePlanned}T${detail.timePlanned}:00`;
      const matchingExistingAssignments = existingAssignments.filter(
        (assignment) =>
          assignment.event_date_time.startsWith(dateTimeKey) &&
          assignment.event_description === detail.eventDescription
      );

      const recipes = matchingExistingAssignments.map((assignment) => ({
        recipeId: assignment.recipe_id,
        memberId: assignment.user_id,
      }));

      return {
        ...detail,
        recipes:
          recipes.length > 0 ? recipes : [{ recipeId: "", memberId: "" }],
      };
    });

    setAssignments(matchedAssignments);
  }, [scheduleDetails, existingAssignments]);

  const handleAddRecipeForMember = (detailIndex) => {
    setAssignments((currentAssignments) => {
      return currentAssignments.map((assignment, index) => {
        if (index === detailIndex) {
          const updatedAssignment = { ...assignment };
          updatedAssignment.recipes = [
            ...updatedAssignment.recipes,
            { recipeId: "", memberId: "" },
          ];
          return updatedAssignment;
        }
        return assignment;
      });
    });
  };

  const handleRemoveRecipe = (detailIndex, recipeIndex) => {
    setAssignments((currentAssignments) => {
      const newAssignments = [...currentAssignments];
      const updatedRecipes = newAssignments[detailIndex].recipes.filter(
        (_, index) => index !== recipeIndex
      );
      newAssignments[detailIndex].recipes = updatedRecipes;
      return newAssignments;
    });
  };

  const fetchCircleMembersProfileInfo = async (circleId) => {
    try {
      let { data: circleMembers, error: membersError } = await supabase
        .from("circle_members")
        .select(
          `
              user_id,
              profiles (
                id,
                first_name,
                last_name,
                email
              )
            `
        )
        .eq("circle_id", circleId)
        .eq("status", 1);

      if (membersError) throw membersError;

      let { data: circle, error: circleError } = await supabase
        .from("circles")
        .select(
          `
              owner_id,
              profiles!inner (
                id,
                first_name,
                last_name,
                email
              )
            `
        )
        .eq("id", circleId)
        .maybeSingle();

      if (circleError) throw circleError;

      if (circle && circle.profiles) {
        const ownerProfile = {
          user_id: circle.owner_id,
          profiles: circle.profiles,
        };
        circleMembers.push(ownerProfile);
      }

      const formattedMembers = circleMembers
        .filter(({ user_id }) => user_id !== null)
        .map(({ user_id, profiles }) => {
          let displayName = "Pending Member";
          if (profiles) {
            displayName =
              profiles.first_name && profiles.last_name
                ? `${profiles.first_name} ${profiles.last_name}`
                : profiles.email;
          }

          return {
            id: user_id,
            name: displayName,
          };
        });

      return formattedMembers;
    } catch (error) {
      console.error("Error fetching circle members' profiles:", error);
      return [];
    }
  };

  useEffect(() => {
    if (addedRecipes.length > 0) {
      const initialAssignments = addedRecipes.map((recipe) => ({
        datePlanned: "",
        timePlanned: "",
        recipes: [{ recipeId: recipe.id, memberId: "" }],
      }));
      setAssignments(initialAssignments);
    }
  }, [addedRecipes]);

  const handleAssignmentChange = (detailIndex, recipeIndex, memberId) => {
    setAssignments((currentAssignments) => {
      const updatedAssignments = [...currentAssignments];
      updatedAssignments[detailIndex].recipes[recipeIndex].memberId = memberId;
      return updatedAssignments;
    });
  };

  useEffect(() => {
    const loadCircleMembers = async () => {
      if (circleId) {
        const members = await fetchCircleMembersProfileInfo(circleId);
        setCircleMembers(members);
      }
    };

    loadCircleMembers();
  }, [circleId]);

  const handleRecipeSelection = (
    detailIndex,
    recipeIndex,
    selectedRecipeId
  ) => {
    setAssignments((currentAssignments) =>
      currentAssignments.map((detail, idx) => {
        if (idx === detailIndex) {
          const updatedRecipes = detail.recipes.map((recipe, rIdx) => {
            if (rIdx === recipeIndex) {
              return { ...recipe, recipeId: selectedRecipeId };
            }
            return recipe;
          });
          return { ...detail, recipes: updatedRecipes };
        }
        return detail;
      })
    );
  };

  const handleFinalize = async () => {
    try {
      const groupPlanId = groupPlansId;

      const { error: deleteError } = await supabase
        .from("group_planned_meals")
        .delete()
        .eq("group_plans_id", groupPlanId);

      if (deleteError) {
        console.error(
          "Error deleting existing meal assignments:",
          deleteError.message
        );
        return;
      }

      const mealAssignments = assignments.flatMap((detail) =>
        detail.recipes.map((recipe) => ({
          group_plans_id: groupPlanId,
          recipe_id: recipe.recipeId,
          user_id: recipe.memberId,
          circle_id: circleId,
          event_date_time: `${detail.datePlanned}T${detail.timePlanned}:00`,
          event_description: detail.eventDescription,
        }))
      );

      if (mealAssignments.length > 0) {
        const { error: mealAssignmentsError } = await supabase
          .from("group_planned_meals")
          .insert(mealAssignments);

        if (mealAssignmentsError) {
          console.error(
            "Error inserting meal assignments:",
            mealAssignmentsError.message
          );
          return;
        }

        Swal.fire({
          title: t("planmeals.success"),
          text: t("groupplans.gpsuccesstext"),
          icon: "success",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            setAddedRecipes([]);
            onClose();
            setReloadTrigger((prevReloadTrigger) => prevReloadTrigger + 1);
          }
        });
      } else {
        console.error("No meal assignments data to insert.");
      }
    } catch (error) {
      console.error("Error in handleFinalize:", error.message);
    }
  };

  return (
    <div
      className={`modal ${
        isOpen ? "modal-open" : ""
      } fixed inset-0 flex items-center justify-center`}
    >
      <div
        className="modal-overlay absolute inset-0 bg-gray-900 opacity-50"
        onClick={onClose}
      ></div>
      <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-xl z-50 shadow-lg overflow-hidden">
        <div className="p-5">
          <h2 className="text-2xl font-bold text-center mb-4">
            {t("groupplans.finalizetext")}
          </h2>
          <p className="text-center text-sm">
            {t("misc.atthistime")}
            <span
              onClick={handlePlanMealsClick}
              className="text-blue-400 hover:cursor-pointer"
            >
              {" "}
              {t("dashboard.planmeals")}
            </span>
            .
          </p>
          <div className="modal-content overflow-y-auto max-h-[calc(100vh-200px)] px-4">
            <div className="max-w-4xl mx-auto">
              <div className="space-y-4">
                {assignments.map((detail, detailIndex) => (
                  <div
                    key={detailIndex}
                    className="p-6 bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out"
                  >
                    <h3 className="text-2xl font-bold text-gray-800 mb-4">
                      {`${detail.eventDescription} ${t("groupplans.on")} ${
                        detail.datePlanned
                      } ${t("groupplans.at")} ${detail.timePlanned}:`}
                    </h3>{" "}
                    {detail.recipes.map((recipe, recipeIndex) => (
                      <div
                        key={recipeIndex}
                        className="flex items-center justify-between gap-6 mb-4"
                      >
                        <div className="flex-1 min-w-0">
                          <select
                            className="form-select block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            value={recipe.recipeId || ""}
                            onChange={(e) =>
                              handleRecipeSelection(
                                detailIndex,
                                recipeIndex,
                                e.target.value
                              )
                            }
                          >
                            {!recipe.recipeId && (
                              <option disabled value="">
                                {t("groupplans.selectrec")}
                              </option>
                            )}
                            {addedRecipes.map((addedRecipe) => (
                              <option
                                key={addedRecipe.id}
                                value={addedRecipe.id}
                              >
                                {addedRecipe.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="flex-1 min-w-0">
                          <select
                            className="form-select block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                            value={recipe.memberId}
                            onChange={(e) =>
                              handleAssignmentChange(
                                detailIndex,
                                recipeIndex,
                                e.target.value
                              )
                            }
                          >
                            {!recipe.memberId && (
                              <option disabled value="">
                                {t("groupplans.assignmem")}
                              </option>
                            )}
                            {circleMembers.map((member) => (
                              <option key={member.id} value={member.id}>
                                {member.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <button
                          onClick={() =>
                            handleRemoveRecipe(detailIndex, recipeIndex)
                          }
                          className="ml-4 text-red-600 hover:text-red-800 transition-colors duration-150"
                        >
                          <MdOutlineRemove className="h-8 w-8" />
                        </button>
                      </div>
                    ))}
                    <div className="text-center">
                      <button
                        onClick={() => handleAddRecipeForMember(detailIndex)}
                        className="btn btn-outline inline-block px-6 py-2.5 text-stone-800 font-medium text-xs leading-tight uppercase rounded hover:bg-blue-700 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active shadow-lg transition duration-150 ease-in-out"
                      >
                        {detail.recipes.length > 0
                          ? t("groupplans.addanotherrec")
                          : t("groupplans.addrec")}
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <button
              onClick={handleFinalize}
              className="btn bg-green-600 text-white hover:bg-green-700"
            >
              {t("groupplans.finalize")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditFinalizeMealModal;
