import React from 'react';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

const MarkdownContent = ({ content }) => {
  const renderer = new marked.Renderer();
  renderer.paragraph = text => `<div>${text}</div>`;
  renderer.listitem = text => `<li>${text}</li>`;
  renderer.image = () => '';

  marked.use({ renderer });

  const createMarkup = (markdown) => {
    const rawMarkup = marked(markdown);
    const sanitizedMarkup = DOMPurify.sanitize(rawMarkup);
    return { __html: sanitizedMarkup };
  };

  return <div className="markdown-content" dangerouslySetInnerHTML={createMarkup(content)} />;
};

export default MarkdownContent;
