import { useContext } from "react";
import { DataContext } from "../../context/DataContext";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { GiExitDoor } from "react-icons/gi";
import { supabase } from "../../supabaseClient";
const LeaveGroupMeal = ({ groupId, userId }) => {
  const { actions } = useContext(DataContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLeave = async () => {
    Swal.fire({
      title: t("groupmeals.leave"),
      text: t("groupmeals.leaveconfirm"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc2626",
      cancelButtonColor: "#3b82f6",
      confirmButtonText: t("groupmeals.leaveyes"),
      cancelButtonText: t("groupmeals.cancel"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { data, error } = await supabase
          .from("other_group_meal_assignments")
          .delete()
          .match({ other_group_meals_id: groupId, user_id: userId });

        if (error) {
          Swal.fire(
            t("groupmeals.error"), 
            t("groupmeals.error2"), 
            "error");
        } else {
          Swal.fire(
            t("groupmeals.leavesuccess"),
            t("groupmeals.leavecont"),
            "success"
          ).then(() => {
            if (userId) {
              navigate("/plan-meals");
            }
          });
        }
      }
    });
  };

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        handleLeave();
      }}
      className="btn btn-outline hover:bg-red-500"
    >
      <GiExitDoor className="w-7 h-7" />
    </button>
  );
};

export default LeaveGroupMeal;
