import React, { useEffect, useState, useContext } from "react";
import { supabase } from "../../supabaseClient";
import dayjs from "dayjs";
import WriteComment from "./WriteCommentsMeal";
import Swal from "sweetalert2";
import ProfilePicture from "../../assets/logo-recipe-book.png";
import { DataContext } from "../../context/DataContext";
import {
  MdOutlineReply,
  MdVisibility,
  MdVisibilityOff,
  MdOutlineEdit,
  MdOutlineDelete,
} from "react-icons/md";
import { useTranslation } from "react-i18next";

const DisplayComments = ({
  groupMealId,
  userId,
  groupMealOwner,
  isUserLoggedIn,
}) => {
  const [comments, setComments] = useState([]);
  const [replyTo, setReplyTo] = useState(null);
  const [hiddenComments, setHiddenComments] = useState(new Set());
  const [expandedComments, setExpandedComments] = useState(new Set());
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editText, setEditText] = useState("");
  const { t } = useTranslation();
  const { state } = useContext(DataContext);
  const { lastCommentUpdate } = state;
  const context = useContext(DataContext);
  const user = context ? context.user : null;
  const SUPABASE_URL = "https://pombulhrhrwmutljshfd.supabase.co";
  const bucket_name = "recipe-files";

  const getImageUrl = (imagePath) => {
    if (!imagePath) return ProfilePicture;
    if (imagePath.startsWith(`${bucket_name}/`)) {
      return `${SUPABASE_URL}/storage/v1/object/public/${imagePath}`;
    }
    return `${SUPABASE_URL}/storage/v1/object/public/${bucket_name}/${imagePath}`;
  };

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const response = await supabase
          .rpc("fetch_comments_meal", {
            input_group_meal_id: groupMealId,
            input_user_id: userId,
          })
          .order("created_at", { ascending: false });

        const { data, error } = response;

        if (error) {
          console.error("Error fetching comments:", error.message);
        }

        if (data) {
          const nestedComments = organizeComments(data);
          setComments(nestedComments);
        }
      } catch (err) {
        console.error("Error fetching comments:", err.message);
      }
    };

    fetchComments();
  }, [groupMealId, userId, lastCommentUpdate]);

  const organizeComments = (comments) => {
    const commentMap = new Map();

    comments.forEach((comment) => {
      comment.replies = [];
      commentMap.set(comment.id, comment);
    });

    return comments.filter((comment) => {
      if (comment.parent_id) {
        const parentComment = commentMap.get(comment.parent_id);
        if (parentComment) {
          parentComment.replies.push(comment);
        }
        return false;
      }
      return true;
    });
  };

  const loadComments = async () => {
    try {
      const response = await supabase
        .rpc("fetch_comments_meal", {
          input_group_meal_id: groupMealId,
          input_user_id: userId,
        })
        .order("created_at", { ascending: false });

      const { data, error } = response;

      if (error) {
        console.error("Error fetching comments:", error.message);
      }

      if (data) {
        const nestedComments = organizeComments(data);
        setComments(nestedComments);
      }
    } catch (err) {
      console.error("Error fetching comments:", err.message);
    }
  };

  const deleteComment = async (commentId) => {
    Swal.fire({
      title: t("comments.sureprompt"),
      text: t("comments.suretext"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3b82f6",
      cancelButtonColor: "#dc2626",
      confirmButtonText: t("comments.conftext"),
      cancelButtonText: t("comments.canceltext"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const { data, error } = await supabase
            .from("comments")
            .update({
              text: null,
              deleted: true,
              user_id: null,
            })
            .match({ id: commentId });

          if (error) {
            throw error;
          }

          setComments((currentComments) =>
            currentComments.map((comment) =>
              comment.id === commentId
                ? { ...comment, deleted: true, text: null, edited: true }
                : comment
            )
          );

          Swal.fire(t("comments.deleted"), t("comments.deletedt"), "success");

          loadComments();
        } catch (error) {
          console.error("Error deleting comment:", error);
          Swal.fire(t("comments.error"), t("comments.errort"), "error");
        }
      }
    });
  };

  const startEdit = (comment) => {
    setEditingCommentId(comment.id);
    setEditText(comment.text);
  };

  const cancelEdit = () => {
    setEditingCommentId(null);
    setEditText("");
  };

  const saveEdit = async (commentId) => {
    try {
      const { error } = await supabase
        .from("comments")
        .update({ text: editText, edited: true })
        .match({ id: commentId });
      if (error) {
        throw error;
      }
      setComments((currentComments) =>
        currentComments.map((comment) =>
          comment.id === commentId
            ? { ...comment, text: editText, edited: true }
            : comment
        )
      );
      cancelEdit();
      Swal.fire({
        title: t("comments.success"),
        text: t("comments.successt"),
        icon: "success",
        confirmButtonText: t("comments.ok"),
      });
      loadComments();
    } catch (error) {
      console.error("Error updating comment:", error);
    }
  };

  const toggleCommentVisibility = (commentId) => {
    setHiddenComments((prevHiddenComments) => {
      const newHiddenComments = new Set(prevHiddenComments);
      if (newHiddenComments.has(commentId)) {
        newHiddenComments.delete(commentId);
      } else {
        newHiddenComments.add(commentId);
      }
      return newHiddenComments;
    });
  };

  const renderComments = (comments, depth = 0, isTopLevel = true) => {
    return comments.map((comment, index) => (
      <div
        key={comment.id}
        className={`comment-container ${
          isTopLevel ? "px-4" : "pl-4"
        } py-2 bg-white shadow-sm border border-gray-200 rounded-lg`}
        style={{ paddingLeft: `${depth * 1}rem` }}
      >
        <div
          className="comment p-4"
          style={{
            marginLeft: `${depth * 10}px`,
            marginTop: `${isTopLevel ? 0 : 4}px`,
            marginRight: `${depth * 10}px`,
          }}
        >
          {comment.deleted ? (
            <p className="text-gray-500 italic">
              {t("comments.deletedcomment")}
            </p>
          ) : (
            <>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <img
                    className="w-10 h-10 rounded-full mr-3"
                    src={getImageUrl(comment.user_data?.image)}
                    alt={`${comment.user_data?.first_name || "User"} ${
                      comment.user_data?.last_name || ""
                    }`}
                    onError={(e) => (e.currentTarget.src = ProfilePicture)}
                  />
                  <div>
                    <p className="text-sm font-semibold text-gray-900">
                      {comment.user_data
                        ? `${comment.user_data.first_name || "User"} ${
                            comment.user_data.last_name || ""
                          }`
                        : t("comments.anon")}
                    </p>
                    <p className="text-xs text-gray-500">
                      {new Date(comment.created_at).toLocaleDateString()}{" "}
                      {comment.edited && `(${t("comments.edited")})`}
                    </p>
                  </div>
                </div>
                <button
                  onClick={() => toggleCommentVisibility(comment.id)}
                  className="focus:outline-none"
                >
                  {hiddenComments.has(comment.id) ? (
                    <MdVisibilityOff className="text-gray-500" />
                  ) : (
                    <MdVisibility className="text-gray-500" />
                  )}
                </button>
              </div>
              {editingCommentId === comment.id ? (
                <div className="mt-3">
                  <input
                    type="text"
                    className="w-full text-gray-900 bg-white border border-gray-300 focus:border-blue-400 mb-2 p-2 rounded-lg shadow-sm transition duration-150 ease-in-out focus:outline-none"
                    value={editText}
                    onChange={(e) => setEditText(e.target.value)}
                  />
                  <div className="flex items-center space-x-2">
                    <button
                      className="inline-flex items-center py-1.5 px-3 text-sm font-medium text-center text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
                      onClick={() => saveEdit(comment.id)}
                    >
                      {t("comments.save")}
                    </button>
                    <button
                      className="inline-flex items-center py-1.5 px-3 text-sm font-medium text-center text-white bg-red-500 rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300"
                      onClick={cancelEdit}
                    >
                      {t("comments.canceltext")}
                    </button>
                  </div>
                </div>
              ) : (
                <p className="text-gray-900 mt-3">{comment.text}</p>
              )}

              <div className="flex items-center justify-start mt-2 space-x-2">
                {userId && (
                  <button
                    className="flex items-center text-blue-500 hover:text-blue-700 transition duration-150 ease-in-out p-2 rounded hover:bg-blue-100"
                    onClick={() => setReplyTo(comment.id)}
                    title="Reply"
                  >
                    <MdOutlineReply className="mr-1" /> {t("comments.reply")}
                  </button>
                )}
                {userId &&
                (comment.is_user_author_result || userId === groupMealOwner) ? (
                  <>
                    <button
                      className="flex items-center text-green-500 hover:text-green-700 transition duration-150 ease-in-out p-2 rounded hover:bg-green-100"
                      onClick={() => startEdit(comment)}
                      title="Edit"
                    >
                      <MdOutlineEdit className="mr-1" /> {t("comments.edit")}
                    </button>
                    <button
                      className="flex items-center text-red-500 hover:text-red-700 transition duration-150 ease-in-out p-2 rounded hover:bg-red-100"
                      onClick={() =>
                        deleteComment(comment.id, userId, groupMealOwner)
                      }
                      title="Delete"
                    >
                      <MdOutlineDelete className="mr-1" />{" "}
                      {t("comments.delete")}
                    </button>
                  </>
                ) : null}
              </div>
            </>
          )}
          {replyTo === comment.id && (
            <WriteComment
              groupMealId={groupMealId}
              userId={userId}
              parentId={comment.id}
              onCancel={() => setReplyTo(null)}
            />
          )}
        </div>
        {!hiddenComments.has(comment.id) && comment.replies && (
          <div className="pl-4 mt-2">
            {renderComments(comment.replies, depth + 1, false)}
          </div>
        )}
      </div>
    ));
  };

  return <div>{renderComments(comments)}</div>;
};

export default DisplayComments;
