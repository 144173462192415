import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import fetchGroupMealDetails from "../components/utilities/fetchGroupInvites";
import { Helmet } from "react-helmet-async";
import {
  MdArrowBackIosNew,
  MdCheck,
  MdClose,
  MdVisibility,
  MdNavigateNext,
  MdRefresh,
} from "react-icons/md";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { supabase } from "../supabaseClient";
import LeaveGroupInvite from "../components/utilities/LeaveGroupInvite";
function GroupPlanInvites() {
  const { mealId, assignmentId } = useParams();
  const [mealDetails, setMealDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  dayjs.extend(utc);
  const [actions, setActions] = useState({});
  const [hasAcceptedAssignment, setHasAcceptedAssignment] = useState(false);

  const handleView = (url) => {
    navigate(`/recipes-view/${url}`);
  };

  const handleAccept = (id) => {
    updateAssignmentStatus(id, 1);
    setActions((prev) => ({ ...prev, [id]: 1 }));
    setHasAcceptedAssignment(true);
  };

  const handleDecline = (id) => {
    updateAssignmentStatus(id, 2);
    setActions((prev) => ({ ...prev, [id]: 2 }));
  };

  const handleReset = (id) => {
    updateAssignmentStatus(id, 0);
    setActions((prev) => ({ ...prev, [id]: 0 }));
  };

  const formatDate = (utcDate) => {
    const dayjsDate = dayjs.utc(utcDate);
    const day = t(`days.${dayjsDate.format("dddd")}`);
    const month = t(`months.${dayjsDate.format("MMMM")}`);
    const atSymbol = t("misc.atSymbol") || "[at]";
    return `${day}, ${month} ${dayjsDate.format("D")}, ${dayjsDate.format(
      "YYYY"
    )} ${atSymbol} ${dayjsDate.format("h:mm A")}`;
  };

  useEffect(() => {
    fetchGroupMealDetails(mealId, assignmentId)
      .then((details) => {
        setMealDetails(details);
        setLoading(false);
        const initialActions = details.reduce((acc, item) => {
          acc[item.id] = item.accepted;
          return acc;
        }, {});
        setActions(initialActions);
        const anyAccepted = details.some((item) => item.accepted === 1);
        setHasAcceptedAssignment(anyAccepted);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [mealId, assignmentId]);

  const groupedByDate = mealDetails.reduce((acc, item) => {
    const date = formatDate(item.event_date_time);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(item);
    return acc;
  }, {});

  async function updateAssignmentStatus(assignmentId, acceptedValue) {
    try {
      const { data, error } = await supabase
        .from("other_group_meal_assignments")
        .update({ accepted: acceptedValue })
        .eq("id", assignmentId);

      if (error) {
        console.error("Error updating status:", error.message);
        throw new Error(error.message);
      }

      setMealDetails(
        mealDetails.map((item) => {
          if (item.id === assignmentId) {
            return { ...item, accepted: acceptedValue };
          }
          return item;
        })
      );
    } catch (err) {
      console.error("Failed to update status:", err.message);
    }
  }

  const eventName =
    mealDetails.length > 0 ? mealDetails[0].other_group_meals.event_name : "";

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  }
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      <Helmet>
        <title>RecipeCircle - Accept Group Plan Invitation</title>
        <meta
          name="description"
          content="View and manage your group plan invitations on recipecircle.world"
        />
        <meta
          property="og:title"
          content="RecipeCircle - Accept Group Plan Invitation"
        />
        <meta
          property="og:description"
          content="RecipeCircle - Accept Group Plan Invitation"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://recipeCircle.com/group-plan-invites"
        />
      </Helmet>

      <div className="w-100 container mx-auto">
        <div className="w-full overflow-x-hidden">
          <div className="text-center">
            <h1 className="text-4xl font-bold py-4 text-[#384E79]">
              {t("groupmeals.accept")} {eventName} {t("groupmeals.invitations")}
            </h1>
            <div className="breadcrumbs flex justify-center mb-1">
              <ul>
                <li>
                  <a
                    onClick={() => {
                      navigate("/");
                    }}
                    className="text-[#384E79] hover:text-blue-400"
                  >
                    {t("planmeals.home")}
                  </a>
                </li>
                <li className="text-stone-700">
                  {eventName} {t("groupmeals.invitation")}
                </li>
              </ul>
            </div>
          </div>

          <div className="flex justify-between items-center mb-4">
            <button className="btn btn-outline" onClick={() => navigate("/")}>
              <MdArrowBackIosNew />
            </button>
            <LeaveGroupInvite assignmentId={assignmentId} />
          </div>

          <div className="divider"></div>
        </div>

        <div className="bg-gradient-to-br from-blue-600 to-emerald-400 shadow-lg p-6 rounded-lg my-6 w-full">
          {!hasAcceptedAssignment && (
            <>
              <p className="text-white text-lg mb-4 text-center font-semibold">
                {t("groupmeals.invited")} {eventName}, {t("groupmeals.agp")}
              </p>
              <p className="text-white text-base mb-6 text-center">
                {t("groupmeals.gainaccess")}
              </p>
            </>
          )}

          {hasAcceptedAssignment && (
            <div className="text-center">
              <p className="text-white text-lg mb-4 text-center font-semibold capitalize">
                {eventName} {t("groupmeals.accp")}
              </p>
              <p className="text-white text-base mb-6 text-center">
                {t("groupmeals.in")}
              </p>
              <button
                className="btn-outline btn hover:bg-blue-700 text-white font-semibold py-3 px-6 rounded-lg shadow-xl transition duration-300 ease-in-out transform hover:-translate-y-1 inline-flex items-center"
                onClick={() => {
                  if (mealDetails && mealDetails.length > 0) {
                    navigate(
                      `/group-meals/${mealDetails[0].other_group_meals_id}`
                    );
                  }
                }}
              >
                {t("groupmeals.got")} <MdNavigateNext className="ml-2" />
              </button>
            </div>
          )}
        </div>

        {Object.entries(groupedByDate).map(([date, assignments]) => (
          <div
            key={date}
            className="bg-white shadow-lg rounded-lg overflow-hidden my-6 hover:shadow-xl"
          >
            <div className="px-6 py-4 border-b border-gray-200 bg-gradient-to-r from-blue-500 to-blue-700 ">
              <h2 className="text-2xl font-semibold text-white">{date}</h2>
            </div>
            {assignments.map((assignment) => (
              <div
                key={assignment.id}
                className="px-6 py-4 border-b border-gray-100 last:border-b-0"
              >
                <div className="flex justify-between items-center">
                  <h3 className="text-xl font-semibold text-gray-800">
                    {assignment.event_description}
                  </h3>
                  <div className="flex space-x-4">
                    {actions[assignment.id] === 0 ? (
                      <>
                        <button
                          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-lg shadow transition duration-150 ease-in-out flex items-center"
                          onClick={() => handleAccept(assignment.id)}
                        >
                          <MdCheck className="mr-2" /> {t("groupmeals.accept")}
                        </button>
                        <button
                          className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg shadow transition duration-150 ease-in-out flex items-center"
                          onClick={() => handleDecline(assignment.id)}
                        >
                          <MdClose className="mr-2" /> {t("groupmeals.decline")}
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          className={`text-white font-bold py-2 px-4 rounded-lg shadow transition duration-150 ease-in-out flex items-center ${
                            actions[assignment.id] === 1
                              ? "bg-green-600"
                              : "bg-red-600"
                          }`}
                          disabled
                        >
                          {actions[assignment.id] === 1 ? (
                            <MdCheck className="mr-2" />
                          ) : (
                            <MdClose className="mr-2" />
                          )}
                          {actions[assignment.id] === 1
                            ? t("groupmeals.accepted")
                            : t("groupmeals.declined")}
                        </button>
                        {actions[assignment.id] !== 0 && (
                          <button
                            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg shadow transition duration-150 ease-in-out flex items-center ml-2"
                            onClick={() => handleReset(assignment.id)}
                          >
                            <MdRefresh className="mr-2" />{" "}
                            {t("groupmeals.reset")}
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <p className="text-gray-600 mt-2">{eventName}</p>
                {assignment.recipes && (
                  <div className="mt-4 flex justify-center items-center flex-col">
                    <p className="font-semibold text-gray-800 text-xl mb-4 text-center">
                      {t("groupmeals.recipe")} {assignment.recipes.name}
                    </p>
                    <button
                      onClick={() => handleView(assignment.recipes.url)}
                      className="mt-2 bg-blue-400 hover:bg-blue-600 text-white font-bold py-3 px-5 rounded-lg shadow transition duration-150 ease-in-out flex items-center"
                    >
                      <MdVisibility className="mr-3" />{" "}
                      {t("groupmeals.viewrec")}
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default GroupPlanInvites;
