import React, { useContext, useEffect, useState, useRef } from "react";
import { supabase } from "../../supabaseClient";
import { useForm } from "react-hook-form";
import PhotoUploaderRecipe from "../../components/common/PhotoUploaderRecipe";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { DataContext } from "../../context/DataContext";
import DesktopMenu from "../../components/layout/DesktopWrittenMenu";
import MobileMenu from "../../components/layout/MobileWrittenMenu";
import { categoriesMain, categoriesDietary } from "../../constants";
import Swal from "sweetalert2";
import { BackIcon } from "../../components/common/Icons";
import "sweetalert2/dist/sweetalert2.min.css";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import FormattingTips from "../../components/utilities/formattingtips";
import {
  MdDelete,
  MdArrowCircleDown,
  MdArrowCircleUp,
  MdArrowBackIosNew,
  MdLightbulbOutline,
} from "react-icons/md";

const orderList = (list, value) => {
  return list.sort((a, b) => {
    if (a[value] < b[value]) {
      return -1;
    }
    if (a[value] > b[value]) {
      return 1;
    }
    return 0;
  });
};

const PreventEnterSubmit = () => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return null;
};

const convertToUrlFormat = (str) => {
  return str.toLowerCase().replace(/ /g, "-").replace(/['"]/g, "");
};

const NewRecipe = (props) => {
  const navigate = useNavigate();
  const { state } = useContext(DataContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [circles, setCircles] = useState([]);
  const [data, setData] = useState("");
  const [dataPublic, setDataPublic] = useState(false);
  const [dataSteps, setDataSteps] = useState([]);
  const [dataIngredients, setDataIngredients] = useState([]);
  const [dataBookmarks, setDataBookmarks] = useState([]);
  const [dataCategories, setDataCategories] = useState([]);
  const [dataCategoriesDietary, setDataCategoriesDietary] = useState([]);
  const [dataCircle, setDataCircle] = useState([]);
  const [dataType, setDataType] = useState(1);
  const [dataFile, setDataFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [unit, setUnit] = useState("imperial");
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestedUserRecipes, setSuggestedUserRecipes] = useState([]);
  const [externalLink, setExternalLink] = useState("");
  const [user, setUser] = useState(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [selectedRecipes, setSelectedRecipes] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchUser = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      setUser(user);
    };

    fetchUser();
  }, []);

  const [searchInput, setSearchInput] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [suggestedCategories, setSuggestedCategories] = useState([]);

  const allCategories = [...categoriesMain, ...categoriesDietary];

  const searchResultsRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        searchResultsRef.current &&
        !searchResultsRef.current.contains(event.target)
      ) {
        setSuggestedCategories([]);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleSearchInputChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);

    const filteredCategories = input
      ? allCategories.filter(
          (cat) =>
            cat.label.toLowerCase().includes(input.toLowerCase()) &&
            !selectedCategories.includes(cat.label)
        )
      : [];

    if (filteredCategories.length === 0 && input.trim() !== "") {
      setSuggestedCategories([{ label: input }]);
    } else {
      setSuggestedCategories(filteredCategories);
    }
  };

  const handleAddButtonClick = () => {
    if (searchInput.trim() !== "") {
      handleAddCategory(searchInput);
    }
  };

  const handleAddCategory = (category) => {
    if (!selectedCategories.includes(category)) {
      setSelectedCategories([...selectedCategories, category]);
      setSearchInput("");
      setSuggestedCategories([]);
    }
  };

  const handleRemoveCategory = (category) => {
    const updatedCategories = selectedCategories.filter(
      (cat) => cat !== category
    );
    setSelectedCategories(updatedCategories);
  };

  useEffect(() => {
    setSuggestedCategories([]);
  }, [selectedCategories]);

  useEffect(() => {
    const loadCircles = async () => {
      let {
        data: dataCircles,
        error: errorC,
        status: statusC,
      } = await supabase
        .from("circles")
        .select(`id,name, description, image_url`)
        .eq("owner_id", state?.user?.id);

      setCircles(dataCircles);
      if (errorC && statusC !== 406) {
        throw errorC;
      }
    };
    if (state?.user?.id) {
      loadCircles();
    }
  }, [state.user]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        e.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const internalLinks = selectedRecipes
    .filter((recipe) => !recipe.isExternal)
    .map((recipe) => recipe.id);
  const externalLinks = selectedRecipes
    .filter((recipe) => recipe.isExternal)
    .map((recipe) => recipe.name);

  const processSubmit = async (dataInput) => {
    if (!state?.user?.id) {
      return false;
    }

    setData({ ...data, ...dataInput });
    setIsLoading(true);

    const {
      data: { user },
    } = await supabase.auth.getUser();

    let filePath = null;

    const internalLinks = selectedRecipes
      .filter((recipe) => !recipe.isExternal)
      .map((recipe) => recipe.id);

    const externalLinks = selectedRecipes
      .filter((recipe) => recipe.isExternal)
      .map((recipe) => recipe.name);

    const dataInputSteps = dataSteps
      .filter((item) => item.value !== "")
      .map((item) => {
        return { value: item.value, position: item.position };
      });

    const dataInputBookmarks = dataBookmarks
      .filter((item) => item.value !== "")
      .map((item) => {
        return { value: item.value, position: item.position };
      });

    const date = new Date();

    const insert = {
      owner_id: user.id,
      name: dataInput.name,
      author: dataInput.author,
      description: dataInput.description,
      hack: dataInput.hack,
      duration: dataInput.duration,
      servings: dataInput.servings,
      steps: dataInputSteps,
      image_url: data.image_url,
      public: dataPublic,
      bookmarks: dataInput.bookmarks,
      type: dataType,
      file: filePath,
      url:
        convertToUrlFormat(dataInput.name) +
        "-" +
        date.getFullYear() +
        date.getMonth(),
    };

    let { data: dataInsert, error, status } = await supabase
      .from("recipes")
      .insert(insert)
      .select();

    if (error && status !== 406) {
      throw error;
    }

    if (dataIngredients && dataIngredients.length) {
      const insertIngredients = dataIngredients
        .filter((item) => item.value !== "")
        .map((item) => {
          return {
            recipe_id: dataInsert[0].id,
            name: item.value,
            measure: item.measure,
            position: item.position,
            quantity: item.quantity,
          };
        });

      let { error: errorI, status: statusI } = await supabase
        .from("recipe_ingredients")
        .insert(insertIngredients);

      if (errorI && statusI !== 406) {
        console.error("Error inserting ingredients:", errorI);
        throw errorI;
      }
    }

    if (selectedCategories?.length) {
      const insertCategories = selectedCategories.map((item) => {
        return { recipe_id: dataInsert[0].id, category_key: item, type: 1 };
      });

      let { error: errorCat, status: statusCat } = await supabase
        .from("recipe_categories")
        .insert(insertCategories);

      if (errorCat && statusCat !== 406) {
        console.error("Error inserting categories:", errorCat);
        throw errorCat;
      }
    }

    if (dataCircle?.length) {
      const insertCircles = dataCircle.map((item) => {
        return { recipe_id: dataInsert[0].id, circle_id: item };
      });

      let { error: errorC, status: statusC } = await supabase
        .from("circle_recipes")
        .insert(insertCircles);

      if (errorC && statusC !== 406) {
        throw errorC;
      }
    }

    if (internalLinks.length) {
      const insertInternalLinks = internalLinks.map((linked_recipe_id) => ({
        recipe_id: dataInsert[0].id,
        linked_recipe_id,
        link_type: true,
        user_id: user.id,
      }));

      let { error: errorInternalLinks } = await supabase
        .from("recipe_links")
        .insert(insertInternalLinks);

      if (errorInternalLinks) {
        console.error("Error inserting internal links:", errorInternalLinks);
        throw errorInternalLinks;
      }
    }

    if (externalLinks.length) {
      const insertExternalLinks = externalLinks.map((external_url) => ({
        recipe_id: dataInsert[0].id,
        external_url,
        link_type: false,
        user_id: user.id,
      }));

      let { error: errorExternalLinks } = await supabase
        .from("recipe_links")
        .insert(insertExternalLinks);

      if (errorExternalLinks) {
        console.error("Error inserting external links:", errorExternalLinks);
        throw errorExternalLinks;
      }
    }

    setSuccess(true);
    setIsLoading(false);
    setTimeout(() => {
      navigate("/recipes");
    }, 3000);
  };

  const handleFileSelect = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setDataFile(selectedFile);
    }
  };

  const handleCategory = (e, value) => {
    let updateCategories = selectedCategories;
    if (e.target.checked) {
      updateCategories = [...updateCategories, value];
    } else {
      updateCategories = updateCategories.filter((itemC) => itemC !== value);
    }
    setDataCategories(updateCategories);
  };

  const handleCategoryDietry = (e, value) => {
    let updateCategories = dataCategoriesDietary;
    if (e.target.checked) {
      updateCategories = [...updateCategories, value];
    } else {
      updateCategories = updateCategories.filter((itemC) => itemC !== value);
    }
    setDataCategoriesDietary(updateCategories);
  };

  const handleCircle = (e, value) => {
    let updateCircles = dataCircle;
    if (e.target.checked) {
      updateCircles = [...updateCircles, value];
    } else {
      updateCircles = updateCircles.filter((itemC) => itemC !== value);
    }
    setDataCircle(updateCircles);
  };

  const handleAddIngredient = () => {
    setDataIngredients([
      ...dataIngredients,
      { id: Date.now(), value: "", position: dataIngredients.length + 1 },
    ]);
  };

  const handleChangeIngredient = (e, id, field) => {
    e.preventDefault();
    const updateIngredients = dataIngredients.map((item) => {
      if (item.id === id) {
        return { ...item, [field]: e.target.value };
      }
      return item;
    });
    setDataIngredients(updateIngredients);
  };

  const handlePublicToggle = () => {
    setDataPublic((prev) => !prev);
  };

  const handleIngredientFieldChange = (e, id, field) => {
    e.preventDefault();
    const updatedIngredients = dataIngredients.map((ingredient) => {
      if (ingredient.id === id) {
        return { ...ingredient, [field]: e.target.value };
      }
      return ingredient;
    });
    setDataIngredients(updatedIngredients);
  };

  const handleUnitToggle = () => {
    setUnit((prevUnit) => (prevUnit === "imperial" ? "metric" : "imperial"));

    const updatedIngredients = dataIngredients.map((ingredient) => ({
      ...ingredient,
      measure: "",
    }));

    setDataIngredients(updatedIngredients);
  };

  const handleIngredientRemove = (e, id) => {
    e.preventDefault();
    const item = dataIngredients.find((item) => item.id === id);
    const pos = item.position;
    const updateIngredients = dataIngredients
      .filter((item) => item.id !== id)
      .map((item) => {
        if (item.position > pos) {
          return { ...item, position: item.position - 1 };
        }
        return item;
      });

    setDataIngredients(updateIngredients);
  };

  const handleIngredientMove = (e, id, direction) => {
    e.preventDefault();
    const item = dataIngredients.find((item) => item.id === id);
    const pos = item.position;
    let toPos = null;
    if (direction === "up") {
      toPos = pos - 1;
    } else {
      toPos = pos + 1;
    }

    const updateIngredients = dataIngredients.map((item) => {
      if (item.position === pos) {
        return { ...item, position: toPos };
      } else if (item.position === toPos) {
        return { ...item, position: pos };
      }
      return item;
    });
    setDataIngredients(updateIngredients);
  };

  const handleChangeStep = (e, id, value) => {
    e.preventDefault();
    const updateSteps = dataSteps.map((item) => {
      if (item.id === id) {
        return { ...item, value: value };
      }
      return item;
    });
    setDataSteps(updateSteps);
  };

  const handleStepRemove = (e, id) => {
    e.preventDefault();
    const item = dataSteps.find((item) => item.id === id);
    const pos = item.position;
    const updateSteps = dataSteps
      .filter((item) => item.id !== id)
      .map((item) => {
        if (item.position > pos) {
          return { ...item, position: item.position - 1 };
        }
        return item;
      });
    setDataSteps(updateSteps);
  };

  const handleDeleteStepConfirmation = (e, stepId) => {
    e.preventDefault();

    Swal.fire({
      title: t("createrecipes.deleteprompt"),
      text: t("createrecipes.deletetext"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc2626",
      cancelButtonColor: "#1e293b",
      confirmButtonText: t("createrecipes.deletebtn"),
      cancelButtonText: t("createrecipes.cancelbtn"),
      customClass: {
        confirmButton: "btn btn-outline",
        cancelButton: "btn btn-secondary",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleStepRemove(e, stepId);
      }
    });
  };

  const handleStepMove = (e, id, direction) => {
    e.preventDefault();
    const item = dataSteps.find((item) => item.id === id);
    const pos = item.position;
    let toPos = null;
    if (direction === "up") {
      toPos = pos - 1;
    } else {
      toPos = pos + 1;
    }

    const updateSteps = dataSteps.map((item) => {
      if (item.position === pos) {
        return { ...item, position: toPos };
      } else if (item.position === toPos) {
        return { ...item, position: pos };
      }
      return item;
    });
    setDataSteps(updateSteps);
  };

  const handleChangeBookmark = (e, id, value) => {
    e.preventDefault();
    const updateBookmarks = dataBookmarks.map((item) => {
      if (item.id === id) {
        return { ...item, value: value };
      }
      return item;
    });
    setDataBookmarks(updateBookmarks);
  };

  const handleBookmarkRemove = (e, id) => {
    e.preventDefault();
    const item = dataBookmarks.find((item) => item.id === id);
    const pos = item.position;
    const updateBookmarks = dataBookmarks
      .filter((item) => item.id !== id)
      .map((item) => {
        if (item.position > pos) {
          return { ...item, position: item.position - 1 };
        }
        return item;
      });
    setDataBookmarks(updateBookmarks);
  };

  const handleBookmarkMove = (e, id, direction) => {
    e.preventDefault();
    const item = dataBookmarks.find((item) => item.id === id);
    const pos = item.position;
    let toPos = null;
    if (direction === "up") {
      toPos = pos - 1;
    } else {
      toPos = pos + 1;
    }

    const updateBookmarks = dataBookmarks.map((item) => {
      if (item.position === pos) {
        return { ...item, position: toPos };
      } else if (item.position === toPos) {
        return { ...item, position: pos };
      }
      return item;
    });
    setDataBookmarks(updateBookmarks);
  };

  const onChangeImage = (image) => {
    setData({ ...data, image_url: image });
  };

  const handlePublic = () => {
    setDataPublic(!dataPublic);
  };

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1050);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleAddClick = (recipe) => {
    if (recipe) {
      setSelectedRecipes((prevRecipes) => [...prevRecipes, recipe]);
      setSearchQuery("");
    } else if (externalLink && isValidUrl(externalLink)) {
      setSelectedRecipes((prevRecipes) => [
        ...prevRecipes,
        { id: `external-${Date.now()}`, name: externalLink, isExternal: true },
      ]);
      setExternalLink("");
      setSearchQuery("");
    }
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleRemoveRecipe = (recipeId) => {
    setSelectedRecipes((prevRecipes) =>
      prevRecipes.filter((recipe) => recipe.id !== recipeId)
    );
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      setIsInputFocused(false);
    }, 200);
  };

  const updateSearchQuery = (event) => {
    const inputValue = event.target.value;
    setSearchQuery(inputValue);

    if (isValidUrl(inputValue)) {
      setExternalLink(inputValue);
    } else {
      setExternalLink("");
    }
  };

  const searchUserRecipes = async (query) => {
    if (!user) return;

    if (!isValidUrl(query)) {
      const { data, error } = await supabase
        .from("recipes")
        .select("id, name")
        .ilike("name", `%${query}%`)
        .eq("owner_id", user.id);

      if (error) {
        console.error("Error fetching recipes:", error.message);
        return;
      }

      setSuggestedUserRecipes(data);
    }
  };

  useEffect(() => {
    if (searchQuery && !isValidUrl(searchQuery)) {
      searchUserRecipes(searchQuery);
    }
  }, [searchQuery, user]);

  return (
    <div className="w-100 container mx-auto">
      <div>
        <Helmet>
          <title>RecipeCircle - Create Recipe</title>
          <meta
            name="description"
            content="Share your favorite recipe with RecipeCircle. Create a written recipe with ingredients, steps, and more to share with your circles and the community."
          />
          <meta property="og:title" content="RecipeCircle - Create Recipe" />
          <meta
            property="og:description"
            content="Share your favorite recipe with RecipeCircle. Create a written recipe with ingredients, steps, and more to share with your circles and the community."
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content="https://app.recipecircle.world/recipes/new"
          />
        </Helmet>
        <div className="w-full overflow-x-hidden">
          <div className="text-center">
            <h1 className="text-4xl font-bold py-4 text-[#384E79] capitalize">
              {t("createrecipes.create")}
            </h1>

            <div className="breadcrumbs flex justify-center mb-4">
              <ul>
                <li>
                  <a
                    onClick={() => {
                      navigate("/");
                    }}
                    className="text-[#384E79] hover:text-blue-400"
                  >
                    {t("profile.home")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/recipes");
                    }}
                    className="text-[#384E79] hover:text-blue-400"
                  >
                    {t("recipe.recipes")}
                  </a>
                </li>
                <li className="text-stone-700 capitalize">
                  {t("createrecipes.create")}
                </li>
              </ul>
            </div>
          </div>
          <div className="flex-1">
            <button
              className="btn btn-outline"
              onClick={() => navigate(`/recipes`)}
            >
              <MdArrowBackIosNew />
            </button>
          </div>
          <div className="divider"></div>
        </div>
        <PreventEnterSubmit />
        <form
          className=""
          style={{ position: "relative" }}
          onSubmit={handleSubmit((data) => processSubmit(data))}
        >
          <div className="container w-full flex flex-wrap mx-auto px-2 pt-8 lg:pt-16 mt-16">
            {isMobileView ? <MobileMenu /> : <DesktopMenu />}

            <section className="w-full lg:w-4/5">
              <h1 className="capitalize flex items-center font-sans font-bold break-normal text-gray-700 px-2 text-xl mt-12 lg:mt-0 md:text-2xl">
                {t("createrecipes.create")}
              </h1>

              <hr className="bg-gray-300 my-12" />
              <section id="overview" />
              <h2 className="font-sans font-bold break-normal text-gray-700 px-2 pb-8 text-xl">
                {t("createrecipes.overview")}
              </h2>

              <div className="p-8 mt-6 lg:mt-0 leading-normal rounded shadow bg-white">
                <FormattingTips />
                <div className="flex justify-start">
                  <div className="form-control">
                    <label className="label cursor-pointer">
                      <span className="label-text mr-4">
                        {dataPublic
                          ? t("createrecipes.publicrec")
                          : t("createrecipes.privaterec")}
                      </span>
                      <input
                        type="checkbox"
                        className="toggle"
                        checked={dataPublic}
                        onChange={handlePublicToggle}
                      />
                    </label>
                  </div>
                </div>
                <div class="grid gap-6 mb-6 md:grid-cols-2 mt-6">
                  <div>
                    <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                      {t("createrecipes.name")}
                    </label>
                    <input
                      type="text"
                      {...register("name", { required: true })}
                      className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5 "
                      required
                    />
                    {errors.name && (
                      <span className="text-accent text-sm mt-1">
                        {t("createrecipes.required")}
                      </span>
                    )}
                  </div>

                  <div>
                    <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                      {t("createrecipes.author")}
                    </label>
                    <input
                      type="text"
                      {...register("author", { required: true })}
                      className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                    />
                    {errors.author && (
                      <span className="text-accent text-sm mt-1">
                        {t("createrecipes.required")}
                      </span>
                    )}
                    {errors.name && (
                      <span className="text-accent text-sm mt-1">
                        {t("createrecipes.required")}
                      </span>
                    )}
                  </div>
                </div>

                <div>
                  <label class=" mb-2 text-sm font-medium text-gray-900 dark:text-gray-900 flex items-center">
                    {t("createrecipes.description")}{" "}
                    <span
                      class="h-4 w-4 text-blue-500 ml-1 tooltip"
                      data-tip={t("markdown.enabled")}
                    >
                      <MdLightbulbOutline />
                    </span>
                  </label>
                  <textarea
                    {...register("description")}
                    className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                  />
                  {errors.description && (
                    <span className="text-accent text-sm mt-1">
                      {t("createrecipes.required")}
                    </span>
                  )}
                </div>

                <div class="grid gap-6 mb-6 md:grid-cols-2 mt-6">
                  <div>
                    <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                      {t("createrecipes.duration")}
                    </label>
                    <input
                      type="text"
                      {...register("duration")}
                      className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                    />
                  </div>
                  <div>
                    <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                      {t("createrecipes.servings")}
                    </label>
                    <input
                      type="text"
                      {...register("servings")}
                      className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                    />
                  </div>
                </div>
                <div>
                  <label class="flex mb-2 text-sm font-medium text-gray-900 dark:text-gray-900 items-center">
                    {t("createrecipes.hack")}{" "}
                    <span
                      class="h-4 w-4 text-blue-500 ml-1 tooltip"
                      data-tip={t("markdown.enabled")}
                    >
                      <MdLightbulbOutline />
                    </span>
                  </label>
                  <input
                    type="text"
                    {...register("hack")}
                    className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                  />
                  {errors.hack && (
                    <span className="text-accent text-sm mt-1">
                      {t("createrecipes.required")}
                    </span>
                  )}
                </div>
                <PhotoUploaderRecipe
                  onChangeImage={onChangeImage}
                  image={onChangeImage}
                />
              </div>
              {/*Overview container end*/}
              <section id="ingredients" />
              <h2 className="pb- mt-4 break-normal px-2 font-sans text-xl font-bold text-gray-700">
                {t("createrecipes.ingredients")}
              </h2>
              <div className="mt-6 flex flex-wrap rounded bg-white p-8 shadow lg:mt-0">
                {/* ingredients container start */}
                <div className="flex justify-start mb-5">
                  <div className="form-control">
                    <div className="form-control ml-2">
                      <label className="label cursor-pointer">
                        <span className="label-text mr-4">
                          {unit === "imperial"
                            ? t("createrecipes.imperial")
                            : t("createrecipes.metric")}
                        </span>
                        <input
                          type="checkbox"
                          className="toggle"
                          checked={unit === "metric"}
                          onChange={() => handleUnitToggle()}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                {orderList(dataIngredients, "position").map(
                  (ingredient, index) => (
                    <div
                      key={`ingredient-${ingredient.id}`}
                      className="mb-6 grid grid-cols-4 gap-4 md:grid-cols-8 w-full items-center"
                    >
                      <div className="col-span-1 md:col-span-1">
                        <input
                          type="text"
                          placeholder={t("createrecipes.quantity")}
                          className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                          onChange={(e) =>
                            handleChangeIngredient(e, ingredient.id, "quantity")
                          }
                          value={ingredient.quantity}
                        />
                      </div>
                      <div className="col-span-3 md:col-span-2 lg:col-span-1">
                        <select
                          className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                          onChange={(e) =>
                            handleChangeIngredient(e, ingredient.id, "measure")
                          }
                          value={ingredient.measure || ""}
                        >
                          <option value="" disabled hidden>
                            {t("createrecipes.selectunit")}
                          </option>
                          {unit === "imperial" ? (
                            <>
                              <option value="teaspoon">
                                {t("measurements.tsp")}
                              </option>
                              <option value="tablespoon">
                                {t("measurements.tbsp")}
                              </option>
                              <option value="cup">
                                {t("measurements.cup")}
                              </option>
                              <option value="ounce">
                                {t("measurements.oz")}
                              </option>
                              <option value="pound">
                                {t("measurements.lb")}
                              </option>
                              <option value="fluid_ounce">
                                {t("measurements.floz")}
                              </option>
                              <option value="pint">
                                {t("measurements.pint")}
                              </option>
                              <option value="quart">
                                {t("measurements.quart")}
                              </option>
                              <option value="gallon">
                                {t("measurements.gal")}
                              </option>
                              <option value="unit">
                                {t("measurements.unit")}
                              </option>
                              <option value="pinch">
                                {t("measurements.pinch")}
                              </option>
                            </>
                          ) : (
                            <>
                              <option value="milligram">
                                {t("measurements.mg")}
                              </option>
                              <option value="g">{t("measurements.g")}</option>
                              <option value="kg">{t("measurements.kg")}</option>
                              <option value="ml">{t("measurements.ml")}</option>
                              <option value="liter">
                                {t("measurements.l")}
                              </option>
                              <option value="unit">
                                {t("measurements.unit")}
                              </option>
                              <option value="pinch">
                                {t("measurements.pinch")}
                              </option>
                            </>
                          )}
                        </select>
                      </div>
                      <div className="col-span-4 md:col-span-3 lg:col-span-4">
                        <input
                          type="text"
                          placeholder={t("createrecipes.ingredient")}
                          className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                          onChange={(e) =>
                            handleChangeIngredient(e, ingredient.id, "value")
                          }
                          value={ingredient.value}
                        />
                      </div>
                      <div className="flex flex-wrap gap-2 col-span-4 md:col-span-2 lg:col-span-2">
                        <button
                            className="flex-1 md:flex-auto items-center justify-center text-lg btn btn-outline text-stone-800 hover:bg-emerald-300 hover:text-stone-800 font-bold py-2.5 flex md:w-auto"
                            onClick={(e) =>
                            handleIngredientMove(e, ingredient.id, "up")
                          }
                          disabled={index === 0}
                        >
                            <MdArrowCircleUp className="text-3xl mx-auto" />
                        </button>
                        <button
                            className="flex-1 md:flex-auto items-center justify-center text-lg text-stone-800 btn btn-outline focus:bg-gray-600 active:bg-gray-600 hover:bg-gray-600 font-bold py-2.5 flex md:w-auto"
                            onClick={(e) =>
                            handleIngredientMove(e, ingredient.id, "down")
                          }
                          disabled={index === dataIngredients.length - 1}
                        >
                            <MdArrowCircleDown className="text-3xl mx-auto" />
                        </button>
                        <button
                            className="w-full md:flex-1 items-center justify-center text-lg btn-outline btn focus:bg-red-600 active:bg-red-600 hover:bg-red-600 text-black font-bold py-2.5 flex md:w-auto"
                            type="button"
                          onClick={(e) =>
                            handleIngredientRemove(e, ingredient.id)
                          }
                        >
                            <MdDelete className="text-3xl mx-auto" />
                        </button>
                      </div>
                    </div>
                  )
                )}

                <div className="w-full flex justify-center">
                  <button
                    className="btn btn-outline w-full mt-6 tracking-widest"
                    type="button"
                    onClick={handleAddIngredient}
                  >
                    {t("createrecipes.addingredient")}
                  </button>
                </div>
              </div>
              {/* ingredients container end */}
              <section id="linking-recipes">
                <h2 className="mt-4 mb-4 font-sans font-bold break-normal text-gray-700 px-2 pb- text-xl">
                  {t("createrecipes.linkrecipe")}
                </h2>
                <p className="text-gray-600 px-2 mb-4">
                  {t("createrecipes.linktext")}
                </p>
                <div className="mt-6 flex flex-wrap rounded bg-white p-8 shadow lg:mt-0">
                  <div className="relative mb-4 w-full">
                    <div className="flex items-center border border-gray-900 rounded-lg p-1 relative">
                      <input
                        type="text"
                        id="recipe-link"
                        className={`flex-1 w-full p-4 text-sm text-gray-900 rounded-lg focus:outline-none ${
                          isFocused ? "border-blue-400" : "border-gray-900"
                        }`}
                        placeholder={t("createrecipes.linksearchtext")}
                        value={searchQuery}
                        onChange={updateSearchQuery}
                        onFocus={handleInputFocus}
                        onBlur={handleInputBlur}
                      />
                    </div>
                    {isInputFocused &&
                      (suggestedUserRecipes.length > 0 || externalLink) && (
                        <div className="absolute mt-1 p-2 bg-white border border-gray-300 rounded-lg shadow-lg w-full z-10 max-h-44 overflow-y-auto">
                          {suggestedUserRecipes.map((recipe) => (
                            <div
                              key={recipe.id}
                              className="cursor-pointer py-2 px-2 hover:bg-emerald-300 rounded"
                              onClick={() => handleAddClick(recipe)}
                            >
                              {recipe.name}
                            </div>
                          ))}
                          {externalLink && (
                            <div
                              className="cursor-pointer py-2 px-2 hover:bg-emerald-300 rounded"
                              onClick={() => handleAddClick(null)}
                            >
                              {t("createrecipes.external")} {externalLink}
                            </div>
                          )}
                        </div>
                      )}
                  </div>
                  {selectedRecipes.length > 0 && (
                    <div className="mt-10 flex flex-wrap">
                      {selectedRecipes.map((recipe) => (
                        <div
                          className="rounded-lg mr-2 mb-2 text-black p-3 border border-gray-700"
                          key={recipe.id}
                        >
                          {recipe.name}
                          <button
                            onClick={() => handleRemoveRecipe(recipe.id)}
                            className="ml-2 text-sm text-zinc-500 hover:text-red-500"
                          >
                            {t("createrecipes.remove")}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </section>

              {/* steps container start */}
              <section id="steps" />
              <h2 className="mt-4 mb-4 font-sans font-bold break-normal text-gray-700 px-2 pb- text-xl">
                {t("createrecipes.steps")}
              </h2>
              <div className="mt-6 flex flex-wrap rounded bg-white p-8 shadow lg:mt-0">
                {orderList(dataSteps, "position").map((step, index) => (
                  <div
                    className="mb-6 grid grid-cols-1 gap-2 md:grid-cols-8 w-full"
                    key={`step-${step.id}`}
                  >
                    {/* Mobile Layout */}
                    <div className="col-span-2 md:hidden">
                      <input
                        onChange={(e) =>
                          handleChangeStep(e, step.id, e.target.value)
                        }
                        value={step.value}
                        type="text"
                        placeholder={t("createrecipes.stepstext")}
                        className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                      />
                    </div>
                    <div className="col-span-2 md:hidden mt-2">
                      <div className="flex gap-2">
                        <button
                          className="btn btn-outline flex-1 hover:bg-emerald-300 hover:text-black mb-2"
                          onClick={(e) => handleStepMove(e, step.id, "up")}
                          disabled={step.position === 1}
                        >
                          <MdArrowCircleUp className="text-4xl" />
                        </button>
                        <button
                          className=" flex-1 text-stone-800 btn btn-outline focus:bg-gray-600 active:bg-gray-600 hover:bg-gray-600"
                          onClick={(e) => handleStepMove(e, step.id, "down")}
                          disabled={step.position === dataSteps.length}
                        >
                          <MdArrowCircleDown className="text-4xl" />
                        </button>
                      </div>
                    </div>

                    {/* Desktop Layout */}
                    <div className="md:col-span-1 hidden md:block">
                      <button
                        className="btn btn-outline w-full hover:bg-emerald-300 hover:text-black"
                        onClick={(e) => handleStepMove(e, step.id, "up")}
                        disabled={step.position === 1}
                      >
                        <MdArrowCircleUp className="text-4xl" />
                      </button>
                    </div>
                    <div className="col-span-2 md:col-span-3 lg:col-span-4 hidden md:block">
                      <input
                        onChange={(e) =>
                          handleChangeStep(e, step.id, e.target.value)
                        }
                        value={step.value}
                        type="text"
                        placeholder={t("createrecipes.stepstext")}
                        className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                      />
                    </div>
                    <div className="md:col-span-1 hidden md:block">
                      <button
                        className=" w-full text-stone-800 btn btn-outline focus:bg-gray-600 active:bg-gray-600 hover:bg-gray-600"
                        onClick={(e) => handleStepMove(e, step.id, "down")}
                        disabled={step.position === dataSteps.length}
                      >
                        <MdArrowCircleDown className="text-4xl" />
                      </button>
                    </div>
                    <div className="md:col-span-2 md:block flex items-end">
                      <button
                        className="tracking-widest btn btn-outline hover:bg-red-500 mr-4 w-full"
                        onClick={(e) =>
                          handleDeleteStepConfirmation(e, step.id)
                        }
                      >
                        <MdDelete className="text-2xl " />
                        {t("createrecipes.remove")}
                      </button>
                    </div>
                  </div>
                ))}

                <div className="w-full flex justify-center">
                  <button
                    className="btn btn-outline w-full mt-6 tracking-widest"
                    type="button"
                    onClick={() =>
                      setDataSteps([
                        ...dataSteps,
                        {
                          id: Date.now(),
                          value: "",
                          position: dataSteps.length + 1,
                        },
                      ])
                    }
                  >
                    {t("createrecipes.addstep")}
                  </button>
                </div>
              </div>
              {/* step container end */}
              {/* category container start */}
              <section id="categories" />
              <h2 className="mt-4 mb-4 font-sans font-bold break-normal text-gray-700 px-2 pb- text-xl">
                {t("createrecipes.categories")}
              </h2>
              <div className="mt-6 flex flex-wrap rounded bg-white p-8 shadow lg:mt-0">
                <div className="relative mb-4 w-full">
                  <div className="flex items-center border border-gray-900 rounded-lg p-1 relative">
                    <input
                      type="text"
                      id="category-search"
                      value={searchInput}
                      onChange={handleSearchInputChange}
                      className="flex-1 w-full p-4 text-sm text-gray-900 rounded-lg dark:placeholder-gray-400 dark:text-gray-900 focus:outline-none focus:border-blue-700 focus:ring-1 focus:ring-blue-300"
                      placeholder={t("createrecipes.categoriestext")}
                    />
                    <button
                      type="button"
                      onClick={handleAddButtonClick}
                      className="btn-outline btn text-black focus:outline-none font-medium rounded-lg text-sm px-4 py-2 ml-2"
                    >
                      {t("createrecipes.add")}
                    </button>
                  </div>

                  {/* Shown Categories Display */}
                  {searchInput && suggestedCategories.length > 0 && (
                    <div
                      ref={searchResultsRef}
                      className="absolute mt-1 p-2 bg-white border border-gray-300 rounded-lg shadow-lg w-full z-10 max-h-44 overflow-y-auto"
                    >
                      {suggestedCategories.map((category) => (
                        <div
                          key={category.label}
                          className="cursor-pointer py-2 px-2 hover:bg-emerald-300 rounded-lg"
                          onClick={() => handleAddCategory(category.label)}
                        >
                          {category.label}
                        </div>
                      ))}
                    </div>
                  )}

                  {/* Selected Categories Display */}
                  {selectedCategories.length > 0 && (
                    <div className="mt-10 flex flex-wrap">
                      {selectedCategories.map((category) => (
                        <div
                          className="rounded-lg mr-2 mb-2 text-black p-3  border border-gray-700"
                          key={category}
                        >
                          {category}
                          <input
                            className="hidden"
                            type="checkbox"
                            readOnly
                            checked={selectedCategories.includes(
                              category.value
                            )}
                            defaultChecked
                          ></input>
                          <button
                            onClick={() => handleRemoveCategory(category)}
                            className="ml-2 text-s text-zinc-500 hover:text-red-500"
                          >
                            {t("createrecipes.remove")}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              {/* category container end */}
              {/* circles container start */}
              <section id="circles" />
              <h2 className="mt-4 mb-4 font-sans font-bold break-normal text-gray-700 px-2 pb- text-xl">
                {t("createrecipes.circles")}
              </h2>
              <div className="p-8 mt-6 lg:mt-0 rounded shadow bg-white flex flex-wrap">
                {circles.map((cir) => (
                  <div className="form-control mr-4" key={cir.id}>
                    <label className="label cursor-pointer">
                      <input
                        type="checkbox"
                        checked={dataCircle.includes(cir.id)}
                        className="h-5 w-5 accent-emerald-300 bg-emerald-300 rounded-lg cursor-pointer"
                        onChange={(e) => handleCircle(e, cir.id)}
                      />
                      <span className="ml-2 label-text ">{cir.name}</span>
                    </label>
                  </div>
                ))}
                <button className="w-full mt-8">
                  <input
                    type="submit"
                    className="btn btn-outline w-full tracking-widest"
                    disabled={isLoading || success}
                    value={t("createrecipes.saverec")}
                  />
                </button>
              </div>
              {/* category container end */}
            </section>
            {/*document end*/}
          </div>
        </form>
        {success ? (
          <div className="toast toast-top toast-end">
            <div className="alert alert-success">
              <div>
                <span>{t("createrecipes.recipecreated")}</span>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default NewRecipe;
