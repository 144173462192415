import React, { useState } from 'react';
import { MdLightbulbOutline } from "react-icons/md";
import { useTranslation } from "react-i18next";

const FormattingTips = () => {
  const [showTips, setShowTips] = useState(false);
  const { t } = useTranslation();

  const toggleTips = (event) => {
    event.preventDefault();
    setShowTips(!showTips);
  };

  return (
    <div className="flex flex-col items-center">
      <button
        onClick={toggleTips}
        className="bg-blue-500 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-300 mb-5"
        type="button"
      >
        {showTips ? t("markdown.hidet") : t("markdown.displayt")}
      </button>

      {showTips && (
        <div class="p-8 my-4 leading-normal rounded shadow bg-white transition-all ease-in-out duration-300 border border-gray-200">
        <h3 class="font-bold text-xl mb-4 text-blue-600">{t("markdown.tip")}</h3>
        <p class="text-gray-800 flex items-center"><span class="h-4 w-4 text-blue-500 mx-1 mr-4 -ml-1"><MdLightbulbOutline /></span>{t("markdown.intro")}</p>
        <ul class="list-disc pl-5 mt-4">
            <li><strong class="text-blue-600">{t("markdown.bold")}:</strong> <code class="text-blue-600">**{t("markdown.text")}**</code> or <code class="text-blue-600">__{t("markdown.text")}__</code></li>
            <li><strong class="text-blue-600">{t("markdown.italic")}:</strong> <code class="text-blue-600">*{t("markdown.text")}*</code> or <code class="text-blue-600">_{t("markdown.text")}_</code></li>
            <li><strong class="text-blue-600">{t("markdown.link")}:</strong> <code class="text-blue-600">[{t("markdown.linktext")}](http://url)</code></li>
            <li><strong class="text-blue-600">{t("markdown.Blist")}:</strong><br /><code class="text-blue-600">- {t("markdown.itemone")}</code><br /><code class="text-blue-600">- {t("markdown.itemtwo")}</code></li>
            <li><strong class="text-blue-600">{t("markdown.Nlist")}:</strong><br /><code class="text-blue-600">1. {t("markdown.itemone")}</code><br /><code class="text-blue-600">2. {t("markdown.two")}</code></li>
        </ul>
        <p class="text-gray-800 mt-4">{t("markdown.outro")}</p>
    </div>
      )}
    </div>
  );
};

export default FormattingTips;
