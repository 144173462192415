import { supabase } from "../../supabaseClient";

const fetchEmailSuggestions = async (userId) => {
    try {
        const { data: memberCircleData, error: memberCircleError } = await supabase
            .from('circle_members')
            .select('circle_id, circles (owner_id)')
            .eq('user_id', userId);

        if (memberCircleError) {
            console.error("Error fetching member circles:", memberCircleError.message);
            throw new Error(memberCircleError.message);
        }

        const memberCircleIds = memberCircleData.map(item => item.circle_id);
        const ownerIds = memberCircleData.map(item => item.circles.owner_id);

        const { data: circleMemberEmails, error: circleMembersError } = await supabase
            .from('circle_members')
            .select('email')
            .in('circle_id', memberCircleIds);

        if (circleMembersError) {
            console.error("Error fetching circle members:", circleMembersError.message);
            throw new Error(circleMembersError.message);
        }

        const { data: ownerCircleEmails, error: ownerCircleEmailsError } = await supabase
            .from('profiles')
            .select('email')
            .in('id', ownerIds);

        if (ownerCircleEmailsError) {
            console.error("Error fetching owner emails:", ownerCircleEmailsError.message);
            throw new Error(ownerCircleEmailsError.message);
        }

        const { data: ownedCircles, error: ownedCirclesError } = await supabase
            .from('circles')
            .select('id')
            .eq('owner_id', userId);

        if (ownedCirclesError) {
            console.error("Error fetching owned circles:", ownedCirclesError.message);
            throw new Error(ownedCirclesError.message);
        }

        const ownedCircleIds = ownedCircles.map(circle => circle.id);

        const { data: ownedCircleMemberEmails, error: ownedCircleMemberEmailsError } = await supabase
            .from('circle_members')
            .select('email')
            .in('circle_id', ownedCircleIds);

        if (ownedCircleMemberEmailsError) {
            console.error("Error fetching owned circle members' emails:", ownedCircleMemberEmailsError.message);
            throw new Error(ownedCircleMemberEmailsError.message);
        }

        const emails = new Set([
            ...circleMemberEmails.map(item => item.email),
            ...ownerCircleEmails.map(item => item.email),
            ...ownedCircleMemberEmails.map(item => item.email)
        ]);

        return Array.from(emails);
    } catch (error) {
        console.error("Error fetching email suggestions:", error.message);
        return [];
    }
};

export default fetchEmailSuggestions;
