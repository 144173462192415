import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../components/common/registration.css";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const ResetPassword = (props) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [error, setError] = useState("");
  const [errorToken, setErrorToken] = useState("");
  const [recaptchaScale, setRecaptchaScale] = useState(1);
  const [justifyClass, setJustifyClass] = useState("justify-start");
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      const isMobileWidth = window.innerWidth < 768;
      setIsMobile(isMobileWidth);
      setJustifyClass(isMobileWidth ? "justify-start" : "justify-center");
      setRecaptchaScale(isMobileWidth ? 0.9 : 1);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fragment = window.location.hash.substring(1);
    const params = new URLSearchParams(fragment);
    const error = params.get("error");
    const error_description = params.get("error_description");

    if (error) {
      Swal.fire(decodeURIComponent(error_description), "", "error").then(() => {
        navigate("/forgot-password");
      });
    }
  }, []);

  useEffect(() => {
    if (error) {
      setError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && password !== "") {
      resetPassword(event);
    }
  };

  const resetPassword = async () => {
    if (password === "") {
      Swal.fire(t("resetPW.enterpw"), "", "error");
      return;
    }

    if (!recaptchaValue) {
      Swal.fire(t("resetPW.captcha"), "", "error");
      return;
    }

    if (password !== confirmPassword) {
      Swal.fire(t("resetPW.pwnomatch"), "", "error");
      return;
    }

    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;

    if (!passwordRegex.test(password)) {
      Swal.fire(t("resetPW.pwrequirements"), "", "error");
      return;
    }

    try {
      const { error } = await supabase.auth.updateUser({ password });

      if (error) {
        throw error;
      }
    } catch (error) {
      Swal.fire(error.message, "", "error");
      return;
    }

    Swal.fire(t("resetPW.success"), "", "success");
    navigate("/dashboard");
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Reset Password - RecipeCircle</title>
        <meta
          name="description"
          content="Reset your RecipeCircle account password."
        />
        <meta property="og:title" content="Reset Password - RecipeCircle" />
        <meta
          property="og:description"
          content="Reset your RecipeCircle account password."
        />
        <meta property="og:type" content="website" />
      </Helmet>

      <div
        className={`flex flex-col items-center ${justifyClass} min-h-screen`}
      >
        <div className="flex flex-col-reverse md:flex-row w-full max-w-3xl mx-auto overflow-hidden shadow-xl rc">
          <div
            className="md:w-1/2 bg-center hidden md:block bg-cover md:pt-0"
            style={{
              backgroundImage: `url(${require("../assets/universecirclesWLOGO.jpg")})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          ></div>
          <div className="w-full md:w-1/2 px-4 py-4 text-center bg-white rounded-b-lg relative">
            <h1 className="text-4xl font-bold">{t("resetPW.reset")}</h1>
            {errorToken ? (
              <>
                <p>{errorToken}</p>
                <div className="mt-2">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      Swal.fire({
                        title: t("resetPW.newrq"),
                        text:
                        t("resetPW.expired"),
                        icon: "info",
                      }).then(() => {
                        navigate("/forgot-password");
                      });
                    }}
                  >
                    {t("resetPW.newrq")}
                  </button>
                </div>
              </>
            ) : (
              <>
                {error ? <span>{error}</span> : null}

                <div className="mb-4 mt-6">
                  <label
                    className="block text-left ml-1 text-gray-700 text-sm font-bold mb-2"
                    htmlFor="password"
                  >
                    {t("resetPW.newpw")}
                  </label>
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="w-full shadow-inner appearance-none border rounded py-2 px-3 bg-gray-200 text-gray-700 leading-normal focus:outline-none focus:shadow-outline"
                    id="password"
                    type="password"
                    name="password"
                    placeholder={t("resetPW.reset")}
                    required
                  />
                </div>

                <div className="mb-6">
                  <label
                    className="block text-left ml-1 text-gray-700 text-sm font-bold mb-2"
                    htmlFor="confirmPassword"
                  >
                    {t("resetPW.confirmpw")}
                  </label>
                  <input
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="w-full shadow-inner appearance-none border rounded py-2 px-3 bg-gray-200 text-gray-700 mb-3 leading-normal focus:outline-none focus:shadow-outline"
                    id="confirmPassword"
                    type="password"
                    name="confirmPassword"
                    placeholder={t("resetPW.confirmpw")}
                    required
                  />
                </div>
                <div className="mt-5 mb-2 flex items-center justify-center">
                  <div>
                    <ReCAPTCHA
                      sitekey="6LcUPEkpAAAAAHEbLPWActAuimwqyPsc-nXyKfmC"
                      onChange={(value) => setRecaptchaValue(value)}
                      style={{ transform: `scale(${recaptchaScale})` }}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-start">
                  <div className="mt-4 mx-auto w-full">
                    <button
                      onClick={resetPassword}
                      className="w-full btn btn-outline hover:bg-blue-500 onClick:bg-blue-500 focus:bg-blue-500 text-black hover:text-black focus:text-black"
                      type="submit"
                    >
                      {t("resetPW.resetpw")}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
