import { supabase } from "../../supabaseClient"; 
import dayjs from 'dayjs'; 

export const fetchPastPlannedMeals = async (userId, setPlannedMeals, onCompleted) => { 
    if (!userId) { 
        onCompleted(); 
        return; 
    } 
    try { 
        const { data: plannedMeals, error: plannedMealsError } = await supabase 
            .from("planned_meals") 
            .select(` 
                id, 
                plan_name, 
                planned_meal_recipes:planned_meal_recipes!inner ( 
                    id, 
                    plan_date_time, 
                    plan_description, 
                    recipe:recipes ( 
                        name, 
                        author, 
                        url 
                    ) 
                ) 
            `) 
            .eq("user_id", userId); 

        if (plannedMealsError) throw plannedMealsError; 

        const validPlannedMeals = plannedMeals.map(meal => { 
            const validRecipes = meal.planned_meal_recipes.filter(recipe => 
                dayjs(recipe.plan_date_time).isBefore(dayjs()) 
            ); 
            return { 
                ...meal, 
                planned_meal_recipes: validRecipes 
            }; 
        }).filter(meal => meal.planned_meal_recipes.length > 0); 

        const groupedMeals = validPlannedMeals.reduce((acc, meal) => { 
            const { id, plan_name, planned_meal_recipes } = meal; 
            if (!acc[id]) { 
                acc[id] = { plan_name, recipes: [] }; 
            } 
            acc[id].recipes = acc[id].recipes.concat(planned_meal_recipes.map(recipe => ({ 
                ...recipe, 
                plan_date_time: dayjs(recipe.plan_date_time).isValid() ? recipe.plan_date_time : dayjs().format(), 
                recipe: recipe.recipe 
            }))); 
            return acc; 
        }, {}); 

        setPlannedMeals(groupedMeals); 
    } catch (error) { 
        console.error("Error fetching past planned meals:", error); 
    } finally { 
        onCompleted(); 
    } 
};
