import { supabase } from "../../supabaseClient";
import dayjs from "dayjs";

export const fetchGroupPlannedMeals = async (userId, setGroupPlannedMeals, onCompleted) => {
    if (!userId) {
        onCompleted();
        return;
    }
    try {
        const { data: ownedCircles, error: ownedCirclesError } = await supabase
            .from("circles")
            .select("id, name")
            .eq("owner_id", userId);

        if (ownedCirclesError) throw ownedCirclesError;

        const { data: memberCircles, error: memberCirclesError } = await supabase
            .from("circle_members")
            .select("circle:circles (id, name)")
            .eq("status", "1")
            .eq("user_id", userId);

        if (memberCirclesError) throw memberCirclesError;

        const allCircleIds = [
            ...ownedCircles,
            ...memberCircles.map(member => member.circle)
        ].reduce((acc, next) => (acc.find((x) => x.id === next.id) ? acc : [...acc, next]), []);

        const { data: groupPlans, error: groupPlansError } = await supabase
            .from("group_plans")
            .select(`
                id, 
                circle_id, 
                event_name, 
                group_planned_meals:group_planned_meals!inner (
                    id, 
                    user_id, 
                    recipe_id, 
                    event_date_time, 
                    event_description, 
                    recipe:recipes (
                        name, 
                        author, 
                        url
                    ), 
                    profile:profiles (
                        first_name, 
                        last_name, 
                        email
                    )
                )
            `)
            .in("circle_id", allCircleIds.map((c) => c.id));

        if (groupPlansError) throw groupPlansError;

        const plannedMeals = groupPlans.reduce((acc, plan) => {
            if (!acc[plan.id]) {
                acc[plan.id] = {
                    circle_id: plan.circle_id,
                    circle_name: allCircleIds.find((c) => c.id === plan.circle_id)?.name || "Unnamed Circle",
                    event_name: plan.event_name,
                    planned_meals: [],
                };
            }
            acc[plan.id].planned_meals.push(...plan.group_planned_meals.map(meal => ({
                ...meal,
                event_date_time: dayjs(meal.event_date_time).isValid() ? dayjs(meal.event_date_time).format() : null,
                assigned_to: meal.profile.first_name || meal.profile.last_name ? `${meal.profile.first_name} ${meal.profile.last_name}`.trim() : meal.profile.email,
                recipe: meal.recipe,
            })));
            return acc;
        }, {});

        setGroupPlannedMeals(plannedMeals);
    } catch (error) {
        console.error("Error fetching group planned meals:", error);
    } finally {
        onCompleted();
    }
};
