import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import { DataContext } from "../../context/DataContext";
import dayjs from "dayjs";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { MdAdd, MdNavigateNext, MdOutlineMoreVert } from "react-icons/md";
import { fetchPlannedMeals } from "./FetchPlannedMeals";
import DeletePlannedMeal from "./DeletePlannedMeal";
import { useTranslation } from "react-i18next";
import utc from "dayjs/plugin/utc";

const PlannedMealsDisplay = ({ user, isOpen, onOpenModal, onEditClick }) => {
  const { state, actions } = useContext(DataContext);
  const { lastMealUpdate } = state;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [plannedMeals, setPlannedMeals] = useState({});
  const [expandedCards, setExpandedCards] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  dayjs.extend(utc);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);

  const toggleCardExpansion = (plannedMealsId) => {
    setExpandedCards((prevState) => ({
      ...prevState,
      [plannedMealsId]: !prevState[plannedMealsId],
    }));
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  const handleView = (url) => {
    navigate(`/recipes-view/${url}`);
  };

  useEffect(() => {
    if (state.user && state.user.id) {
      setIsLoading(true);
      fetchPlannedMeals(state.user.id, setPlannedMeals, () =>
        setIsLoading(false)
      );
    }
  }, [state.user, lastMealUpdate]);

  const groupByPlanDateTime = (meals) => {
    return meals.reduce((acc, meal) => {
      const dateTimeKey = dayjs(meal.plan_date_time).format("YYYY-MM-DDTHH:mm");
      if (!acc[dateTimeKey]) {
        acc[dateTimeKey] = {
          plan_date_time: meal.plan_date_time,
          recipes: [],
          plan_description: meal.plan_description,
        };
      }
      acc[dateTimeKey].recipes.push(meal);
      return acc;
    }, {});
  };

  return isLoading ? (
    <div className="flex justify-center items-center w-full">
      <span className="loading loading-spinner loading-lg"></span>
    </div>
  ) : Object.keys(plannedMeals).length === 0 ? (
    <div className="flex items-center justify-center bg-blue-100 py-12 px-4 sm:px-6 lg:px-8 rounded-2xl shadow-xl">
      <div className="max-w-lg w-full space-y-6">
        <div>
          <h2 className="text-center text-4xl font-extrabold text-stone-900">
            {t("planmeals.noplans")}
          </h2>
          <p className="mt-3 text-center text-md text-stone-800">
            {t("planmeals.noplanst1")}
          </p>
          <p className="text-center text-md text-stone-800">
            {t("planmeals.noplanst2")}
          </p>
        </div>
        <div className="mt-10 space-y-8">
          <button
            className="group relative w-full flex justify-center py-3 px-4 
          bg-blue-300 border border-transparent text-md font-medium rounded-md text-stone-800 
            shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
            onClick={onOpenModal}
          >
            <MdAdd className="w-6 h-6 mr-2" />
            {t("planmeals.planmeals")}
          </button>
        </div>
      </div>
    </div>
  ) : (
    <ResponsiveMasonry
      columnsCountBreakPoints={{ 350: 1, 750: 3, 900: 4, 1200: 5 }}
    >
      <Masonry gutter="20px">
        {Object.entries(plannedMeals).map(
          ([plannedMealsId, { plan_name, recipes }]) => {
            const groupedByDateTime = groupByPlanDateTime(recipes);
            return (
              <div
                key={plannedMealsId}
                className="bg-white rounded-lg shadow-sm hover:shadow-lg transition-shadow border border-gray-200 overflow-hidden flex flex-col relative"
              >
                <div className="px-5 pt-5 pb-3 relative">
                  <h3 className="text-xl font-semibold text-blue-600 mb-1">
                    {plan_name}
                  </h3>
                  <div className="absolute top-3 right-4 z-10">
                    <div className="dropdown dropdown-end">
                      <button
                        tabIndex={0}
                        className="btn btn-ghost btn-circle hover:bg-blue-100"
                        onClick={toggleDropdown}
                      >
                        <MdOutlineMoreVert size={24} />
                      </button>
                      {isDropdownOpen && (
                        <ul
                          tabIndex={0}
                          className="dropdown-content menu p-2 shadow-md bg-base-100 rounded-md w-52 border border-gray-200"
                          style={{ position: "absolute" }}
                        >
                          <li className="hover:bg-sky-100">
                            <a
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                closeDropdown();
                                onEditClick(plannedMealsId);
                              }}
                            >
                              {t("planmeals.edit")}
                            </a>
                          </li>
                          <DeletePlannedMeal plannedMealsId={plannedMealsId} />
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="divider"></div>
                  {Object.entries(groupedByDateTime).map(
                    (
                      [dateTime, { plan_date_time, recipes, plan_description }],
                      index
                    ) => (
                      <div key={dateTime} className="mb-4">
                        <div className="text-xs text-gray-500 mb-2">
                          <strong>{t("planmeals.date")}</strong>:{" "}
                          {dayjs
                            .utc(plan_date_time)
                            .format("dddd, MMMM D, YYYY [at] h:mm A")}
                          <br />
                          <strong>{t("planmeals.description")}</strong>:{" "}
                          {plan_description}
                        </div>
                        {recipes.map(({ id, recipe }) => (
                          <div
                            key={id}
                            className="border-b last:border-b-0 p-5 mb-1 hover:bg-blue-100 rounded-lg transition-colors flex justify-between items-center cursor-pointer group"
                            onClick={() => handleView(recipe.url)}
                          >
                            <div>
                              <h4 className="text-lg font-semibold text-blue-500 mb-1">
                                {recipe.name}
                              </h4>
                            </div>
                            <MdNavigateNext className="text-2xl transition-opacity duration-150 ease-in-out opacity-0 group-hover:opacity-100 md:opacity-100" />
                          </div>
                        ))}
                      </div>
                    )
                  )}
                </div>
              </div>
            );
          }
        )}
      </Masonry>
    </ResponsiveMasonry>
  );
};

export default PlannedMealsDisplay;
