import { supabase } from "../../supabaseClient";

const loadCirclePlannedMeals = async (id, setGroupPlans) => {
  let { data, error } = await supabase.rpc("get_circle_plan_meals", {
    circle_id_input: id,
  });

  if (error) {
    console.error("Error loading circle planned meals:", error.message);
    return;
  }

  setGroupPlans(data);
};

export { loadCirclePlannedMeals };
