import { Navigate, useRoutes } from "react-router-dom";
// layouts
import MainLayout from "./components/layout/MainLayout";
// pages
import Landing from "./pages/Landing";
import Home from "./pages/Home";
import PublicRecipes from "./pages/PublicRecipes";
import Recipe from "./pages/recipes/Recipe";
import NewRecipe from "./pages/recipes/NewRecipe";
import NewRecipePhoto from "./pages/recipes/NewRecipePhoto";
import NewRecipeLink from "./pages/recipes/NewRecipeLink";
import ViewRecipe from "./pages/recipes/ViewRecipe";
import UpdateRecipe from "./pages/recipes/UpdateRecipe";
import Circle from "./pages/circles/Circle";
import NewCircle from "./pages/circles/NewCircle";
import PendingCircle from "./pages/circles/PendingCircle";
import ViewCircle from "./pages/circles/ViewCircle";
import ViewInvitationCircle from "./pages/circles/ViewInvitationCircle";
import ViewPublicRecipe from "./pages/recipes/ViewPublicRecipe";
import UpdateCircle from "./pages/circles/UpdateCircle";
import Profile from "./pages/Profile";
import ResetPassword from "./pages/ResetPassword";
import NotFound from "./pages/Page404";
import UpdateRecipeLink from "./pages/recipes/UpdateRecipeLink";
import UpdateRecipePhoto from "./pages/recipes/UpdateRecipePhoto";
import YourCart from "./pages/YourCart";
import PlanMeals from "./pages/PlanMeals";
import NotificationsPage from "./pages/notifications";
import GroupPlanInvites from "./pages/Group-meals-invitation";
import GroupMeals from "./pages/Group-meals";


export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "/", element: <Landing /> },
        { path: "public-recipes", element: <PublicRecipes /> },
        { path: "/dashboard", element: <Home /> },
        { path: "recipes", element: <Recipe /> },
        { path: "recipes/new", element: <NewRecipe /> },
        { path: "recipes/newPhoto", element: <NewRecipePhoto /> },
        { path: "recipes/newLink", element: <NewRecipeLink /> },
        { path: "recipes/:id", element: <ViewRecipe /> },
        { path: "recipes/:id/update", element: <UpdateRecipe /> },
        { path: "recipes/:id/updatephoto", element: <UpdateRecipePhoto /> },
        { path: "recipes/:id/updatelink", element: <UpdateRecipeLink /> },
        { path: "circles", element: <Circle /> },
        { path: "circles/new", element: <NewCircle /> },
        { path: "circles/pending", element: <PendingCircle /> },
        { path: "circles/:id", element: <ViewCircle /> },
        { path: "circles/:id/update", element: <UpdateCircle /> },
        { path: "profile", element: <Profile /> },
        { path: "your-cart", element: <YourCart /> },
        { path: "plan-meals", element: <PlanMeals />},
        { path: "notifications", element: <NotificationsPage />},
        { path: "recipes-view/:url", element: <ViewPublicRecipe /> },
        { path: "circles-invitation/:id", element: <ViewInvitationCircle /> },
        {
          path: "circles-invitation/:id/:status",
          element: <ViewInvitationCircle />,
        },
        { path: "group-plan-invites/:mealId/:assignmentId", element: <GroupPlanInvites /> },
        { path: "group-meals/:mealId", element: <GroupMeals />},

      ],
    },
    
    { path: "404", element: <NotFound /> },
    { path: "circles-invitation/:id", element: <ViewInvitationCircle /> },

    { path: "reset-password", element: <ResetPassword /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
