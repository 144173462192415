import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { supabase } from "../../supabaseClient";

import USFlag from "../../assets/flags/US.svg";
import DEFlag from "../../assets/flags/DE.svg";
import ESFlag from "../../assets/flags/ES.svg";
import FRFlag from "../../assets/flags/FR.svg";
import ITFlag from "../../assets/flags/IT.svg";

function LanguageModal({ isOpen, closeModal, user }) {
  const { i18n } = useTranslation();
  const [userProfile, setUserProfile] = useState(null);
  const { t } = useTranslation();


  const languages = [
    { code: "en", name: "English", flag: USFlag, label: "English" },
    { code: "de", name: "Deutsch", flag: DEFlag, label: "Deutsch" },
    { code: "es", name: "Español", flag: ESFlag, label: "Español" },
    { code: "fr", name: "Français", flag: FRFlag, label: "Français" },
    { code: "it", name: "Italiano", flag: ITFlag, label: "Italiano" },
  ];

  useEffect(() => {
    const loadUser = async () => {
      const { data: sessionData, error: sessionError } = await supabase.auth.getSession();

      if (sessionData.session?.user) {
        const { data: profileData, error: profileError } = await supabase
          .from("profiles")
          .select(`id, language`)
          .eq("id", sessionData.session.user.id)
          .single();

        if (profileError) {
          console.error('Error fetching profile:', profileError.message);
          return;
        }

        if (profileData) {
          setUserProfile(profileData);
          i18n.changeLanguage(profileData.language || 'en');
        }
      }
    };

    loadUser();
  }, []);

  const handleChangeLanguage = async (language) => {
    i18n.changeLanguage(language);
    if (userProfile) {
      const { error } = await supabase
        .from('profiles')
        .update({ language })
        .match({ id: userProfile.id });

      if (error) {
        console.error('Error updating language preference:', error.message);
      }
    } else {
      localStorage.setItem('i18nextLng', language);
    }
    closeModal();
  };

  if (!isOpen) return null;

  if (!isOpen) return null;

  return (
    <div
      className="modal modal-open fixed inset-0 flex items-center justify-center p-4 bg-gray-900 bg-opacity-50 backdrop-filter backdrop-blur-md transition-opacity duration-500 ease-in-out"
      onClick={closeModal}
    >
      <div className="modal-overlay absolute inset-0"></div>

      <div
        className="modal-container bg-white w-full max-w-md mx-auto rounded-2xl shadow-2xl overflow-hidden z-50 transition-all transform duration-300 ease-out scale-95 hover:scale-100"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-col items-center pb-4">
          <p className="text-2xl font-bold text-center mt-4 mb-6 text-gray-800">
          {t("navbar.selectlang")}
          </p>
          <div className="grid grid-cols-1 gap-4 px-4 w-full">
            {languages.map(({ code, name, flag, label }) => (
              <button
                key={code}
                onClick={() => handleChangeLanguage(code)}
                className="group flex items-center justify-start p-3 rounded-md hover:bg-blue-50 transition-colors duration-150 ease-in-out w-full shadow-md hover:shadow-lg"
              >
                <img
                  src={flag}
                  alt={`${name} Flag`}
                  className="w-10 h-6 mr-4 shadow-sm"
                />
                <span className="text-gray-700 group-hover:text-blue-600 font-medium">
                  {label}
                </span>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LanguageModal;
