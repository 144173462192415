import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { supabase } from "../../supabaseClient";
import { GiExitDoor } from "react-icons/gi";

const LeaveCircle = ({ userId, circleId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLeaveCircle = async () => {
    Swal.fire({
      title: t("circles.leave"),
      text: t("circles.leavecont"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc2626",
      cancelButtonColor: "#3b82f6",
      confirmButtonText: t("circles.leaveyes"),
      cancelButtonText: t("circles.cancel"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { error: deleteMemberError } = await supabase
          .from("circle_members")
          .delete()
          .match({ circle_id: circleId, user_id: userId });

        if (deleteMemberError) {
          Swal.fire(t("circles.error"), t("circles.error2"), "error");
        } else {
          const { error: deleteGroupPlannedMealsError } = await supabase
            .from("group_planned_meals")
            .delete()
            .match({ circle_id: circleId, user_id: userId });

          if (deleteGroupPlannedMealsError) {
            Swal.fire(t("circles.error"), t("circles.error2"), "error");
          } else {
            Swal.fire(
              t("circles.left"),
              t("circles.leftcont"),
            ).then(() => {
              navigate("/circles");
            });
          }
        }
      }
    });
  };

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        handleLeaveCircle();
      }}
      className="btn btn-outline hover:bg-red-500"
    >
      <GiExitDoor className="w-7 h-7" />
    </button>
  );
};

export default LeaveCircle

