import React, { useContext, useEffect, useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import { supabase } from "../../supabaseClient";
import mainImage from "../../assets/banner-circle-1.jpeg";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useTranslation } from "react-i18next";
import EditScheduleModal from "../../components/utilities/EditGroupScheduleMeals";
import PlanGroupMeals from "../../components/utilities/PlanGroupMeals";
import ScheduleGroupMeal from "../../components/utilities/ScheduleGroupMeal";
import FinalizeMealModal from "../../components/utilities/FinalizeGroupMeal";
import { loadCirclePlannedMeals } from "../../components/utilities/LoadCircleMeals";
import EditGroupMeals from "../../components/utilities/EditGroupPlanRecipes";
import fetchEmailSuggestions from "../../components/utilities/fetchEmailSuggestions";
import LeaveCircle from "./LeaveCircle";
import {
  MdArrowBackIosNew,
  MdCancel,
  MdAdd,
  MdRemoveCircleOutline,
  MdPersonAdd,
  MdCheckCircle,
  MdVisibility,
  MdGroupRemove,
  MdCheck,
  MdDelete,
  MdOutlineNavigateNext,
  MdPlaylistAdd,
  MdHourglassTop,
  MdEdit,
} from "react-icons/md";
import {
  DeleteIcon,
  DeleteIconSm,
  EditIcon,
} from "../../components/common/Icons";
import CircleHeader from "./CircleHeader";
import { DataContext } from "../../context/DataContext";
import { useNavigate } from "react-router-dom";
import WriteComment from "../../components/common/WriteCommentCircle";
import DisplayComments from "../../components/common/DisplayCommentsCircle";
import Swal from "sweetalert2";
import PhotoUploaderCircle from "../../components/common/PhotoUploaderCircle";
import EditFinalizeMealModal from "../../components/utilities/EditFinalizeSchedule";

const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const ViewCircle = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useContext(DataContext);
  const [circle, setCircle] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const [data, setData] = useState([]);
  const [recipesExists, setRecipesExists] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [newMember, setNewMember] = useState(null);
  const [newMemberPerm, setNewMemberPerm] = useState(0);
  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingRecipes, setIsLoadingRecipes] = useState(true);
  const [errorMember, setErrorMember] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fileInputRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const { register, handleSubmit } = useForm();
  const [addedRecipes, setAddedRecipes] = useState([]);
  const [plannedRecipes, setPlannedRecipes] = useState([]);
  const { t } = useTranslation();
  const context = useContext(DataContext);
  const user = context ? context.user : null;
  const [scheduleDetails, setScheduleDetails] = useState([
    {
      datePlanned: "",
      timePlanned: "",
      eventName: "",
    },
  ]);
  const [searchTerm, setSearchTerm] = useState("");
  const [recipesUpdateCounter, setRecipesUpdateCounter] = useState(0);
  const statusIcon = {
    0: <MdPersonAdd className="text-yellow-400" />,
    1: <MdCheckCircle className="text-green-500" />,
    2: <MdCancel className="text-red-500" />,
    new: <MdHourglassTop className="text-blue-500" />,
  };

  function getStatusTooltip(item) {
    if (item.status === 1 && item.user_id === null) {
      return t("viewcircle.status1");
    }

    switch (item.status) {
      case 0:
        return t("viewcircle.status2");
      case 1:
        return t("viewcircle.status3");
      case 2:
        return t("viewcircle.status4");
      default:
        return "";
    }
  }

  const [groupPlans, setGroupPlans] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState(null);

  const [reloadTrigger, setReloadTrigger] = useState(0);

  dayjs.extend(utc);
  dayjs.extend(timezone);

  useEffect(() => {
    if (state.user?.id) {
      fetchEmailSuggestions(state.user.id)
        .then(setEmailSuggestions)
        .catch(console.error);
    }
  }, [state.user?.id]);

  useEffect(() => {
    const filtered = emailSuggestions.filter((email) =>
      email.toLowerCase().includes((newMember || "").toLowerCase())
    );

    setFilteredSuggestions(filtered);

    if (newMember?.length > 1) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  }, [newMember, emailSuggestions]);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const [isPlanningModalOpen, setIsPlanningModalOpen] = useState(false);
  const openPlanningModal = (mealPlanId = null) => {
    setIsPlanningModalOpen(true);
    setCurrentPlannedMealsId(mealPlanId);
  };

  const closePlanningModal = () => {
    setIsPlanningModalOpen(false);
    setCurrentPlannedMealsId(null);
  };

  const [isEditGroupMealOpen, setisEditGroupMealOpen] = useState(false);
  const openEditGroupMeal = (groupPlansId) => {
    setisEditGroupMealOpen(true);
    setCurrentPlannedMealsId(groupPlansId);
  };

  const closeEditGroupMeal = () => {
    setisEditGroupMealOpen(false);
  };

  const [
    isEditSchedulePlanModalOpen,
    setIsEditSchedulePlanModalOpen,
  ] = useState(false);

  const openEditSchedulePlanModal = async (groupPlansId) => {
    let { data, error } = await supabase.rpc("get_group_plan_details", {
      group_plans_id_input: groupPlansId,
    });

    if (error) {
      console.error("Error loading group plan details:", error.message);
      return;
    }

    const planDetails = data.map((detail) => ({
      datePlanned: detail.event_date_time.split("T")[0],
      timePlanned: detail.event_date_time.split("T")[1].substring(0, 5),
      eventDescription: detail.event_description,
    }));

    setScheduleDetails(planDetails);
    closeEditGroupMeal();
    setIsEditSchedulePlanModalOpen(true);
  };

  const closeEditSchedulePlanModal = () => {
    setIsEditSchedulePlanModalOpen(false);
  };

  const [isEditFinalizeModalOpen, setIsEditFinalizeModalOpen] = useState(false);

  const openEditFinalizeModal = (scheduleDetails, groupPlansId) => {
    setScheduleDetails(scheduleDetails);
    setCurrentPlannedMealsId(groupPlansId);
    setAddedRecipes(addedRecipes);
    setIsEditSchedulePlanModalOpen(false);
    setIsEditFinalizeModalOpen(true);
  };

  const closeEditFinalizeModal = () => setIsEditFinalizeModalOpen(false);

  const [isFinalizeModalOpen, setIsFinalizeModalOpen] = useState(false);
  const openFinalizeModal = () => setIsFinalizeModalOpen(true);
  const closeFinalizeModal = () => setIsFinalizeModalOpen(false);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentPlannedMealsId, setCurrentPlannedMealsId] = useState(null);

  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);

  const closeScheduleModal = () => {
    setIsScheduleModalOpen(false);
  };

  const openScheduleModal = () => setIsScheduleModalOpen(true);

  const eventsByGroup = groupPlans.reduce((acc, plan) => {
    acc[plan.group_plans_id] = acc[plan.group_plans_id] || [];
    acc[plan.group_plans_id].push(plan);
    return acc;
  }, {});

  const loadCircle = async () => {
    setIsLoading(true);

    try {
      let { data: dataC, error: errorC, status: statusC } = await supabase
        .from("circles")
        .select(
          `
          id, name, description, image_url, owner_id, created_at,
          circle_recipes(
            *,
            recipes (id, name, url, author)
          ),
          circle_members(
            id, email, status, permission, user_id
          )
        `
        )
        .eq("id", id)
        .single();

      if (errorC && statusC !== 406) {
        throw new Error(errorC.message);
      }

      setCircle(dataC);
      setImageURL(
        `${process.env.REACT_APP_SUPABASE_BUCKET_URL}${dataC.image_url}`
      );
      setRecipesExists(dataC.circle_recipes.map((item) => item.recipes.id));
    } catch (error) {
      console.error("Error loading circle:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!circle || id !== circle?.id) {
      loadCircle();
    }
  }, [id]);
  const CircleModal = ({ isOpen, onClose, circle }) => {
    const fileInputRef = useRef(null);
    const { register, handleSubmit, setValue, reset } = useForm();

    const [formData, setFormData] = useState({
      name: "",
      description: "",
      image_url: null,
    });

    useEffect(() => {
      if (circle) {
        const updatedFormData = {
          name: circle.name,
          description: circle.description,
          image_url: circle.image_url,
        };
        setFormData(updatedFormData);
        setValue("name", circle.name);
        setValue("description", circle.description);
      }
    }, [circle, setValue]);

    const onChangeImage = (imageUrl) => {
      setFormData({ ...formData, image_url: imageUrl });
    };

    const processSubmit = async (dataInput) => {
      const updatedData = {
        ...formData,
        name: dataInput.name,
        description: dataInput.description,
      };

      try {
        const {
          data: { user },
        } = await supabase.auth.getUser();
        const update = {
          id: circle.id,
          owner_id: user.id,
          ...updatedData,
        };

        let { error, status } = await supabase
          .from("circles")
          .upsert(update)
          .single();

        if (error && status !== 406) throw error;

        Swal.fire({
          icon: "success",
          title: t("viewcircle.editprompt"),
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          reset();
          onClose();
          loadCircle();
        });
      } catch (error) {
        console.error("Error updating circle:", error);
        Swal.fire({
          icon: "error",
          title: t("viewcircle.error"),
          text: t("viewcircle.errortext"),
          showConfirmButton: false,
          timer: 2000,
        });
      }
    };

    return (
      <div
        className={`modal ${
          isOpen ? "modal-open" : ""
        } fixed w-full h-full top-0 left-0 flex items-center justify-center`}
      >
        <div
          className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
          onClick={onClose}
        ></div>
        <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-2xl  z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
            <div className="flex flex-col items-center pb-3">
              <p className="text-2xl font-bold text-center">
                {t("viewcircle.edit")} {circle?.name}
              </p>
              <div className="divider"></div>
            </div>
            <div className="">
              <form onSubmit={handleSubmit((data) => processSubmit(data))}>
                <PhotoUploaderCircle
                  imageUrl={formData.image_url}
                  onChangeImage={onChangeImage}
                  uploading={uploading}
                />

                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">{t("viewcircle.name")}</span>
                  </label>
                  <input
                    {...register("name", {
                      required: true,
                    })}
                    defaultValue={circle?.name}
                    className="input border border-gray-900 focus:outline-none focus:border-blue-500 p-2"
                  />
                </div>

                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">
                      {t("viewcircle.description")}
                    </span>
                  </label>
                  <textarea
                    {...register("description")}
                    defaultValue={circle?.description}
                    className="input border border-gray-900 focus:outline-none focus:border-blue-500 p-2 h-32"
                  />
                </div>

                <button
                  type="submit"
                  className={`btn btn-outline mt-4 w-full ${
                    uploading ? "disabled:opacity-50" : ""
                  }`}
                  disabled={uploading}
                >
                  {uploading
                    ? t("viewcircle.uploading")
                    : t("viewcircle.savesettings")}
                </button>
              </form>
            </div>
            <div className="flex justify-end pt-2 mt-2">
              <button
                className="modal-close px-4 bg-transparent p-3 rounded-lg text-stone-700 hover:bg-gray-300"
                onClick={onClose}
              >
                {t("viewcircle.close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const loadRecipes = async () => {
    setIsLoadingRecipes(true);
    let { data: dataRecipes, error: errorR, status: statusR } = await supabase
      .from("recipes")
      .select(
        `id, name, author, description, type, duration, servings, steps, image_url, public, owner_id,
        recipe_ingredients (
          name
        ),recipe_categories (
          category_key,
          type
        ),profiles (first_name, last_name)`
      )
      .eq("owner_id", state?.user?.id)
      .order("created_at", { ascending: false });

    if (errorR && statusR !== 406) {
      throw errorR;
    }
    setRecipes(dataRecipes);
    setIsLoadingRecipes(false);
  };

  const handleDelete = async () => {
    if (circle?.owner_id === state.user?.id) {
      const { error } = await supabase.from("circles").delete().eq("id", id);
      if (error) {
        throw error;
      }
    } else {
      const selectedMember = circle?.circle_members.find(
        (item) => item.email === state.user?.email
      );
      if (selectedMember) {
        const updates = {
          id: selectedMember.id,
          status: 2,
        };

        const { error } = await supabase.from("circle_members").upsert(updates);

        if (error) {
          throw error;
        }
      }
    }

    navigate("/circles");
  };

  const handleDeleteMember = async () => {
    const { error } = await supabase
      .from("circle_members")
      .delete()
      .eq("id", selectedItem);
    if (error) {
      throw error;
    }

    const updateMembers = circle.circle_members.filter(
      (item) => item.id !== selectedItem
    );
    setCircle({ ...circle, circle_members: updateMembers });
  };

  const handleDeleteRecipe = async () => {
    const { error } = await supabase
      .from("circle_recipes")
      .delete()
      .eq("id", selectedItem);
    if (error) {
      throw error;
    }

    const updateRecipes = circle.circle_recipes.filter(
      (item) => item.id !== selectedItem
    );
    setCircle({ ...circle, circle_recipes: updateRecipes });
    setRecipesExists(updateRecipes.map((item) => item.recipes.id));
  };

  const handleAddMember = async () => {
    if (
      newMember === null ||
      newMember === "" ||
      validateEmail(newMember) === false
    ) {
      setErrorMember(t("viewcircle.erroremail"));
      setTimeout(() => {
        setErrorMember(null);
      }, 5000);
      return;
    }
    if (newMemberPerm === 0) {
      setErrorMember(t("viewcircle.errorpermission"));
      setTimeout(() => {
        setErrorMember(null);
      }, 5000);
      return;
    }

    const myEmail = circle.circle_members.find(
      (item) => newMember === state.user?.email
    );
    if (myEmail) {
      setErrorMember(t("viewcircle.errorowner"));
      setTimeout(() => {
        setErrorMember(null);
      }, 5000);
      return;
    }

    const existsMember = circle.circle_members.find(
      (item) => item.email === newMember
    );
    if (existsMember) {
      setErrorMember(t("viewcircle.errormember"));
      setTimeout(() => {
        setErrorMember(null);
      }, 5000);
      return;
    }

    const insertMember = {
      circle_id: circle.id,
      email: newMember,
      permission: newMemberPerm,
    };
    let { data: dataM, error: errorM, status: statusM } = await supabase
      .from("circle_members")
      .insert(insertMember)
      .select(`id, email, status, permission`)
      .single();

    if (errorM && statusM !== 406) {
      throw errorM;
    }

    const updateMembers = [...circle.circle_members, dataM];

    setCircle({ ...circle, circle_members: updateMembers });

    // Send notification with email js
    const templateParams = {
      circle_name: circle.name,
      from_name: `${state.user?.first_name} ${state.user?.last_name}`,
      to_email: newMember,
      permission: newMemberPerm === 1 ? "explore" : "explore, contribute",
      url: `${process.env.REACT_APP_URL}circles-invitation/${dataM.id}`,
    };
    emailjs
      .send(
        "service_f1pcnl8",
        "template_7o2eokn",
        templateParams,
        process.env.REACT_APP_EMAIL_JS_TOKEN
      )
      .then((result) => {
        console.log(result.text);
      })
      .catch((error) => {
        console.log(error.text);
      });
    setNewMember(null);
    setNewMemberPerm(0);
  };

  const handleAddRecipe = async (recipeId) => {
    const insertRecipe = {
      circle_id: circle.id,
      recipe_id: recipeId,
      owner_id: state.user?.id,
    };
    let { data: dataR, error: errorR, status: statusR } = await supabase
      .from("circle_recipes")
      .insert(insertRecipe)
      .select(
        `
        id, recipe_id, owner_id,
        recipes ( id, name, url, author )`
      )
      .single();

    if (errorR && statusR !== 406) {
      throw errorR;
    }

    const updateRecipes = [...circle.circle_recipes, dataR];

    setCircle({ ...circle, circle_recipes: updateRecipes });
    setRecipesExists([...recipesExists, recipeId]);
    setRecipesUpdateCounter((prevCounter) => prevCounter + 1);
  };

  const circleImg = circle?.image_url
    ? `${process.env.REACT_APP_SUPABASE_BUCKET_URL}${circle.image_url}`
    : mainImage;

  const manageCircle = circle?.owner_id === state.user?.id;

  const manageRecipe =
    circle?.circle_members.find(
      (item) => item.email === state.user?.email && item.permission === 2
    ) || circle?.owner_id === state?.user?.id;

  const manageRecipeUser = circle?.circle_recipes.find(
    (item) => item.owner_id === state.user?.id
  );

  useEffect(() => {
    if (id) {
      loadCirclePlannedMeals(id, setGroupPlans);
    }
  }, [id, reloadTrigger]);

  const groupPlansId =
    groupPlans.length > 0 ? groupPlans[0].group_plans_id : undefined;

  const deleteGroupPlan = async (groupId) => {
    try {
      const response = await supabase
        .from("group_plans")
        .delete()
        .eq("id", groupId);

      if (response.error) {
        throw response.error.message;
      }

      setReloadTrigger((reloadTrigger) => reloadTrigger + 1);
    } catch (error) {
      console.error("Error deleting group plan:", error);
    }
  };

  const handleDeleteGroupPlan = (groupId) => {
    Swal.fire({
      title: t("viewcircle.deletegroupplan"),
      text: t("viewcircle.deletegptext"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc2626",
      cancelButtonColor: "#3b82f6",
      confirmButtonText: t("viewcircle.yesdelete"),
      cancelButtonText: t("viewcircle.cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteGroupPlan(groupId);
        Swal.fire(
          t("viewcircle.deleted"),
          t("viewcircle.gpdeleted"),
          "success"
        );
      }
    });
  };
  const description = circle?.description || t("viewcircle.descriptioncircle");

  return (
    <div>
      <Helmet>
        <title>
          {circle
            ? `${circle.name} - RecipeCircle`
            : "View Circle - RecipeCircle"}
        </title>
        <meta name="description" content={description} />
        <meta
          property="og:title"
          content={
            circle
              ? `${circle.name} - RecipeCircle`
              : "View Circle - RecipeCircle"
          }
        />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://app.recipecircle.world/circles/${id}`}
        />
      </Helmet>
      <div className="w-100 container mx-auto">
        <PlanGroupMeals
          isOpen={isPlanningModalOpen}
          onClose={closePlanningModal}
          circleId={id}
          plannedRecipes={plannedRecipes}
          setPlannedRecipes={setPlannedRecipes}
          onContinue={openScheduleModal}
          handleAddRecipe={handleAddRecipe}
          recipesUpdateCounter={recipesUpdateCounter}
        />
        <EditGroupMeals
          isOpen={isEditGroupMealOpen}
          onClose={closeEditGroupMeal}
          groupPlansId={currentPlannedMealsId}
          circleId={id}
          addedRecipes={addedRecipes}
          setAddedRecipes={setAddedRecipes}
          onContinue={() => openEditSchedulePlanModal(currentPlannedMealsId)}
          recipesUpdateCounter={recipesUpdateCounter}
        />
        <EditScheduleModal
          isOpen={isEditSchedulePlanModalOpen}
          onClose={closeEditSchedulePlanModal}
          scheduleDetails={scheduleDetails}
          setScheduleDetails={setScheduleDetails}
          openFinalizeEditModal={(scheduleDetails) =>
            openEditFinalizeModal(scheduleDetails, currentPlannedMealsId)
          }
          groupPlansId={currentPlannedMealsId}
          addedRecipes={addedRecipes}
          setAddedRecipes={setAddedRecipes}
        />
        <ScheduleGroupMeal
          isOpen={isScheduleModalOpen}
          onClose={closeScheduleModal}
          openFinalizeModal={openFinalizeModal}
          circleId={id}
          plannedRecipes={plannedRecipes}
          scheduleDetails={scheduleDetails}
          setPlannedRecipes={setPlannedRecipes}
          setScheduleDetails={setScheduleDetails}
        />
        <EditFinalizeMealModal
          isOpen={isEditFinalizeModalOpen}
          onClose={closeEditFinalizeModal}
          circleId={id}
          plannedRecipes={plannedRecipes}
          addedRecipes={addedRecipes}
          setAddedRecipes={setAddedRecipes}
          scheduleDetails={scheduleDetails}
          reloadTrigger={reloadTrigger}
          setReloadTrigger={setReloadTrigger}
          groupPlansId={currentPlannedMealsId}
        />

        <FinalizeMealModal
          isOpen={isFinalizeModalOpen}
          onClose={closeFinalizeModal}
          circleId={id}
          plannedRecipes={plannedRecipes}
          setPlannedRecipes={setPlannedRecipes}
          scheduleDetails={scheduleDetails}
          setScheduleDetails={setScheduleDetails}
          reloadTrigger={reloadTrigger}
          setReloadTrigger={setReloadTrigger}
        />
        <div className="w-full overflow-x-hidden">
          <div className="text-center">
            <h1 className="text-4xl font-bold py-4 text-[#384E79]">
              {circle?.name}
            </h1>
            <div className="breadcrumbs flex justify-center mb-1">
              <ul>
                <li>
                  <a
                    onClick={() => {
                      navigate("/");
                    }}
                    className="text-[#384E79] hover:text-blue-400"
                  >
                    {t("viewcircle.home")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/circles");
                    }}
                    className="text-[#384E79] hover:text-blue-400"
                  >
                    {t("viewcircle.yourcirlces")}
                  </a>
                </li>
                <li className="text-stone-700">{circle?.name}</li>
              </ul>
            </div>
          </div>

          <div className="flex justify-between mb-1">
            <div className="flex-1">
              <button
                className="btn btn-outline"
                onClick={() => {
                  navigate("/circles");
                }}
              >
                <MdArrowBackIosNew />
              </button>
            </div>
            {!manageCircle && (
              <div className="flex-1 text-right">
                <div>
                  <LeaveCircle userId={state.user?.id} circleId={id} />
                </div>
              </div>
            )}
            {manageCircle && (
              <div className="flex-1 text-right">
                <div>
                  <button
                    className="btn btn-outline mr-2 hover:bg-green-400"
                    onClick={openModal}
                  >
                    <EditIcon />
                  </button>
                  <CircleModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    circle={circle}
                  />

                  <label
                    htmlFor="my-modal"
                    className="btn btn-outline hover:bg-red-400"
                  >
                    <DeleteIcon />
                  </label>
                </div>
              </div>
            )}
          </div>

          <div className="divider"></div>
        </div>

        {isLoading ? (
          <div className="flex justify-center">
            <button className="btn btn-outline btn-accent loading">
              loading
            </button>
          </div>
        ) : (
          <div className="flex justify-center">
            <div className="card w-full md:w-2/3 lg:w-2/3 bg-gray-200 shadow-2xl mb-8 rounded-lg overflow-hidden">
              <div className="w-full h-60 md:h-96 lg:h-96 relative">
                <img
                  className="absolute inset-0 w-full h-full object-cover"
                  src={circleImg}
                  alt="Circle"
                />
              </div>
              <div className="card-body p-6 md:p-10">
                <h2 className="font-bold text-4xl mb-4">{circle?.name}</h2>
                {circle?.description && (
                  <div className="border border-gray-300 bg-gray-100 rounded-box shadow-xl p-6">
                    <p className="text-gray-600">{circle.description}</p>
                  </div>
                )}

                <div className="bg-gray-100 p-4 mt-4 rounded-box shadow-xl">
                  {circle?.circle_members?.length ? (
                    <div className="space-y-4">
                      <div className="font-bold text-2xl mb-2 flex justify-between items-center">
                        <span>{t("viewcircle.members")}</span>
                        {manageCircle && (
                          <label
                            htmlFor="add-member-modal"
                            className="btn btn-outline hover:bg-blue-400"
                            type="button"
                            onClick={() => {
                              setNewMember("");
                              setNewMemberPerm(0);
                              setErrorMember(null);
                            }}
                          >
                            <MdAdd /> {t("viewcircle.addmember")}
                          </label>
                        )}
                      </div>
                      <div className="divider"></div>
                      {circle?.circle_members
                        .filter((item) => manageCircle || item.status === 1)
                        .map((item, index) => {
                          const icon =
                            item.status === 1 && item.user_id === null
                              ? statusIcon["new"]
                              : statusIcon[item.status];
                          return (
                            <div
                              key={`member-${index}`}
                              className="bg-white p-4 rounded-md my-2 shadow-sm cursor-pointer flex flex-col md:flex-row justify-between items-center hover:shadow-lg"
                            >
                              <div className="flex flex-col md:flex-row items-center w-full">
                                {manageCircle && (
                                  <label
                                    htmlFor="delete-member-modal"
                                    className="btn bg-red-500 text-zinc-200 btn-circle btn-sm mr-4 hover:bg-red-600 mb-2 md:mb-0"
                                    onClick={() => {
                                      setSelectedItem(item.id);
                                    }}
                                  >
                                    <MdGroupRemove />
                                  </label>
                                )}
                                <span className="font-medium truncate w-full md:w-auto">
                                  {item.email}
                                </span>
                                {item.email === state.user?.email && (
                                  <span className="badge badge-accent ml-2">
                                    {t("viewcircle.me")}
                                  </span>
                                )}
                              </div>
                              <div className="flex items-center mt-2 md:mt-0">
                                {manageCircle && (
                                  <span className="flex items-center mr-4">
                                    {Number(item.permission) === 1 ? (
                                      <div
                                        className="tooltip"
                                        data-tip={t("viewcircle.view")}
                                      >
                                        <MdVisibility className="text-green-500 mr-1 text-lg" />
                                      </div>
                                    ) : (
                                      <div
                                        className="tooltip"
                                        data-tip={t("viewcircle.viewadd")}
                                      >
                                        <MdPlaylistAdd className="text-blue-500 mr-1 text-lg" />
                                      </div>
                                    )}
                                  </span>
                                )}
                                <div
                                  className="tooltip md:tooltip-top sm:tooltip-top lg:tooltip-left"
                                  data-tip={getStatusTooltip(item)}
                                >
                                  <span className="icon text-lg">{icon}</span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  ) : (
                    <>
                      <div className="text-center">
                        {t("viewcircle.nomembers")}
                      </div>
                      <div className="w-100">
                        <label
                          htmlFor="add-member-modal"
                          className="btn btn-outline w-full hover:bg-blue-400 mt-2"
                          type="button"
                          onClick={() => {
                            setNewMember("");
                            setNewMemberPerm(0);
                            setErrorMember(null);
                          }}
                        >
                          <MdAdd /> {t("viewcircle.addmember")}
                        </label>
                      </div>
                    </>
                  )}
                </div>

                <div className="mt-4 bg-gray-100 p-4 rounded-lg">
                  {circle?.circle_recipes?.length ? (
                    <div className="space-y-4">
                      <div className="font-bold text-2xl mb-2 flex justify-between items-center">
                        <span>{t("viewcircle.recipes")}</span>
                        {manageRecipe && (
                          <label
                            htmlFor="recipes-modal"
                            className="btn btn-outline hover:bg-blue-400"
                            onClick={loadRecipes}
                          >
                            <MdAdd /> {t("viewcircle.addrecipe")}
                          </label>
                        )}
                      </div>
                      <div className="divider"></div>
                      {circle?.circle_recipes.map((item, index) => (
                        <div
                          key={`recipe-${index}`}
                          className="bg-white p-4 rounded-md my-2 shadow-sm cursor-pointer flex items-center justify-between hover:shadow-lg hover:bg-blue-100 transition-shadow duration-200"
                          onClick={() =>
                            navigate(`/recipes-view/${item.recipes.url}`)
                          }
                        >
                          <div className="flex items-center space-x-4 flex-grow">
                            {(manageCircle ||
                              item.owner_id === state?.user?.id) && (
                              <label
                                htmlFor="delete-recipe-modal"
                                className="btn bg-red-500 text-zinc-200 btn-circle btn-sm mr-4 hover:bg-red-600 mb-2 md:mb-0"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setSelectedItem(item.id);
                                }}
                              >
                                <MdRemoveCircleOutline />
                              </label>
                            )}
                            <div>
                              <span className="font-medium text-gray-800">
                                {item.recipes.name}
                              </span>
                              {item.recipes.author ? (
                                <div className="text-sm italic text-gray-500">
                                  {t("viewrecipe.recipeby")}{" "}
                                  {item.recipes.author}
                                </div>
                              ) : (
                                <div className="text-sm italic text-gray-500">
                                  No author
                                </div>
                              )}
                            </div>
                          </div>
                          <MdOutlineNavigateNext className="text-gray-400" />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <>
                      <div className="text-center">
                        {t("viewcircle.norecipes")}
                      </div>
                      <div className="w-100">
                        <label
                          htmlFor="recipes-modal"
                          className="btn btn-outline w-full hover:bg-blue-400 mt-2"
                          onClick={loadRecipes}
                        >
                          <MdAdd /> {t("viewcircle.addrecipe")}
                        </label>
                      </div>
                    </>
                  )}
                </div>

                <div className="bg-gray-100 p-4 mt-4 rounded-box shadow-md">
                  <div className="space-y-4">
                    <div className="mb-2 flex justify-between items-center">
                      <span className="font-bold text-2xl">
                        {t("viewcircle.planneventmeals")}
                      </span>
                      {manageCircle && (
                        <button
                          className="btn btn-outline hover:bg-blue-400 flex items-center"
                          onClick={openPlanningModal}
                        >
                          <MdAdd className="mr-2" />{" "}
                          {t("viewcircle.plannedevents")}
                        </button>
                      )}
                    </div>
                    <div className="divider"></div>
                    {Object.keys(eventsByGroup).length > 0 &&
                      Object.entries(eventsByGroup).map(([groupId, plans]) => (
                        <div
                          key={groupId}
                          className="bg-gray-200 p-4 rounded-lg shadow mb-4"
                        >
                          <div className="text-gray-900 text-2xl font-bold mb-4">
                            {plans[0].event_name}
                          </div>
                          {plans.map((plan, index) => (
                            <React.Fragment key={plan.event_date_time}>
                              <div className="flex justify-between items-center mb-4">
                                <div className="italic text-gray-800">
                                  <span className="font-semibold">
                                    {plan.event_description}
                                  </span>{" "}
                                  {t("viewcircle.on")}{" "}
                                  {dayjs
                                    .utc(plan.event_date_time)
                                    .format(
                                      `DD/MM/YYYY [${t("viewcircle.at")}] HH:mm`
                                    )}
                                </div>
                                {manageCircle && index === 0 && (
                                  <div className="ml-auto flex items-center">
                                    <button
                                      className="text-emerald-400 hover:text-emerald-500 mr-4"
                                      onClick={() => openEditGroupMeal(groupId)}
                                    >
                                      <MdEdit className="w-6 h-6" />
                                    </button>
                                    <button
                                      className="text-red-500 hover:text-red-600"
                                      onClick={() =>
                                        handleDeleteGroupPlan(groupId)
                                      }
                                    >
                                      <MdDelete className="w-6 h-6" />
                                    </button>
                                  </div>
                                )}
                              </div>
                              {plan.recipes.map((recipe) => (
                                <div
                                  key={recipe.id}
                                  className="bg-white p-4 rounded-md my-2 shadow-sm cursor-pointer flex items-center justify-between hover:shadow-lg hover:bg-blue-100 transition-shadow duration-200"
                                  onClick={() =>
                                    navigate(`/recipes-view/${recipe.url}`)
                                  }
                                >
                                  <div>
                                    <span className="text-blue-600 font-semibold">
                                      {recipe.name}
                                    </span>
                                    <div className="text-sm text-gray-600">
                                      {t("viewcircle.assigned")}{" "}
                                      {recipe.profile?.first_name ||
                                      recipe.profile?.last_name
                                        ? `${recipe.profile.first_name || ""} ${
                                            recipe.profile.last_name || ""
                                          }`.trim()
                                        : recipe.profile?.email ||
                                          "Unknown User"}
                                    </div>
                                  </div>
                                  <MdOutlineNavigateNext className="text-gray-400" />
                                </div>
                              ))}
                              {index !== plans.length - 1 && (
                                <div className="divider"></div>
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <>
          {state && state.user && (
            <>
              <section className="bg-gray-200 py-8 lg:py-16 antialiased shadow-2xl rounded-2xl mx-auto my-8 w-full md:w-2/3 lg:w-2/3">
                <div className="px-4 sm:px-6 lg:px-8">
                  <h2 className="text-gray-800 mb-6 font-semibold text-2xl border-b border-gray-300 pb-4">
                    {circle?.name} {t("viewcircle.discussion")}
                  </h2>
                  <div className="mb-6">
                    <WriteComment
                      circleId={id}
                      userId={state?.user?.id}
                      onCancel={() => {}}
                    />
                  </div>
                  <div className="space-y-6">
                    <DisplayComments circleId={id} userId={state?.user?.id} />
                  </div>
                </div>
              </section>
            </>
          )}
        </>
        <input type="checkbox" id="edit-modal" className="modal-toggle" />

        <div
          className={`modal fixed w-full h-full top-0 left-0 flex items-center justify-center`}
        >
          <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>

          <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-2xl z-50 overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">
              <div className="flex flex-col items-center pb-3">
                <p className="text-2xl font-bold text-center">
                  {t("viewcircle.edit")} {circle?.name}
                </p>{" "}
              </div>
              <div className="flex justify-end pt-4">
                <label
                  htmlFor="edit-modal"
                  className="btn btn-outline hover:bg-blue-400"
                  onClick={closeModal}
                >
                  {t("viewcircle.close")}
                </label>
              </div>
            </div>
          </div>
        </div>

        <input type="checkbox" id="my-modal" className="modal-toggle" />
        <div
          className={`modal fixed w-full h-full top-0 left-0 flex items-center justify-center`}
        >
          <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
          <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-2xl z-50 overflow-y-auto">
            <div className="modal-content py-4 text-left px-6">
              <div className="flex flex-col items-center pb-1">
                <p className="text-2xl font-bold text-center">
                  {t("viewcircle.delete")}
                </p>
              </div>
              <div className="divider"></div>
              <p className="text-md text-gray-700 text-center mt-4">
                {t("viewcircle.deletecirc1")} {circle?.name}?
              </p>
              <div className="flex justify-end pt-4">
                <button
                  className="px-4 bg-red-500 text-white p-3 rounded-lg mr-2 hover:bg-red-600"
                  onClick={handleDelete}
                >
                  {t("viewcircle.delete")}
                </button>
                <label
                  htmlFor="my-modal"
                  className="btn btn-outline hover:bg-blue-400"
                >
                  {t("viewcircle.cancel")}
                </label>
              </div>
            </div>
          </div>
        </div>

        <input
          type="checkbox"
          id="delete-member-modal"
          className="modal-toggle"
        />
        <div className="modal">
          <div className="modal-box bg-white w-96 mx-auto p-6 rounded-lg shadow-lg">
            <label
              htmlFor="delete-member-modal"
              className="btn btn-sm btn-circle absolute right-2 top-2 bg-white text-stone-800 hover:text-gray-700 transition duration-300"
            >
              ✕
            </label>
            <h3 className="font-bold text-2xl mb-4 text-center">
              {t("viewcircle.removemember")}
            </h3>
            <div className="divider"></div>
            <p className="text-gray-700 py-4 text-center">
              {t("viewcircle.removemembertext")}
            </p>
            <div className="modal-action mt-4 text-center">
              <label
                htmlFor="delete-member-modal"
                className="btn bg-red-400 hover:bg-red-500 text-stone-800 cursor-pointer"
                onClick={handleDeleteMember}
              >
                {t("viewcircle.removemember")}
              </label>
            </div>
          </div>
        </div>

        <input
          type="checkbox"
          id="delete-recipe-modal"
          className="modal-toggle"
        />
        <div className="modal">
          <div className="modal-box bg-white w-96 mx-auto p-6 rounded-lg shadow-lg">
            <label
              htmlFor="delete-recipe-modal"
              className="btn btn-sm btn-circle bg-white absolute right-2 top-2 text-stone-800 hover:text-gray-700 transition duration-300"
            >
              ✕
            </label>
            <h3 className="font-bold text-2xl mb-4 text-center">
              {t("viewcircle.removerecipe")}
            </h3>
            <div className="divider"></div>
            <p className="text-gray-700 py-4 text-center">
              {t("viewcircle.removerecipetext")}
            </p>
            <div className="modal-action mt-4 text-center">
              <label
                htmlFor="delete-recipe-modal"
                className="btn bg-red-400 hover:bg-red-500 cursor-pointer"
                onClick={handleDeleteRecipe}
              >
                {t("viewcircle.removerecipe")}
              </label>
            </div>
          </div>
        </div>

        <input type="checkbox" id="add-member-modal" className="modal-toggle" />
        <div className="modal">
          <div className="modal-box bg-white w-96 mx-auto p-6 rounded-lg shadow-lg">
            <label
              htmlFor="add-member-modal"
              className="btn btn-sm btn-circle bg-white absolute right-2 top-2 text-stone-800 hover:text-gray-700 transition duration-300"
            >
              ✕
            </label>
            <h3 className="font-bold text-2xl mb-4 text-center">
              {t("viewcircle.addmember")}
            </h3>
            <div className="divider"></div>
            <div className="w-full">
              <input
                className="input border-black w-full mb-2 p-2 rounded-lg focus:outline-none focus:border-blue-500"
                onChange={(e) => {
                  setNewMember(e.target.value);
                  if (!e.target.value) setShowSuggestions(false);
                  else setShowSuggestions(true);
                }}
                value={newMember ?? ""}
                placeholder={t("viewcircle.enteremail")}
                onFocus={() => setShowSuggestions(true)}
                onBlur={() => {
                  setTimeout(() => setShowSuggestions(false), 100);
                }}
              />
              {showSuggestions && newMember && emailSuggestions.length > 0 && (
                <ul className="list-none p-0 m-0 mb-4 max-h-40 overflow-y-auto border border-gray-300 rounded-lg">
                  {filteredSuggestions
                    .filter((email) =>
                      email
                        .toLowerCase()
                        .includes((newMember || "").toLowerCase())
                    )
                    .map((email, index) => (
                      <li
                        key={index}
                        className="p-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => {
                          setNewMember(email);
                          setShowSuggestions(false);
                        }}
                      >
                        {email}
                      </li>
                    ))}
                </ul>
              )}
              <div className="mt-2">
                <select
                  className="select select-bordered w-full max-w-xs p-2 rounded-lg focus:outline-none focus:border-blue-500"
                  onChange={(e) => setNewMemberPerm(e.target.value)}
                  value={newMemberPerm ?? 0}
                >
                  <option value={0} disabled>
                    {t("viewcircle.selectperm")}
                  </option>
                  <option value={1}>{t("viewcircle.viewperm")}</option>
                  <option value={2}>{t("viewcircle.viewaddperm")}</option>
                </select>
              </div>
            </div>

            <div className="modal-action mt-4">
              <label
                htmlFor="add-member-modal"
                className="btn btn-outline hover:bg-blue-400 w-full cursor-pointer"
                onClick={handleAddMember}
              >
                {t("viewcircle.invite")}
              </label>
            </div>
          </div>
        </div>

        <input type="checkbox" id="recipes-modal" className="modal-toggle" />
        <div className="modal">
          <div className="modal-box bg-white w-full max-w-4xl mx-auto p-6 rounded-lg flex flex-col shadow-xl">
            <label
              htmlFor="recipes-modal"
              className="btn btn-sm btn-circle absolute right-2 top-2 text-stone-800 hover:text-gray-700 bg-white transition duration-300"
            >
              ✕
            </label>
            <h3 className="font-bold text-2xl mb-4 text-center">
              {t("viewcircle.addrecipes")}
            </h3>
            <div>
              <input
                type="text"
                placeholder={t("recipe.searchrecipes")}
                className="w-full input border border-stone-400 focus:outline-none focus:border-blue-500 focus:ring-0"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className="divider"></div>
            {isLoadingRecipes ? (
              <div className="flex justify-center">
                <span className="loading loading-spinner loading-lg"></span>
              </div>
            ) : recipes
                .filter((item) => !recipesExists.includes(item.id))
                .filter((item) =>
                  item.name.toLowerCase().includes(searchTerm.toLowerCase())
                ).length ? (
              <div className="overflow-x-auto mt-4">
                <table className="table w-full bg-base-100">
                  <thead>
                    <tr>
                      <th>{t("viewcircle.recipes")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {recipes
                      .filter((item) => !recipesExists.includes(item.id))
                      .filter((item) =>
                        item.name
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                      .map((item, index) => (
                        <tr key={`user-recipe-${index}`}>
                          <td>{item.name}</td>
                          <td className="w-16">
                            <button
                              className="btn btn-outline hover:bg-blue-400 btn-sm"
                              onClick={() => handleAddRecipe(item.id)}
                            >
                              {t("createrecipes.add")}
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="card border">
                <div className="card-body">
                  <h2 className="card-title">
                    {searchTerm
                      ? t("viewcircle.nomatchrecipes")
                      : t("viewcircle.noaddrecipes")}
                  </h2>
                </div>
              </div>
            )}
          </div>
        </div>

        {errorMember ? (
          <div className="toast toast-top toast-end">
            <div className="alert alert-error">
              <div>
                <span>{errorMember}</span>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ViewCircle;
