import React, { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import PageTitle from "../../components/layout/PageTitle";
import { supabase } from "../../supabaseClient";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import SearchBox from "../../components/common/SearchBox";
import { DataContext } from "../../context/DataContext";

const PendingCircle = (props) => {
  const navigate = useNavigate();
  const { state } = useContext(DataContext);
  const [data, setData] = useState([]);
  const [dataDisplay, setDataDisplay] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadCircles = async () => {
      let {
        data: dataCirclesShare,
        error: errorCShare,
        status: statusCShare,
      } = await supabase
        .from("circle_members")
        .select(
          `id, email,
          circles(
            id, name, description, image_url, owner_id, created_at,
            circle_recipes(
              id
            ),
            circle_members(
              id, email, created_at
            ),
            profiles (first_name, last_name)
          )`
        )
        .eq("email", state.user.email)
        .eq("status", 0)
        .order("created_at", { ascending: false });

      if (errorCShare && statusCShare !== 406) {
        throw errorCShare;
      }

      const dataBreakdown = dataCirclesShare.map((item) => {
        return item.circles;
      });

      setData(dataBreakdown);
      setDataDisplay(dataBreakdown);

      setIsLoading(false);
    };
    if (state?.user?.id) {
      loadCircles();
    }
  }, [state]);

  const onChangeSearch = (value) => {
    const newData = data.filter((item) => {
      return item.name.toLowerCase().includes(value.toLowerCase());
    });
    setDataDisplay(newData);
  };

  return (
    <>
      <PageTitle title="Circles" />
      <div className="flex mb-4">
        <Breadcrumbs
          data={[
            { name: "Home", link: "/" },
            { name: "Circles", link: "/circles" },
            { name: "Pending Circles" },
          ]}
        />
        <SearchBox onChange={onChangeSearch} />
        <button
          className="btn btn-primary"
          onClick={() => navigate("/circles/new")}
        >
          New Circle
        </button>
      </div>
      {isLoading ? (
        <div className="flex justify-center">
          <span className="loading loading-spinner loading-lg"></span>

        </div>
      ) : dataDisplay.length ? (
        <div>
          <div className="overflow-x-auto">
            <table className="table table-zebra">
              {/* head */}
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Invited By</th>
                  <th>Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {dataDisplay.map((item, index) => (
                  <tr>
                    <th>{index + 1}</th>
                    <td>{item.name}</td>
                    <td>
                      {item.profiles.first_name} {item.profiles.last_name}
                    </td>
                    <td>
                      {dayjs(
                        item.circle_members.find(
                          (item) => item.email === state.user.email
                        )?.created_at
                      ).format("MM/DD/YYYY")}
                    </td>
                    <td className="w-56">
                      <button
                        className="btn btn-secondary mr-2"
                        onClick={() =>
                          navigate(
                            `/circles-invitation/${
                              item.circle_members.find(
                                (item) => item.email === state.user.email
                              )?.id
                            }/1`
                          )
                        }
                      >
                        Accept
                      </button>
                      <button
                        className="btn btn-accent"
                        onClick={() =>
                          navigate(
                            `/circles-invitation/${
                              item.circle_members.find(
                                (item) => item.email === state.user.email
                              )?.id
                            }/2`
                          )
                        }
                      >
                        Deny
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div
          className="hero bg-base-200 rounded-box"
          style={{ minHeight: 480 }}
        >
          <div className="hero-content text-center">
            <div className="max-w-md">
              <h1 className="text-5xl font-bold">No Pending Circles!</h1>
              {!data.length ? (
                <>
                  <p className="py-6">Create your own circles.</p>
                  <button
                    className="btn btn-primary"
                    onClick={() => navigate("/circles/new")}
                  >
                    New Circle
                  </button>
                </>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PendingCircle;
