import React, { useState, useEffect } from "react";
import { MdEdit, MdOutlineRemove } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { supabase } from "../../supabaseClient";
import Flatpickr from "react-flatpickr";
require("flatpickr/dist/themes/material_blue.css");

const EditScheduleModal = ({
  isOpen,
  onClose,
  openFinalizeEditModal,
  plannedRecipes,
  plannedMealsId,
}) => {
  const [localScheduleDetails, setLocalScheduleDetails] = useState([]);
  const [eventName, setEventName] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const FetchPlanMealDetails = async () => {
      try {
        let { data: planMeals, error: planMealsError } = await supabase
          .from("planned_meals")
          .select(`
            plan_name,
            planned_meal_recipes (
              id,
              plan_date_time,
              plan_description,
              recipe_id,
              recipe:recipes (
                id, name, author
              )
            )
          `)
          .eq("id", plannedMealsId)
          .single();
  
        if (planMealsError) throw planMealsError;
  
        if (!planMeals) {
          console.error("No plan found with the given ID");
          return;
        }
  
        const uniqueDetailsMap = new Map();
  
        planMeals.planned_meal_recipes.forEach((meal) => {
          const dateTimeKey = meal.plan_date_time + meal.plan_description;
          if (!uniqueDetailsMap.has(dateTimeKey)) {
            uniqueDetailsMap.set(dateTimeKey, {
              datePlanned: meal.plan_date_time.split("T")[0],
              timePlanned: meal.plan_date_time.split("T")[1].substring(0, 5),
              eventDescription: meal.plan_description,
              recipes: []
            });
          }
          if (meal.recipe) {
            uniqueDetailsMap.get(dateTimeKey).recipes.push(meal.recipe);
          }
        });
  
        const uniquePlanDetails = Array.from(uniqueDetailsMap.values());
        setEventName(planMeals.plan_name);
        setLocalScheduleDetails(uniquePlanDetails);
      } catch (error) {
        console.error("Error fetching plan details:", error.message);
      }
    };
  
    if (isOpen) {
      FetchPlanMealDetails();
    }
  }, [isOpen, plannedMealsId]);
  

  const handleAddScheduleDetail = () => {
    setLocalScheduleDetails([
      ...localScheduleDetails,
      {
        datePlanned: "",
        timePlanned: "",
        eventDescription: "",
        recipes: []
      },
    ]);
  };
  

  const handleDetailChange = (index, value, type) => {
    const newScheduleDetails = [...localScheduleDetails];
    if (type === "eventDescription") {
      newScheduleDetails[index].eventDescription = value;
    } else if (type === "datetime") {
      const [date, time] = value.split("T");
      newScheduleDetails[index].datePlanned = date;
      newScheduleDetails[index].timePlanned = time.slice(0, 5);
    }
    setLocalScheduleDetails(newScheduleDetails);
  };

  const allDetailsFilled =
    localScheduleDetails.every(
      (detail) =>
        detail.datePlanned && detail.timePlanned && detail.eventDescription
    ) && eventName.trim() !== "";
    

    
  const handleContinue = async () => {
    if (allDetailsFilled) {
      openFinalizeEditModal(localScheduleDetails, plannedMealsId, eventName);
      onClose();
    }
  };

  return (
    <div
      className={`modal ${
        isOpen ? "modal-open" : ""
      } fixed w-full h-full top-0 left-0 flex items-center justify-center`}
    >
      <div
        className="modal-overlay fixed w-full h-full bg-gray-900 opacity-50"
        onClick={onClose}
      ></div>
      <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-xl z-50 overflow-y-auto">
        <div className="p-4 md:p-5">
          <h2 className="text-xl md:text-2xl font-bold text-center mb-1">
            {t("planmeals.edit")} {eventName} {t("planmeals.sched")}
          </h2>
          <div className="modal-content overflow-y-auto max-h-[calc(100vh-200px)] px-2 md:px-4 mt-4 md:mt-5">
            {localScheduleDetails.map((detail, index) => (
              <div key={index} className="mb-3 md:mb-4">
                <div key={index} className="flex-1">
                  <label
                    htmlFor={`dateTimePlanned-${index}`}
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("planmeals.datetimep")}
                  </label>
                  <Flatpickr
                    id={`dateTimePlanned-${index}`}
                    className="form-input w-full px-2 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
                    options={{
                      enableTime: true,
                      altInput: true,
                      disableMobile: true,
                      altFormat: "F j, Y, H:i",
                      dateFormat: "Y-m-d H:i",
                      time_24hr: true,
                    }}
                    value={`${detail.datePlanned}T${detail.timePlanned}`}
                    onChange={([selectedDate]) => {
                      const timezoneOffset =
                        selectedDate.getTimezoneOffset() * 60000;
                      const adjustedDate = new Date(
                        selectedDate.getTime() - timezoneOffset
                      );
                      handleDetailChange(
                        index,
                        adjustedDate.toISOString(),
                        "datetime"
                      );
                    }}
                  />
                </div>
                <div className="mt-2 md:mt-4">
                  <label
                    htmlFor={`eventDescription-${index}`}
                    className="block text-sm font-medium text-gray-700"
                  >
                    {t("groupplans.mealdesc")}
                  </label>
                  <input
                    type="text"
                    id={`eventDescription-${index}`}
                    className="form-input w-full px-2 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300 mb-3"
                    placeholder={t("groupplans.mealdesctext")}
                    value={detail.eventDescription}
                    onChange={(e) =>
                      handleDetailChange(
                        index,
                        e.target.value,
                        "eventDescription"
                      )
                    }
                  />
                </div>
                {localScheduleDetails.length > 1 && (
                  <div className="mt-2">
                    <button
                      type="button"
                      onClick={() =>
                        setLocalScheduleDetails(
                          localScheduleDetails.filter((_, idx) => idx !== index)
                        )
                      }
                      className="w-full flex items-center justify-center px-3 py-2 text-lg rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 bg-red-500 hover:bg-red-600 border border-red-700 text-white"
                      aria-label="Remove schedule detail"
                    >
                      <MdOutlineRemove className="h-6 w-6" />
                      <span className="ml-2">{t("groupplans.remove")}</span>
                    </button>
                  </div>
                )}
              </div>
            ))}
            <div className="flex justify-center mt-4">
              <button
                type="button"
                onClick={handleAddScheduleDetail}
                className="btn btn-outline inline-block px-4 py-2 text-stone-800 font-medium text-xs leading-tight uppercase rounded hover:bg-blue-700 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
              >
                {t("groupplans.addanother")}
              </button>
            </div>
            <div className="mt-4 md:mt-6 border-t border-stone-300">
              <label
                htmlFor="eventName"
                className="block text-sm font-medium text-gray-700 mt-4"
              >
                {t("groupplans.eventname")}
              </label>
              <input
                type="text"
                id="eventName"
                className="form-input w-full px-2 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300 mb-3"
                placeholder={t("groupplans.eventnamet")}
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
              />
            </div>
            <div className="mt-4 md:mt-5 flex flex-col md:flex-row justify-between items-center">
              <button
                className={`btn ${
                  allDetailsFilled
                    ? "bg-green-500 hover:bg-green-600"
                    : "bg-green-600 hover:bg-green-700 opacity-50 cursor-not-allowed"
                } w-full md:w-auto mb-2 md:mb-0`}
                disabled={!allDetailsFilled}
                onClick={handleContinue}
              >
                {t("groupplans.continue")}
              </button>
              <button
                className="modal-close btn bg-transparent hover:bg-gray-300 text-stone-700 rounded-lg w-full md:w-auto"
                onClick={onClose}
              >
                {t("groupplans.close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditScheduleModal;
