import React, { useEffect, useState, useContext } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { Helmet } from "react-helmet-async";
import { supabase } from "../../supabaseClient";
import mainImage from "../../assets/banner-recipe-1.jpeg";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import PageTitle from "../../components/layout/PageTitle";
import { categoriesMain, categoriesDietary } from "../../constants";
import WriteComment from "../../components/common/WriteCommentRecipe";
import DisplayComments from "../../components/common/DisplayCommentsRecipe";
import { DataContext } from "../../context/DataContext";
import ReactPlayer from "react-player/lazy";
import MarkdownContent from "../../components/utilities/markdowncontent";
import {
  MdOutlinePublic,
  MdOutlinePrivateConnectivity,
  MdAddShoppingCart,
  MdAdd,
  MdOutlineRemove,
  MdArrowBackIosNew,
  MdShare,
} from "react-icons/md";
import { TfiFacebook, TfiTwitter } from "react-icons/tfi";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import useWindowSize from "../../components/utilities/windowresize";
import Fraction from "fraction.js";



const getCategoryLabel = (categoryKey) => {
  const predefinedCategory = categoriesMain.find(
    (cMain) => cMain.value === categoryKey
  );

  return predefinedCategory?.label || categoryKey;
};

const ViewRecipe = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { url } = useParams();
  const { id } = useParams();
  const [recipe, setRecipe] = useState(null);
  const [author, setAuthor] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const context = useContext(DataContext);
  const user = context ? context.user : null;
  const state = context ? context.state : null;
  const recipeId = recipe?.id;
  const [currentServings, setCurrentServings] = useState(0);
  const [originalServings, setOriginalServings] = useState(0);
  const [addedItems, setAddedItems] = useState([]);
  const [disabledItems, setDisabledItems] = useState([]);
  const { t } = useTranslation();
  const [bookmarksMetadata, setBookmarksMetadata] = useState([]);
  const { width } = useWindowSize();

  function ShareButtons({ recipeName, recipeUrl }) {
    const handleShare = async () => {
      if (navigator.share) {
        try {
          await navigator.share({
            title: recipeName,
            url: recipeUrl,
          });
        } catch (error) {
          console.error("Error sharing", error);
        }
      } else {
      }
    };
  
    return (
      <div className="flex space-x-2">
        <a
          href={`https://twitter.com/intent/tweet?text=Check out this recipe! ${recipeName}&url=${recipeUrl}`}
          target="_blank"
          rel="noopener noreferrer"
          className="btn flex items-center space-x-1 bg-sky-400 text-white hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 rounded-lg py-2 px-4 transition duration-150 ease-in-out"
        >
          <TfiTwitter className="text-lg" />
          <span>Tweet</span>
        </a>
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${recipeUrl}`}
          target="_blank"
          rel="noopener noreferrer"
          className="btn flex items-center space-x-1 bg-blue-500 text-white hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 rounded-lg py-2 px-4 transition duration-150 ease-in-out"
        >
          <TfiFacebook className="text-lg" />
          <span>{t("misc.post")}</span>
        </a>
        <button
          onClick={handleShare}
          className="btn flex items-center space-x-1 bg-gray-600 text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-offset-2 rounded-lg py-2 px-4 transition duration-150 ease-in-out"
        >
          <MdShare className="text-lg" />
          <span>{t("misc.share")}</span>
        </button>
      </div>
    );
  }

  const handleIncrementServings = () => {
    setCurrentServings((prevServings) => {
      const newServings = Number(prevServings) + 1;
      return newServings;
    });
  };
  const handleDecrementServings = () => {
    setCurrentServings((prevServings) => {
      const newServings = Math.max(Number(prevServings) - 1, 1);
      return newServings;
    });
  };

  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  const [localUser, setLocalUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const {
          data: { user },
        } = await supabase.auth.getUser();

        if (user) {
          setLocalUser(user);
        } else {
          console.log("No user data fetched from Supabase");
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchUser();
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const hideLayout = urlParams.get("hide-layout");

  const fetchMetadata = async (url) => {
    try {
      const response = await fetch(
        "https://recipecircle-node.vercel.app/fetch-metadata",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ url }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const metadata = await response.json();
      return metadata;
    } catch (error) {
      console.error("Failed to fetch metadata:", error);
      return null;
    }
  };

  const isYouTubeLink = (url) => {
    const youtubePatterns = [
      /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.?be)\/.+$/,
      /(?:http?s?:\/\/)?(?:[\w-]+\.)?youtube\.[\w-]+\/.+$/,
    ];
    return youtubePatterns.some((pattern) => pattern.test(url));
  };

  function getNumericPart(input) {
    if (typeof input !== "string") {
      input = input != null ? String(input) : "";
    }
    const numbers = input.match(/\d+/);
    return numbers ? Number(numbers[0]) : null;
  }

  useEffect(() => {
    const loadRecipe = async () => {
      let { data: dataR, error: errorR, status: statusR } = await supabase
        .from("recipes")
        .select(
          `
          id, name, author, description, hack, duration, servings, steps, public, file, image_url, bookmarks, type, url,
          recipe_ingredients ( name, quantity, measure ),
          recipe_categories ( category_key, type ),
          recipe_links!fk_recipe ( 
            id, 
            recipe_id, 
            linked_recipe_id, 
            external_url,
            linked_recipe:linked_recipe_id ( id, name, url ) 
          )
        `
        )
        .eq("url", url)
        .single();

      if (errorR && statusR !== 406) {
        throw errorR;
      }

      if (dataR) {
        dataR.recipeUrl = `https://app.recipecircle.world/recipes-view/${dataR.url}`;
        if (dataR.steps && typeof dataR.steps === "string") {
          try {
            dataR.steps = JSON.parse(dataR.steps);
          } catch (error) {
            console.error("Error parsing steps:", error);
            dataR.steps = [];
          }
        }

        setRecipe(dataR);
        setAuthor(dataR.author);
        setCurrentServings(getNumericPart(dataR.servings) || 0);
        setOriginalServings(getNumericPart(dataR.servings) || 0);

        if (dataR.bookmarks && dataR.bookmarks.length > 0) {
          const bookmarksWithMetadata = await Promise.all(
            dataR.bookmarks.map(async (bookmarkUrl) => {
              if (!isYouTubeLink(bookmarkUrl)) {
                const metadata = await fetchMetadata(bookmarkUrl);
                return { url: bookmarkUrl, metadata };
              }
              return { url: bookmarkUrl };
            })
          );

          setBookmarksMetadata(bookmarksWithMetadata);
        } else {
          setBookmarksMetadata([]);
        }
      }
      setIsLoading(false);
    };

    if (!recipe || url !== recipe.url) {
      loadRecipe();
    }
  }, [url, recipe]);

  const handleOpenLinkExternal = (item) => {
    window.open(item?.value ?? item, "_blank");
  };

  const parseQuantity = (quantity) => {
    const parts = quantity.trim().split(/\s+/);
    let total = new Fraction(0);

    parts.forEach((part) => {
      try {
        if (part.includes("/")) {
          total = total.add(new Fraction(part));
        } else {
          total = total.add(new Fraction(Number(part)));
        }
      } catch (error) {
        console.error("Failed to parse part:", part, error);
      }
    });

    return total;
  };

  const pluralizeMeasure = (originalQuantity, adjustedQuantity, measure) => {
    if (!measure) return "";

    const normalizedMeasure = measure.trim().toLowerCase();
    const adjustedFraction = new Fraction(adjustedQuantity);
    const adjustedNumericQuantity = adjustedFraction.valueOf();

    if (isNaN(adjustedNumericQuantity) || adjustedNumericQuantity <= 0) {
      return measure;
    }

    const isFractionLessThanOne = Math.abs(adjustedNumericQuantity) < 1;

    const singularKey = `measurements.${normalizedMeasure}`;
    const pluralKey = `measurements.${normalizedMeasure}s`;

    if (!isFractionLessThanOne) {
      const pluralRules = new Intl.PluralRules("en-US", { type: "cardinal" });
      const pluralFormNeeded =
        pluralRules.select(Math.abs(adjustedNumericQuantity)) !== "one";

      if (pluralFormNeeded) {
        const pluralTranslation = t(pluralKey, {
          defaultValue: normalizedMeasure + "s",
        });
        return pluralTranslation;
      }
    }

    const singularTranslation = t(singularKey, {
      defaultValue: measure,
    });
    return singularTranslation;
  };

  const handleLinkClick = (link) => {
    Swal.fire({
      title: t("viewrecipe.warning"),
      text: t("viewrecipe.warningtext"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("viewrecipe.confirmtext"),
      cancelButtonText: t("viewrecipe.canceltext"),
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(link, "_blank");
      }
    });
  };

  const handleExternalLink = (url, hostname) => {
    Swal.fire({
      title: t("viewrecipe.leaving"),
      html: `${t("viewrecipe.leavingtext1")} <strong>${hostname}</strong> ${t(
        "viewrecipe.leavingtext2"
      )}. ${t("viewrecipe.noteresponsibility")}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3b82f6",
      cancelButtonColor: "#dc2626",
      confirmButtonText: t("viewrecipe.confirmtext"),
      cancelButtonText: t("viewrecipe.canceltext"),
    }).then((result) => {
      if (result.isConfirmed) {
        window.open(url, "_blank");
      }
    });
  };

  const adjustedIngredientQuantity = (quantity) => {
    const numericCurrentServings = getNumericPart(currentServings);
    const numericOriginalServings = getNumericPart(originalServings);

    if (!numericCurrentServings || !numericOriginalServings) return quantity;

    const numericQuantity = parseQuantity(quantity);
    const adjustedQuantity = numericQuantity
      .mul(numericCurrentServings)
      .div(numericOriginalServings);

    return adjustedQuantity.toFraction(true);
  };

  useEffect(() => {
    setCurrentServings(recipe?.servings);
    setOriginalServings(recipe?.servings);
  }, [recipe]);

  const recipeImg = recipe?.image_url
    ? `${process.env.REACT_APP_SUPABASE_BUCKET_URL}${recipe.image_url}`
    : mainImage;

  const filePath = recipe?.file
    ? `${process.env.REACT_APP_SUPABASE_BUCKET_URL}${recipe.file}`
    : null;

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const addToCart = async (item) => {
    try {
      setDisabledItems((currentItems) => [...currentItems, item.name]);
      let adjustedQuantity = adjustedIngredientQuantity(item.quantity);
      const originalServings = parseInt(recipe.servings, 10) || 1;
      const selectedServings = parseInt(currentServings, 10) || 1;

      const { data, error } = await supabase.from("cart_items").insert([
        {
          user_id: state.user.id,
          recipe_id: recipe.id,
          ingredient_name: item.name,
          quantity: adjustedQuantity.toString(),
          measure: item.measure,
          original_servings: originalServings,
          selected_servings: selectedServings,
        },
      ]);

      if (error) throw error;
      setAddedItems((prevItems) => [...prevItems, item.name]);
      setToastMessage(`${item.name} ${t("yourcart.added")}`);
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        setDisabledItems((currentItems) =>
          currentItems.filter((name) => name !== item.name)
        );
      }, 3000);
    } catch (error) {
      console.error("Error adding item to cart:", error.message);
      setDisabledItems((currentItems) =>
        currentItems.filter((name) => name !== item.name)
      );
    }
  };

  const addAllIngredientsToCart = async () => {
    try {
      setDisabledItems(
        recipe?.recipe_ingredients?.map((ingredient) => ingredient.name)
      );

      const originalServings = parseInt(recipe.servings, 10) || 1;
      const selectedServings = parseInt(currentServings, 10) || 1;

      const itemsToAdd = recipe?.recipe_ingredients?.map((ingredient) => {
        let adjustedQuantity = adjustedIngredientQuantity(ingredient.quantity);
        adjustedQuantity =
          !isNaN(adjustedQuantity) && adjustedQuantity !== null
            ? adjustedQuantity.toString()
            : ingredient.quantity;

        return {
          user_id: state.user.id,
          recipe_id: recipe.id,
          ingredient_name: ingredient.name,
          quantity: adjustedQuantity,
          measure: ingredient.measure,
          original_servings: originalServings,
          selected_servings: selectedServings,
        };
      });

      const { data, error } = await supabase
        .from("cart_items")
        .insert(itemsToAdd);

      if (error) throw error;

      setAddedItems((prevItems) => [
        ...prevItems,
        ...recipe?.recipe_ingredients?.map((ingredient) => ingredient.name),
      ]);

      setToastMessage(t("viewrecipe.addedall"));
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        setDisabledItems([]);
      }, 3000);
    } catch (error) {
      console.error("Error adding all ingredients to cart:", error.message);
      setDisabledItems([]);
    }
  };

  const decimalToFraction = (input, tolerance = 1e-6) => {
    const commonDecimals = {
      ".14": "1/7",
      ".17": "1/6",
      ".29": "2/7",
      ".30": "1/3",
      ".33": "1/3",
      ".43": "3/7",
      ".44": "4/9",
      ".57": "4/7",
      ".58": "7/12",
      ".67": "2/3",
      ".71": "5/7",
      ".78": "7/9",
      ".83": "5/6",
      ".86": "6/7",
      ".89": "8/9",
      ".92": "11/12",
    };

    for (let decimal in commonDecimals) {
      if (Math.abs((input % 1) - Number(decimal)) < tolerance) {
        let wholeNumberPart = Math.floor(input);
        let fractionPart = commonDecimals[decimal];
        return wholeNumberPart
          ? `${wholeNumberPart} ${fractionPart}`
          : fractionPart;
      }
    }

    let fraction = new Fraction(input).simplify(tolerance);
    return fraction.toFraction(true);
  };
  if (notFound) {
    return (
      <div className="container mx-auto p-4">
        <PageTitle title="Recipe not found" />
      </div>
    );
  }

  return (
    <>
      <div className="w-100 container mx-auto">
        <div className="w-full overflow-x-hidden">
          <Helmet>
            <title>{recipe?.name}</title>
            <meta name="description" content={recipe?.description} />
            <meta property="og:title" content={recipe?.name} />
            <meta property="og:type" content="article" />
            <meta property="og:image" content={recipeImg} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={recipe?.name} />
            <meta name="twitter:description" content={recipe?.description} />
            <meta name="twitter:image" content={recipeImg} />
          </Helmet>
          <div className="text-center">
            <h1 className="text-4xl font-bold py-4 text-[#384E79]">
              {recipe?.name}
              <span className="text-[#384E79] text-sm block mt-2">
                {t("viewrecipe.by")} {author}
              </span>
            </h1>
            <div className="breadcrumbs flex justify-center mb-1">
              <ul>
                <li>
                  <a
                    onClick={() => {
                      navigate("/");
                    }}
                    className="text-[#384E79] hover:text-blue-400"
                  >
                    {t("viewrecipe.home")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/public-recipes");
                    }}
                    className="text-[#384E79] hover:text-blue-400"
                  >
                    {t("publicrecipes.publicrec")}
                  </a>
                </li>
                <li className="text-stone-700">{recipe?.name}</li>
              </ul>
            </div>
          </div>
          <div className="flex justify-between mb-1">
            <div className="flex-1">
              <button
                className="btn btn-outline"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <MdArrowBackIosNew />
              </button>
            </div>
          </div>
          <div className="divider"></div>
        </div>
      </div>
      <div className="container mx-auto p-4">
        {isLoading ? (
          <div className="flex justify-center">
            <span className="loading loading-spinner loading-lg"></span>
          </div>
        ) : (
          <div>
            <div className="flex justify-center">
              <div className="card w-full max-w-screen-lg bg-base-100 shadow-xl mb-4 overflow-hidden">
                <div
                  className="relative w-full"
                  style={{ paddingTop: "56.25%" }}
                >
                  <img
                    src={recipeImg}
                    alt={`Picture of ${recipe?.name}`}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                </div>
                <div className="card-body p-4 md:p-8 lg:p-8">
                  <div className="flex justify-between items-center mb-4">
                    <h1 className="font-semibold text-3xl text-gray-800">
                      {recipe?.name}
                    </h1>
                    {recipe?.public ? (
                      <div className="flex items-center cursor-pointer">
                        <span className="text-green-500 mr-2">
                          {t("viewrecipe.public")}
                        </span>
                        <MdOutlinePublic className="text-green-500" />
                      </div>
                    ) : (
                      <div className="flex items-center cursor-pointer">
                        <span className="text-stone-800 mr-2">
                          {t("viewrecipe.private")}
                        </span>
                        <MdOutlinePrivateConnectivity className="text-stone-800" />
                      </div>
                    )}
                  </div>
                  {recipe?.author && (
                    <div className="text-gray-500 text-sm mb-4 italic">
                      {t("viewrecipe.recipeby")} {recipe?.author}
                    </div>
                  )}
                  {recipe?.description && (
                    <p className="text-base text-gray-700 mb-6">
                      <MarkdownContent content={recipe?.description} />
                    </p>
                  )}

                  {recipe?.hack && (
                    <div className="bg-blue-200 text-stone-800 p-4 rounded-xl mb-6 shadow-xl">
                      <h2 className="text-xl font-semibold mb-2">
                        {t("viewrecipe.hack")}
                      </h2>
                      <p className="text-sm">
                        <MarkdownContent content={recipe?.hack} />
                      </p>
                    </div>
                  )}
                  {(recipe?.duration ||
                    (recipe?.servings && recipe?.servings > 0)) && (
                    <div className="flex justify-between items-center mb-6 p-4 shadow-xl rounded-xl">
                      {recipe?.duration && (
                        <div
                          className="text-gray-600 flex flex-col justify-center items-start"
                          style={{ flex: "1 1 auto" }}
                        >
                          <span className="text-lg font-semibold">
                            {t("viewrecipe.duration")}
                          </span>
                          <span style={{ whiteSpace: "nowrap" }}>
                            {recipe?.duration}
                          </span>
                        </div>
                      )}
                      {recipe?.servings > 0 && (
                        <div className="flex justify-end items-center text-gray-600 w-full">
                          <div className="flex flex-col items-center">
                            <span className="text-lg font-semibold">
                              {t("viewrecipe.servings")}
                            </span>{" "}
                            <div className="flex items-center">
                              <button
                                className="btn btn-xs btn-outline"
                                onClick={handleDecrementServings}
                              >
                                <MdOutlineRemove />
                              </button>
                              <input
                                type="number"
                                className="input input-xs w-12 border rounded-md mx-1 text-center border-black focus:outline-none focus:border-blue-500 focus:ring-0"
                                value={currentServings}
                                onChange={(e) =>
                                  setCurrentServings(Number(e.target.value))
                                }
                              />
                              <button
                                className="btn btn-xs btn-outline"
                                onClick={handleIncrementServings}
                              >
                                <MdAdd />
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="flex justify-center ">
                    <ShareButtons
                      recipeName={recipe?.name}
                      recipeUrl={recipe?.recipeUrl}
                    />
                  </div>
                  <div className="divider"></div>
                  {recipe?.type === 1 && (
                    <div className="flex flex-col w-full">
                      {recipe?.recipe_ingredients?.length > 0 && (
                        <div className="card bg-base-100 rounded-xl p-6 my-6 shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
                          <h2
                            className="text-3xl font-bold mb-6"
                            style={{ color: "#1976d2" }}
                          >
                            {t("viewrecipe.ingredients")}
                          </h2>
                          <div className="space-y-4">
                            {recipe.recipe_ingredients.map((item, index) => (
                              <div
                                key={`ingredient-${index}`}
                                className="flex justify-between items-center bg-white p-3 rounded-lg shadow-sm"
                                style={{ border: "1px solid #E0E0E0" }}
                              >
                                <div className="flex items-center space-x-3">
                                  <div className="text-md font-semibold capitalize">
                                    {item.quantity
                                      ? decimalToFraction(
                                          adjustedIngredientQuantity(
                                            item.quantity
                                          )
                                        )
                                      : ""}{" "}
                                    {item.quantity && item.measure
                                      ? pluralizeMeasure(
                                          item.quantity,
                                          adjustedIngredientQuantity(
                                            item.quantity
                                          ),
                                          item.measure.replace(/\_/g, " ")
                                        )
                                      : item.measure
                                      ? t(
                                          `measurements.${item.measure.replace(
                                            /\_/g,
                                            " "
                                          )}`,
                                          { count: 1, context: "singular" }
                                        )
                                      : ""}
                                  </div>

                                  <div className="text-md capitalize">
                                    <MarkdownContent content={item.name} />
                                  </div>
                                </div>
                                {localUser ? (
                                  <button
                                    onClick={() => addToCart(item)}
                                    className={`btn btn-outline btn-sm flex items-center ${
                                      addedItems.includes(item.name)
                                        ? "btn-disabled"
                                        : ""
                                    } ${
                                      !disabledItems.includes(item.name)
                                        ? "hover:bg-blue-400 hover:text-stone-800"
                                        : ""
                                    }`}
                                    title="Add to Cart"
                                    disabled={disabledItems.includes(item.name)}
                                  >
                                    <span className="text-lg mr-2">
                                      <MdAddShoppingCart />
                                    </span>
                                    {addedItems.includes(item.name)
                                      ? t("viewrecipe.added")
                                      : t("viewrecipe.add")}
                                  </button>
                                ) : null}
                              </div>
                            ))}
                          </div>
                          {localUser ? (
                            <div className="flex w-full lg:w-auto mt-4 lg:mt-0 lg:justify-end">
                              <button
                                className="mt-6 btn py-2 px-4 rounded-lg bg-blue-500 hover:bg-blue-600 text-white font-semibold transition duration-200 ease-in-out transform hover:scale-105 shadow-lg w-full lg:w-auto"
                                onClick={addAllIngredientsToCart}
                              >
                                <MdAddShoppingCart />
                                {t("viewrecipe.addall")}
                              </button>
                            </div>
                          ) : null}
                          {showToast && (
                            <div className="toast toast-top toast-end">
                              <div className="alert alert-warning">
                                <span>{toastMessage}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {recipe?.steps?.length > 0 && (
                        <div className="card bg-base-100 rounded-xl p-6 my-6 shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
                          <h2
                            className="text-3xl font-bold mb-6"
                            style={{ color: "#1976d2" }}
                          >
                            {t("viewrecipe.steps")}
                          </h2>
                          <div className="space-y-4">
                            {recipe?.steps.map((item, index) => (
                              <div
                                key={`step-${index}`}
                                className="bg-white p-3 rounded-lg shadow-sm text-md"
                                style={{ border: "1px solid #E0E0E0" }}
                              >
                                <div className="flex items-start space-x-3">
                                  <span className="font-semibold text-lg">
                                    {index + 1}.
                                  </span>
                                  <span className="flex-1">
                                    <MarkdownContent
                                      content={item?.value ?? item}
                                    />
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {recipe?.type === 2 && recipe?.file != null && (
                    <div className="flex justify-center items-center">
                      <div className="rounded-lg overflow-hidden">
                        <DocViewer
                          pluginRenderers={DocViewerRenderers}
                          documents={[{ uri: filePath }]}
                          config={{ header: { disableHeader: true } }}
                          onClick={() =>
                            document.getElementById("imageModal").showModal()
                          }
                        />
                      </div>
                    </div>
                  )}
                  {recipe?.type === 2 && recipe?.file != null && (
                    <style jsx>
                      {`
                        #react-doc-viewer,
                        #proxy-renderer,
                        #image-renderer,
                        #image-img {
                          background-color: transparent !important;
                          border-radius: 10px;
                        }
                      `}
                    </style>
                  )}
                  {recipe?.type === 2 && recipe?.file != null && (
                    <dialog
                      id="imageModal"
                      className="modal"
                      style={{
                        width: "100%",
                        margin: 0,
                        padding: 0,
                        backgroundColor: "transparent",
                        overflow: "hidden",
                      }}
                    >
                      <div className="modal-box max-w-full bg-white text-[#384E79]">
                        <h1 className="text-center font-semibold text-4xl mb-3">
                          {recipe?.name}
                        </h1>
                        <div className="divider"></div>
                        <DocViewer
                          pluginRenderers={DocViewerRenderers}
                          documents={[{ uri: filePath }]}
                          config={{ header: { disableHeader: true } }}
                        />
                        <div className="modal-action flex justify-end pt-2">
                          <button
                            className="modal-close px-4 bg-transparent p-3 rounded-lg text-stone-700 hover:bg-gray-300"
                            onClick={() =>
                              document.getElementById("imageModal").close()
                            }
                          >
                            {t("viewrecipe.close")}
                          </button>
                        </div>
                      </div>
                    </dialog>
                  )}
                  {recipe &&
                    recipe.recipe_links &&
                    recipe.recipe_links.length > 0 && (
                      <div className="p-4 bg-white shadow-md rounded-lg">
                        <h2
                          className="text-3xl font-bold mb-6"
                          style={{ color: "#1976d2" }}
                        >
                          {t("viewrecipe.related")}
                        </h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                          {recipe.recipe_links.map((link, index) => (
                            <div
                              key={index}
                              className="card bg-base-100 shadow-xl hover:shadow-2xl transition-shadow duration-200 rounded-lg"
                            >
                              {link.external_url ? (
                                <div
                                  onClick={() =>
                                    handleExternalLink(
                                      link.external_url,
                                      new URL(link.external_url).hostname
                                    )
                                  }
                                  className="p-4 block text-center cursor-pointer hover:bg-blue-100 transition-colors duration-150 rounded-lg text-blue-400"
                                >
                                  <div className="text-sm font-medium truncate">
                                    {new URL(link.external_url).hostname}
                                  </div>
                                </div>
                              ) : (
                                <div
                                  onClick={() =>
                                    navigate(
                                      `/recipes-view/${link.linked_recipe.url}`
                                    )
                                  }
                                  className="p-4 block text-center cursor-pointer hover:bg-blue-100 transition-colors duration-150 rounded-lg text-blue-400"
                                >
                                  <div className="text-sm font-medium truncate">
                                    {link.linked_recipe.name}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  {recipe?.type === 3 && bookmarksMetadata.length > 0 && (
                    <div className="card bg-base-100 rounded-xl shadow-md overflow-hidden p-4 md:p-8 hover:shadow-lg">
                      <div className="text-xl font-bold mb-4">
                        {t("viewrecipe.links")}
                      </div>
                      <ul className="menu rounded-box divide-y divide-gray-200 ">
                        {bookmarksMetadata.map((item, index) => (
                          <li
                            key={`links-${index}`}
                            className="cursor-pointer mt-5"
                            onClick={(e) => {
                              e.preventDefault();
                              handleLinkClick(item.url);
                            }}
                          >
                            {isYouTubeLink(item.url) ? (
                              <div className="flex justify-center items-center overflow-hidden ">
                                <div
                                  className={
                                    width > 768
                                      ? "w-full h-full"
                                      : "w-full max-w-xl"
                                  }
                                >
                                  <ReactPlayer
                                    url={item.url}
                                    className="react-player"
                                    playing={false}
                                    controls={true}
                                    width="100%"
                                    height={width > 768 ? "470px" : "100%"}
                                  />
                                </div>
                              </div>
                            ) : (
                              item.metadata && (
                                <div className="flex flex-col items-center border border-gray-200 hover:bg-blue-400 hover:opacity-60 w-full md:flex-row">
                                  {item.metadata.image && (
                                    <img
                                      src={item.metadata.image}
                                      alt=""
                                      className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
                                    />
                                  )}
                                  <div className="flex flex-col justify-between p-4 leading-normal">
                                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">
                                      {item.metadata.title || item.url}
                                    </h5>
                                    <p className="mb-3 font-normal text-gray-700">
                                      {item.metadata.description}
                                    </p>
                                    <div className="text-xs text-gray-500">
                                      {new URL(item.url).hostname}
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {recipe?.recipe_categories &&
                    recipe.recipe_categories.length > 0 && (
                      <div className="card bg-base-100 rounded-xl p-6 my-6 shadow-md hover:shadow-lg transition-shadow duration-300 ease-in-out">
                        <h2
                          className="text-3xl font-bold mb-6"
                          style={{ color: "#1976d2" }}
                        >
                          {t("viewrecipe.categories")}
                        </h2>
                        <div className="flex flex-wrap gap-4">
                          {recipe.recipe_categories.map((cItem, index) => (
                            <span
                              key={cItem.category_key}
                              className={`inline-block text-md font-medium py-2 px-4 rounded-full cursor-pointer ${
                                cItem.type === 1
                                  ? "bg-white border border-blue-400"
                                  : "bg-white border border-green-400"
                              } hover:bg-blue-100 hover:text-blue-600 transition-colors duration-300 ease-in-out`}
                              title={
                                cItem.type === 1
                                  ? getCategoryLabel(cItem.category_key)
                                  : categoriesDietary.find(
                                      (cMain) =>
                                        cMain.value === cItem.category_key
                                    )?.label
                              }
                            >
                              {cItem.type === 1
                                ? getCategoryLabel(cItem.category_key)
                                : categoriesDietary.find(
                                    (cMain) =>
                                      cMain.value === cItem.category_key
                                  )?.label}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
            {localUser ? (
              <>
                <section className="bg-gray-200 py-8 lg:py-16 antialiased shadow-2xl rounded-2xl mx-auto my-8 w-full md:w-2/3 lg:w-2/3">
                  <div className="px-4 sm:px-6 lg:px-8">
                    <h2 className="text-gray-800 mb-6 font-semibold text-2xl border-b border-gray-300 pb-4">
                      {recipe?.name} {t("viewrecipe.discussion")}
                    </h2>
                    <WriteComment recipeId={recipe.id} userId={localUser.id} />
                    <DisplayComments
                      recipeId={recipe.id}
                      userId={localUser.id}
                      recipeOwnerId={recipe?.owner_id}
                      onCancel={() => {}}
                    />
                  </div>
                </section>
              </>
            ) : (
              <p className="text-center text-blue-800">
                {t("viewrecipe.nouser")}
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default ViewRecipe;
