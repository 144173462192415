import React, { useState, useEffect } from "react";
import { supabase } from "../../supabaseClient";
import slugify from "slugify";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const UrlSubmissionModal = ({ isUrlOpen, onUrlClose, userId }) => {
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [spoonacularApiKey, setSpoonacularApiKey] = useState("");
  const SPOONACULAR_BASE_URL = "https://api.spoonacular.com";
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (isUrlOpen) {
      fetchApiKey();
    }
  }, [isUrlOpen, userId]);

  const fetchApiKey = async () => {
    setIsLoading(true);
    const { data, error } = await supabase
      .from('api_keys')
      .select('spoon_api')
      .eq('user_id', userId)
      .single();

    if (error) {
      setErrorMessage(t('errors.apiKeyFetchFailed'));
      setIsLoading(false);
    } else if (data) {
      setSpoonacularApiKey(data.spoon_api);
      setIsLoading(false);
    } else {
      setErrorMessage(t('errors.noApiKey'));
      setIsLoading(false);
    }
  };

  const fetchRecipeFromURL = async (submittedURL) => {
    try {
      const response = await fetch(
        `${SPOONACULAR_BASE_URL}/recipes/extract?url=${encodeURIComponent(
          submittedURL
        )}`,
        {
          headers: {
            "x-api-key": spoonacularApiKey,
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          `Network response was not ok: ${response.status} ${response.statusText}`
        );
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error calling Spoonacular API:", error);
      throw error;
    }
  };

  const formatRecipeForSupabase = (recipeData) => {
    const {
      title,
      extendedIngredients,
      analyzedInstructions,
      readyInMinutes,
      author = "Generated by RecipeCircle"
    } = recipeData;
  
    let baseSlug = slugify(title, { lower: true, strict: true });
    let uniqueSlug = `${baseSlug}-${nanoid(5)}`;
  
    const toSingularMeasure = (measure) => {
      const measurementMap = {
        tsp: "Teaspoon",
        teaspoon: "Teaspoon",
        teaspoons: "Teaspoon",
        tbsp: "Tablespoon",
        tsps: "Teaspoon",
        tbsps: "Tablespoon",
        tablespoons: "Tablespoons",
        cup: "Cup",
        cups: "Cup",
        lb: "Pound",
        lbs: "Pound",
        oz: "Ounce",
        ozs: "Ounce",
        floz: "Fluid Ounce",
        flozs: "Fluid Ounce",
        pint: "Pint",
        pints: "Pint",
        quart: "Quart",
        quarts: "Quart",
        gal: "Gallon",
        gals: "Gallon",
        unit: "Unit",
        units: "Unit",
        pinch: "Pinch",
        pinches: "Pinch",
        mg: "Milligram",
        mgs: "Milligram",
        g: "Gram",
        gs: "Gram",
        kg: "Kilogram",
        kgs: "Kilogram",
        ml: "Milliliter",
        mls: "Milliliter",
        l: "Liter",
        ls: "Liter",
      };
      const normalizedMeasure = measure.toLowerCase();
      return measurementMap[normalizedMeasure] || measure;
    };
  
    const recipe = {
      name: title,
      author: author, 
      description: "This recipe was generated automatically. Please check its factuality.",
      duration: `${readyInMinutes} minutes`,
      public: false,
      steps: JSON.stringify(analyzedInstructions[0].steps.map(step => step.step)),
      type: 1,
      url: uniqueSlug,
      owner_id: userId,
    };
  
    const ingredients = extendedIngredients.map((ingredient, index) => ({
      name: ingredient.name,
      measure: toSingularMeasure(ingredient.unit),
      position: index + 1,
      quantity: ingredient.amount.toString(),
    }));
  
    return { recipe, ingredients };
  };
  

  const insertRecipeIntoSupabase = async (
    { recipe, ingredients },
    submittedURL
  ) => {
    try {
      let { error: insertError } = await supabase
        .from("recipes")
        .insert([{ ...recipe, owner_id: userId }]);
      if (insertError) {
        console.error("Error on inserting recipe:", insertError);
        throw new Error(`Error inserting recipe: ${insertError.message}`);
      }

      let {
        data: retrievedRecipes,
        error: retrieveError,
      } = await supabase
        .from("recipes")
        .select("id")
        .eq("name", recipe.name)
        .eq("url", recipe.url)
        .single();

      if (retrieveError || !retrievedRecipes) {
        console.error("Error retrieving recipe after insert:", retrieveError);
        throw new Error("Failed to retrieve recipe after insert.");
      }

      let recipeId = retrievedRecipes.id;

      const ingredientsWithRecipeId = ingredients.map((ingredient) => ({
        ...ingredient,
        recipe_id: recipeId,
      }));

      await supabase.from("recipe_ingredients").insert(ingredientsWithRecipeId);
      await supabase
        .from("original_urls")
        .insert([{ recipe_id: recipeId, url: submittedURL, user_id: userId }]);

      return { success: true, recipeId };
    } catch (error) {
      console.error("Error processing recipe insertion:", error);
      return { success: false, error: error.message };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");

    try {
      const recipeData = await fetchRecipeFromURL(url);
      const formattedData = formatRecipeForSupabase(recipeData, userId);
      const insertedData = await insertRecipeIntoSupabase(formattedData, url);

      if (insertedData.success && insertedData.recipeId) {
        onUrlClose();
        navigate(`/recipes/${insertedData.recipeId}`);
      } else {
        throw new Error("Failed to insert recipe. Please try again.");
      }
    } catch (error) {
      console.error("Error processing recipe:", error);
      setErrorMessage(
        error.message || "Failed to process the recipe. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`modal ${
        isUrlOpen ? "modal-open" : ""
      } fixed inset-0 flex items-center justify-center`}
    >
      <div
        className="modal-overlay absolute inset-0 bg-black bg-opacity-30 backdrop-filter backdrop-blur-sm"
        onClick={onUrlClose}
      ></div>
      <div className="modal-container bg-white  w-full max-w-lg mx-auto rounded-xl shadow-2xl overflow-hidden z-50">
        <div className="modal-content py-4 text-left px-6">
          {isLoading ? (
            <div className="flex justify-center items-center h-full">
              <span className="loading loading-spinner loading-lg"></span>
            </div>
          ) : (
            <>
              {errorMessage && (
                <div className="text-red-500  text-center mb-4">
                  {errorMessage}
                </div>
              )}
              <form onSubmit={handleSubmit} className="space-y-6">
                <h3 className="text-2xl font-semibold text-center text-gray-900 ">
                  {t("misc.recipeurl")}
                </h3>
                <p>{t("misc.urlwarning")}</p>
                <input
                  type="text"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder="Enter recipe URL"
                  className="mt-4 px-4 py-2 w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-blue-400 focus:ring-0 active:border-blue-400 transition duration-150 ease-in-out"
                  required
                />

                <div className="flex justify-between items-center pt-4">
                  <button
                    type="button"
                    className="modal-close px-5 py-2.5 bg-transparent rounded-lg text-gray-600  hover:bg-gray-100  transition duration-150 ease-in-out"
                    onClick={onUrlClose}
                  >
                    {t("yourcart.close")}
                  </button>
                  <button
                    type="submit"
                    className="px-5 py-2.5 bg-blue-500 rounded-lg text-white hover:bg-blue-600 focus:ring-0 transition duration-150 ease-in-out"
                  >
                    {t("misc.process")}
                  </button>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default UrlSubmissionModal;
