import React, { useContext, useEffect, useState } from "react";
import { supabase, supabaseService } from "../../supabaseClient";
import { useNavigate, useParams } from "react-router-dom";
import universecirclesWLOGO from "../../assets/logo-recipe-circle.png";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { DataContext } from "../../context/DataContext";

const ViewInvitationCircle = (props) => {
  const navigate = useNavigate();
  const { id, status } = useParams();
  const [data, setData] = useState([]);
  const [circle, setCircle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [successStatus, setSuccessStatus] = useState(0);
  const [session, setSession] = useState(null);
  const { t } = useTranslation();
  const { state } = useContext(DataContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorAction, setErrorAction] = useState("");
  const [circleId, setCircleId] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    const loadCircle = async () => {
      let { data: dataI, error: errorI } = await supabase
        .from("circle_members")
        .select("id, circle_id, permission, status, email, created_at, user_id")
        .eq("id", id)
        .single();

      if (errorI) {
        setIsLoading(true);
        return;
      }

      setData(dataI);
      setCircleId(dataI.circle_id);

      let { data: dataC, error: errorC } = await supabase
        .from("circles")
        .select("id, name, description, image_url, owner_id, created_at")
        .eq("id", dataI.circle_id)
        .single();

      if (errorC) {
        setErrorMessage("Failed to load circle data.");
        setIsLoading(true);
        return;
      }

      setCircle(dataC);
      setIsLoading(false);

      if (!state.user) {
        if (dataI.user_id) {
          setErrorMessage(t("circleInvite.circlemember"));
          setErrorAction("login");
          setIsLoading(false);
          return;
        } else {
          setErrorMessage("");
        }
      } else {
        if (state.user?.id !== dataI.user_id) {
          setErrorMessage(t("circleInvite.noperm"));
          setErrorAction("dashboard");
          setIsLoading(false);
          return;
        } else {
          setErrorMessage("");
        }
      }
    };

    const handleStatus = async () => {
      if (status) {
        if (!errorMessage && !errorAction) {
          if (status === "1") {
            handleAccept();
            setSuccessStatus(1);
          } else if (status === "2") {
            handleDeny();
            setSuccessStatus(2);
          }
        }
      }
    };

    loadCircle();
    handleStatus();
    setIsLoading(false);
  }, [id, state.user]);

  const handleNavigate = () => {
    if (state.user?.id) {
      navigate(`/circles/${circleId}`);
    } else {
      navigate(`/sign-up`);
    }
  };

  const handleDecline = () => {
    if (state?.user.id) {
      navigate(`/dashboard`);
    } else {
      navigate(`/sign-up`);
    }
  };

  const handleAccept = async () => {
    setIsLoading(true);
    const updates = {
      id: id,
      status: 1,
    };

    try {
      const { error: updateError } = await supabase
        .from("circle_members")
        .update(updates)
        .match({ id: id });

      if (updateError) {
        throw updateError;
      }
      setSuccessStatus(1);
    } catch (error) {
      console.error("Error handling circle member acceptance:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeny = async () => {
    setIsLoading(true);
    const updates = {
      id: id,
      status: 2,
    };

    const { error } = await supabaseService
      .from("circle_members")
      .upsert(updates);

    if (error) {
      throw error;
    }

    setSuccessStatus(2);
    setIsLoading(false);
  };

  if (notFound) {
    return (
      <>
        <Helmet>
          <title>View Invitation Circle - RecipeCircle</title>
          <meta
            name="description"
            content="View invitation circle details on RecipeCircle."
          />
          <meta
            property="og:title"
            content="View Invitation Circle - RecipeCircle"
          />
          <meta
            property="og:description"
            content="View invitation circle details on RecipeCircle."
          />
          <meta property="og:type" content="website" />
        </Helmet>

        <div className="container mx-auto card w-full md:w-1/2 lg:w-1/2 bg-base-100 shadow-xl">
          <div className="card-body items-center text-center">
            <div>
              <img src={universecirclesWLOGO} alt="RecipeCircle" width="120" />
            </div>
            <h2 className="card-title text-accent text-3xl">
              {t("circleInvite.used")}
            </h2>
            <h3 className="card-title text-lg">
              {t("circleInvite.usedtext1")}
            </h3>
            <h4 className="card-title text-sm">
              {t("circleInvite.usedtext2")}
            </h4>
            <div className="flex justify-center gap-4 mt-4">
              <button
                className="btn btn-outline hover:bg-blue-400"
                onClick={() => navigate(`/log-in`)}
              >
                {t("circleInvite.login")}
              </button>
              <button
                className="btn btn-outline hover:bg-blue-400"
                onClick={() => navigate(`/sign-up`)}
              >
                {t("circleInvite.register")}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <Helmet>
        <title>View Invitation Circle - RecipeCircle</title>
        <meta
          name="description"
          content="View invitation circle details on RecipeCircle."
        />
        <meta
          property="og:title"
          content="View Invitation Circle - RecipeCircle"
        />
        <meta
          property="og:description"
          content="View invitation circle details on RecipeCircle."
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="p-4 bg-gray-100 min-h-screen flex justify-center items-center">
        {isLoading ? (
          <span className="loading loading-spinner loading-lg"></span>
        ) : errorMessage ? (
          <div className="card w-full max-w-md bg-white shadow-2xl rounded-2xl overflow-hidden">
            <figure className="bg-gray-200 p-4">
              <img src={universecirclesWLOGO} alt="RecipeCircle" width="120" />
            </figure>
            <div className="card-body text-center p-8">
              <h2 className="text-2xl font-bold text-gray-800 mb-4">
                {errorMessage}
              </h2>
              {errorAction === "dashboard" && (
                <button
                  className="btn btn-outline hover:bg-blue-400"
                  onClick={() => navigate("/dashboard")}
                >
                  {t("circleInvite.dashboard")}
                </button>
              )}
              {errorAction === "login" && (
                <button
                  className="btn btn-outline hover:bg-blue-400"
                  onClick={() => navigate("/log-in")}
                >
                  {t("circleInvite.login")}
                </button>
              )}
            </div>
          </div>
        ) : (
          <div className="card w-full max-w-md bg-white shadow-2xl rounded-2xl overflow-hidden">
            <figure className="bg-gray-200 p-4">
              <img src={universecirclesWLOGO} alt="RecipeCircle" width="120" />
            </figure>
            <div className="card-body text-center p-8">
              {successStatus === 0 && (
                <>
                  <h2 className="text-2xl font-bold text-gray-800 mb-4">
                    {t("circleInvite.invited")}
                  </h2>
                  <p className="text-gray-600">
                    {t("circleInvite.jointext")}{" "}
                    <strong className="text-gray-800">{circle?.name}</strong>{" "}
                    {t("circleInvite.exploretext")}
                  </p>
                  <p className="text-gray-600">{t("circleInvite.sharetext")}</p>
                  <div className="card-actions justify-center mt-8 space-x-4">
                    <button className="btn btn-success" onClick={handleAccept}>
                      {t("circleInvite.accept")}
                    </button>
                    <button className="btn btn-error" onClick={handleDeny}>
                      {t("circleInvite.decline")}
                    </button>
                  </div>
                </>
              )}
              {successStatus === 1 && (
                <>
                  <h3 className="text-lg font-semibold text-secondary">
                    {t("circleInvite.accepted")}
                  </h3>
                  <p className="text-gray-600">
                    {t("circleInvite.weclome")} <strong>{circle?.name}</strong>!
                  </p>
                  <p className="text-gray-600">
                    {t("circleInvite.ready")}{" "}
                    <strong>
                      {data?.permission === 1
                        ? t("circleInvite.explore")
                        : t("circleInvite.explorecreate")}
                    </strong>{" "}
                    {t("circleInvite.community")}
                  </p>
                  {!state.user?.id && (
                    <p className="text-gray-600">
                      {t("circleInvite.toview")} <strong>{circle?.name}</strong>{" "}
                      {t("circleInvite.available")}
                      to{" "}
                      {data?.permission === 1
                        ? t("circleInvite.explore")
                        : t("circleInvite.explorecreate")}
                    </p>
                  )}
                  <div className="card-actions justify-center mt-8">
                    <button
                      className="btn btn-outline w-full hover:bg-blue-400"
                      onClick={handleNavigate}
                    >
                      {state.user?.id
                        ? `${t("circleInvite.gtc", {
                            circleName: circle?.name,
                          })} ${circle?.name}`
                        : t("circleInvite.reg")}
                    </button>
                  </div>
                </>
              )}
              {successStatus === 2 && (
                <>
                  <h1 className="text-lg font-semibold text-accent">
                    {t("circleInvite.denied")}
                  </h1>
                  <p className="text-gray-600">{t("circleInvite.declined")}</p>
                  {!state.user?.id && (
                    <p className="text-gray-600">{t("circleInvite.noacc")}</p>
                  )}
                  <div className="card-actions justify-center mt-8">
                    <button
                      className="btn btn-outline w-full hover:bg-blue-400"
                      onClick={handleDecline}
                    >
                      {state.user?.id
                        ? t("circleInvite.dashboard")
                        : t("circleInvite.reg")}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ViewInvitationCircle;
