/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import { useForm } from "react-hook-form";
import PhotoUploader from "../components/common/PhotoUploader";
import { Helmet } from "react-helmet-async";
import { DataContext } from "../context/DataContext";
import { useNavigate } from "react-router-dom";
import { MdMenu, MdSave, MdDelete, MdHelpOutline } from "react-icons/md";
import { useTranslation } from "react-i18next";

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;

const initialData = {
  first_name: "",
  last_name: "",
  email: "",
  about: "",
  image: "",
};

const Form = (props) => {
  const { register, handleSubmit } = useForm();
  const { actions, state } = useContext(DataContext);
  const [userLogged, setUserLogged] = useState(null);
  const [data, setData] = useState(initialData);
  const [updatedFlag, setUpdatedFlag] = useState(false);
  const [successFlag, setSuccessFlag] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [spoonApiKey, setSpoonApiKey] = useState("");
  const [isKeyExisting, setIsKeyExisting] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  useEffect(() => {
    const loadUser = async () => {
      let dataLoad = { ...data };
      const {
        data: { user },
      } = await supabase.auth.getUser();
      dataLoad = { ...dataLoad, email: user.email };
      setUserLogged(user);

      let { data: dataP, error, status } = await supabase
        .from("profiles")
        .select(`first_name, last_name, about, image`)
        .eq("id", user.id)
        .single();

      dataLoad = { ...dataLoad, ...dataP };
      setData(dataLoad);
      setIsLoading(false);

      if (error && status !== 406) {
        throw error;
      }
    };

    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchApiKey = async () => {
    const { data, error } = await supabase
      .from("api_keys")
      .select("spoon_api")
      .eq("user_id", state.user?.id)
      .single();

    if (error) {
      return;
    } else if (data) {
      setSpoonApiKey(data.spoon_api);
      setIsKeyExisting(true);
    }
  };

  useEffect(() => {
    if (state.user?.id) {
      fetchApiKey();
    }
  }, [state.user?.id]);

  const handleDeleteApiKey = async () => {
    const { error } = await supabase
      .from("api_keys")
      .delete()
      .eq("user_id", state.user?.id);

    if (error) {
      console.error("Error deleting API Key", error);
    } else {
      setSpoonApiKey("");
      setIsKeyExisting(false);
      setToastMessage(t("profile.deleted"));
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    }
  };

  const processSubmit = async (dataInput) => {
    const dataToUpdate = { ...data, ...dataInput };
    setData(dataToUpdate);
    const updates = {
      id: userLogged.id,
      first_name: dataToUpdate.first_name,
      last_name: dataToUpdate.last_name,
      about: dataToUpdate.about,
      image: dataToUpdate.image,
    };

    const {
      data: dataProfileUpdate,
      error: profileError,
    } = await supabase
      .from("profiles")
      .upsert(updates)
      .select("id, first_name, last_name, about, image")
      .single();

    if (profileError) {
      throw profileError;
    }

    const apiKeyData = {
      user_id: state.user?.id,
      spoon_api: dataInput.api_key,
    };

    const { error: apiKeyError } = await supabase
      .from("api_keys")
      .upsert(apiKeyData, {
        onConflict: "user_id",
      });

    if (apiKeyError) {
      throw apiKeyError;
    } else {
      fetchApiKey();
    }

    actions.setUser(dataProfileUpdate);
    setUpdatedFlag(true);
    setTimeout(() => {
      setUpdatedFlag(false);
    }, 3000);
  };

  const openChangePasswordModal = () => {
    setPassword("");
    setConfirmPassword("");
    document.getElementById("change_password_modal").showModal();
  };

  const InputField = ({ label, register, defaultValue }) => (
    <div className="form-control w-full">
      <label className="label">
        <span className="label-text">{label}</span>
      </label>
      <input
        type="text"
        {...register}
        defaultValue={defaultValue}
        className="input border-black focus:border-blue-400 focus:outline-none focus:ring-0"
      />
    </div>
  );

  const submitChangePassword = (e) => {
    e.preventDefault();

    if (!passwordRegex.test(password)) {
      alert(t("profile.pwreq1"));
      return;
    }

    if (password.length <= 6) {
      alert(t("profile.pwreq2"));
      return;
    }

    if (password !== confirmPassword) {
      alert(t("profile.pwreq3"));
      return;
    }
    supabase.auth.updateUser({ password }).then((data) => {
      setSuccessFlag(true);
      setTimeout(() => {
        setSuccessFlag(false);
      }, 3000);
      document.getElementById("change_password_modal").close();
    });
  };

  const onChangeImage = (image) => {
    setData({ ...data, image });
  };

  const navigateToUrl = (url) => {
    window.open(url, "_blank");
  };

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  }

  return (
    <div className="w-100 container mx-auto">
      <div>
        <Helmet>
          <title>RecipeCircle - My Profile</title>
          <meta
            name="description"
            content="View and manage your profile on RecipeCircle. Update your information, manage preferences, and explore your activity."
          />
          <meta property="og:title" content="RecipeCircle - My Profile" />
          <meta
            property="og:description"
            content="View and manage your profile on RecipeCircle. Update your information, manage preferences, and explore your activity."
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content="https://app.recipecircle.world/profile"
          />
        </Helmet>
        <div className="w-full overflow-x-hidden">
          <div className="text-center">
            <h1 className="text-4xl font-bold py-4 text-[#384E79]">
              {t("profile.userprof")}
            </h1>
            <div className="breadcrumbs flex justify-center mb-4">
              <ul>
                <li>
                  <a
                    onClick={() => {
                      navigate("/");
                    }}
                    className="text-[#384E79] hover:text-blue-400"
                  >
                    {t("profile.home")}
                  </a>
                </li>
                <li className="text-stone-700">{t("profile.profile")}</li>
              </ul>
            </div>
          </div>
          <div className="divider"></div>
        </div>
        {updatedFlag ? (
          <div className="toast toast-top toast-end z-[999]">
            <div className="alert alert-success">
              <div>
                <span>{t("profile.update")}</span>
              </div>
            </div>
          </div>
        ) : null}
        {successFlag ? (
          <div className="toast toast-top toast-end">
            <div className="alert alert-success">
              <div>
                <span>{t("profile.pwupdate")}</span>
              </div>
            </div>
          </div>
        ) : null}

        <div className="flex justify-center">
          <div className="card w-full md:w-3/4 lg:w-1/2 bg-gray-100 shadow-xl mb-4">
            <div className="flex justify-end p-4">
              <div className="dropdown dropdown-end">
                <button tabIndex={0} className="btn btn-ghost btn-circle">
                  <MdMenu className="w-6 h-6" />
                </button>
                <ul
                  tabIndex={0}
                  className="menu dropdown-content bg-base-100 rounded-box w-52"
                >
                  <li>
                    <a onClick={openChangePasswordModal}>
                      {t("profile.changepw")}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <PhotoUploader image={data.image} onChangeImage={onChangeImage} />
            <div className="card-body items-center text-center">
              <form onSubmit={handleSubmit(processSubmit)}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <InputField
                    label={t("profile.firstn")}
                    register={register("first_name")}
                    defaultValue={data.first_name}
                  />
                  <InputField
                    label={t("profile.lastn")}
                    register={register("last_name")}
                    defaultValue={data.last_name}
                  />
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">{t("profile.about")}</span>
                  </label>
                  <textarea
                    {...register("about")}
                    defaultValue={data.about}
                    className="textarea border-black focus:border-blue-400 focus:outline-none focus:ring-0 h-32"
                  />
                </div>
                <div className="form-control w-full">
                  <label className="label mt-3">
                    <div className="flex flex-col text-left">
                      <span className="label-text mb-1">
                        {t("profile.spoonApiKey")}
                      </span>
                      {!isKeyExisting && (
                        <>
                          <p className="text-gray-600 text-sm">
                            {t("profile.certain")}{" "}
                            <span
                              onClick={() =>
                                navigateToUrl(
                                  "https://spoonacular.com/food-api/console#Profile"
                                )
                              }
                              className="cursor-pointer text-blue-400 hover:text-blue-500"
                              role="link"
                              tabIndex="0"
                            >
                              {t("profile.certaincont")}
                            </span>
                          </p>
                          <p className="text-gray-600 text-sm">
                            {t("profile.register")}{" "}
                            <span
                              onClick={() =>
                                navigateToUrl(
                                  "https://spoonacular.com/food-api/console#Dashboard"
                                )
                              }
                              className="cursor-pointer text-blue-400 hover:text-blue-500"
                              role="link"
                              tabIndex="0"
                            >
                              {t("profile.registercont")}
                            </span>
                          </p>
                        </>
                      )}
                    </div>
                  </label>
                  <input
                    type="password"
                    {...register("api_key")}
                    value={spoonApiKey || ""}
                    onChange={(e) => setSpoonApiKey(e.target.value)}
                    placeholder={
                      isKeyExisting
                        ? t("profile.updateSpoonApiKey")
                        : t("profile.spoonApiKey")
                    }
                    className="mt-3 input border-black w-full focus:border-blue-400 focus:outline-none focus:ring-0"
                  />

                  {isKeyExisting && (
                    <button
                      type="button"
                      onClick={handleDeleteApiKey}
                      className="btn btn-error mt-2"
                    >
                      <MdDelete className="mr-2" />
                      {t("profile.deleteApiKey")}
                    </button>
                  )}
                </div>

                <div className="mt-4">
                  <button type="submit" className="btn btn-outline">
                    <MdSave className="mr-2" />
                    {t("profile.save")}
                  </button>
                </div>
              </form>
              {showToast && (
                <div className="toast toast-top toast-end z-50">
                  <div className="alert alert-error">
                    <span>{toastMessage}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <dialog id="change_password_modal" className="modal">
          <div className="modal-box bg-white w-96 mx-auto p-6 rounded-lg shadow-lg">
            <h3 className="font-bold text-2xl mb-4 text-center">
              {t("profile.changepw")}
            </h3>
            <div className="divider"></div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">{t("profile.pw")}</span>
              </label>
              <div className="join">
                <input
                  type={passwordShow ? "text" : "password"}
                  placeholder={t("profile.pw")}
                  className="input border-black focus:border-blue-400 focus:outline-none focus:ring-0 flex-1"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="form-control">
              <label className="label">
                <span className="label-text">{t("profile.confpw")}</span>
              </label>
              <div className="join">
                <input
                  type={passwordShow ? "text" : "password"}
                  placeholder={t("profile.confpw")}
                  className="input border-black focus:border-blue-400 focus:outline-none focus:ring-0 flex-1"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-action">
              <form method="dialog">
                <button className="btn btn-sm btn-circle absolute right-2 top-2 bg-white text-stone-800 hover:text-gray-700 transition duration-300">
                  ✕
                </button>
                <div className="mt-4">
                  <button
                    className="btn btn-outline"
                    onClick={submitChangePassword}
                  >
                    {t("profile.changepw")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </dialog>
      </div>
    </div>
  );
};

export default Form;
