import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const importTranslations = (locale) => import(`./locales/${locale}.json`);

const initTranslations = async () => {
  const [enTranslations, deTranslations, esTranslations, frTranslations, itTranslations] = await Promise.all([
    importTranslations('ENGtranslations'),
    importTranslations('DEtranslations'),
    importTranslations('EStranslations'),
    importTranslations('FRtranslations'),
    importTranslations('ITtranslations')
  ]);

  return {
    en: { translation: enTranslations.default },
    de: { translation: deTranslations.default },
    es: { translation: esTranslations.default },
    fr: { translation: frTranslations.default },
    it: { translation: itTranslations.default }
  };
};

initTranslations().then((resources) => {
  i18n
    .use(initReactI18next)
    .init({
      resources,
      lng: 'en',
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false
      }
    });
});

export default i18n;
