import React, { useContext, useState, useEffect } from "react";
import { supabase } from "../../supabaseClient";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Swal from "sweetalert2";
import { MdOutlineRemove } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { DataContext } from "../../context/DataContext";
import fetchEmailSuggestions from "./fetchEmailSuggestions";
import emailjs from "@emailjs/browser";

const FinalizeGroupMealModal = ({
  isOpen,
  onClose,
  user,
  addedRecipes,
  setAddedRecipes,
  scheduleDetails,
  setScheduleDetails,
}) => {
  const { t } = useTranslation();
  const { actions } = useContext(DataContext);
  const [emailSuggestions, setEmailSuggestions] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [inputEmail, setInputEmail] = useState("");

  const [emailStates, setEmailStates] = useState(
    scheduleDetails.map(
      (detail) =>
        detail.recipes?.map(() => ({
          inputEmail: "",
          filteredSuggestions: [],
        })) || []
    )
  );

  dayjs.extend(utc);

  const handleRecipeSelection = (detailIndex, recipeIndex, recipeId) => {
    if (!recipeId) {
      console.error("Invalid recipe ID selected.");
      return;
    }

    const newScheduleDetails = [...scheduleDetails];
    newScheduleDetails[detailIndex].recipes[recipeIndex].recipeId = recipeId;
    setScheduleDetails(newScheduleDetails);
  };

  useEffect(() => {
    if (user && user.id) {
      fetchEmailSuggestions(user.id).then(setEmailSuggestions);
    }
  }, [user]);

  const handleRemoveRecipe = (detailIndex, recipeIndex) => {
    setScheduleDetails((currentDetails) => {
      const updatedDetails = currentDetails.map((detail, index) => {
        if (index === detailIndex) {
          return {
            ...detail,
            recipes: detail.recipes.filter((_, idx) => idx !== recipeIndex),
          };
        }
        return detail;
      });

      setEmailStates((currentStates) => {
        const updatedStates = currentStates.map((state, index) => {
          if (index === detailIndex) {
            return state.filter((_, idx) => idx !== recipeIndex);
          }
          return state;
        });
        return updatedStates;
      });

      return updatedDetails;
    });
  };

  const handleAddRecipeForMember = (detailIndex) => {
    setScheduleDetails((currentDetails) => {
      const newRecipe = {
        recipeId: "",
        assignedEmail: "",
        eventDescription: "",
      };
      const updatedDetails = currentDetails.map((detail, index) => {
        if (index === detailIndex) {
          return {
            ...detail,
            recipes: [...detail.recipes, newRecipe],
          };
        }
        return detail;
      });

      setEmailStates((currentStates) => {
        const updatedStates = currentStates.map((state, index) => {
          if (index === detailIndex) {
            return [...state, { inputEmail: "", filteredSuggestions: [] }];
          }
          return state;
        });
        return updatedStates;
      });

      return updatedDetails;
    });
  };

  const handleEmailChange = (detailIndex, recipeIndex, email) => {
    const isValidEmail = validateEmail(email);
    setEmailStates((currentStates) => {
      const newStates = [...currentStates];
      if (!newStates[detailIndex]) {
        newStates[detailIndex] = [];
      }
      if (!newStates[detailIndex][recipeIndex]) {
        newStates[detailIndex][recipeIndex] = {
          inputEmail: "",
          isValid: false,
          filteredSuggestions: [],
        };
      }
      newStates[detailIndex][recipeIndex].inputEmail = email;
      newStates[detailIndex][recipeIndex].isValid = isValidEmail;
      newStates[detailIndex][
        recipeIndex
      ].filteredSuggestions = emailSuggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(email.toLowerCase())
      );
      return newStates;
    });
    setScheduleDetails((currentDetails) => {
      const newDetails = [...currentDetails];
      if (
        newDetails[detailIndex] &&
        newDetails[detailIndex].recipes[recipeIndex]
      ) {
        newDetails[detailIndex].recipes[recipeIndex].assignedEmail = email;
        newDetails[detailIndex].recipes[recipeIndex].isValid = isValidEmail;
      }
      return newDetails;
    });
  };

  const handleFocus = (detailIndex, recipeIndex) => {
    setEmailStates((currentStates) => {
      const newStates = [...currentStates];
      if (newStates[detailIndex] && newStates[detailIndex][recipeIndex]) {
        newStates[detailIndex][
          recipeIndex
        ].filteredSuggestions = emailSuggestions;
      }
      return newStates;
    });
  };

  const handleBlur = (detailIndex, recipeIndex) => {
    setTimeout(() => {
      setEmailStates((currentStates) => {
        const newStates = [...currentStates];
        newStates[detailIndex][recipeIndex].filteredSuggestions = [];
        return newStates;
      });
    }, 100);
  };

  const userLookup = async (email) => {
    const { data, error } = await supabase
      .from("profiles")
      .select("id")
      .eq("email", email)
      .single();

    if (error) {
      console.error("Error looking up user:", error.message);
      return null;
    }

    return data ? data.id : null;
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleFinalize = async () => {
    try {
      let invalidEmails = [];
      for (let detailIndex in emailStates) {
        for (let recipeIndex in emailStates[detailIndex]) {
          const entry = emailStates[detailIndex][recipeIndex];
          if (!entry.isValid && entry.inputEmail) {
            console.error("Invalid email:", entry.inputEmail);
            invalidEmails.push(entry.inputEmail);
          }
        }
      }

      if (invalidEmails.length > 0) {
        throw new Error(
          t("groupplans.invalidemail") `${invalidEmails.join(", ")}`
        );
      }

      const { data: ownerData } = await supabase
        .from("profiles")
        .select("first_name, last_name, email")
        .eq("id", user.id)
        .single();

      const ownerName =
        ownerData.first_name && ownerData.last_name
          ? `${ownerData.first_name} ${ownerData.last_name}`
          : ownerData.email;

      const { data: groupMeal, error: groupMealError } = await supabase
        .from("other_group_meals")
        .insert([
          { event_name: scheduleDetails[0].planName, owner_id: user.id },
        ])
        .select("id");

      if (groupMealError) {
        console.error("Error inserting group meal:", groupMealError.message);
        throw new Error("Failed to insert group meal");
      }

      if (!groupMeal || groupMeal.length === 0) {
        console.error("No group meal data returned after insertion.");
        throw new Error("No group meal data returned");
      }

      const groupMealId = groupMeal[0].id;

      const assignmentsPromises = scheduleDetails.flatMap((detail) =>
        detail.recipes.map(async (recipe) => {
          const userId = await userLookup(recipe.assignedEmail);
          return {
            other_group_meals_id: groupMealId,
            recipe_id: recipe.recipeId,
            event_date_time: `${detail.datePlanned}T${detail.timePlanned}:00`,
            event_description: detail.eventDescription || "",
            user_id: userId,
            email: recipe.assignedEmail,
            accepted: 0,
          };
        })
      );

      const mealAssignments = await Promise.all(
        assignmentsPromises.map(async (assignmentPromise) => {
          const assignment = await assignmentPromise;
          return {
            ...assignment,
            accepted: assignment.user_id === user.id ? 1 : 0,
          };
        })
      );

      const { error: assignmentsError } = await supabase
        .from("other_group_meal_assignments")
        .insert(mealAssignments);

      if (assignmentsError) {
        console.error(
          "Error inserting meal assignments:",
          assignmentsError.message
        );
        throw new Error("Failed to insert meal assignments");
      }

      const { data: fetchedAssignments, error: fetchError } = await supabase
        .from("other_group_meal_assignments")
        .select(
          `
            id,
            other_group_meals_id,
            recipe_id,
            event_date_time,
            event_description,
            user_id,
            email,
            accepted,
            recipes (name, url)
          `
        )
        .eq("other_group_meals_id", groupMealId);

      if (fetchError) {
        console.error(
          "Error fetching meal assignments with recipe details:",
          fetchError.message
        );
        throw new Error("Failed to fetch meal assignments");
      }

      const emailMap = new Map();
      fetchedAssignments.forEach((assignment) => {
        if (assignment.email === user.email) {
          return;
        }

        if (!emailMap.has(assignment.email)) {
          emailMap.set(assignment.email, {
            event_name: groupMeal[0].event_name,
            owner_id: ownerName,
            assignments: [],
            first_assignment_id: assignment.id,
          });
        }

        const eventData = emailMap.get(assignment.email);
        let group = eventData.assignments.find(
          (group) => group.event_date_time === assignment.event_date_time
        );
        if (!group) {
          group = {
            event_description: assignment.event_description,
            event_date_time: assignment.event_date_time,
            recipes: [],
          };
          eventData.assignments.push(group);
        }
        group.recipes.push({
          recipe_name: assignment.recipes.name,
          url: assignment.recipes.url,
          email: assignment.email,
        });
      });

      emailMap.forEach((data, email) => {
        if (email) {
          const assignmentsDescription = data.assignments
            .map((assignment) => {
              const date = dayjs
                .utc(assignment.event_date_time)
                .format("MMMM DD, YYYY HH:mm:ss");
              return [
                `${assignment.event_description} on ${date}:`,
                ...assignment.recipes.map(
                  (r) =>
                    `${r.recipe_name} - View at: https://app.recipecircle.world/recipes-view/${r.url}`
                ),
              ].join("\n");
            })
            .join("\n\n");
          const emailData = {
            email: email,
            event_name: scheduleDetails[0].planName,
            owner_id: data.owner_id,
            meal_plan_id: groupMealId,
            first_assignment_id: data.first_assignment_id,
            grouped_assignments: assignmentsDescription,
          };
          emailjs
            .send(
              "service_f1pcnl8",
              "template_86n4l4q",
              emailData,
              process.env.REACT_APP_EMAIL_JS_TOKEN
            )
            .catch((error) => {
              console.error("Email sending failed for:", email, error);
            });
        }
      });

      Swal.fire({
        title: t("groupplans.success"),
        text: t("groupplans.emailssent"),        
        icon: "success",
        confirmButtonText: t("planmeals.ok"),
      }).then((result) => {
        if (result.isConfirmed) {
          setScheduleDetails([
            {
              datePlanned: "",
              timePlanned: "",
              eventDescription: "",
              planName: "",
              eventName: "",
              recipes: [{ recipeId: "", assignedEmail: "" }],
            },
          ]);
          setEmailStates([
            [
              {
                inputEmail: "",
                filteredSuggestions: [],
              },
            ],
          ]);
          onClose();
        }
      });
    } catch (error) {
      console.error("Error finalizing group meal plan:", error.message);
      Swal.fire({
        title: t("planmeals.error"),
        text: t("groupplans.failedtosave") `${error.message || error}`,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <div
      className={`modal ${
        isOpen ? "modal-open" : ""
      } fixed inset-0 flex items-center justify-center`}
    >
      <div
        className="modal-overlay absolute inset-0 bg-gray-900 opacity-50"
        onClick={onClose}
      ></div>
      <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-xl z-50 shadow-lg overflow-hidden">
        <div className="p-5">
          <h2 className="text-2xl font-bold text-center mb-4">
            {t("groupplans.finalizefor")} {scheduleDetails[0]?.planName}
          </h2>
          <div className="modal-content overflow-y-auto max-h-[calc(100vh-200px)] px-4">
            {scheduleDetails.map((detail, detailIndex) => (
              <div key={detailIndex} className="space-y-4">
                <h3 className="text-xl font-bold">
                  {detail.eventDescription} {t("groupplans.on")} {detail.datePlanned} {t("groupplans.at")}{" "}
                  {detail.timePlanned}
                </h3>
                {detail.recipes && detail.recipes.length > 0
                  ? detail.recipes.map((recipe, recipeIndex) => (
                      <div
                        key={recipeIndex}
                        className="flex flex-col gap-3 mb-4"
                      >
                        <div className="flex items-center gap-4">
                          <div className="flex-1">
                            <select
                              className="form-select block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white border border-gray-300 rounded transition ease-in-out focus:border-blue-600 focus:outline-none"
                              value={recipe.recipeId || ""}
                              onChange={(e) =>
                                handleRecipeSelection(
                                  detailIndex,
                                  recipeIndex,
                                  e.target.value
                                )
                              }
                            >
                              <option
                                value=""
                                disabled
                                selected={!recipe.recipeId}
                              >
                                {t("planmeals.select")}
                              </option>
                              {addedRecipes.map((addedRecipe) => (
                                <option
                                  key={addedRecipe.id}
                                  value={addedRecipe.id}
                                >
                                  {addedRecipe.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="relative flex-1">
                            <input
                              type="email"
                              placeholder={t("groupplans.assignto")}
                              className="px-3 py-1.5 text-base font-normal text-gray-700 bg-white border border-gray-300 rounded transition ease-in-out focus:border-blue-600 focus:outline-none w-full"
                              value={
                                emailStates[detailIndex]?.[recipeIndex]
                                  ?.inputEmail || ""
                              }
                              onChange={(e) =>
                                handleEmailChange(
                                  detailIndex,
                                  recipeIndex,
                                  e.target.value
                                )
                              }
                              onFocus={() =>
                                handleFocus(detailIndex, recipeIndex)
                              }
                              onBlur={() =>
                                handleBlur(detailIndex, recipeIndex)
                              }
                            />
                            {emailStates[detailIndex]?.[recipeIndex]
                              ?.filteredSuggestions?.length > 0 && (
                              <ul className="absolute z-10 list-none bg-white border border-gray-300 rounded mt-1 overflow-y-auto max-h-40 min-w-full left-[-100px] right-0">
                                {emailStates[detailIndex][
                                  recipeIndex
                                ].filteredSuggestions.map((email, index) => (
                                  <li
                                    key={index}
                                    className="px-3 py-2 hover:bg-gray-100 cursor-pointer"
                                    onClick={() =>
                                      handleEmailChange(
                                        detailIndex,
                                        recipeIndex,
                                        email
                                      )
                                    }
                                    title={email}
                                  >
                                    {email}
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        </div>
                        <button
                          onClick={() =>
                            handleRemoveRecipe(detailIndex, recipeIndex)
                          }
                          className="btn bg-red-500 text-white w-full px-6 py-2.5 font-medium text-xs leading-tight uppercase rounded hover:bg-red-700 transition duration-150 ease-in-out mt-2 mb-4"
                        >
                          {t("groupplans.remove")}
                        </button>
                      </div>
                    ))
                  : null}
                <div className="text-center">
                  <button
                    onClick={() => handleAddRecipeForMember(detailIndex)}
                    className="btn btn-outline inline-block px-6 py-2.5 mb-5 text-stone-800 font-medium text-xs leading-tight uppercase rounded hover:bg-blue-700 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    {detail.recipes && detail.recipes.length > 0
                      ? t("planmeals.addanother")
                      : t("planmeals.addanotherdate")}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-end p-4">
          <button
            onClick={handleFinalize}
            className="btn bg-green-600 text-white"
          >
            {t("groupplans.finalize")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinalizeGroupMealModal;
