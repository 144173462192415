import React from 'react';
import { useTranslation } from "react-i18next";
import { MdArrowBackIosNew } from 'react-icons/md';
import {
    EditIcon,
    DeleteIcon,
    PublicIcon,
  } from "../../components/common/Icons";
  import { useNavigate, useParams } from "react-router-dom";

  const RecipeHeader = ({ author, recipeName, handleEditClick }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
      <div className="w-100 container mx-auto">
        <div className="w-full overflow-x-hidden">
          <div className="text-center">
            <h1 className="text-4xl font-bold py-4 text-[#384E79]">
              {recipeName}
              <span className="text-[#384E79] text-sm block mt-2">{t("viewrecipe.by")} {author}</span>
            </h1>
            <div className="breadcrumbs flex justify-center mb-1">
              <ul>
                <li>
                  <a
                    onClick={() => {
                      navigate("/");
                    }}
                    className="text-[#384E79] hover:text-blue-400"
                  >
                    {t("viewrecipe.home")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/recipes");
                    }}
                    className="text-[#384E79] hover:text-blue-400"
                  >
                    {t("viewrecipe.yourrecipes")}
                  </a>
                </li>
                <li className="text-stone-700">{recipeName}</li>
              </ul>
            </div>
          </div>
    
          <div className="flex justify-between mb-1">
            <div className="flex-1">
              <button
                className="btn btn-outline"
                onClick={() => {
                  navigate("/recipes");
                }}
              >
                <MdArrowBackIosNew />
              </button>
            </div>
    
            <div className="flex-1 text-right">
              <div className="flex justify-end">
                <button className="btn btn-outline mr-2" onClick={handleEditClick}>
                  <EditIcon />
                </button>
                <label htmlFor="my-modal" className="btn btn-outline">
                  <DeleteIcon />
                </label>
              </div>
            </div>
          </div>
    
          <div className="divider"></div>
        </div>
        </div>
      );
    };
    
    export default RecipeHeader;