/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import { DataContext } from "../../context/DataContext";
import MobileNavbarSignedIn from "../common/MobileNavbarSignedIn";
import CookieConsent from "../utilities/cookieconsent";

const Layout = (props) => {
  const { state, actions } = useContext(DataContext);
  const { children } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [textColorClass, setTextColorClass] = useState("text-zinc-200");
  const [linkBackgroundColor, setLinkBackgroundColor] =
    useState("bg-transparent");
  const [profileBackgroundColor, setProfileBackgroundColor] =
    useState("bg-transparent");
  const profileMenuRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const loadUser = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      let { data: dataProfile } = await supabase
        .from("profiles")
        .select(`id, first_name, last_name, about, image`)
        .eq("id", user.id)
        .single();
      actions.setUser({ ...dataProfile, email: user.email });
      setIsLoading(false);
    };

    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  const signOut = async () => {
    await supabase.auth.signOut();
    navigate("/");
  };

  const { pathname } = location;

  const closeMenus = () => {
    setUserMenuOpen(false);
    setMobileMenuOpen(false);
  };

  const handleClickOutside = (event) => {
    if (
      profileMenuRef.current &&
      !profileMenuRef.current.contains(event.target)
    ) {
      closeMenus();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const isMobileWidth = window.innerWidth < 768;
      setIsMobile(isMobileWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    document.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("click", handleClickOutside);
    };
  }, [mobileMenuOpen]);

  const handleNavigate = (path) => {
    setIsOpen(false);
    navigate(path); 
  };

  return (
    <>
      <MobileNavbarSignedIn />
      <div className="w-full">{children}</div>
    </>
  );
};

export default Layout;
