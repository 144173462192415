import React, { useContext, useEffect, useState, useRef } from "react";
import { supabase } from "../../supabaseClient";
import { useForm } from "react-hook-form";
import PhotoUploaderRecipe from "../../components/common/PhotoUploaderRecipe";
import { useNavigate, useParams } from "react-router-dom";
import PageTitle from "../../components/layout/PageTitle";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { DataContext } from "../../context/DataContext";
import { BackIcon } from "../../components/common/Icons";
import { DeleteIconSm, DownIcon, UpIcon } from "../../components/common/Icons";
import { categoriesMain, categoriesDietary } from "../../constants";
import DesktopMenu from "../../components/layout/DesktopWrittenMenu";
import MobileMenu from "../../components/layout/MobileWrittenMenu";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import FormattingTips from "../../components/utilities/formattingtips";

import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import {
  MdDelete,
  MdArrowCircleDown,
  MdArrowCircleUp,
  MdArrowBackIosNew,
  MdLightbulbOutline,
} from "react-icons/md";

const PreventEnterSubmit = () => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return null;
};

const orderList = (list, value) => {
  return list.sort((a, b) => {
    if (a[value] < b[value]) {
      return -1;
    }
    if (a[value] > b[value]) {
      return 1;
    }
    return 0;
  });
};

const UpdateRecipe = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { state } = useContext(DataContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [recipe, setRecipe] = useState(null);
  const [circles, setCircles] = useState([]);
  const [data, setData] = useState("");
  const [dataPublic, setDataPublic] = useState(false);
  const [dataSteps, setDataSteps] = useState([]);
  const [dataIngredients, setDataIngredients] = useState([]);
  const [dataBookmarks, setDataBookmarks] = useState([]);
  const [dataCategories, setDataCategories] = useState([]);
  const [dataCategoriesDietary, setDataCategoriesDietary] = useState([]);
  const [dataDirtyField, setDataDirtyField] = useState({
    ingredient: false,
    category: false,
    categoryDietary: false,
    circle: false,
  });
  const [dataCircle, setDataCircle] = useState([]);
  const [dataFile, setDataFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSuccess, setIsLoadingSuccess] = useState(false);
  const [success, setSuccess] = useState(false);
  const [originalDataIngredients, setOriginalDataIngredients] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [suggestedCategories, setSuggestedCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestedUserRecipes, setSuggestedUserRecipes] = useState([]);
  const [externalLink, setExternalLink] = useState("");
  const [user, setUser] = useState(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [selectedRecipes, setSelectedRecipes] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [originalLinkedRecipes, setOriginalLinkedRecipes] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchUser = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      setUser(user);
    };

    fetchUser();
  }, []);

  const allCategories = [...categoriesMain, ...categoriesDietary];

  const searchResultsRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        searchResultsRef.current &&
        !searchResultsRef.current.contains(event.target)
      ) {
        setSuggestedCategories([]);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleSearchInputChange = (e) => {
    const input = e.target.value;
    setSearchInput(input);

    const filteredCategories = input
      ? allCategories.filter(
          (cat) =>
            cat.label.toLowerCase().includes(input.toLowerCase()) &&
            !selectedCategories.includes(cat.label)
        )
      : [];

    if (filteredCategories.length === 0 && input.trim() !== "") {
      setSuggestedCategories([{ label: input }]);
    } else {
      setSuggestedCategories(filteredCategories);
    }
  };

  const handleAddButtonClick = () => {
    if (searchInput.trim() !== "") {
      handleAddCategory(searchInput);
    }
  };

  const handleAddCategory = (category) => {
    const trimmedCategory = category.trim();

    if (
      trimmedCategory !== "" &&
      !selectedCategories.includes(trimmedCategory)
    ) {
      setSelectedCategories([...selectedCategories, trimmedCategory]);
      setSearchInput("");
      setSuggestedCategories([]);
      setDataDirtyField({ ...dataDirtyField, category: true });
    }
  };

  const handleRemoveCategory = (category) => {
    const updatedCategories = selectedCategories.filter(
      (cat) => cat !== category
    );
    setSelectedCategories(updatedCategories);
    setDataDirtyField({ ...dataDirtyField, category: true });
  };

  useEffect(() => {
    setSuggestedCategories([]);
  }, [selectedCategories]);

  useEffect(() => {
    const loadRecipe = async () => {
      let { data: dataR, error: errorR, status: statusR } = await supabase
        .from("recipes")
        .select(
          `id, name, author, description, hack, duration, servings, steps, bookmarks, public, image_url, type,
          recipe_ingredients ( id, name, measure, quantity ),
          recipe_categories ( category_key, type ),
          recipe_links!fk_recipe ( id, linked_recipe_id, external_url, link_type ),
          circle_recipes ( circle_id )`
        )
        .eq("id", id)
        .single();

      if (errorR && statusR !== 406) {
        console.error("Error loading recipe:", errorR);
        return;
      }

      setRecipe(dataR);
      setDataPublic(dataR.public);

      setOriginalDataIngredients(
        dataR.recipe_ingredients.map((ingredient, index) => ({
          id: ingredient.id,
          value: ingredient.name,
          measure: ingredient.measure,
          quantity: ingredient.quantity,
          position: ingredient.position ?? index + 1,
        }))
      );

      setDataIngredients(
        dataR.recipe_ingredients.map((ingredient, index) => ({
          id: ingredient.id,
          name: ingredient.name,
          measure: ingredient.measure,
          quantity: ingredient.quantity,
          position: ingredient.position ?? index + 1,
        }))
      );

      const steps = Array.isArray(dataR.steps)
        ? dataR.steps
        : JSON.parse(dataR.steps);
      const formattedSteps = steps.map((step, index) => ({
        id: `step-${index + 1}`,
        value: typeof step === "string" ? step : step.value,
        position: step.position || index + 1,
      }));

      setDataSteps(formattedSteps);

      const recipeBookmarks = dataR?.bookmarks?.map((bookmark, index) => ({
        id: `bookmark-${index + 1}`,
        value: bookmark?.value ?? bookmark,
        position: bookmark?.position ?? index + 1,
      }));

      setDataBookmarks(recipeBookmarks);

      const categories = dataR?.recipe_categories
        .filter((cItem) => cItem.type === 1)
        .map((c) => c.category_key);
      setSelectedCategories(categories ?? []);

      const categoriesDietary = dataR?.recipe_categories
        .filter((cItem) => cItem.type === 2)
        .map((c) => c.category_key);
      setDataCategoriesDietary(categoriesDietary ?? []);

      const circleRecipes = dataR?.circle_recipes.map((c) => c.circle_id);
      setDataCircle(circleRecipes ?? []);

      const linkedRecipes = await Promise.all(
        dataR.recipe_links.map(async (link) => {
          if (link.link_type) {
            const {
              data: linkedRecipeData,
              error: linkedRecipeError,
            } = await supabase
              .from("recipes")
              .select("id, name")
              .eq("id", link.linked_recipe_id)
              .single();

            if (linkedRecipeError) {
              console.error(
                "Error fetching linked recipe details:",
                linkedRecipeError
              );
              return null;
            }

            return {
              linkId: link.id,
              id: linkedRecipeData.id,
              name: linkedRecipeData.name,
              isExternal: false,
            };
          } else {
            return {
              linkId: link.id,
              id: `external-${Date.now()}`,
              name: link.external_url,
              isExternal: true,
            };
          }
        })
      );

      const validLinkedRecipes = linkedRecipes.filter(Boolean);
      setSelectedRecipes(validLinkedRecipes);
      setOriginalLinkedRecipes([...validLinkedRecipes]);

      setIsLoading(false);
    };
    if (!recipe || id !== recipe.id) {
      loadRecipe();
    }
  }, [id, recipe]);

  useEffect(() => {
    const loadCircles = async () => {
      if (!state?.user?.id) {
        return false;
      }

      let {
        data: dataCircles,
        error: errorC,
        status: statusC,
      } = await supabase
        .from("circles")
        .select(`id,name, description, image_url`)
        .eq("owner_id", state?.user?.id);
      setCircles(dataCircles);
      if (errorC && statusC !== 406) {
        throw errorC;
      }
    };
    loadCircles();
  }, [state.user]);

  const originalLinkedRecipeIds = new Set(
    originalLinkedRecipes.map((link) => link.id)
  );
  const currentLinkedRecipeIds = new Set(
    selectedRecipes.filter((link) => !link.isExternal).map((link) => link.id)
  );

  const originalLinkIds = new Set(
    originalLinkedRecipes.map((link) => link.linkId)
  );
  const currentLinkIds = new Set(selectedRecipes.map((link) => link.linkId));

  const linksToAdd = selectedRecipes.filter(
    (link) =>
      (link.isExternal && !originalLinkedRecipeIds.has(link.id)) ||
      (!link.isExternal && !originalLinkedRecipeIds.has(link.id))
  );

  const linksToRemove = originalLinkedRecipes.filter(
    (link) => !currentLinkIds.has(link.linkId)
  );

  const processSubmit = async (dataInput) => {
    if (!state?.user?.id) {
      return false;
    }
    setData({ ...data, ...dataInput });
    setIsLoadingSuccess(true);

    let filePath = null;

    if (recipe.type === 2 && dataFile) {
      const extension = dataFile.name.split(".").pop();

      const { data: dataUpload, error } = await supabase.storage
        .from("recipe-files")
        .upload(
          `private/files/file-${Date.now()}${extension ? `.${extension}` : ""}`,
          dataFile
        );

      if (error) {
        throw error;
      }

      filePath = dataUpload.path;
    }

    const update = {
      id: recipe.id,
      name: dataInput.name,
      author: dataInput.author,
      description: dataInput.description,
      hack: dataInput.hack,
      duration: dataInput.duration,
      servings: dataInput.servings,
      steps: dataSteps.map((item) => item.value),
      bookmarks: dataBookmarks?.map((item) => item.value),
      image_url: data.image_url,
      public: dataPublic,
      file: filePath ?? recipe.file,
      owner_id: state.user.id,
    };

    let { error, status } = await supabase
      .from("recipes")
      .upsert(update)
      .single();

    if (error && status !== 406) {
      throw error;
    }

    if (dataDirtyField.ingredient) {
      const formattedIngredients = dataIngredients.map((ingredient) => ({
        name: ingredient.name,
        quantity: ingredient.quantity.toString(),
        measure: ingredient.measure,
        position: ingredient.position,
      }));

      try {
        const { error } = await supabase.rpc("update_recipe_ingredients", {
          _recipe_id: recipe.id,
          new_ingredients: formattedIngredients,
        });

        if (error) throw error;
      } catch (error) {
        console.error("Failed to update ingredients:", error);
        setIsLoadingSuccess(false);
        return;
      }
    }

    if (dataDirtyField.category) {
      const { data: existingCategories, error: getError } = await supabase
        .from("recipe_categories")
        .select("category_key")
        .eq("recipe_id", recipe.id)
        .eq("type", 1);

      if (getError) {
        throw getError;
      }

      const existingCategoryKeys = existingCategories.map(
        (cat) => cat.category_key
      );

      const uniqueSelectedCategories = [...new Set(selectedCategories)];

      const newCategories = uniqueSelectedCategories.filter(
        (item) => !existingCategoryKeys.includes(item)
      );

      if (newCategories.length) {
        const insertCategories = newCategories.map((item) => ({
          recipe_id: recipe.id,
          category_key: item,
          type: 1,
        }));

        const {
          error: insertError,
          status: insertStatus,
        } = await supabase.from("recipe_categories").insert(insertCategories);

        if (insertError && insertStatus !== 406) {
          console.error("Error inserting categories:", insertError);
          throw insertError;
        }
      }

      const categoriesToDelete = existingCategoryKeys.filter(
        (item) => !uniqueSelectedCategories.includes(item)
      );

      if (categoriesToDelete.length) {
        const { error: deleteError } = await supabase
          .from("recipe_categories")
          .delete()
          .eq("recipe_id", recipe.id)
          .eq("type", 1)
          .in("category_key", categoriesToDelete);

        if (deleteError) {
          console.error("Error deleting categories:", deleteError);
          throw deleteError;
        }
      }
    }

    if (dataDirtyField.categoryDietary) {
      const { error } = await supabase
        .from("recipe_categories")
        .delete()
        .eq("recipe_id", recipe.id)
        .eq("type", 2);
      if (error) {
        throw error;
      }
    }

    if (dataDirtyField.categoryDietary && dataCategoriesDietary?.length) {
      const insertCategoriesDietary = dataCategoriesDietary.map((item) => ({
        recipe_id: recipe.id,
        category_key: item,
        type: 2,
      }));

      let { error: errorCatD, status: statusCatD } = await supabase
        .from("recipe_categories")
        .insert(insertCategoriesDietary);
      if (errorCatD && statusCatD !== 406) {
        throw errorCatD;
      }
    }

    if (dataDirtyField.circle) {
      const { error } = await supabase
        .from("circle_recipes")
        .delete()
        .eq("recipe_id", recipe.id);
      if (error) {
        throw error;
      }
    }

    if (dataDirtyField.circle && dataCircle?.length) {
      // Insert Circles
      const insertCircles = dataCircle.map((item) => ({
        recipe_id: recipe.id,
        circle_id: item,
      }));

      let { error: errorC, status: statusC } = await supabase
        .from("circle_recipes")
        .insert(insertCircles);
      if (errorC && statusC !== 406) {
        throw errorC;
      }
    }

    try {
      for (const link of linksToAdd) {
        let linkInsert;
        if (link.isExternal) {
          linkInsert = {
            recipe_id: recipe.id,
            external_url: link.name,
            link_type: false,
            user_id: state.user.id,
          };
        } else {
          linkInsert = {
            recipe_id: recipe.id,
            linked_recipe_id: link.id,
            link_type: true,
            user_id: state.user.id,
          };
        }

        const { error } = await supabase
          .from("recipe_links")
          .insert([linkInsert]);
        if (error) throw error;
      }

      for (const link of linksToRemove) {
        const deleteQuery = supabase
          .from("recipe_links")
          .delete()
          .match({ id: link.linkId });

        const { error: deleteError } = await deleteQuery;
        if (deleteError) {
          console.error("Failed to delete link:", link, deleteError);
          throw deleteError;
        }
      }
    } catch (error) {
      console.error("Error updating recipe links:", error);
      setIsLoadingSuccess(false);
      return;
    }

    setSuccess(true);
    setIsLoadingSuccess(false);
    setTimeout(() => {
      navigate("/recipes");
    }, 3000);
  };

  const handleCategory = (e, value) => {
    let updateCategories = selectedCategories;
    if (e.target.checked) {
      updateCategories = [...updateCategories, value];
    } else {
      updateCategories = updateCategories.filter((itemC) => itemC !== value);
    }
    setSelectedCategories(updateCategories);
    setDataDirtyField({ ...dataDirtyField, category: true });
  };

  const handleCategoryDietry = (e, value) => {
    let updateCategories = dataCategoriesDietary;
    if (e.target.checked) {
      updateCategories = [...updateCategories, value];
    } else {
      updateCategories = updateCategories.filter((itemC) => itemC !== value);
    }
    setDataCategoriesDietary(updateCategories);
    setDataDirtyField({ ...dataDirtyField, categoryDietary: true });
  };

  const handleCircle = (e, value) => {
    let updateCircles = dataCircle;
    if (e.target.checked) {
      updateCircles = [...updateCircles, value];
    } else {
      updateCircles = updateCircles.filter((itemC) => itemC !== value);
    }
    setDataCircle(updateCircles);
    setDataDirtyField({ ...dataDirtyField, circle: true });
  };

  const handleAddIngredient = () => {
    setDataIngredients([
      ...dataIngredients,
      {
        id: Date.now(),
        name: "",
        quantity: "",
        measure: "",
        position: dataIngredients.length + 1,
      },
    ]);
    setDataDirtyField({ ...dataDirtyField, ingredient: true });
  };

  const handleChangeIngredient = (e, id, field) => {
    e.preventDefault();
    const updatedIngredients = dataIngredients.map((item) => {
      if (item.id === id) {
        return { ...item, [field]: e.target.value };
      }
      return item;
    });
    setDataIngredients(updatedIngredients);
    setDataDirtyField({ ...dataDirtyField, ingredient: true });
  };

  const handleIngredientRemove = (e, id) => {
    e.preventDefault();
    const item = dataIngredients.find((item) => item.id === id);
    if (!item) {
      console.error("Ingredient not found:", id);
      return;
    }
    const pos = item.position;
    const updatedIngredients = dataIngredients
      .filter((item) => item.id !== id)
      .map((item) => {
        if (item.position > pos) {
          return { ...item, position: item.position - 1 };
        }
        return item;
      });

    setDataIngredients(updatedIngredients);
    setDataDirtyField({ ...dataDirtyField, ingredient: true });
  };

  const handleIngredientMove = (e, id, direction) => {
    e.preventDefault();
    const item = dataIngredients.find((item) => item.id === id);
    const pos = item.position;
    let toPos = null;
    if (direction === "up") {
      toPos = pos - 1;
    } else {
      toPos = pos + 1;
    }

    const updateIngredients = dataIngredients.map((item) => {
      if (item.position === pos) {
        return { ...item, position: toPos };
      } else if (item.position === toPos) {
        return { ...item, position: pos };
      }
      return item;
    });
    setDataIngredients(updateIngredients);
    setDataDirtyField({ ...dataDirtyField, ingredient: true });
  };

  const handleAddStep = () => {
    setDataSteps([
      ...dataSteps,
      { id: Date.now(), value: "", position: dataSteps.length + 1 },
    ]);
  };

  const handleChangeStep = (id, value) => {
    const updateSteps = dataSteps.map((item) => {
      if (item.id === id) {
        return { ...item, value: value };
      }
      return item;
    });
    setDataSteps(updateSteps);
  };

  const handleStepRemove = (e, id) => {
    e.preventDefault();
    const item = dataSteps.find((item) => item.id === id);
    const pos = item.position;
    const updateSteps = dataSteps
      .filter((item) => item.id !== id)
      .map((item) => {
        if (item.position > pos) {
          return { ...item, position: item.position - 1 };
        }
        return item;
      });
    setDataSteps(updateSteps);
  };

  const handleDeleteStepConfirmation = (e, stepId) => {
    e.preventDefault();

    Swal.fire({
      title: t("createrecipes.deleteprompt"),
      text: t("createrecipes.deletetext"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc2626",
      cancelButtonColor: "#1e293b",
      confirmButtonText: t("createrecipes.deletebtn"),
      customClass: {
        confirmButton: "btn btn-outline",
        cancelButton: "btn btn-secondary",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        handleStepRemove(e, stepId);
      }
    });
  };

  const handleStepMove = (e, id, direction) => {
    e.preventDefault();
    const item = dataSteps.find((item) => item.id === id);
    const pos = item.position;
    let toPos = null;
    if (direction === "up") {
      toPos = pos - 1;
    } else {
      toPos = pos + 1;
    }

    const updateSteps = dataSteps.map((item) => {
      if (item.position === pos) {
        return { ...item, position: toPos };
      } else if (item.position === toPos) {
        return { ...item, position: pos };
      }
      return item;
    });
    setDataSteps(updateSteps);
  };

  const handleAddBookmark = () => {
    setDataBookmarks([
      ...dataBookmarks,
      { id: Date.now(), value: "", position: dataBookmarks.length + 1 },
    ]);
  };

  const handleChangeBookmark = (e, id) => {
    e.preventDefault();
    const updateBookmarks = dataBookmarks.map((item) => {
      if (item.id === id) {
        return { ...item, value: e.target.value };
      }
      return item;
    });
    setDataBookmarks(updateBookmarks);
  };

  const handleBookmarkRemove = (e, id) => {
    e.preventDefault();
    const item = dataBookmarks.find((item) => item.id === id);
    const pos = item.position;
    const updateBookmarks = dataBookmarks
      .filter((item) => item.id !== id)
      .map((item) => {
        if (item.position > pos) {
          return { ...item, position: item.position - 1 };
        }
        return item;
      });
    setDataBookmarks(updateBookmarks);
  };

  const handleBookmarkMove = (e, id, direction) => {
    e.preventDefault();
    const item = dataBookmarks.find((item) => item.id === id);
    const pos = item.position;
    let toPos = null;
    if (direction === "up") {
      toPos = pos - 1;
    } else {
      toPos = pos + 1;
    }

    const updateBookmarks = dataBookmarks.map((item) => {
      if (item.position === pos) {
        return { ...item, position: toPos };
      } else if (item.position === toPos) {
        return { ...item, position: pos };
      }
      return item;
    });
    setDataBookmarks(updateBookmarks);
  };

  const handleFileSelect = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const selectedFile = event.target.files[0];
      setDataFile(selectedFile);
    }
  };

  const onChangeImage = (image) => {
    setData({ ...data, image_url: image });
  };

  const handlePublic = () => {
    setDataPublic(!dataPublic);
  };

  const handlePublicToggle = () => {
    setDataPublic((prev) => !prev);
  };

  const [unit, setUnit] = useState("imperial");

  const handleUnitToggle = () => {
    setUnit((prevUnit) => (prevUnit === "imperial" ? "metric" : "imperial"));

    const updatedIngredients = dataIngredients.map((ingredient) => ({
      ...ingredient,
      measure: "",
    }));

    setDataIngredients(updatedIngredients);
  };

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 1050);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleAddClick = (recipe) => {
    if (recipe) {
      setSelectedRecipes((prevRecipes) => [...prevRecipes, recipe]);
      setSearchQuery("");
    } else if (externalLink && isValidUrl(externalLink)) {
      setSelectedRecipes((prevRecipes) => [
        ...prevRecipes,
        { id: `external-${Date.now()}`, name: externalLink, isExternal: true },
      ]);
      setExternalLink("");
      setSearchQuery("");
    }
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleRemoveRecipe = (linkIdToRemove) => {
    setSelectedRecipes((prevRecipes) =>
      prevRecipes.filter((recipe) => recipe.linkId !== linkIdToRemove)
    );
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setTimeout(() => {
      setIsInputFocused(false);
    }, 200);
  };

  const updateSearchQuery = (event) => {
    const inputValue = event.target.value;
    setSearchQuery(inputValue);

    if (isValidUrl(inputValue)) {
      setExternalLink(inputValue);
    } else {
      setExternalLink("");
    }
  };

  const searchUserRecipes = async (query) => {
    if (!user) return;

    if (!isValidUrl(query)) {
      const { data, error } = await supabase
        .from("recipes")
        .select("id, name")
        .ilike("name", `%${query}%`)
        .eq("owner_id", user.id);

      if (error) {
        console.error("Error fetching recipes:", error.message);
        return;
      }

      setSuggestedUserRecipes(data);
    }
  };

  useEffect(() => {
    if (searchQuery && !isValidUrl(searchQuery)) {
      searchUserRecipes(searchQuery);
    }
  }, [searchQuery, user]);

  const title = recipe
    ? `Update ${recipe?.name} - RecipeCircle`
    : "Update Recipe - RecipeCircle";
  const description = recipe
    ? `Update the written recipe for ${recipe?.name} on RecipeCircle.`
    : "Update your recipe on RecipeCircle.";

  return (
    <div className="w-100 container mx-auto">
      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:type" content="website" />
        </Helmet>
        <div className="w-full overflow-x-hidden">
          <div className="text-center">
            <h1 className="capitalize text-4xl font-bold py-4 text-[#384E79]">
              {t("createrecipes.edit")} {recipe?.name}{" "}
              {t("createrecipes.recipe")}
            </h1>
            <div className="breadcrumbs flex justify-center mb-4">
              <ul>
                <li>
                  <a
                    onClick={() => {
                      navigate("/");
                    }}
                    className="text-[#384E79] hover:text-blue-400"
                  >
                    {t("profile.home")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/recipes");
                    }}
                    className="text-[#384E79] hover:text-blue-400"
                  >
                    {t("recipe.recipes")}
                  </a>
                </li>
                <li className="text-stone-700 capitalize">
                  {t("createrecipes.edit")} {recipe?.name}{" "}
                  {t("createrecipes.recipe")}
                </li>
              </ul>
            </div>
          </div>
          <div className="flex justify-space-between mb-1">
            <div className="flex-1">
              <button
                className="btn btn-outline"
                onClick={() => navigate(`/recipes/${recipe?.id}`)}
              >
                <MdArrowBackIosNew />
              </button>
            </div>
          </div>
          <div className="divider"></div>
        </div>
        <PreventEnterSubmit />

        <form
          className="form-control w-full"
          onSubmit={handleSubmit((data) => processSubmit(data))}
          style={{ position: "relative" }}
        >
          {isLoading ? (
            <div className="flex justify-center">
              <span className="loading loading-spinner loading-lg"></span>
            </div>
          ) : (
            <div className="container w-full flex flex-wrap mx-auto px-2 pt-8 lg:pt-16 mt-16">
              {isMobileView ? <MobileMenu /> : <DesktopMenu />}
              <section className="w-full lg:w-4/5">
                <h1 className="capitalize flex items-center font-sans font-bold break-normal text-gray-700 px-2 text-xl mt-12 lg:mt-0 md:text-2xl">
                  {t("createrecipes.edit")} {recipe?.name}{" "}
                  {t("createrecipes.recipe")}
                </h1>

                <hr className="bg-gray-300 my-12" />
                <section id="overview" />
                <h2 className="font-sans font-bold break-normal text-gray-700 px-2 pb-8 text-xl">
                  {t("createrecipes.overview")}
                </h2>

                <div className="p-8 mt-6 lg:mt-0 leading-normal rounded shadow bg-white">
                  <FormattingTips />

                  <div className="flex justify-start">
                    <div className="form-control">
                      <label className="label cursor-pointer">
                        <span className="label-text mr-4">
                          {dataPublic
                            ? t("createrecipes.publicrec")
                            : t("createrecipes.privaterec")}
                        </span>
                        <input
                          type="checkbox"
                          className="toggle"
                          checked={dataPublic}
                          onChange={handlePublicToggle}
                        />
                      </label>
                    </div>
                  </div>
                  <div class="grid gap-6 mb-6 md:grid-cols-2 mt-6">
                    <div>
                      <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                        {t("createrecipes.name")}
                      </label>
                      <input
                        {...register("name", {
                          required: true,
                          value: recipe.name,
                        })}
                        className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                      />
                      {errors.name && (
                        <span className="text-accent text-sm mt-1">
                          {t("createrecipes.required")}
                        </span>
                      )}
                    </div>

                    <div>
                      <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                        {t("createrecipes.author")}
                      </label>
                      <input
                        {...register("author", {
                          required: true,
                          value: recipe.author,
                        })}
                        className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                      />
                      {errors.name && (
                        <span className="text-accent text-sm mt-1">
                          {t("createrecipes.required")}
                        </span>
                      )}
                    </div>
                  </div>
                  <div>
                    <label class=" mb-2 text-sm font-medium text-gray-900 dark:text-gray-900 flex items-center">
                      {t("createrecipes.description")}{" "}
                      <span
                        class="h-4 w-4 text-blue-500 ml-1 tooltip"
                        data-tip={t("markdown.enabled")}
                      >
                        <MdLightbulbOutline />
                      </span>
                    </label>
                    <textarea
                      {...register("description", {
                        value: recipe.description,
                      })}
                      className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                    />
                  </div>
                  <div class="grid gap-6 mb-6 md:grid-cols-2 mt-6">
                    <div>
                      <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                        {t("createrecipes.duration")}
                      </label>
                      <input
                        type="text"
                        {...register("duration", { value: recipe.duration })}
                        className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                      />
                    </div>
                    <div>
                      <label class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                        {t("createrecipes.servings")}
                      </label>
                      <input
                        type="text"
                        {...register("servings", { value: recipe.servings })}
                        className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                      />
                    </div>
                  </div>
                  <div>
                    <label class="flex mb-2 text-sm font-medium text-gray-900 dark:text-gray-900 items-center">
                      {t("createrecipes.hack")}{" "}
                      <span
                        class="h-4 w-4 text-blue-500 ml-1 tooltip"
                        data-tip={t("markdown.enabled")}
                      >
                        <MdLightbulbOutline />
                      </span>
                    </label>
                    <input
                      type="text"
                      {...register("hack", { value: recipe.hack })}
                      className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                    />
                  </div>
                  <PhotoUploaderRecipe
                    onChangeImage={onChangeImage}
                    image={recipe.image_url}
                  />
                </div>
                {/*Overview container end*/}
                <section id="ingredients" />
                <h2 className="pb- mt-4 break-normal px-2 font-sans text-xl font-bold text-gray-700">
                  {t("createrecipes.ingredients")}
                </h2>
                <div className="mt-6 flex flex-wrap rounded bg-white p-8 shadow lg:mt-0">
                  {/* ingredients container start */}
                  <div className="flex justify-start">
                    <div className="form-control mb-5">
                      <div className="form-control ml-2">
                        <label className="label cursor-pointer">
                          <span className="label-text mr-4">
                            {unit === "imperial"
                              ? t("createrecipes.imperial")
                              : t("createrecipes.metric")}
                          </span>
                          <input
                            type="checkbox"
                            className="toggle"
                            checked={unit === "metric"}
                            onClick={handleUnitToggle}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  {orderList(dataIngredients, "position").map(
                    (ingredient, index) => (
                      <div
                        key={`ingredient-${ingredient.id}`}
                        className="mb-6 grid grid-cols-4 gap-4 md:grid-cols-8 w-full items-center"
                      >
                        <div className="col-span-1 md:col-span-1">
                          <input
                            type="text"
                            className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                            onChange={(e) =>
                              handleChangeIngredient(
                                e,
                                ingredient.id,
                                "quantity"
                              )
                            }
                            value={ingredient.quantity}
                            placeholder={t("createrecipes.quantity")}
                          />
                        </div>

                        <div className="col-span-3 md:col-span-2 lg:col-span-1">
                          <select
                            className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                            onChange={(e) =>
                              handleChangeIngredient(
                                e,
                                ingredient.id,
                                "measure"
                              )
                            }
                            value={ingredient.measure || ""}
                          >
                            <option value="" disabled hidden>
                              {t("createrecipes.selectunit")}
                            </option>
                            {unit === "imperial" ? (
                              <>
                                <option value="teaspoon">
                                  {t("measurements.tsp")}
                                </option>
                                <option value="tablespoon">
                                  {t("measurements.tbsp")}
                                </option>
                                <option value="cup">
                                  {t("measurements.cup")}
                                </option>
                                <option value="ounce">
                                  {t("measurements.oz")}
                                </option>
                                <option value="pound">
                                  {t("measurements.lb")}
                                </option>
                                <option value="fluid_ounce">
                                  {t("measurements.floz")}
                                </option>
                                <option value="pint">
                                  {t("measurements.pint")}
                                </option>
                                <option value="quart">
                                  {t("measurements.quart")}
                                </option>
                                <option value="gallon">
                                  {t("measurements.gal")}
                                </option>
                                <option value="unit">
                                  {t("measurements.unit")}
                                </option>
                                <option value="pinch">
                                  {t("measurements.pinch")}
                                </option>
                              </>
                            ) : (
                              <>
                                <option value="milligram">
                                  {t("measurements.mg")}
                                </option>
                                <option value="g">{t("measurements.g")}</option>
                                <option value="kg">
                                  {t("measurements.kg")}
                                </option>
                                <option value="ml">
                                  {t("measurements.ml")}
                                </option>
                                <option value="liter">
                                  {t("measurements.l")}
                                </option>
                                <option value="unit">
                                  {t("measurements.unit")}
                                </option>
                                <option value="pinch">
                                  {t("measurements.pinch")}
                                </option>
                              </>
                            )}
                          </select>
                        </div>

                        <div className="col-span-4 md:col-span-3 lg:col-span-4">
                          <input
                            type="text"
                            className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                            onChange={(e) =>
                              handleChangeIngredient(e, ingredient.id, "name")
                            }
                            value={ingredient.name}
                            placeholder="Ingredient name. Markdown enabled"
                          />
                        </div>

                        <div className="flex flex-wrap gap-2 col-span-4 md:col-span-2 lg:col-span-2">
                          <button
                            className="flex-1 md:flex-auto items-center justify-center text-lg btn btn-outline text-stone-800 hover:bg-emerald-300 hover:text-stone-800 font-bold py-2.5 flex md:w-auto"
                            onClick={(e) =>
                              handleIngredientMove(e, ingredient.id, "up")
                            }
                            disabled={index === 0}
                          >
                            <MdArrowCircleUp className="text-3xl mx-auto" />
                          </button>
                          <button
                            className="flex-1 md:flex-auto items-center justify-center text-lg text-stone-800 btn btn-outline focus:bg-gray-600 active:bg-gray-600 hover:bg-gray-600 font-bold py-2.5 flex md:w-auto"
                            onClick={(e) =>
                              handleIngredientMove(e, ingredient.id, "down")
                            }
                            disabled={index === dataIngredients.length - 1}
                          >
                            <MdArrowCircleDown className="text-3xl mx-auto" />
                          </button>
                          <button
                            className="w-full md:flex-1 items-center justify-center text-lg btn-outline btn focus:bg-red-600 active:bg-red-600 hover:bg-red-600 text-black font-bold py-2.5 flex md:w-auto"
                            type="button"
                            onClick={(e) =>
                              handleIngredientRemove(e, ingredient.id)
                            }
                          >
                            <MdDelete className="text-3xl mx-auto" />
                          </button>
                        </div>
                      </div>
                    )
                  )}

                  <div className="w-full flex justify-center">
                    <button
                      className="btn btn-outline w-full mt-6 tracking-widest"
                      type="button"
                      onClick={handleAddIngredient}
                    >
                      {t("createrecipes.addingredient")}
                    </button>
                  </div>
                </div>
                {/* ingredients container end */}
                {/* ingredients container end */}
                <section id="linking-recipes">
                  <h2 className="mt-4 mb-4 font-sans font-bold break-normal text-gray-700 px-2 pb- text-xl">
                    {t("createrecipes.linkrecipe")}
                  </h2>
                  <p className="text-gray-600 px-2 mb-4">
                    {t("createrecipes.linktext")}
                  </p>
                  <div className="mt-6 flex flex-wrap rounded bg-white p-8 shadow lg:mt-0">
                    <div className="relative mb-4 w-full">
                      <div className="flex items-center border border-gray-900 rounded-lg p-1 relative">
                        <input
                          type="text"
                          id="recipe-link"
                          className={`flex-1 w-full p-4 text-sm text-gray-900 rounded-lg focus:outline-none ${
                            isFocused ? "border-blue-400" : "border-gray-900"
                          }`}
                          placeholder={t("createrecipes.linksearchtext")}
                          value={searchQuery}
                          onChange={updateSearchQuery}
                          onFocus={handleInputFocus}
                          onBlur={handleInputBlur}
                        />
                      </div>
                      {isInputFocused &&
                        (suggestedUserRecipes.length > 0 || externalLink) && (
                          <div className="absolute mt-1 p-2 bg-white border border-gray-300 rounded-lg shadow-lg w-full z-10 max-h-44 overflow-y-auto">
                            {suggestedUserRecipes.map((recipe) => (
                              <div
                                key={recipe.id}
                                className="cursor-pointer py-2 px-2 hover:bg-emerald-300 rounded"
                                onClick={() => handleAddClick(recipe)}
                              >
                                {recipe.name}
                              </div>
                            ))}
                            {externalLink && (
                              <div
                                className="cursor-pointer py-2 px-2 hover:bg-emerald-300 rounded"
                                onClick={() => handleAddClick(null)}
                              >
                                {t("createrecipes.external")} {externalLink}
                              </div>
                            )}
                          </div>
                        )}
                    </div>
                    {selectedRecipes.length > 0 && (
                      <div className="mt-10 flex flex-wrap">
                        {selectedRecipes.map((recipe) => (
                          <div
                            className="rounded-lg mr-2 mb-2 text-black p-3 border border-gray-700"
                            key={recipe.linkId}
                          >
                            {recipe.name}
                            <button
                              onClick={() => handleRemoveRecipe(recipe.linkId)}
                              className="ml-2 text-sm text-zinc-500 hover:text-red-500"
                            >
                              {t("createrecipes.remove")}
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </section>
                {/* steps container start */}
                <section id="steps" />
                <h2 className="mt-4 mb-4 font-sans font-bold break-normal text-gray-700 px-2 pb- text-xl">
                  {t("createrecipes.steps")}
                </h2>
                <div className="mt-6 flex flex-wrap rounded bg-white p-8 shadow lg:mt-0">
                  {orderList(dataSteps, "position").map((step, index) => (
                    <div
                      className="mb-6 grid grid-cols-1 gap-2 md:grid-cols-8 w-full"
                      key={`step-${step.id}`}
                    >
                      {/* Mobile Layout */}
                      <div className="col-span-2 md:hidden">
                        <input
                          onChange={(e) =>
                            handleChangeStep(step.id, e.target.value)
                          }
                          value={step?.value}
                          type="text"
                          className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                        />
                      </div>
                      <div className="col-span-2 md:hidden mt-2">
                        <div className="flex gap-2">
                          <button
                            className="btn btn-outline flex-1 hover:bg-emerald-300 hover:text-black mb-2"
                            onClick={(e) => handleStepMove(e, step.id, "up")}
                            disabled={step.position === 1}
                          >
                            <MdArrowCircleUp className="text-4xl" />
                          </button>
                          <button
                            className="btn btn-outline flex-1 focus:bg-gray-600 active:bg-gray-600 hover:bg-gray-600"
                            onClick={(e) => handleStepMove(e, step.id, "down")}
                            disabled={step.position === dataSteps.length}
                          >
                            <MdArrowCircleDown className="text-4xl" />
                          </button>
                        </div>
                      </div>
                      <div className="col-span-2 md:hidden">
                        <input
                          onChange={(e) =>
                            handleChangeStep(step.id, e.target.value)
                          }
                          value={step?.value}
                          type="text"
                          className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                        />
                      </div>

                      {/* Desktop Layout */}
                      <div className="md:col-span-1 hidden md:block">
                        <button
                          className="btn btn-outline w-full hover:bg-emerald-300 hover:text-black"
                          onClick={(e) => handleStepMove(e, step.id, "up")}
                          disabled={step.position === 1}
                        >
                          <MdArrowCircleUp className="text-4xl" />
                        </button>
                      </div>
                      <div className="col-span-2 md:col-span-3 lg:col-span-4 hidden md:block">
                        <input
                          onChange={(e) =>
                            handleChangeStep(step.id, e.target.value)
                          }
                          value={step?.value}
                          type="text"
                          placeholder={t("createrecipes.stepstext")}
                          className="border border-gray-400 text-gray-900 text-lg rounded-lg block w-full p-2.5"
                        />
                      </div>
                      <div className="md:col-span-1 hidden md:block">
                        <button
                          className="btn btn-outline w-full focus:bg-gray-600 active:bg-gray-600 hover:bg-gray-600"
                          onClick={(e) => handleStepMove(e, step.id, "down")}
                          disabled={step.position === dataSteps.length}
                        >
                          <MdArrowCircleDown className="text-4xl" />
                        </button>
                      </div>
                      <div className="md:col-span-2 md:block flex items-end">
                        <button
                          className="tracking-widest btn btn-outline hover:bg-red-500 mr-4 w-full"
                          onClick={(e) =>
                            handleDeleteStepConfirmation(e, step.id)
                          }
                        >
                          <MdDelete className="text-2xl " />
                          {t("createrecipes.remove")}
                        </button>
                      </div>
                    </div>
                  ))}

                  <div className="w-full flex justify-center">
                    <button
                      className="btn btn-outline w-full mt-6 tracking-widest"
                      type="button"
                      onClick={() =>
                        setDataSteps([
                          ...dataSteps,
                          {
                            id: Date.now(),
                            value: "",
                            position: dataSteps.length + 1,
                          },
                        ])
                      }
                    >
                      {t("createrecipes.addstep")}
                    </button>
                  </div>
                </div>
                {/* step container end */}
                {/* category container start */}
                <section id="categories" />
                <h2 className="mt-4 mb-4 font-sans font-bold break-normal text-gray-700 px-2 pb- text-xl">
                  {t("createrecipes.categories")}
                </h2>
                <div className="mt-6 flex flex-wrap rounded bg-white p-8 shadow lg:mt-0">
                  <div className="relative mb-4 w-full">
                    <div className="flex items-center border border-gray-900 rounded-lg p-1 relative">
                      <input
                        type="text"
                        id="category-search"
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        className="flex-1 w-full p-4 text-sm text-gray-900 rounded-lg dark:placeholder-gray-400 dark:text-gray-900 focus:outline-none focus:border-blue-700 focus:ring-1 focus:ring-blue-300"
                        placeholder={t("createrecipes.categoriestext")}
                      />
                      <button
                        type="button"
                        onClick={handleAddButtonClick}
                        className="btn-outline btn text-black focus:outline-none font-medium rounded-lg text-sm px-4 py-2 ml-2"
                      >
                        {t("createrecipes.add")}
                      </button>
                    </div>

                    {/* Shown Categories Display */}
                    {searchInput && suggestedCategories.length > 0 && (
                      <div
                        ref={searchResultsRef}
                        className="absolute mt-1 p-2 bg-white border border-gray-300 rounded-lg shadow-lg w-full z-10 max-h-44 overflow-y-auto"
                      >
                        {suggestedCategories.map((category) => (
                          <div
                            key={category.label}
                            className="cursor-pointer py-2 px-2 hover:bg-emerald-300 rounded-lg"
                            onClick={() => handleAddCategory(category.label)}
                          >
                            {category.label}
                          </div>
                        ))}
                      </div>
                    )}

                    {/* Selected Categories Display */}
                    {selectedCategories.length > 0 && (
                      <div className="mt-10 flex flex-wrap">
                        {selectedCategories.map((category) => (
                          <div
                            className="rounded-lg mr-2 mb-2 text-black p-3  border border-gray-700"
                            key={category}
                          >
                            {category}
                            <input
                              className="hidden"
                              type="checkbox"
                              readOnly
                              checked={selectedCategories.includes(category)}
                              defaultChecked
                            ></input>
                            <button
                              type="button"
                              onClick={() => handleRemoveCategory(category)}
                              className="ml-2 text-s text-zinc-500 hover:text-red-500"
                            >
                              {t("createrecipes.remove")}
                            </button>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                {/* category container end */}
                <section id="circles" />
                <h2 className="mt-4 mb-4 font-sans font-bold break-normal text-gray-700 px-2 pb- text-xl">
                  {t("createrecipes.circles")}
                </h2>
                <div className="p-8 mt-6 lg:mt-0 rounded shadow bg-white flex flex-wrap">
                  {circles.map((cir) => (
                    <div className="form-control mr-4" key={cir.id}>
                      <label className="label cursor-pointer">
                        <input
                          type="checkbox"
                          checked={dataCircle.includes(cir.id)}
                          className="h-5 w-5 accent-emerald-300 bg-emerald-300 rounded-lg cursor-pointer"
                          onChange={(e) => handleCircle(e, cir.id)}
                        />
                        <span className="ml-2 label-text ">{cir.name}</span>
                      </label>
                    </div>
                  ))}
                  <div className="w-full mt-8">
                    <input
                      type="submit"
                      className="btn btn-outline w-full tracking-widest"
                      disabled={isLoading || success}
                      value={t("createrecipes.saverec")}
                    />
                  </div>
                </div>
              </section>

              {isLoadingSuccess ? (
                <progress className="progress w-full"></progress>
              ) : null}
            </div>
          )}
        </form>
        {success ? (
          <div className="toast toast-top toast-end">
            <div className="alert alert-success">
              <div>
                <span>{t("createrecipes.recipeupdated")}</span>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UpdateRecipe;
