import React, { useState, useEffect } from "react";
import { MdAdd, MdCheck } from "react-icons/md";
import { supabase } from "../../supabaseClient";
import { useTranslation } from "react-i18next";

const PlanGroupMeals = ({
  isOpen,
  onClose,
  circleId,
  plannedRecipes,
  setPlannedRecipes,
  onContinue,
  recipesUpdateCounter,
}) => {
  const [recipes, setRecipes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const { t } = useTranslation();
  const handleContinue = () => {
    onClose();
    onContinue();
  };

  const handleAddRecipe = (recipe) => {
    setPlannedRecipes((prevAddedRecipes) =>
      prevAddedRecipes.find((r) => r.id === recipe.id)
        ? prevAddedRecipes.filter((r) => r.id !== recipe.id)
        : [...prevAddedRecipes, recipe]
    );
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(async () => {
      setIsLoading(true);
      if (circleId) {
        let {
          data: circleRecipesData,
          error: circleRecipesError,
        } = await supabase
          .from("circle_recipes")
          .select(
            `
              recipe_id,
              recipes:recipe_id (id, name, author, owner_id)
            `
          )
          .eq("circle_id", circleId);

        let recipesData =
          circleRecipesData?.map(({ recipes }) => recipes) || [];

        if (searchInput) {
          const { data: categoryMatchData } = await supabase
            .from("recipe_categories")
            .select("recipe_id")
            .ilike("category_key", `%${searchInput}%`);

          const categoryMatchRecipeIds = categoryMatchData.map(
            (c) => c.recipe_id
          );

          recipesData = recipesData.filter(
            (recipe) =>
              recipe.name.toLowerCase().includes(searchInput.toLowerCase()) ||
              categoryMatchRecipeIds.includes(recipe.id)
          );
        }

        if (circleRecipesError) {
          console.error("Error fetching circle recipes:", circleRecipesError);
        } else {
          setRecipes(recipesData);
        }
      }
      setIsLoading(false);
    }, 300);

    return () => clearTimeout(debounceTimeout);
  }, [circleId, searchInput, recipesUpdateCounter]); 

  return (
    <div
      className={`modal ${
        isOpen ? "modal-open" : ""
      } fixed w-full h-full top-0 left-0 flex items-center justify-center`}
    >
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={onClose}
      ></div>
      <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-xl z-50 overflow-y-auto">
        <div className="flex flex-col items-center pb-3">
          <p className="text-2xl font-bold text-center mt-5">
            {t("groupplans.plan")}
          </p>
        </div>
        <div className="modal-content overflow-y-auto max-h-[calc(100vh-200px)] px-4 mt-5">
          <div className="w-full flex justify-center mb-3">
            <input
              type="text"
              className="input border border-gray-900 focus:outline-none focus:border-blue-500 w-full max-w-xs"
              placeholder={t("groupplans.search")}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
          {isLoading ? (
            <div className="flex justify-center">
              <span className="loading loading-spinner loading-lg"></span>
            </div>
          ) : recipes.length > 0 ? (
            recipes.map((recipe) => (
              <div
                key={recipe.id}
                className="flex justify-between items-center p-4 border-b border-gray-200"
              >
                <div>
                  <p className="text-lg font-semibold">{recipe.name}</p>
                  <p className="text-sm text-gray-500 italic">
                    {recipe.author}
                  </p>
                </div>
                <button
                  className={`btn ${
                    plannedRecipes.some((r) => r.id === recipe.id)
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "btn-outline hover:bg-blue-400"
                  }`}
                  onClick={() => handleAddRecipe(recipe)}
                >
                  {plannedRecipes.some((r) => r.id === recipe.id) ? (
                    <>
                      {" "}
                      <MdCheck /> {t("groupplans.added")}{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      <MdAdd /> {t("groupplans.add")}{" "}
                    </>
                  )}
                </button>
              </div>
            ))
          ) : (
            <div className="text-center text-gray-700">
              {t("groupplans.norec")}
            </div>
          )}
        </div>
        <div className="flex justify-between items-center p-4">
          <button
            className="modal-close px-4 bg-transparent p-3 rounded-lg text-stone-700 hover:bg-gray-300"
            onClick={onClose}
          >
            {t("groupplans.close")}
          </button>
          <button
            className="btn bg-green-600 text-white hover:bg-green-700"
            disabled={plannedRecipes.length === 0}
            onClick={handleContinue}
          >
            {t("groupplans.continue")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlanGroupMeals;
