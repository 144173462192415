import React, { useState, useEffect } from "react";
import { MdAdd, MdCheck } from "react-icons/md";
import { supabase } from "../../supabaseClient";
import { useTranslation } from "react-i18next";

const PlannedMealModal = ({
  isOpen,
  onClose,
  user,
  onOpenScheduleModal,
  addedRecipes,
  setAddedRecipes,
}) => {
  const [recipes, setRecipes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const { t } = useTranslation();
  const handleContinue = () => {
    onClose();
    onOpenScheduleModal();
  };

  const handleClose = () => {
    setAddedRecipes([]);
    onClose();
  };

  const handleAddRecipe = (recipe) => {
    setAddedRecipes((prevAddedRecipes) =>
      prevAddedRecipes.find((r) => r.id === recipe.id)
        ? prevAddedRecipes.filter((r) => r.id !== recipe.id)
        : [...prevAddedRecipes, recipe]
    );
  };

  useEffect(() => {
    const timerId = setTimeout(async () => {
      setIsLoading(true);
      if (user?.id) {
        let query = supabase
          .from("recipes")
          .select(
            `
            id,
            name,
            author,
            owner_id
          `
          )
          .eq("owner_id", user.id);

        if (searchInput) {
          query = query.ilike("name", `%${searchInput}%`);
        }

        let { data: recipesData, error: recipesError } = await query;

        let categoriesData = [];
        if (searchInput) {
          const {
            data: categoriesQueryData,
            error: categoriesError,
          } = await supabase
            .from("recipe_categories")
            .select("recipe_id")
            .ilike("category_key", `%${searchInput}%`);

          if (!categoriesError && categoriesQueryData.length > 0) {
            const recipeIds = categoriesQueryData.map((item) => item.recipe_id);
            const uniqueRecipeIds = [...new Set(recipeIds)];
            const {
              data: additionalRecipesData,
              error: additionalRecipesError,
            } = await supabase
              .from("recipes")
              .select(
                `
                id,
                name,
                author,
                owner_id
              `
              )
              .in("id", uniqueRecipeIds)
              .eq("owner_id", user.id);

            if (!additionalRecipesError) {
              categoriesData = additionalRecipesData;
            }
          }
        }

        const combinedRecipes = [...recipesData, ...categoriesData];
        const uniqueRecipes = Array.from(
          new Map(combinedRecipes.map((recipe) => [recipe.id, recipe])).values()
        );

        if (recipesError) {
          console.error("Error fetching recipes:", recipesError);
        } else {
          setRecipes(uniqueRecipes);
        }
        setIsLoading(false);
      }
    }, 300);

    return () => clearTimeout(timerId);
  }, [user?.id, searchInput]);

  return (
    <div
      className={`modal ${
        isOpen ? "modal-open" : ""
      } fixed w-full h-full top-0 left-0 flex items-center justify-center`}
    >
      <div
        className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
        onClick={handleClose}
      ></div>
      <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-xl z-50 overflow-y-auto">
        <div className="flex flex-col items-center pb-3">
          <p className="text-2xl font-bold text-center mt-3">
          {t("planmeals.plannext")}
          </p>
        </div>
        <div className="mt-2 overflow-y-auto max-h-[calc(100vh-200px)] px-4">

        <div className="w-full flex justify-center mb-1">
          <input
            type="text"
            className="input border border-gray-900 focus:outline-none focus:border-blue-500 w-full max-w-xs"
            placeholder={t("planmeals.searchrec")}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>

          {isLoading ? (
            <div className="flex justify-center">
              <span className="loading loading-spinner loading-lg"></span>
            </div>
          ) : recipes.length > 0 ? (
            recipes.map((recipe) => (
              <div
                key={recipe.id}
                className="flex justify-between items-center p-4 border-b border-gray-200"
              >
                <div>
                  <p className="text-lg font-semibold">{recipe.name}</p>
                  <p className="text-sm text-gray-500 italic">
                    {recipe.author}
                  </p>
                </div>
                <button
                  className={`btn ${
                    addedRecipes.some((r) => r.id === recipe.id)
                      ? "bg-blue-500 hover:bg-blue-600 text-white"
                      : "btn-outline hover:bg-green-400"
                  }`}
                  onClick={() => handleAddRecipe(recipe)}
                >
                  {addedRecipes.some((r) => r.id === recipe.id) ? (
                    <>
                      {" "}
                      <MdCheck /> {t("planmeals.added")}{" "}
                    </>
                  ) : (
                    <>
                      {" "}
                      <MdAdd /> {t("planmeals.add")}{" "}
                    </>
                  )}{" "}
                </button>
              </div>
            ))
          ) : (
            <div className="text-center text-gray-700">{t("groupplans.norec")}</div>
          )}
        </div>
        <div className="flex justify-between items-center p-4">
          <button
            className="btn bg-green-600 text-white hover:bg-green-700"
            disabled={addedRecipes.length === 0}
            onClick={handleContinue}
          >
            {t("planmeals.continue")}
          </button>
          <button
            className="modal-close px-4 bg-transparent p-3 rounded-lg text-stone-700 hover:bg-gray-300"
            onClick={handleClose}
          >
            {t("planmeals.close")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlannedMealModal;
