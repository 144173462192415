/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useNavigate } from "react-router-dom";

const Breadcrumbs = (props) => {
  const navigate = useNavigate();
  const { data } = props;

  return (
    <div className="text-sm breadcrumbs pb-4 flex-1 hidden md:block lg:block">
      <ul>
        {data.map((item, index) => (
          <li key={`breadcrumb-${index}`}>
            {item?.link ? (
              <a onClick={(e) => navigate(item.link)}>{item.name}</a>
            ) : (
              item.name
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Breadcrumbs;
