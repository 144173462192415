/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import mainLogo from "../assets/logo-recipe-circle.png";
import {
  BiLogoTiktok,
  BiLogoYoutube,
  BiLogoTwitter,
  BiLogoFacebook,
  BiLogoLinkedin,
  BiLogoMediumOld,
} from "react-icons/bi";
import {
  MdSearch,
  MdOutlinePublic,
  MdOutlinePrivateConnectivity,
} from "react-icons/md";
import mainBannerRecipes from "../assets/banner-recipe-1.jpeg";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";
import { Helmet } from "react-helmet-async";
import { categoriesMain, categoriesDietary, } from "../constants";
import ResponsiveNavbar from "../components/common/ResponsiveNavbar";
import { useTranslation } from "react-i18next";

const getCategoryLabel = (categoryKey) => {
  const predefinedCategory = categoriesMain.find(
    (cMain) => cMain.value === categoryKey
  );

  return predefinedCategory?.label || categoryKey;
};

const PublicRecipes = (props) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [dataDisplay, setDataDisplay] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const iconStyle = { fontSize: "30px" };

  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  const onChangeSearch = (e) => {
    const value = e.target.value;
    const newData = data.filter((item) => {
      return item.name.toLowerCase().includes(value.toLowerCase());
    });
    setDataDisplay(newData);
  };

  // get query params
  const urlParams = new URLSearchParams(window.location.search);
  const hideLayout = urlParams.get("hide-layout");

  useEffect(() => {
    const loadRecipes = async () => {
      let {
        data: dataRecipes,
        error: errorR,
        status: statusR,
      } = await supabase
        .from("recipes")
        .select(
          `id, name, author, description, type, duration, servings, steps, image_url, public, owner_id, created_at, url,
        recipe_ingredients (
          name
        ),recipe_categories (
          category_key,
          type
        ),profiles (first_name, last_name)`
        )
        .eq("public", 1)
        .limit(20)
        .order("created_at", { ascending: false });

      if (errorR && statusR !== 406) {
        throw errorR;
      }
      setData(dataRecipes);
      setDataDisplay(dataRecipes);
      setIsLoading(false);
    };

    loadRecipes();
  }, []);

  const handleView = (url) => {
    navigate(`/recipes-view/${url}`);
  };

  return (
    <>
    <Helmet>
        <title>RecipeCircle - Public Recipes</title>
        <meta name="description" content="Discover a variety of delicious public recipes on RecipeCircle. Browse and explore recipes shared by other users to find new inspiration." />
        <meta property="og:title" content="RecipeCircle - Public Recipes" />
        <meta property="og:description" content="Discover a variety of delicious public recipes on RecipeCircle. Browse and explore recipes shared by other users to find new inspiration." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://app.recipecircle.world/public-recipes" />
      </Helmet>
      
      <div className="container mx-auto w-full overflow-x-hidden">
        <div className="text-center">
          <h1 className="text-4xl font-bold py-4 text-[#384E79]">
            {t("publicrecipes.publicrec")}
          </h1>
          <div className="breadcrumbs flex justify-center mb-4">
            <ul>
              <li>
                <a
                  onClick={() => {
                    navigate("/");
                  }}
                  className="text-[#384E79] hover:text-blue-400"
                >
                  {t("publicrecipes.home")}
                </a>
              </li>
              <li className="text-stone-700">{t("publicrecipes.publicrec")}</li>
            </ul>
          </div>
        </div>{" "}
        {/* page title breadcrumbs */}
        <div className="flex flex-col md:flex-row justify-center items-center mb-4">
          {" "}
          <div className="form-control w-full md:w-1/3 md:max-w-xs">
            {" "}
            <div className="relative">
              <input
                type="text"
                placeholder={t("publicrecipes.search")}
                className="w-full pr-16 input border border-black focus:outline-none focus:border-blue-500 focus:ring-0"
                onChange={(e) => onChangeSearch(e)}
              />
              <div className="absolute top-0 right-0 rounded-l-none btn btn-square btn-ghost hover:bg-transparent">
                <MdSearch className="h-5 w-5" />
              </div>
            </div>
          </div>
        </div>
        {/* search */}
        {isLoading ? (
          <div className="flex justify-center">
            <span className="loading loading-spinner loading-lg"></span>

          </div>
        ) : dataDisplay.length ? (
          <div className="mt-10 container mx-auto mb-10">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {dataDisplay.map((item) => (
                <div
                  className="card bg-white rounded-xl drop-shadow-xl border border-gray-300 overflow-hidden flex"
                  key={item.id}
                  onClick={(e) => handleView(item.url)}
                  style={{ cursor: "pointer" }}
                >
                  <figure
                    className="h-40 bg-cover bg-center"
                    style={{
                      backgroundImage: `url("${
                        item?.image_url
                          ? `${process.env.REACT_APP_SUPABASE_BUCKET_URL}${item.image_url}`
                          : mainBannerRecipes
                      }")`,
                    }}
                  />
                  <div className="card-body p-4">
                    <h2 className="card-title text-[#384E79]">{item.name}</h2>
                    <div className="text-xs text-gray-600">
                      {t("publicrecipes.recipeby")}{" "}
                      {item.author ||
                        `${item.profiles.first_name} ${item.profiles.last_name}`}
                    </div>
                    <div className="text-xs text-stone-800 pt-2 pb-2">
                     {t("publicrecipes.CreatedOn")} {dayjs(item.created_at).format("MM/DD/YYYY")}
                    </div>
                    {item.type === 1 && (
                      <p className="text-sm text-gray-800">
                        {t("publicrecipes.duration")} {item?.duration ? item?.duration : "-"}
                      </p>
                    )}
                    <div className="flex flex-wrap gap-2 my-2">
                      {item.recipe_categories
                        .filter((cItem) => cItem.type === 1)
                        .map((cItem2, index, array) => (
                          <span
                            className={`text-sm mr-1 ${
                              index < array.length - 1
                                ? "border-r border-gray-500 pr-2"
                                : ""
                            }`}
                            key={cItem2.category_key}
                          >
                            {getCategoryLabel(cItem2.category_key)}
                          </span>
                        ))}
                    </div>
                    <div className="flex flex-wrap gap-2">
                      {item.recipe_categories
                        .filter((cItem) => cItem.type === 2)
                        .map((cItem2, index, array) => (
                          <span
                            className={`text-sm mr-1 ${
                              index < array.length - 1
                                ? "border-r border-gray-500 pr-2"
                                : ""
                            }`}
                            key={cItem2.category_key}
                          >
                            {
                              categoriesDietary.find(
                                (cMain) => cMain.value === cItem2.category_key
                              )?.label
                            }
                          </span>
                        ))}
                    </div>
                    <div className="divider"></div>
                    <div className="absolute bottom-2 right-2 flex items-center">
                      {item.public ? (
                        <>
                          <MdOutlinePublic className="text-green-500" />
                          <span className="ml-1 text-s text-green-500">
                            {t("publicrecipes.publicrec")}
                          </span>
                        </>
                      ) : (
                        <>
                          <MdOutlinePrivateConnectivity className="text-stone-800" />
                          <span className="ml-1 text-s text-stone-800">
                            {t("publicrecipes.private")}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
        <div className="">
          <div className="container mx-auto"></div>
          {!hideLayout ? null : null}
        </div>
      </div>
     
    </>
  );
};

export default PublicRecipes;
