import React, { useState, useEffect } from "react";
import { MdOutlineRemove } from "react-icons/md";
import { useTranslation } from "react-i18next";
import Flatpickr from "react-flatpickr";
require("flatpickr/dist/themes/material_blue.css");

const ScheduleGroupMealModal = ({
  isOpen,
  onClose,
  setScheduleDetails,
  scheduleDetails,
  openFinalizeGroupMealModal,
}) => {
  const [datePlanned, setDatePlanned] = useState("");
  const [timePlanned, setTimePlanned] = useState("");
  const [planName, setPlanName] = useState("");
  const { t } = useTranslation();
  const [error, setError] = useState("");

  const handlePlanNameChange = (newPlanName) => {
    setScheduleDetails((currentDetails) => {
      const updatedDetails = currentDetails.map((detail) => ({
        ...detail,
        planName: newPlanName
      }));
      return updatedDetails;
    });
  };
  
  const handleDateTimeChange = (index, datetime) => {
    const newScheduleDetails = [...scheduleDetails];
    const [date, time] = datetime.split("T");
    newScheduleDetails[index].datePlanned = date;
    newScheduleDetails[index].timePlanned = time.slice(0, 5);
    setScheduleDetails(newScheduleDetails);
  };
  const handleContinue = () => {
    const allDetailsFilled = scheduleDetails.every((detail) => {
      const isFilled =
        detail.datePlanned &&
        detail.timePlanned &&
        detail.eventDescription &&
        detail.planName &&
        detail.eventDescription !== "";
      if (!isFilled) {
        setError("Please fill in all required fields before continuing.");
        return false;
      }
      return true;
    });

    if (allDetailsFilled) {
      setError("");
      const updatedScheduleDetails = scheduleDetails.map((detail) => {
        const dateTimePlanned = `${detail.datePlanned} ${detail.timePlanned}:00`;
        return { ...detail, planName: detail.planName, dateTimePlanned };
      });
      setScheduleDetails(updatedScheduleDetails);

      onClose();
      openFinalizeGroupMealModal();
    }
  };

  return (
    <div
      className={`modal ${
        isOpen ? "modal-open" : ""
      } fixed w-full h-full top-0 left-0 flex items-center justify-center`}
    >
      <div
        className="modal-overlay fixed w-full h-full bg-gray-900 opacity-50"
        onClick={onClose}
      ></div>
      <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-xl z-50 overflow-y-auto">
        <div className="p-4 md:p-5">
          <h2 className="text-xl md:text-2xl font-bold text-center mb-1">
            {t("groupplans.schedulet")}
          </h2>
          <div className="modal-content overflow-y-auto max-h-[calc(100vh-200px)] px-2 md:px-4 mt-4 md:mt-5">
            <div className="mx-auto">
              <legend className="sr-only">{t("groupplans.scheduledet")}</legend>
              <p className="text-sm md:text-md text-center mb-3 md:mb-4">
                {t("groupplans.scheduledettext")}
              </p>
              {error && (
                <p className="text-red-500 text-sm text-center">{error}</p>
              )}
              {scheduleDetails.map((detail, index) => (
                <div key={index} className="mb-3 md:mb-4">
                  <div className="flex flex-col md:flex-row md:items-end space-y-2 md:space-y-0 md:space-x-2">
                    <div className="flex-1">
                      <label
                        htmlFor={`dateTimePlanned-${index}`}
                        className="block text-sm font-medium text-gray-700"
                      >
                        {t("planmeals.datetimep")}
                      </label>
                      <Flatpickr
                        id={`dateTimePlanned-${index}`}
                        className="form-input w-full px-2 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300"
                        options={{
                          enableTime: true,
                          altInput: true,
                          disableMobile: true,
                          altFormat: "F j, Y, H:i",
                          dateFormat: "Y-m-d H:i",
                          time_24hr: true,
                        }}
                        value={`${detail.datePlanned}T${detail.timePlanned}`}
                        onChange={([selectedDate]) => {
                          const timezoneOffset =
                            selectedDate.getTimezoneOffset() * 60000;

                          const adjustedDate = new Date(
                            selectedDate.getTime() - timezoneOffset
                          );

                          handleDateTimeChange(
                            index,
                            adjustedDate.toISOString()
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="mt-2 md:mt-4">
                    <label
                      htmlFor={`eventDescription-${index}`}
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t("groupplans.mealdesc")}
                    </label>
                    <input
                      type="text"
                      id={`eventDescription-${index}`}
                      className="form-input w-full px-2 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300 mb-3"
                      placeholder={t("groupplans.mealdesctext")}
                      value={detail.eventDescription || ""}
                      onChange={(e) => {
                        const newScheduleDetails = [...scheduleDetails];
                        newScheduleDetails[index].eventDescription =
                          e.target.value;
                        setScheduleDetails(newScheduleDetails);
                      }}
                    />
                  </div>
                  {scheduleDetails.length > 1 && (
                    <>
                      <div className="mt-2">
                        <button
                          type="button"
                          onClick={() =>
                            setScheduleDetails(
                              scheduleDetails.filter((_, idx) => idx !== index)
                            )
                          }
                          className="w-full flex items-center justify-center px-3 py-2 text-lg rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 bg-red-500 hover:bg-red-600 border border-red-700 text-white"
                          aria-label="Remove schedule detail"
                        >
                          <MdOutlineRemove className="h-6 w-6" />
                          <span className="ml-2">{t("groupplans.remove")}</span>
                        </button>
                      </div>
                      <div className="divider"></div>
                    </>
                  )}
                </div>
              ))}

              <div className="flex justify-center mt-4">
                <button
                  type="button"
                  onClick={() =>
                    setScheduleDetails([
                      ...scheduleDetails,
                      { datePlanned: "", timePlanned: "", recipes: [] },
                    ])
                  }
                  className="btn btn-outline inline-block px-4 py-2 text-stone-800 font-medium text-xs leading-tight uppercase rounded hover:bg-blue-700 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 shadow-lg transition duration-150 ease-in-out"
                >
                  {t("groupplans.addanother")}
                </button>
              </div>
              <div className="mt-4 md:mt-6 border-t border-stone-300">
                <label
                  htmlFor="planName"
                  className="block text-sm font-medium text-gray-700 mt-4"
                >
                  {t("groupplans.eventname")}
                </label>
                <input
                  type="text"
                  id="planName"
                  className="form-input w-full px-2 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-300 mb-3"
                  placeholder={t("groupplans.eventnamet")}
                  value={scheduleDetails[0]?.planName || ""}
                  onChange={(e) => handlePlanNameChange(e.target.value)}
                />
              </div>
            </div>
            <div className="mt-4 md:mt-5 flex flex-col md:flex-row justify-between items-center">
              <button
                className="btn bg-green-500 hover:bg-green-600 w-full md:w-auto mb-2 md:mb-0"
                onClick={handleContinue}
              >
                {t("groupplans.continue")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleGroupMealModal;
