import { Outlet } from "react-router-dom";
import CookieConsent from "../utilities/cookieconsent";
import Footer from "../common/footer";
import MobileNavbar from "../common/MobileNavbar";
const MainLayoutGuest = (props) => {
  const { children } = props;

  return (
    <>
      <MobileNavbar />
      <div className="flex flex-col min-h-screen">
        <main className="flex-grow">
          <Outlet />
        </main>
      </div>
      <Footer />
      <CookieConsent />
    </>
  );
};

export default MainLayoutGuest;
