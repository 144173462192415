import React, { useContext, useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import mainBannerRecipes from "../../assets/banner-recipe-1.jpeg";
import { supabase } from "../../supabaseClient";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import { useTranslation } from "react-i18next";
import UrlSubmissionModal from "../../components/utilities/UrlSubmissionModal";
import { categoriesMain, categoriesDietary, typesLabel } from "../../constants";
import {
  MdSearch,
  MdOutlineNoteAdd,
  MdOutlineLink,
  MdOutlinePhotoCamera,
  MdOutlinePublic,
  MdOutlinePrivateConnectivity,
} from "react-icons/md";
import { HiOutlineDocumentMagnifyingGlass } from "react-icons/hi2";

const getCategoryLabel = (categoryKey) => {
  const predefinedCategory = categoriesMain.find(
    (cMain) => cMain.value === categoryKey
  );

  return predefinedCategory?.label || categoryKey;
};

const Recipe = (props) => {
  const navigate = useNavigate();
  const { state } = useContext(DataContext);
  const [data, setData] = useState([]);
  const [dataDisplay, setDataDisplay] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUrlModalOpen, setIsUrlModalOpen] = useState(false);
  const { t } = useTranslation();
  const openModal = () => setIsModalOpen(true);
  const openUrlModal = () => setIsUrlModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const closeUrlModal = () => setIsUrlModalOpen(false);
  const onClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };
  const [isApiKeyPresent, setIsApiKeyPresent] = useState(false);

  useEffect(() => {
    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
  }, []);

  useEffect(() => {
    const checkApiKeyExists = async () => {
      const { data, error } = await supabase
        .from("api_keys")
        .select("id")
        .eq("user_id", state.user?.id)
        .single();

      if (error) {
        console.error("Error checking API key existence", error);
      } else {
        setIsApiKeyPresent(!!data);
      }
    };

    if (state.user?.id) {
      checkApiKeyExists();
    }
  }, [state.user?.id]);

  useEffect(() => {
    const loadRecipes = async () => {
      let { data: dataRecipes, error: errorR, status: statusR } = await supabase
        .from("recipes")
        .select(
          `id, name, author, description, type, duration, servings, steps, image_url, public, owner_id,
        recipe_ingredients (
          name
        ),recipe_categories (
          category_key,
          type
        ),profiles (first_name, last_name)`
        )
        .eq("owner_id", state?.user?.id)
        .order("created_at", { ascending: false });

      if (errorR && statusR !== 406) {
        throw errorR;
      }
      setData(dataRecipes);
      setDataDisplay(dataRecipes);
      setIsLoading(false);
    };
    if (state?.user?.id) {
      loadRecipes();
    }
  }, [state]);

  const handleView = (recipeId) => {
    navigate(`/recipes/${recipeId}`);
  };

  const onChangeSearch = (e) => {
    const value = e.target.value;
    const newData = data.filter((item) => {
      return item.name.toLowerCase().includes(value.toLowerCase());
    });
    setDataDisplay(newData);
  };

  const RecipeModal = ({ isOpen, onClose }) => {
    const handleAnalyzeClick = () => {
      if (isApiKeyPresent) {
        onClose(); 
        openUrlModal(); 
      }
    };
    return (
      <div
        className={`modal ${
          isOpen ? "modal-open" : ""
        } fixed w-full h-full top-0 left-0 flex items-center justify-center`}
      >
        <div
          className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
          onClick={onClose}
        ></div>

        <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-2xl  z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
            <div className="flex flex-col items-center pb-3">
              <p className="text-2xl font-bold text-center">
                {t("recipe.add")}
              </p>
            </div>

            <div className="mt-5">
              <div
                className="flex items-center rounded-lg hover:bg-gray-200 transition-colors cursor-pointer p-4"
                onClick={() => {
                  onClose();
                  navigate("/recipes/new");
                }}
              >
                <MdOutlineNoteAdd className="text-3xl text-[#384E79] mr-5" />
                <div className="flex-grow">
                  <p className="font-semibold">{t("recipe.written")}</p>
                  <p className="text-sm">{t("recipe.writtentext")}</p>
                </div>
              </div>

              <div
                className="flex items-center rounded-lg hover:bg-gray-200 transition-colors cursor-pointer p-4"
                onClick={() => {
                  onClose();
                  navigate("/recipes/newPhoto");
                }}
              >
                <div className="flex items-center">
                  <MdOutlinePhotoCamera className="text-2xl text-[#384E79] mr-5" />
                  <div>
                    <p className="font-semibold">{t("recipe.photo")}</p>
                    <p className="text-sm mt-1">{t("recipe.phototext")}</p>
                  </div>
                </div>
              </div>

              <div
                className="flex items-center rounded-lg hover:bg-gray-200 transition-colors cursor-pointer p-4"
                onClick={() => {
                  onClose();
                  navigate("/recipes/newLink");
                }}
              >
                <div className="flex items-center">
                  <MdOutlineLink className="text-3xl text-[#384E79] mr-5" />
                  <div>
                    <p className="font-semibold">{t("recipe.link")}</p>
                    <p className="text-sm">{t("recipe.linktext")}</p>
                  </div>
                </div>
              </div>
              <div
                className="flex items-center rounded-lg hover:bg-gray-200 transition-colors cursor-pointer p-4"
                onClick={handleAnalyzeClick}
              >
                <div
                  className={`flex items-center ${
                    !isApiKeyPresent ? "opacity-50" : ""
                  }`}
                >
                  <HiOutlineDocumentMagnifyingGlass className="text-3xl text-[#384E79] mr-5" />
                  <div>
                    <p className="font-semibold">{t("recipe.analyze")}</p>
                    <p className="text-sm">{t("recipe.analyzetext")}</p>
                    {!isApiKeyPresent && (
                      <p className="text-sm text-red-500">
                        {t("recipe.apiKeyNeeded")}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-end pt-2">
              <button
                className="modal-close px-4 bg-transparent p-3 rounded-lg text-stone-700 hover:bg-gray-300"
                onClick={onClose}
              >
                {t("recipe.close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-100 container mx-auto">
      <div className="w-full overflow-x-hidden">
        <Helmet>
          <title>RecipeCircle - Your Recipes</title>
          <meta
            name="description"
            content="Browse and manage all your recipes on RecipeCircle. Save, organize, and share your favorite recipes with friends."
          />
          <meta property="og:title" content="RecipeCircle - Your Recipes" />
          <meta
            property="og:description"
            content="Browse and manage all your recipes on RecipeCircle. Save, organize, and share your favorite recipes with friends."
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content="https://app.recipecircle.world/recipes"
          />
        </Helmet>
        <div className="text-center">
          <h1 className="text-4xl font-bold py-4 text-[#384E79]">
            {t("recipe.recipes")}
          </h1>
          <div className="breadcrumbs flex justify-center mb-4">
            <ul>
              <li>
                <a
                  onClick={() => {
                    navigate("/");
                  }}
                  className="text-[#384E79] hover:text-blue-400"
                >
                  {t("recipe.home")}
                </a>
              </li>
              <li className="text-stone-700">{t("recipe.recipes")}</li>
            </ul>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center mb-4">
          <div className="w-full md:w-1/3 md:order-1"></div>

          <div className="form-control w-full md:w-1/3 md:max-w-xs md:order-2">
            <div className="relative">
              <input
                type="text"
                placeholder={t("recipe.searchrecipes")}
                className="w-full pr-16 input border border-black focus:outline-none focus:border-blue-500 focus:ring-0"
                onChange={(e) => onChangeSearch(e)}
              />
              <div className="absolute top-0 right-0 rounded-l-none btn btn-square btn-ghost hover:bg-transparent">
                <MdSearch className="h-5 w-5" />
              </div>
            </div>
          </div>

          <div className="dropdown lg:dropdown-end md:dropdown-end w-full md:w-1/3 md:order-3 md:text-right mt-4 md:mt-0">
            <button
              tabIndex={0}
              role="button"
              className="btn btn-outline text-black hover:bg-blue-400 w-full md:w-auto"
              onClick={openModal}
            >
              {t("recipe.newrecipe")}
            </button>

            <RecipeModal isOpen={isModalOpen} onClose={closeModal} />
          </div>
        </div>
        {state.user?.id && (
          <UrlSubmissionModal
            isUrlOpen={isUrlModalOpen}
            onUrlClose={closeUrlModal}
            userId={state.user.id}
          />
        )}
        <div className="divider"></div>

        {isLoading ? (
          <div className="flex justify-center">
            <span className="loading loading-spinner loading-lg"></span>
          </div>
        ) : dataDisplay.length ? (
          <div className="mt-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
              {dataDisplay.map((item) => (
                <div
                  className="card bg-white rounded-xl drop-shadow-xl border border-gray-300 overflow-hidden flex"
                  key={item.id}
                  onClick={(e) => handleView(item.id)}
                  style={{ cursor: "pointer" }}
                >
                  <figure
                    className="h-40 bg-cover bg-center"
                    style={{
                      backgroundImage: `url("${
                        item?.image_url
                          ? `${process.env.REACT_APP_SUPABASE_BUCKET_URL}${item.image_url}`
                          : mainBannerRecipes
                      }")`,
                    }}
                  />
                  <div className="card-body p-4">
                    <h2 className="card-title text-[#384E79]">{item.name}</h2>
                    <div className="text-xs text-gray-600">
                      {t("recipe.recipeby")}{" "}
                      {item.author ||
                        `${item.profiles.first_name} ${item.profiles.last_name}`}
                    </div>
                    <div className="text-xs text-stone-800 pt-2 pb-2">
                      {t("recipe.CreatedOn")}{" "}
                      {dayjs(item.created_at).format("MM/DD/YYYY")}
                    </div>
                    {item.type === 1 && (
                      <p className="text-sm text-gray-800">
                        {t("recipe.duration")}{" "}
                        {item?.duration ? item?.duration : "-"}
                      </p>
                    )}
                    <div className="flex flex-wrap gap-2 my-2">
                      {item.recipe_categories
                        .filter((cItem) => cItem.type === 1)
                        .map((cItem2, index, array) => (
                          <span
                            className={`text-sm mr-1 ${
                              index < array.length - 1
                                ? "border-r border-gray-500 pr-2"
                                : ""
                            }`}
                            key={cItem2.category_key}
                          >
                            {getCategoryLabel(cItem2.category_key)}
                          </span>
                        ))}
                    </div>
                    <div className="flex flex-wrap gap-2 mb-2">
                      {item.recipe_categories
                        .filter((cItem) => cItem.type === 2)
                        .map((cItem2, index, array) => (
                          <span
                            className={`text-sm mr-1 ${
                              index < array.length - 1
                                ? "border-r border-gray-500 pr-2"
                                : ""
                            }`}
                            key={cItem2.category_key}
                          >
                            {
                              categoriesDietary.find(
                                (cMain) => cMain.value === cItem2.category_key
                              )?.label
                            }
                          </span>
                        ))}
                    </div>

                    <div className="absolute bottom-2 right-2 flex items-center">
                      {item.public ? (
                        <>
                          <MdOutlinePublic className="text-green-500" />
                          <span className="ml-1 text-s text-green-500">
                            {t("recipe.public")}
                          </span>
                        </>
                      ) : (
                        <>
                          <MdOutlinePrivateConnectivity className="text-stone-800" />
                          <span className="ml-1 text-s text-stone-800">
                            {t("recipe.private")}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div
            className="hero bg-base-200 rounded-box"
            style={{ minHeight: 380 }}
          >
            <div className="hero-content text-center">
              <div className="max-w-md">
                <h1 className="text-5xl font-bold">{t("recipe.norecipe")}</h1>
                {!data.length ? (
                  <>
                    <p className="py-6">{t("recipe.norecipetext")}</p>
                    <div className="dropdown lg:dropdown-end md:dropdown-end w-full md:w-1/3 md:order-3 md:text-right mt-4 md:mt-0">
                      <button
                        tabIndex={0}
                        role="button"
                        className="btn btn-outline text-black hover:bg-blue-400 w-full md:w-auto"
                        onClick={openModal}
                      >
                        {t("recipe.newrecipe")}
                      </button>

                      <RecipeModal isOpen={isModalOpen} onClose={closeModal} />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Recipe;
