export const categoriesMain = [
  { value: "air-fryer", label: "Air Fryer" },
  { value: "american", label: "American" },
  { value: "appetizers", label: "Appetizers" },
  { value: "baking", label: "Baking" },
  { value: "bbqs-and-picnics", label: "BBQs and Picnics" },
  { value: "beef", label: "Beef" },
  { value: "beverages", label: "Beverages" },
  { value: "boiling", label: "Boiling" },
  { value: "breakfast", label: "Breakfast" },
  { value: "brunch", label: "Brunch" },
  { value: "budget-friendly", label: "Budget-Friendly" },
  { value: "chicken", label: "Chicken" },
  { value: "chinese", label: "Chinese" },
  { value: "christmas", label: "Christmas" },
  { value: "date-nights", label: "Date Nights" },
  { value: "desserts", label: "Desserts" },
  { value: "dressings", label: "Dressings" },
  { value: "dinner", label: "Dinner" },
  { value: "easter", label: "Easter" },
  { value: "eggs", label: "Eggs" },
  { value: "fathers-day", label: "Father's Day" },
  { value: "fish-seafood", label: "Fish & Seafood" },
  { value: "french", label: "French" },
  { value: "greek", label: "Greek" },
  { value: "grilling", label: "Grilling" },
  { value: "halloween", label: "Halloween" },
  { value: "healthy-choices", label: "Healthy Choices" },
  { value: "indian", label: "Indian" },
  { value: "instant-pot", label: "Instant Pot" },
  { value: "italian", label: "Italian" },
  { value: "japanese", label: "Japanese" },
  { value: "kid-friendly", label: "Kid-Friendly" },
  { value: "lunch", label: "Lunch" },
  { value: "lunch-dinner", label: "Lunch & Dinner" },
  { value: "mediterranean", label: "Mediterranean" },
  { value: "meats", label: "Meats" },
  { value: "mexican", label: "Mexican" },
  { value: "mothers-day", label: "Mother's Day" },
  { value: "new-years", label: "New Year's" },
  { value: "pan-frying", label: "Pan-Frying" },
  { value: "parties", label: "Parties" },
  { value: "pasta", label: "Pasta" },
  { value: "pizza", label: "Pizza" },
  { value: "poultry", label: "Poultry" },
  { value: "quinoa", label: "Quinoa" },
  { value: "quick-and-easy", label: "Quick and Easy" },
  { value: "recipe-tips", label: "Recipe Tips" },
  { value: "rice", label: "Rice" },
  { value: "roasting", label: "Roasting" },
  { value: "salads", label: "Salads" },
  { value: "sauces-gravies", label: "Sauces & Gravies" },
  { value: "seafood", label: "Seafood" },
  { value: "slow-cooker", label: "Slow Cooker" },
  { value: "snacks", label: "Snacks" },
  { value: "special-diets", label: "Special Diets" },
  { value: "steaming", label: "Steaming" },
  { value: "stir-frying", label: "Stir-Frying" },
  { value: "soups", label: "Soups" },
];

export const categoriesDietary = [
  { value: "vegan", label: "Vegan" },
  { value: "vegetarian", label: "Vegetarian" },
  { value: "diabetic", label: "Diabetic" },
  { value: "pescatarian", label: "Pescatarian" },
  { value: "keto-paleo", label: "Keto/Paleo" },
  { value: "kosher", label: "Kosher" },
  { value: "low-fat", label: "Low Fat" },
  { value: "low-sodium", label: "Low Sodium" },
  { value: "gluten-free", label: "Gluten-Free" },
];

export const typesLabel = {
  1: "e-form",
  2: "Media",
  3: "Link",
};
