import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { supabase } from "../supabaseClient";
import { Helmet } from "react-helmet-async";
import { useTranslation } from 'react-i18next';


export default function Page404() {
  const [user, setUser] = useState(null);
  const { t } = useTranslation();  

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setUser(session?.user || null);
    });
  }, []);
  return (
    <>
    <Helmet>
        <title>RecipeCircle - 404</title>
        <meta name="description" content="Recipe Circle 404 Page" />
        <meta property="og:title" content="RecipeCircle 404 Page" />
        <meta property="og:description" content="Recipe Circle 404 Page" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://app.recipecircle.world/404" />
        <meta name="twitter:title" content="Recipe Circle 404 Page" />
        <meta name="twitter:description" content="Recipe Circle 404 Page" />
      </Helmet>
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-blue-400 via-blue-600 to-purple-300">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-6">
        {t('404.msg')}
        </h1>
        <p className="text-center text-gray-600 mb-8">
        {t('404.sorry')}
        </p>
        <div className="flex justify-center">
          {user ? (
            <Link
              to="/dashboard"
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded mr-2"
            >
             {t('404.dashboard')}
            </Link>
          ) : null}
          <Link
            to="/"
            className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
          >
            {t('404.landing')}
          </Link>
        </div>
      </div>
    </div>
    </>
  );
}
