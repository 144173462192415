import React, { useState, useRef, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import { useTranslation } from 'react-i18next';

const PhotoUploaderCircle = ({ onChangeImage }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const fileInputRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const { t } = useTranslation();  

  const handlePhotoSelect = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const selectedPhoto = event.target.files[0];
      setSelectedImage(URL.createObjectURL(selectedPhoto));

      const extension = selectedPhoto.name.split(".").pop();
      const filePath = `private/circles/banner-${Date.now()}${extension ? `.${extension}` : ""}`;

      try {
        setUploading(true); 

        const { data: dataUpload, error } = await supabase.storage
          .from("recipe-files")
          .upload(filePath, selectedPhoto);

        if (error) {
          throw error;
        } else if (dataUpload) {
          onChangeImage(dataUpload.path);
        }
      } finally {
        setUploading(false);
      }
    }
  };

  const handleButtonClick = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  return (
    <div className="form-control">
      <label className="label">
        <span className="label-text">{t('photoupcirc.legend')}</span>
      </label>
      <input
        type="file"
        accept="image/*"
        className="file:btn file:btn-outline"
        onChange={handlePhotoSelect}
        ref={fileInputRef}
        hidden
        disabled={uploading}
      />
      <button
        className="btn btn-outline btn-block"
        onClick={handleButtonClick}
        disabled={uploading}
      >
        {t('photoupcirc.choose')}
      </button>
      {selectedImage && (
        <div className="mt-2 flex justify-center">
          <img
            src={selectedImage}
            alt="Selected"
            style={{ maxWidth: '100%', maxHeight: '200px' }}
            className="rounded-lg"
          />
        </div>
      )}
    </div>
  );
};

export default PhotoUploaderCircle;
