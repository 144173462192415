import React, { useContext, useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import mainBannerCircles from "../../assets/banner-circle-1.jpeg";
import { supabase } from "../../supabaseClient";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import { MdSearch, MdCheck, MdClose } from "react-icons/md";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet-async";
import PhotoUploaderCircle from "../../components/common/PhotoUploaderCircle";
import { useTranslation } from "react-i18next";

const Circle = (props) => {
  const navigate = useNavigate();
  const { state } = useContext(DataContext);
  const [data, setData] = useState([]);
  const [dataDisplay, setDataDisplay] = useState([]);
  const [pendingApproval, setPendingApproval] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCircleInviteModalOpen, setIsCircleInviteModalOpen] = useState(false);
  const fileInputRef = useRef(null);
  const { register, handleSubmit } = useForm();
  const { t } = useTranslation();

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const openCircleInviteModal = () => setIsCircleInviteModalOpen(true);
  const closeCircleInviteModal = () => setIsCircleInviteModalOpen(false);

  useEffect(() => {
    const loadCircles = async () => {
      let { data: dataCircles, error: errorC, status: statusC } = await supabase
        .from("circles")
        .select(
          `id, name, description, image_url, owner_id, created_at,
          circle_recipes(
            id
          ),
          circle_members(
            id, email, created_at
          ),
          profiles (first_name, last_name)`
        )
        .eq("owner_id", state.user.id)
        .order("created_at", { ascending: false });

      if (errorC && statusC !== 406) {
        throw errorC;
      }

      let {
        data: dataCirclesShare,
        error: errorCShare,
        status: statusCShare,
      } = await supabase
        .from("circle_members")
        .select(
          `id, email,
          circles(
            id, name, description, image_url, owner_id, created_at,
            circle_recipes(
              id
            ),
            circle_members(
              id, email
            ),
            profiles (first_name, last_name)
          )`
        )
        .eq("email", state.user.email)
        .eq("status", 1)
        .order("created_at", { ascending: false });

      if (errorCShare && statusCShare !== 406) {
        throw errorCShare;
      }

      let {
        data: dataCirclesPending,
        error: errorCPending,
        status: statusCPending,
      } = await supabase
        .from("circle_members")
        .select(`id`)
        .eq("email", state.user.email)
        .eq("status", 0);

      if (errorCPending && statusCPending !== 406) {
        throw errorCPending;
      }

      const dataBreakdown = dataCirclesShare.map((item) => {
        return item.circles;
      });

      const dataIntegration = dataCircles.concat(dataBreakdown);
      setData(dataIntegration);
      setDataDisplay(dataIntegration);
      setPendingApproval(dataCirclesPending.length);
      setIsLoading(false);
    };
    if (state?.user?.id) {
      loadCircles();
    }
  }, [state]);

  const onChangeSearch = (e) => {
    const value = e.target.value;
    const newData = data.filter((item) => {
      return item.name.toLowerCase().includes(value.toLowerCase());
    });
    setDataDisplay(newData);
  };

  const handleView = (circleId) => {
    navigate(`/circles/${circleId}`);
  };

  const handlePending = () => {
    navigate(`/circles/pending`);
  };

  const onChangeImage = (image) => {
    setData({ ...data, image_url: image });
  };

  const CircleInviteModal = ({
    isOpen,
    onClose,
    dataDisplay = [],
    supabaseService,
    state,
  }) => {
    const [circleInvitations, setCircleInvitations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [successStatus, setSuccessStatus] = useState(0);

    useEffect(() => {
      const loadCircleInvitations = async () => {
        if (state?.user?.id) {
          let {
            data: dataCirclesShare,
            error: errorCShare,
            status: statusCShare,
          } = await supabaseService
            .from("circle_members")
            .select(
              `
            id, email, status, created_at,
            circles(
              id, name, description, image_url, owner_id, created_at,
              owner: profiles!inner(first_name, last_name)
            )
          `
            )
            .eq("email", state.user.email)
            .eq("status", 0)
            .order("created_at", { ascending: false });

          if (errorCShare && statusCShare !== 406) {
            throw errorCShare;
          }

          setCircleInvitations(dataCirclesShare);
          setIsLoading(false);
        }
      };

      if (isOpen) {
        loadCircleInvitations();
      }
    }, [isOpen, state, supabaseService]);

    const handleAccept = async (invitationId) => {
      setIsLoading(true);

      try {
        const updates = {
          status: 1,
          user_id: state.user.id,
        };
        const { data, error } = await supabaseService
          .from("circle_members")
          .update(updates)
          .eq("id", invitationId);

        if (error) {
          console.error("Error in accepting invitation:", error);
          throw error;
        }

        setSuccessStatus(1);
        setIsLoading(false);

        await Swal.fire({
          title: t("circles.accepted"),
          text: t("circles.acceptedtext"),
          icon: "success",
          confirmButtonText: t("circles.ok"),
        });

        onClose();

        window.location.reload();
      } catch (error) {
        console.error("Error in handleAccept:", error);
        await Swal.fire({
          title: t("circles.error"),
          text: t("circles.errortext1"),
          icon: "error",
          confirmButtonText: t("circles.ok"),
        });
      } finally {
        setIsLoading(false);
      }
    };

    const handleDeny = async (invitationId) => {
      setIsLoading(true);

      try {
        const updates = {
          status: 2,
        };
        const { data, error } = await supabaseService
          .from("circle_members")
          .update(updates)
          .eq("id", invitationId);

        if (error) {
          console.error("Error in declining invitation:", error);
          throw error;
        }


        if (data && data.length === 0) {
          console.warn(
            "No rows updated - check if ID is correct and permissions are set"
          );
        }

        setSuccessStatus(2);
        setIsLoading(false);

        await Swal.fire({
          title: t("circles.declined"),
          text: t("circles.declinetext"),
          icon: "success",
          confirmButtonText: t("circles.ok"),
        });

        onClose();

        window.location.reload();
      } catch (error) {
        console.error("Error in handleDeny:", error);
        await Swal.fire({
          title: t("circles.error"),
          text: t("circles.errortext2"),
          icon: "error",
          confirmButtonText: "OK",
        });
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <div
        className={`modal ${
          isOpen ? "modal-open" : ""
        } fixed w-full h-full top-0 left-0 flex items-center justify-center`}
      >
        <div
          className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
          onClick={onClose}
        ></div>
        <div
          className="modal-box relative bg-white shadow-xl rounded-lg p-6"
          onClick={onClose}
        >
          <h3 className="text-2xl font-semibold text-gray-900 mb-4 text-center">
            {t("circles.pending")}
          </h3>
          <div className="divider"></div>

          <div className="overflow-x-auto">
            <div className="flex flex-col mb-4">
              {circleInvitations.map((invitation, index) => (
                <div
                  key={index}
                  className="border-b border-gray-200 mb-2 p-4 flex flex-col sm:flex-row items-start sm:items-center justify-between hover:bg-gray-100 rounded-lg"
                >
                  <div className="mb-2 sm:mb-0">
                    <p className="text-lg font-semibold text-gray-800">
                      {invitation.circles.name}
                    </p>
                    <p className="text-gray-600">
                    {t("circles.invited")}{invitation.circles.owner.first_name}{" "}
                      {invitation.circles.owner.last_name}
                    </p>
                  </div>

                  <div className="flex items-center space-x-2 sm:space-x-4 mt-2 sm:mt-0">
                    <button
                      className="btn bg-green-400 text-white flex items-center hover:bg-green-500"
                      onClick={() => handleAccept(invitation.id)}
                    >
                      <MdCheck className="inline-block mr-2" /> {t("circles.accept")}
                    </button>
                    <button
                      className="btn bg-red-400 text-white flex items-center hover:bg-red-500"
                      onClick={() => handleDeny(invitation.id)}
                    >
                      <MdClose className="inline-block mr-2" /> {t("circles.deny")}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex justify-end pt-2 mt-2">
            <button
              className="modal-close px-4 bg-transparent p-3 rounded-lg text-stone-700 hover:bg-gray-300"
              onClick={onClose}
            >
              {t("circles.close")}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const CircleModal = ({ isOpen, onClose }) => {
    const [previewUrl, setPreviewUrl] = useState(null);
    const fileInputRef = useRef(null);

    const [data, setData] = useState({
      name: "",
      description: "",
      image_url: null,
    });

    const onChangeImage = (imageUrl) => {
      setData({ ...data, image_url: imageUrl });
    };

    const handlePhotoSelect = () => {
      fileInputRef.current.click();
    };

    const processSubmit = async (dataInput) => {
      try {
        const {
          data: { user },
        } = await supabase.auth.getUser();
        const insert = {
          owner_id: user.id,
          name: dataInput.name,
          description: dataInput.description,
          image_url: data?.image_url,
        };

        let { data: dataInsert, error, status } = await supabase
          .from("circles")
          .insert(insert)
          .select();

        if (error && status !== 406) {
          throw error;
        }

        setPreviewUrl(null);
        setData({
          name: "",
          description: "",
          image_url: null,
        });

        Swal.fire({
          icon: "success",
          title: t("circles.circletext"),
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          onClose();
          window.location.reload();
        });
      } catch (error) {
        console.error("Error creating circle:", error);

        Swal.fire({
          icon: "error",
          title: t("circles.errorcreating"),
          text:
          t("circles.errorcreatingtext"),
          showConfirmButton: false,
          timer: 2000,
        });
      }
    };

    return (
      <div
        className={`modal ${
          isOpen ? "modal-open" : ""
        } fixed w-full h-full top-0 left-0 flex items-center justify-center`}
      >
        <div
          className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
          onClick={onClose}
        ></div>
        <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-2xl  z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
            <div className="flex flex-col items-center pb-3">
              <p className="text-2xl font-bold text-center">
                {t("circles.create")}
              </p>
              <div className="divider"></div>
            </div>
            <div className="">
              <form onSubmit={handleSubmit((data) => processSubmit(data))}>
                <PhotoUploaderCircle onChangeImage={onChangeImage} />
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">{t("circles.createname")}</span>
                  </label>
                  <input
                    {...register("name", { required: true })}
                    className="input border border-gray-900 focus:outline-none focus:border-blue-500 p-2"
                  />
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">{t("circles.createdesc")}</span>
                  </label>
                  <textarea
                    {...register("description")}
                    className="input border border-gray-900 focus:outline-none focus:border-blue-500 p-2 h-32"
                  />
                </div>

                <button type="submit" className="btn btn-outline mt-4 w-full">
                  {t("circles.createbtn")}
                </button>
              </form>
            </div>
            <div className="flex justify-end pt-2 mt-2">
              <button
                className="modal-close px-4 bg-transparent p-3 rounded-lg text-stone-700 hover:bg-gray-300"
                onClick={onClose}
              >
                {t("circles.close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>RecipeCircle - My Circles</title>
        <meta
          name="description"
          content="Explore and manage your circles on RecipeCircle. Create, join, and manage circles to connect with friends and share recipes."
        />
        <meta property="og:title" content="RecipeCircle - My Circles" />
        <meta
          property="og:description"
          content="Explore and manage your circles on RecipeCircle. Create, join, and manage circles to connect with friends and share recipes."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://app.recipecircle.world/circles"
        />
      </Helmet>
      <div className="w-100 container mx-auto">
      <div className="w-full overflow-x-hidden">
        <div className="text-center">
          <h1 className="text-4xl font-bold py-4 text-[#384E79]">{t("circles.circles")}</h1>
          <div className="breadcrumbs flex justify-center mb-4">
            <ul>
              <li>
                <a
                  onClick={() => {
                    navigate("/");
                  }}
                  className="text-[#384E79] hover:text-blue-400"
                >
                  {t("circles.home")}
                </a>
              </li>
              <li className="text-stone-700">{t("circles.circles")}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <div className="w-full md:w-1/3 md:order-1 mb-4 md:mb-0">
          {pendingApproval > 0 && (
            <button
              className="btn btn-outline text-black hover:bg-blue-400 w-full md:w-auto mr-2 md:mr-0"
              tabIndex={1}
              role="button"
              onClick={openCircleInviteModal}
            >
              {t("circles.pendingapproval")}
              <div className="badge badge-info text-zinc-200">
                +{pendingApproval}
              </div>
            </button>
          )}
          <CircleInviteModal
            isOpen={isCircleInviteModalOpen}
            onClose={closeCircleInviteModal}
            dataDisplay={dataDisplay}
            supabaseService={supabase}
            state={state}
          />
        </div>

        <div className="form-control w-full md:w-1/3 md:max-w-xs md:order-2 mb-4 md:mb-0">
          <div className="relative">
            <input
              type="text"
              placeholder={t("circles.searchcircle")}
              className="w-full pr-16 input border border-black focus:outline-none focus:border-blue-500 focus:ring-0"
              onChange={(e) => onChangeSearch(e)}
            />
            <div className="absolute top-0 right-0 rounded-l-none btn btn-square btn-ghost hover:bg-transparent">
              <MdSearch className="h-5 w-5" />
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/3 md:order-3 md:text-right">
          <button
            tabIndex={0}
            role="button"
            className="btn btn-outline text-black hover:bg-blue-400 w-full md:w-auto"
            onClick={openModal}
          >
            {t("circles.newcircle")}
          </button>
          <CircleModal isOpen={isModalOpen} onClose={closeModal} />
        </div>
      </div>
      <div className="divider"></div>

      {isLoading ? (
        <div className="flex justify-center">
          <span className="loading loading-spinner loading-lg"></span>
        </div>
      ) : dataDisplay.length ? (
        <div className="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {dataDisplay.map((item) => (
            <div
              className="card bg-white rounded-xl drop-shadow-xl border border-gray-300 overflow-hidden flex"
              key={item.id}
              onClick={(e) => handleView(item.id)}
              style={{ cursor: "pointer" }}
            >
              <figure
                className="h-40 bg-cover bg-center"
                style={{
                  backgroundImage: `url("${
                    item?.image_url
                      ? `${process.env.REACT_APP_SUPABASE_BUCKET_URL}${item.image_url}`
                      : mainBannerCircles
                  }")`,
                }}
              />
              <div className="card-body p-4">
                <h2 className="card-title text-[#384E79]">{item.name}</h2>

                {item.owner_id === state.user.id ? (
                  <div className="text-xs text-gray-600">
                    {t("circles.owncircle")}
                  </div>
                ) : (
                  <div className="text-xs text-gray-600">
                    {t("circles.circleowner")} {item.profiles.first_name}{" "}
                    {item.profiles.last_name}
                  </div>
                )}

                <div className="text-xs text-stone-800 pt-2 pb-2">
                {t("circles.created")} {dayjs(item.created_at).format("MM/DD/YYYY")}
                </div>
                <p className="text-sm text-gray-800">
                  {item?.description ? item?.description : ""}
                </p>
                <div className="card-actions justify-end">
                  <h4 className="text-base">
                  {t("circles.members")}
                    <span className="badge badge-outline ml-2">
                      {item.circle_members?.length}
                    </span>
                  </h4>
                  <h4 className="text-base">
                  {t("circles.recipes")}
                    <span className="badge ml-2 badge-outline">
                      {item.circle_recipes?.length}
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div
          className="hero bg-gray-100 rounded-box"
          style={{ minHeight: 480 }}
        >
          <div className="hero-content text-center">
            <div className="w-full">
              <h1 className="text-5xl font-bold">{t("circles.weclome")}</h1>
              <p className="py-6">
              {t("circles.weclometext1")}
              </p>
              <button
                className="btn btn-outline hover:bg-blue-400 w-full md:w-auto"
                onClick={openModal}
              >
                {t("circles.createcircletext")}
              </button>
            </div>
          </div>
        </div>
      )}
      </div>
    </>
  );
};

export default Circle;
