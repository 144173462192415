import React, { useContext, useEffect, useState } from "react";
import mainBannerCircles from "../assets/banner-circle-1.jpeg";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { supabase } from "../supabaseClient";
import mainBannerRecipes from "../assets/banner-recipe-1.jpeg";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../context/DataContext";
import { categoriesMain, categoriesDietary, typesLabel } from "../constants";
import { Helmet } from "react-helmet-async";
import PlannedMealModal from "../components/utilities/PlannedMealModal";
import EditMealModal from "../components/utilities/EditMealModal";
import ScheduleMealModal from "../components/utilities/ScheduleMealModal";
import PlannedMealsDisplay from "../components/utilities/PlannedMealsDisplay";
import { useTranslation } from "react-i18next";
import FinalizeMealModal from "../components/utilities/FinalizeMealModal.jsx";
import EditScheduleModal from "../components/utilities/EditScheduleMealModal.jsx";
import EditFinalizeMealModal from "../components/utilities/EditFinalizeMealModal.jsx";
import GroupPlanModal from "../components/utilities/GroupPlanRecipesModal.jsx";
import ScheduleGroupMealModal from "../components/utilities/GroupPlanScheduleModal.jsx";
import GroupPlannedMealsDisplay from "../components/utilities/GroupPlannedMealsDisplay.jsx";
import FinalizeGroupMealModal from "../components/utilities/GroupPlanFinalizeModal.jsx";

import {
  MdOutlinePublic,
  MdOutlinePrivateConnectivity,
  MdNavigateNext,
  MdOutlinePersonOutline,
  MdOutlineGroup,
} from "react-icons/md";

const getCategoryLabel = (categoryKey) => {
  const predefinedCategory = categoriesMain.find(
    (cMain) => cMain.value === categoryKey
  );

  return predefinedCategory?.label || categoryKey;
};

const Home = (props) => {
  const navigate = useNavigate();
  const { state } = useContext(DataContext);
  const [data, setData] = useState([]);
  const [dataDisplay, setDataDisplay] = useState([]);
  const [dataCircle, setDataCircle] = useState([]);
  const [dataCircleDisplay, setDataCircleDisplay] = useState([]);
  // const [dataDisplayShared, setDataDisplayShared] = useState([]);
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [addedRecipes, setAddedRecipes] = useState([]);
  const [plannedRecipes, setPlannedRecipes] = useState([]);
  const [currentPlannedMealsId, setCurrentPlannedMealsId] = useState(null);
  const [eventName, setEventName] = useState("");
  const [planName, setPlanName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const openScheduleModal = () => {
    setIsScheduleModalOpen(true);
  };
  const closeScheduleModal = () => {
    setIsScheduleModalOpen(false);
  };

  const [
    isScheduleGroupMealModalOpen,
    setIsScheduleGroupMealModalOpen,
  ] = useState(false);
  const openGroupScheduleModal = () => {
    setIsScheduleGroupMealModalOpen(true);
  };
  const closeScheduleGroupMealModal = () => {
    setIsScheduleGroupMealModalOpen(false);
  };

  const openModal = () => {
    setIsSelectPlanOpen(false);
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);

  const [isGroupRecipeModalOpen, setIsGroupRecipeModalOpen] = useState(false);

  const openGroupModal = () => {
    setIsSelectPlanOpen(false);
    setIsGroupRecipeModalOpen(true);
  };
  const closeGroupModal = () => setIsGroupRecipeModalOpen(false);

  const [
    isEditSchedulePlanModalOpen,
    setIsEditSchedulePlanModalOpen,
  ] = useState(false);

  const openEditSchedulePlanModal = async (plannedMealsId) => {
    let { data, error } = await supabase.rpc("get_plan_meal_details", {
      planned_meals_id_input: plannedMealsId,
    });

    if (error) {
      console.error("Error loading plan details:", error.message);
      return;
    }

    const planDetails = data.map((detail) => ({
      datePlanned: detail.plan_date_time.split("T")[0],
      timePlanned: detail.plan_date_time.split("T")[1].substring(0, 5),
      planDescription: detail.plan_description,
      recipes: detail.recipe_details,
    }));

    setScheduleDetails(planDetails);
    closeEditModal();
    setIsEditSchedulePlanModalOpen(true);
  };

  const closeEditSchedulePlanModal = () => {
    setIsEditSchedulePlanModalOpen(false);
  };

  const openEditModal = (plannedMealsId) => {
    setCurrentPlannedMealsId(plannedMealsId);
    setIsEditModalOpen(true);
  };
  const closeEditModal = () => setIsEditModalOpen(false);

  const [isFinalizeModalOpen, setIsFinalizeModalOpen] = useState(false);
  const openFinalizeModal = () => setIsFinalizeModalOpen(true);
  const closeFinalizeModal = () => {
    setIsFinalizeModalOpen(false);
    setPlannedRecipes([]);
    setAddedRecipes([]);
    setEventName("");
    setPlanName("");
  };

  const [
    isFinalizeGroupMealModalOpen,
    setIsFinalizeGroupMealModalOpen,
  ] = useState(false);

  const openFinalizeGroupMealModal = () =>
    setIsFinalizeGroupMealModalOpen(true);
  const closeFinalizeGroupMealModal = () => {
    setIsFinalizeGroupMealModalOpen(false);
    setPlannedRecipes([]);
    setAddedRecipes([]);
    setEventName("");
    setPlanName("");
  };

  const [scheduleDetails, setScheduleDetails] = useState([
    {
      datePlanned: "",
      timePlanned: "",
      eventDescription: "",
      planName: "",
      eventName: "",
      recipes: [{ recipeId: "" }],
    },
  ]);
  const [isEditFinalizeModalOpen, setIsEditFinalizeModalOpen] = useState(false);

  const normalizeRecipeIds = (details) => {
    return details.map((day) => ({
      ...day,
      recipes: day.recipes
        ? day.recipes.map((recipe) => ({
            ...recipe,
            recipeId: recipe.recipeId || recipe.id,
          }))
        : [],
    }));
  };

  const openEditFinalizeModal = (
    scheduleDetails,
    currentPlannedMealsId,
    newEventName
  ) => {
    const normalizedDetails = normalizeRecipeIds(scheduleDetails);
    setScheduleDetails(normalizedDetails);
    setCurrentPlannedMealsId(currentPlannedMealsId);
    setEventName(newEventName);
    setPlannedRecipes(plannedRecipes);
    setIsEditSchedulePlanModalOpen(false);
    setIsEditFinalizeModalOpen(true);
  };

  const closeEditFinalizeModal = () => {
    setIsEditFinalizeModalOpen(false);
    setPlannedRecipes([]);
    setEventName("");
  };

  useEffect(() => {
    const loadCircles = async () => {
      setIsLoading(true);

      let { data: ownedCircles, error: ownedError } = await supabase
        .from("circles")
        .select(
          `id, name, description, image_url, owner_id, created_at,
          circle_recipes(
            id
          ),
          circle_members(
            id, email
          ),
          profiles (first_name, last_name)`
        )
        .eq("owner_id", state.user.id);

      let { data: memberCircles, error: memberError } = await supabase
        .from("circle_members")
        .select(
          `circles:circle_id (id, name, description, image_url, owner_id, created_at,
            circle_recipes(id),
            circle_members(id, email),
            profiles (first_name, last_name))`
        )
        .eq("email", state.user.email)
        .eq("status", 1);

      if (ownedError || memberError) {
        console.error("Error loading circles:", ownedError, memberError);
        setIsLoading(false);
        return;
      }

      const sharedCircles = memberCircles.map((item) => item.circles);

      const allCircles = [...ownedCircles, ...sharedCircles].reduce(
        (acc, circle) => {
          if (!acc.find((c) => c.id === circle.id)) {
            acc.push(circle);
          }
          return acc;
        },
        []
      );

      setDataCircle(allCircles);
      setDataCircleDisplay(allCircles);
      setIsLoading(false);
    };

    if (state?.user?.id) {
      loadCircles();
    }
  }, [state]);

  const redirectToPlanMeals = () => {
    navigate("/plan-meals");
  };

  const redirectToCircles = () => {
    navigate("/circles");
  };

  const redirectToPublicRecipes = () => {
    navigate("/public-recipes");
  };

  useEffect(() => {
    const loadRecipes = async () => {
      let { data: dataRecipes, error: errorR, status: statusR } = await supabase
        .from("recipes")
        .select(
          `id, name, author, description, type, duration, servings, steps, image_url, public, owner_id, url, created_at,
        recipe_ingredients (
          name
        ),recipe_categories (
          category_key,
          type
        ),profiles (first_name, last_name)`
        )
        .eq("public", 1)
        .limit(4)
        .order("created_at", { ascending: false });

      if (errorR && statusR !== 406) {
        throw errorR;
      }
      setData(dataRecipes);
      setDataDisplay(dataRecipes);
    };

    loadRecipes();
  }, []);

  const handleView = (url) => {
    navigate(`/recipes-view/${url}`);
  };

  const handleCircleView = (circleId) => {
    navigate(`/circles/${circleId}`);
  };

  const [isSelectPlanOpen, setIsSelectPlanOpen] = useState(false);

  const openSelectPlanModal = () => {
    setIsSelectPlanOpen(true);
  };
  const closeSelectPlanModal = () => {
    setIsSelectPlanOpen(false);
  };

  const SelectPlanMeal = ({ isSelectPlanOpen, onSelectPlanClose }) => {
    return (
      <div
        className={`modal ${
          isSelectPlanOpen ? "modal-open" : ""
        } fixed w-full h-full top-0 left-0 flex items-center justify-center`}
      >
        <div
          className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"
          onClick={onSelectPlanClose}
        ></div>

        <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-2xl  z-50 overflow-y-auto">
          <div className="modal-content py-4 text-left px-6">
            <div className="flex flex-col items-center pb-3">
              <p className="text-2xl font-bold text-center">Plan New Meals</p>
            </div>

            <div className="mt-5">
              <div
                className="flex items-center rounded-lg hover:bg-gray-200 transition-colors cursor-pointer p-4"
                onClick={openModal}
              >
                <MdOutlinePersonOutline className="text-3xl text-[#384E79] mr-5" />
                <div className="flex-grow">
                  <p className="font-semibold">{t("misc.solo")}</p>
                  <p className="text-sm">{t("misc.solotext")}</p>
                </div>
              </div>
              <div
                className="flex items-center rounded-lg hover:bg-gray-200 transition-colors cursor-pointer p-4"
                onClick={openGroupModal}
              >
                <MdOutlineGroup className="text-3xl text-[#384E79] mr-5" />
                <div className="flex-grow">
                  <p className="font-semibold">{t("misc.group")}</p>
                  <p className="text-sm">{t("misc.grouptext")}</p>
                </div>
              </div>
            </div>

            <div className="flex justify-end pt-2">
              <button
                className="modal-close px-4 bg-transparent p-3 rounded-lg text-stone-700 hover:bg-gray-300"
                onClick={onSelectPlanClose}
              >
                {t("recipe.close")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div>
        <Helmet>
          <title>RecipeCircle Dashboard</title>
          <meta
            name="description"
            content="Welcome to RecipeCircle dashboard. Plan your meals, connect with circle connections, and explore public recipes."
          />
          <meta property="og:title" content="RecipeCircle Dashboard" />
          <meta
            property="og:description"
            content="Welcome to RecipeCircle dashboard. Plan your meals, connect with circle connections, and explore public recipes."
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content="https://app.recipecircle.world/dashboard"
          />
          <meta name="twitter:title" content="RecipeCircle Dashboard" />
          <meta
            name="twitter:description"
            content="Welcome to RecipeCircle dashboard. Plan your meals, connect with circle connections, and explore public recipes."
          />
        </Helmet>
        <div
          className="hero"
          style={{
            height: 200,
            overflow: "hidden",
            backgroundImage: `url(${process.env.REACT_APP_SUPABASE_BUCKET_URL}public/recipe-book-tablet.jpeg)`,
          }}
        >
          <div className="hero-overlay bg-opacity-60"></div>
          <div className="hero-content text-center text-neutral-content">
            <div className="max-w-md">
              <h1 className="mb-5 text-5xl font-bold">RecipeCircle</h1>
              <p className="mb-5 text-2xl text-primary">
                {t("dashboard.slogan")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 container mx-auto">
        <div className="mx-auto px-2 sm:px-4 py-4">
          

          <div className="flex flex-col sm:flex-row justify-between items-center mt-8 mb-2">
            <h1 className="text-2xl sm:text-3xl mb-4 sm:mb-0">
              {t("dashboard.yourcircles")}
            </h1>
            <button
              className="btn btn-outline w-full sm:w-auto border-stone-800 text-stone-500 hover:bg-blue-400 hover:text-stone-800 flex justify-center sm:justify-start items-center gap-2 py-2"
              onClick={redirectToCircles}
            >
              {t("dashboard.yourcircles")}
              <MdNavigateNext className="w-4 h-4 sm:w-6 sm:h-6" />
            </button>
          </div>
          <div className="divider"></div>

          {isLoading ? (
            <div className="flex justify-center">
              <span className="loading loading-spinner loading-lg"></span>
            </div>
          ) : dataCircleDisplay.length ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              {dataCircleDisplay.map((item) => (
                <div
                  className="card bg-white rounded-xl drop-shadow-xl border border-gray-300 overflow-hidden flex"
                  key={item.id}
                  onClick={(e) => handleCircleView(item.id)}
                  style={{ cursor: "pointer" }}
                >
                  <figure
                    className="h-40 bg-cover bg-center"
                    style={{
                      backgroundImage: `url("${
                        item?.image_url
                          ? `${process.env.REACT_APP_SUPABASE_BUCKET_URL}${item.image_url}`
                          : mainBannerCircles
                      }")`,
                    }}
                  />
                  <div className="card-body p-4">
                    <h2 className="card-title text-[#384E79]">{item.name}</h2>

                    {item.owner_id === state.user.id ? (
                      <div className="text-xs text-gray-600">
                        {t("dashboard.owncircle")}
                      </div>
                    ) : (
                      <div className="text-xs text-gray-600">
                        {t("dashboard.circleowner")} {item.profiles.first_name}{" "}
                        {item.profiles.last_name}
                      </div>
                    )}

                    <div className="text-xs text-stone-800 pt-2 pb-2">
                      {t("dashboard.created")}{" "}
                      {dayjs(item.created_at).format("MM/DD/YYYY")}
                    </div>
                    <p className="text-sm text-gray-800">
                      {item?.description ? item?.description : ""}
                    </p>
                    <div className="card-actions justify-end">
                      <h4 className="text-base">
                        {t("dashboard.members")}
                        <span className="badge badge-outline ml-2">
                          {item.circle_members?.length}
                        </span>
                      </h4>
                      <h4 className="text-base">
                        {t("dashboard.recipes")}
                        <span className="badge ml-2 badge-outline">
                          {item.circle_recipes?.length}
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8 backdrop-blur-lg rounded-xl">
              <div className="max-w-md w-full space-y-8">
                <div>
                  <h2 className="mt-6 text-center text-3xl font-extrabold text-stone-900">
                    {t("dashboard.nocircle")}
                  </h2>
                  <p className="mt-2 text-center text-sm text-stone-600">
                    {t("dashboard.nocircletext1")}
                  </p>
                </div>
                <div className="mt-8 space-y-6">
                  <p className="text-center text-sm text-stone-600">
                    {t("dashboard.nocircletext2")}
                  </p>
                  <button
                    className="btn btn-outline group relative w-full flex justify-center py-2 px-4 
                  text-sm font-medium rounded-md text-stone-800 border border-stone-800 
                  hover:bg-blue-400"
                    onClick={() => navigate("/circles")}
                  >
                    {t("dashboard.connections")}
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="flex flex-col sm:flex-row justify-between items-center mt-8 mb-2">
            <h1 className="text-2xl sm:text-3xl mb-4 sm:mb-0">
              {t("dashboard.plannedmeals")}
            </h1>
            <button
              className="btn btn-outline w-full sm:w-auto border-stone-800 text-stone-500 hover:bg-blue-400 hover:text-stone-800 flex justify-center sm:justify-start items-center gap-2 py-2"
              onClick={redirectToPlanMeals}
            >
              {t("dashboard.planmeals")}
              <MdNavigateNext className="w-4 h-4 sm:w-6 sm:h-6" />
            </button>
          </div>
          <div className="divider"></div>

          <SelectPlanMeal
            isSelectPlanOpen={isSelectPlanOpen}
            onSelectPlanClose={closeSelectPlanModal}
          />

          <PlannedMealModal
            isOpen={isModalOpen}
            onClose={closeModal}
            user={state.user}
            addedRecipes={addedRecipes}
            setAddedRecipes={setAddedRecipes}
            onOpenScheduleModal={openScheduleModal}
          />

          <ScheduleMealModal
            isOpen={isScheduleModalOpen}
            onClose={closeScheduleModal}
            openFinalizeModal={openFinalizeModal}
            scheduleDetails={
              scheduleDetails.length > 0
                ? scheduleDetails
                : [
                    {
                      datePlanned: "",
                      timePlanned: "",
                      eventDescription: "",
                      recipes: [{ recipeId: "" }],
                    },
                  ]
            }
            selectedRecipes={addedRecipes}
            setSelectedRecipes={setAddedRecipes}
            setScheduleDetails={setScheduleDetails}
            user={state.user}
          />

          <FinalizeMealModal
            isOpen={isFinalizeModalOpen}
            onClose={closeFinalizeModal}
            user={state.user}
            addedRecipes={addedRecipes}
            setAddedRecipes={setAddedRecipes}
            scheduleDetails={scheduleDetails}
            setScheduleDetails={setScheduleDetails}
          />

          <EditMealModal
            isOpen={isEditModalOpen}
            onClose={closeEditModal}
            user={state.user}
            plannedMealsId={currentPlannedMealsId}
            plannedRecipes={plannedRecipes}
            setPlannedRecipes={setPlannedRecipes}
            onContinue={() => openEditSchedulePlanModal(currentPlannedMealsId)}
          />
          <EditScheduleModal
            isOpen={isEditSchedulePlanModalOpen}
            onClose={closeEditSchedulePlanModal}
            scheduleDetails={scheduleDetails}
            plannedMealsId={currentPlannedMealsId}
            setScheduleDetails={setScheduleDetails}
            openFinalizeEditModal={(
              localScheduleDetails,
              plannedMealsId,
              eventName
            ) =>
              openEditFinalizeModal(
                localScheduleDetails,
                plannedMealsId,
                eventName
              )
            }
            user={state.user}
            plannedRecipes={plannedRecipes}
            setPlannedRecipes={setPlannedRecipes}
          />

          <EditFinalizeMealModal
            isOpen={isEditFinalizeModalOpen}
            onClose={closeEditFinalizeModal}
            scheduleDetails={scheduleDetails}
            setScheduleDetails={setScheduleDetails}
            plannedMealsId={currentPlannedMealsId}
            user={state.user}
            eventName={eventName}
            plannedRecipes={plannedRecipes}
            setPlannedRecipes={setPlannedRecipes}
          />

          <GroupPlanModal
            isOpen={isGroupRecipeModalOpen}
            onClose={closeGroupModal}
            user={state.user}
            addedRecipes={addedRecipes}
            setAddedRecipes={setAddedRecipes}
            onOpenGroupScheduleModal={openGroupScheduleModal}
          />
          <ScheduleGroupMealModal
            isOpen={isScheduleGroupMealModalOpen}
            onClose={closeScheduleGroupMealModal}
            openFinalizeGroupMealModal={openFinalizeGroupMealModal}
            scheduleDetails={
              scheduleDetails.length > 0
                ? scheduleDetails
                : [
                    {
                      datePlanned: "",
                      timePlanned: "",
                      eventDescription: "",
                      recipes: [{ recipeId: "" }],
                    },
                  ]
            }
            selectedRecipes={addedRecipes}
            setSelectedRecipes={setAddedRecipes}
            setScheduleDetails={setScheduleDetails}
            user={state.user}
          />

          <FinalizeGroupMealModal
            isOpen={isFinalizeGroupMealModalOpen}
            onClose={closeFinalizeGroupMealModal}
            user={state.user}
            addedRecipes={addedRecipes}
            setAddedRecipes={setAddedRecipes}
            scheduleDetails={scheduleDetails}
            setScheduleDetails={setScheduleDetails}
          />

          <PlannedMealsDisplay
            user={state.user}
            onOpenModal={openSelectPlanModal}
            onEditClick={openEditModal}
          />

          <GroupPlannedMealsDisplay user={state.user} />
          <div className="flex flex-col sm:flex-row justify-between items-center mt-8 mb-2 sm:mb-0">
            <h1 className="text-2xl sm:text-3xl mb-4 sm:mb-0">
              {t("dashboard.explore")}
            </h1>
            <button
              className="btn btn-outline w-full sm:w-auto border-stone-800 text-stone-500 hover:bg-blue-400 hover:text-stone-800 flex justify-center sm:justify-start items-center gap-2 py-2"
              onClick={redirectToPublicRecipes}
            >
              {t("dashboard.publicrec")}
              <MdNavigateNext className="w-4 h-4 sm:w-6 sm:h-6" />
            </button>
          </div>
          <div className="divider"></div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-5">
            {dataDisplay.map((item) => (
              <div
                className="card bg-white rounded-xl drop-shadow-xl border border-gray-300 overflow-hidden flex"
                key={item.id}
                onClick={(e) => handleView(item.url)}
                style={{ cursor: "pointer" }}
              >
                <figure
                  className="h-40 bg-cover bg-center"
                  style={{
                    backgroundImage: `url("${
                      item?.image_url
                        ? `${process.env.REACT_APP_SUPABASE_BUCKET_URL}${item.image_url}`
                        : mainBannerRecipes
                    }")`,
                  }}
                />
                <div className="card-body p-4">
                  <h2 className="card-title text-[#384E79]">{item.name}</h2>
                  <div className="text-xs text-gray-600">
                    {t("dashboard.recipeby")}{" "}
                    {item.author ||
                      `${item.profiles.first_name} ${item.profiles.last_name}`}
                  </div>
                  <div className="text-xs text-stone-800 pt-2 pb-2">
                    {t("dashboard.created")}{" "}
                    {dayjs(item.created_at).format("MM/DD/YYYY")}
                  </div>
                  {item.type === 1 && (
                    <p className="text-sm text-gray-800">
                      {t("dashboard.duration")}{" "}
                      {item?.duration ? item?.duration : "-"}
                    </p>
                  )}
                  <div className="flex flex-wrap gap-2 my-2">
                    {item.recipe_categories
                      .filter((cItem) => cItem.type === 1)
                      .map((cItem2, index, array) => (
                        <span
                          className={`text-sm mr-1 ${
                            index < array.length - 1
                              ? "border-r border-gray-500 pr-2"
                              : ""
                          }`}
                          key={cItem2.category_key}
                        >
                          {getCategoryLabel(cItem2.category_key)}
                        </span>
                      ))}
                  </div>
                  <div className="flex flex-wrap gap-2 mb-2">
                    {item.recipe_categories
                      .filter((cItem) => cItem.type === 2)
                      .map((cItem2, index, array) => (
                        <span
                          className={`text-sm mr-1 ${
                            index < array.length - 1
                              ? "border-r border-gray-500 pr-2"
                              : ""
                          }`}
                          key={cItem2.category_key}
                        >
                          {
                            categoriesDietary.find(
                              (cMain) => cMain.value === cItem2.category_key
                            )?.label
                          }
                        </span>
                      ))}
                  </div>

                  <div className="absolute bottom-2 right-2 flex items-center">
                    {item.public ? (
                      <>
                        <MdOutlinePublic className="text-green-500" />
                        <span className="ml-1 text-s text-green-500">
                          {t("dashboard.public")}
                        </span>
                      </>
                    ) : (
                      <>
                        <MdOutlinePrivateConnectivity className="text-stone-800" />
                        <span className="ml-1 text-s text-stone-800">
                          {t("dashboard.private")}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
