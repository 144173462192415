import React from 'react';
import { useTranslation } from "react-i18next";

const DesktopMenu = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full px-6 text-xl leading-normal text-gray-800 lg:w-1/5">
      <p className="text-base font-bold py-2 lg:pb-6 text-gray-700">
      {t("menus.menu")}
      </p>
      <div className="block lg:hidden sticky inset-0">
        <button
          id="menu-toggle"
          className="flex w-full justify-end px-3 py-3 bg-white lg:bg-transparent border rounded border-gray-600 appearance-none focus:outline-none"
        >
          <svg
            className="fill-current h-3 float-right"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </button>
      </div>
      <div
        className="w-full sticky inset-0 hidden max-h-64 lg:h-auto overflow-x-hidden overflow-y-auto lg:overflow-y-hidden lg:block mt-0 my-2 lg:my-0 border border-gray-400 lg:border-transparent bg-white shadow lg:shadow-none lg:bg-transparent z-20"
        style={{ top: "6em" }}
        id="menu-content"
      >
        <ul className="list-reset py-2 md:py-0">
          <li className="py-1 md:my-2 lg:hover:bg-transparent border-l-4 border-transparent">
            <a
              href="#overview"
              style={{ scrollBehavior: "smooth" }}
              className="block pl-4 align-middle text-gray-700 no-underline"
            >
              <span className="pb-1 md:pb-0 text-sm">{t("menus.overview")}</span>
            </a>
          </li>
          <li className="py-1 md:my-2 lg:hover:bg-transparent border-l-4 border-transparent">
            <a
              href="#ingredients"
              className="block pl-4 align-middle text-gray-700 no-underline"
            >
              <span className="pb-1 md:pb-0 text-sm">{t("menus.ingredients")}</span>
            </a>
          </li>
          <li className="py-1 md:my-2  lg:hover:bg-transparent border-l-4 border-transparent">
            <a
              href="#steps"
              className="block pl-4 align-middle text-gray-700 no-underline "
            >
              <span className="pb-1 md:pb-0 text-sm">{t("menus.steps")}</span>
            </a>
          </li>
          <li className="py-1 md:my-2  lg:hover:bg-transparent border-l-4 border-transparent">
            <a
              href="#categories"
              className="block pl-4 align-middle text-gray-700 no-underline "
            >
              <span className="pb-1 md:pb-0 text-sm">{t("menus.categories")}</span>
            </a>
          </li>
          <li className="py-1 md:my-2  lg:hover:bg-transparent border-l-4 border-transparent">
            <a
              href="#circles"
              className="block pl-4 align-middle text-gray-700 no-underline"
            >
              <span className="pb-1 md:pb-0 text-sm">{t("menus.circles")}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DesktopMenu;
