import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";
import "../components/common/registration.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";

const Signup = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [justifyClass, setJustifyClass] = useState("justify-start");
  const MySwal = withReactContent(Swal);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const { t } = useTranslation();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    const handleResize = () => {
      const isMobileWidth = window.innerWidth < 768;
      setIsMobile(isMobileWidth);
      setJustifyClass(isMobileWidth ? "justify-start" : "justify-center");
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;

  useEffect(() => {
    if (error) {
      setError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && email !== "" && password !== "") {
      handleSignUp(event);
    }
  };

  const handleSignUp = async () => {
    if (password !== confirmPassword) {
      showError(t("signUp.pwnomatch"));
      return;
    }

    const hasConsentedToCookies =
      localStorage.getItem("cookieConsent") === "true";
    if (!hasConsentedToCookies) {
      showError(t("cookie.agree"));
      return;
    }

    if (!passwordRegex.test(password)) {
      showError(t("signUp.pwrequirements"));
      return;
    }

    try {
      const { error } = await supabase.auth.signUp({
        email,
        password,
      });

      if (error) {
        if (error.message.includes("already registered")) {
          showError(t("signUp.emailAlreadyRegistered"));
        } else {
          showError(error.message);
        }
        return;
      }

      setSuccess(true);
    } catch (error) {
      showError(error.message);
    }
  };

  const showError = (errorMessage) => {
    Swal.fire({
      icon: "error",
      title: "Error",
      text: errorMessage,
    });
  };

  const handleResendEmail = async () => {
    if (buttonDisabled) {
      Swal.fire({
        icon: "error",
        title: t("logIn.error"),
        text: t("signUp.wait"),
        timer: 1000,
        timerProgressBar: true,
      });
      return;
    }

    try {
      const { error } = await supabase.auth.signUp({
        email,
        password: password,
      });
      if (error) {
        if (
          error.status === 400 &&
          error.message.includes("already registered")
        ) {
          Swal.fire(
            t("signUp.sent1"),
            t("signUp.sent"),
            "success"
          );
        } else {
          showError(error.message);
        }
      } else {
        Swal.fire(
          t("signUp.sent1"),
            t("signUp.sent"),
          "success"
        );
      }
      setButtonDisabled(true);
      setTimeout(() => {
        setButtonDisabled(false);
      }, 60000);
    } catch (error) {
      showError(error.message);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Sign Up - RecipeCircle</title>
        <meta
          name="description"
          content="Sign up for a new RecipeCircle account."
        />
        <meta property="og:title" content="Sign Up - RecipeCircle" />
        <meta
          property="og:description"
          content="Sign up for a new RecipeCircle account."
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <div
        className={`flex flex-col items-center ${justifyClass} min-h-screen`}
      >
        <div className="flex flex-col-reverse md:flex-row w-full max-w-3xl mx-auto overflow-hidden shadow-xl rc mb-6">
          {success ? (
            <div className="flex flex-col items-center justify-center h-full w-full p-6 bg-gray-100 bg-opacity-80 shadow-xl">
              <div className="bg-white rounded-xl shadow-lg p-8 w-full max-w-md">
                <div className="flex flex-col items-center justify-center">
                  <div
                    className="w-40 h-40 bg-cover bg-no-repeat mb-4"
                    style={{
                      backgroundImage: `url(${require("../assets/logo-recipe-circle.png")})`,
                    }}
                  ></div>
                  <h1 class="text-2xl font-bold text-gray-800 text-center mb-3">
                    {" "}
                    Your Security Matters to Us{" "}
                  </h1>
                  <p class="text-gray-600 text-center">
                    {" "}
                    {t("signUp.ensure")}{" "}
                    <span class="text-blue-500 font-semibold truncate">
                      {email}
                    </span>
                    . {t("signUp.verify")}
                  </p>

                  <button
                    className={`mt-4 px-4 py-2 ${
                      buttonDisabled
                        ? "bg-gray-500"
                        : "bg-blue-500 hover:bg-blue-600"
                    } text-white rounded`}
                    disabled={buttonDisabled}
                    onClick={handleResendEmail}
                  >
                    {t("signUp.verification")}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div
                className="md:w-1/2 bg-center hidden md:block bg-cover md:pt-0"
                style={{
                  backgroundImage: `url(${require("../assets/logo-recipe-circle.png")})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                }}
              ></div>
              <div className="w-full md:w-1/2 px-4 py-4 text-center bg-white rounded-b-lg relative">
                {error ? (
                  <div className="alert alert-error shadow-lg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="stroke-current flex-shrink-0 h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <span>{error}</span>
                  </div>
                ) : null}
                <div className="p-3 pb-8 text-gray-700 text-center">
                  <h1 className="text-gray-900 text-3xl font-bold">
                    Register for a Recipe Circle account
                  </h1>
                </div>
                <div className="mb-4">
                  <label
                    className="block text-left ml-1 text-gray-700 text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    {t("signUp.email")}
                  </label>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="w-full shadow-inner appearance-none border rounded py-2 px-3 bg-gray-200 text-gray-700 leading-normal focus:outline-none focus:shadow-outline"
                    type="email"
                    name="email"
                    placeholder={t("signUp.email")}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-left ml-1 text-gray-700 text-sm font-bold mb-2"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <div className="relative w-full">
                    <input
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="w-full shadow-inner appearance-none border rounded py-2 px-3 pr-10 bg-gray-200 text-gray-700 leading-normal focus:outline-none focus:shadow-outline"
                      id="password"
                      name="password"
                      placeholder="Enter your password"
                      type={showPassword ? "text" : "password"}
                      required
                    />
                    <button
                      onClick={togglePasswordVisibility}
                      className="absolute inset-y-0 right-0 pr-3 flex items-center"
                    >
                      {showPassword ? (
                        <MdOutlineVisibility size={24}/>
                      ) : (
                        <MdOutlineVisibilityOff size={24}/>
                      )}
                    </button>
                  </div>
                </div>
                <div className="mb-6">
                  <label
                    className="block text-left ml-1 text-gray-700 text-sm font-bold mb-2"
                    htmlFor="confirmPassword"
                  >
                    Confirm Password
                  </label>
                  <div className="relative w-full">
                    <input
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="w-full shadow-inner appearance-none border rounded py-2 px-3 pr-10 bg-gray-200 text-gray-700 leading-normal focus:outline-none focus:shadow-outline"
                      id="confirmPassword"
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      placeholder="Confirm your password"
                      required
                    />
                    <button
                      onClick={toggleConfirmPasswordVisibility}
                      className="absolute inset-y-0 right-0 pr-3 flex items-center"
                    >
                      {showConfirmPassword ? (
                        <MdOutlineVisibility size={24}/>
                      ) : (
                        <MdOutlineVisibilityOff size={24}/>
                      )}
                    </button>
                  </div>
                </div>
                <div className="flex items-center justify-start">
                  <div className="mt-4 mx-auto w-full">
                    <button
                      className="w-full btn btn-outline hover:bg-blue-500 onclick:bg-blue-500 focus:bg-blue-500 text-black hover:text-black focus:text-black"
                      type="submit"
                      onClick={handleSignUp}
                    >
                      {t("signUp.regtext")}
                    </button>
                  </div>
                </div>
                <div className="divider"></div>
                <h6 className="mt-4">
                  {t("signUp.prompt")}{" "}
                  <a
                    className="text-blue-500 cursor-pointer"
                    onClick={() => navigate("/log-in")}
                  >
                    {t("signUp.signin")}
                  </a>
                </h6>
              </div>
            </>
          )}
        </div>
        <div className="absolute bottom-0 left-0 w-full h-8 bg-white rounded-b-lg"></div>
      </div>
    </React.Fragment>
  );
};

export default Signup;
