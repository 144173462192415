import { supabase } from "../../supabaseClient";

async function fetchGroupMealDetails(mealId, assignmentId) {
    try {
        const { data: assignmentData, error: assignmentError } = await supabase
            .from('other_group_meal_assignments')
            .select('email')
            .eq('id', assignmentId)
            .single();

        if (assignmentError) throw new Error(assignmentError.message);
        if (!assignmentData) throw new Error("No assignment found with the given ID.");

        const userEmail = assignmentData.email;

        const { data, error } = await supabase
            .from('other_group_meal_assignments')
            .select(`
                *,
                other_group_meals:other_group_meals_id (event_name),
                recipes:recipe_id (name, url)
            `)
            .eq('other_group_meals_id', mealId)
            .eq('email', userEmail) 
            .order('event_date_time', {ascending: true});

        if (error) throw new Error(error.message);

        return data;
    } catch (err) {
        throw new Error('Failed to fetch group meal details: ' + err.message);
    }
}


export default fetchGroupMealDetails;
