import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../context/DataContext";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { MdNavigateNext } from "react-icons/md";
import { fetchGroupPlannedMeals } from "./fetchGroupPlanMeals";
import { useTranslation } from "react-i18next";

const GroupPlannedMealsDisplay = ({
  user,
  isOpen,
  onOpenModal,
  onEditClick,
}) => {
  const { state, actions } = useContext(DataContext);
  const { lastMealUpdate } = state;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [groupPlannedMeals, setGroupPlannedMeals] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  dayjs.extend(utc);

  const handleView = (url) => {
    navigate(`/recipes-view/${url}`);
  };

  const handleCircleView = (circleId) => {
    navigate(`/circles/${circleId}`);
  };

  const redirectToCircles = () => {
    navigate("/circles");
  };

  useEffect(() => {
    if (state.user && state.user.id) {
      setIsLoading(true);
      fetchGroupPlannedMeals(state.user.id, setGroupPlannedMeals, () =>
        setIsLoading(false)
      );
    }
  }, [state.user, lastMealUpdate]);

  const formatDate = (utcDate) => {
    const dayjsDate = dayjs.utc(utcDate);
    const day = t(`days.${dayjsDate.format("dddd")}`);
    const month = t(`months.${dayjsDate.format("MMMM")}`);
    const atSymbol = t("misc.atSymbol") || "[at]";
    return `${day}, ${month} ${dayjsDate.format("D")}, ${dayjsDate.format(
      "YYYY"
    )} ${atSymbol} ${dayjsDate.format("h:mm A")}`;
  };

  return isLoading ? null : Object.keys(groupPlannedMeals).length === 0 ? (
    <div className="mt-10 flex items-center justify-center bg-blue-100 py-12 px-4 sm:px-6 lg:px-8 rounded-2xl shadow-xl">
      <div className="max-w-lg w-full space-y-6">
        <div>
          <h2 className="text-center text-4xl font-extrabold text-stone-900">
            {t("groupmeals.nocircmeals")}
          </h2>
          <p className="mt-3 text-center text-md text-stone-800">
            {t("groupmeals.start")}
          </p>
          <p className="text-center text-md text-stone-800">
            {t("groupmeals.available")}
          </p>
        </div>
        <div className="mt-10 space-y-8">
          <button
            className="group relative w-full flex justify-center py-3 px-4 bg-blue-300 border border-transparent text-md font-medium rounded-md text-stone-800 shadow-md hover:shadow-lg transition-all duration-300 ease-in-out"
            onClick={redirectToCircles}
          >
            {t("groupmeals.goto")}
            <MdNavigateNext className="w-6 h-6 mr-2" />
          </button>
        </div>
      </div>
    </div>
  ) : (
    <>
      <h2 className="text-xl font-semibold mb-4 mt-10">
        {t("groupmeals.circlmeals")}
      </h2>
      <div className="divider"></div>
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 350: 1, 750: 3, 900: 4, 1200: 5 }}
      >
        <Masonry gutter="20px">
          {Object.entries(groupPlannedMeals).map(
            ([groupPlanId, groupPlanData]) => (
              <div
                key={groupPlanId}
                className="bg-white rounded-lg shadow-sm hover:shadow-lg transition-shadow border border-gray-200 overflow-hidden flex flex-col relative"
              >
                <div className="px-5 pt-5 pb-3 relative">
                  <h3
                    className="text-xl font-semibold text-blue-600 mb-1 cursor-pointer"
                    onClick={() => handleCircleView(groupPlanData.circle_id)}
                  >
                    {groupPlanData.circle_name} - {groupPlanData.event_name}
                  </h3>
                  <div className="divider"></div>
                  {groupPlanData.planned_meals.map((meal, index) => (
                    <div key={index} className="mb-4">
                      <div className="text-xs text-gray-500 mb-2">
                        <strong>{t("groupmeals.date")}:</strong>{" "}
                        {formatDate(meal.event_date_time)}
                        <br />
                        <strong>{t("groupmeals.description")}:</strong>{" "}
                        {meal.event_description}
                      </div>
                      <div
                        key={meal.id}
                        className="border-b last:border-b-0 p-5 mb-1 hover:bg-blue-100 rounded-lg transition-colors flex justify-between items-center cursor-pointer group"
                        onClick={() => handleView(meal.recipe.url)}
                      >
                        <div>
                          <h4 className="text-lg font-semibold text-blue-500 mb-1">
                            {meal.recipe.name}
                          </h4>
                          <p className="text-sm text-gray-600">
                            {t("viewcircle.assigned")} {meal.assigned_to}
                          </p>
                        </div>
                        <MdNavigateNext className="text-2xl transition-opacity duration-150 ease-in-out opacity-0 group-hover:opacity-100 md:opacity-100" />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          )}
        </Masonry>
      </ResponsiveMasonry>
    </>
  );
};

export default GroupPlannedMealsDisplay;
