import React, { useContext } from "react";
import { DataContext } from "../../context/DataContext";
import { supabase } from "../../supabaseClient";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { MdDelete } from "react-icons/md";

const DeleteGroupMeal = ({ groupId }) => {
  const { actions } = useContext(DataContext);
  const { t } = useTranslation();

  const handleDelete = async () => {
    Swal.fire({
      title: t("groupmeals.prompt"),
      text: t("groupmeals.subtext"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dc2626",
      cancelButtonColor: "#3b82f6",
      confirmButtonText: t("groupmeals.yes"),
      cancelButtonText: t("groupmeals.cancel"),
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { data, error } = await supabase
          .from("other_group_meals")
          .delete()
          .match({ id: groupId });
        if (error) {
          console.error("Error deleting group meal:", error);
          Swal.fire(
            t("groupmeals.failure"),
            t("groupmeals.failuretext"),
            "error"
          );
        } else {
          Swal.fire(
            t("groupmeals.deleted"),
            t("groupmeals.deletedtext"),
            "success"
          ).then(() => {
            actions.refreshMeals();
          });
        }
      }
    });
  };

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        handleDelete();
      }}
      className="btn btn-outline hover:bg-red-500"    >
      <MdDelete className="w-7 h-7"/>
    </button>
  );
};

export default DeleteGroupMeal;
