import React, { useEffect, useState } from "react";
import { supabase } from "../../supabaseClient";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import PageTitle from "../../components/layout/PageTitle";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import PhotoUploaderCircle from "../../components/common/PhotoUploaderCircle";
import { useNavigate } from "react-router-dom";

const UpdateCircle = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { register, handleSubmit } = useForm();
  const [circle, setCircle] = useState(null);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadCircle = async () => {
      let {
        data: dataC,
        error: errorC,
        status: statusC,
      } = await supabase
        .from("circles")
        .select(
          `id, name, description, image_url, created_at,
          circle_recipes(
            *,
            recipes ( id, name )
          ),
          circle_members(
            id, email, status
          )`
        )
        .eq("id", id)
        .single();

      if (errorC && statusC !== 406) {
        throw errorC;
      }

      setCircle(dataC);
      setIsLoading(false);
    };
    if (!circle || id !== circle.id) {
      loadCircle();
    }
  }, [id, circle]);

  const processSubmit = async (dataInput) => {
    setData({ ...data, ...dataInput });

    const update = {
      id: circle.id,
      name: dataInput.name,
      description: dataInput.description,
      image_url: data?.image_url,
    };

    let { error, status } = await supabase
      .from("circles")
      .upsert(update)
      .single();

    if (error && status !== 406) {
      throw error;
    }

    navigate("/circles");
  };

  const onChangeImage = (image) => {
    setData({ ...data, image_url: image });
  };

  return (
    <div>
      <PageTitle title="New Circle" />
      <Breadcrumbs
        data={[
          { name: "Home", link: "/" },
          { name: "Circles", link: "/circles" },
          { name: "New Circle" },
        ]}
      />
      <div className="flex justify-space-between mb-1">
        <div className="flex-1">
          <button
            className="btn btn-secondary btn-square btn-outline"
            onClick={() => {
              navigate("/circles");
            }}
          >
            ❮
          </button>
        </div>
      </div>
      {isLoading ? (
        <div className="flex justify-center">
         <span className="loading loading-spinner loading-lg"></span>

        </div>
      ) : (
        <div className="flex justify-center ">
          <div className="card w-full md:w-2/3 lg:w-2/3 bg-base-200 shadow-xl mb-4">
            <PhotoUploaderCircle
              onChangeImage={onChangeImage}
              image={circle?.image_url}
            />

            <div className="card-body items-center text-center p-4 md:p-8 lg:p-8">
              <form
                className="form-control w-full"
                onSubmit={handleSubmit((data) => processSubmit(data))}
              >
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">Name</span>
                  </label>
                  <input
                    {...register("name", {
                      required: true,
                      value: circle.name,
                    })}
                    className="input input-bordered"
                  />
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">Description</span>
                  </label>
                  <textarea
                    {...register("description", { value: circle.description })}
                    className="input input-bordered h-32"
                  />
                </div>
                <div className="divider divider-horizontal"></div>

                <div className="mt-4">
                  <input
                    type="submit"
                    className="btn btn-primary w-full"
                    value="save"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateCircle;
