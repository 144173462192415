import React, { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../components/common/registration.css";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";

const Login = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const MySwal = withReactContent(Swal);
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [recaptchaScale, setRecaptchaScale] = useState(1);
  const [justifyClass, setJustifyClass] = useState("justify-start");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const isMobileWidth = window.innerWidth < 768;
      setIsMobile(isMobileWidth);
      setJustifyClass(isMobileWidth ? "justify-start" : "justify-center");
      setRecaptchaScale(isMobileWidth ? 0.9 : 1);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (error) {
      setError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && email !== "" && password !== "") {
      signIn(event);
    }
  };

  const signIn = async () => {
    if (email === "" || password === "") {
      MySwal.fire({
        icon: "error",
        title: t("logIn.email"),
        text: t("logIn.errortemail"),
      });
      return;
    }

    const { user, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      MySwal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });
      return;
    }
    if (rememberMe) {
      localStorage.setItem("rememberMe", "true");
    } else {
      localStorage.removeItem("rememberMe");
    }
    navigate("/dashboard");
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Login - RecipeCircle</title>
        <meta
          name="description"
          content="Login to your RecipeCircle account."
        />
        <meta property="og:title" content="Login - RecipeCircle" />
        <meta
          property="og:description"
          content="Login to your RecipeCircle account."
        />
        <meta property="og:type" content="website" />
      </Helmet>

      <div
        className={`flex flex-col items-center ${justifyClass} min-h-screen`}
      >
        <div className="flex flex-col-reverse md:flex-row w-full max-w-3xl mx-auto overflow-hidden shadow-xl rc">
          <div
            className="md:w-1/2 bg-center hidden md:block bg-cover md:pt-0"
            style={{
              backgroundImage: `url(${require("../assets/logo-recipe-circle.png")})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          ></div>

          <div className="w-full md:w-1/2 px-4 py-4 text-center bg-white rounded-b-lg relative">
            {error ? <span>{error}</span> : null}

            <div className="p-3 pb-8 text-gray-700 text-center">
              <h1 className="text-gray-900 text-3xl font-bold">
                {t("logIn.wecome")}
              </h1>
            </div>

            <div className="mb-4">
              <label
                className="block text-left ml-1 text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                {t("logIn.email")}
              </label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={handleKeyDown}
                className="w-full shadow-inner appearance-none border rounded py-2 px-3 bg-gray-200 text-gray-700 leading-normal focus:outline-none focus:shadow-outline"
                id="email"
                type="email"
                name="email"
                placeholder={t("logIn.email")}
                required
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-left ml-1 text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                Password
              </label>
              <div className="relative w-full">
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full shadow-inner appearance-none border rounded py-2 px-3 pr-10 bg-gray-200 text-gray-700 leading-normal focus:outline-none focus:shadow-outline"
                  id="password"
                  name="password"
                  placeholder="Enter your password"
                  type={showPassword ? "text" : "password"}
                  required
                />
                <button
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  type="button"
                >
                  {showPassword ? (
                    <MdOutlineVisibility size={24} />
                  ) : (
                    <MdOutlineVisibilityOff size={24} />
                  )}
                </button>
              </div>
            </div>
            <div className="mt-5 mb-2 flex items-center justify-center">
              <label>
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />{" "}
                {t("logIn.remember")}
              </label>
            </div>

            <div className="flex items-center justify-start">
              <div className="mt-4 mx-auto w-full">
                <button
                  className="w-full btn btn-outline hover:bg-blue-500 onclick:bg-blue-500 focus:bg-blue-500 text-black hover:text-black focus:text-black"
                  type="submit"
                  onClick={signIn}
                >
                  {t("logIn.login")}
                </button>
              </div>
            </div>

            <h6 className="mt-4">
              {t("logIn.forgot")}{" "}
              <a
                className="text-blue-500 cursor-pointer"
                onClick={() => navigate("/forgot-password")}
              >
                {t("logIn.click")}
              </a>
            </h6>
            <div className="divider"></div>
            <h6 className="mt-4">
              {t("logIn.new")}{" "}
              <a
                className="text-blue-500 cursor-pointer"
                onClick={() => navigate("/sign-up")}
              >
                {t("logIn.register")}
              </a>
            </h6>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
