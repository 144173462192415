import React, { useContext, useEffect, useRef } from "react";
import { supabase } from "../../supabaseClient";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { MdOutlineRemove } from "react-icons/md";
import { useTranslation } from "react-i18next";
import { DataContext } from "../../context/DataContext";
const EditFinalizeMealModal = ({
  isOpen,
  onClose,
  user,
  plannedRecipes,
  setPlannedRecipes,
  scheduleDetails,
  setScheduleDetails,
  eventName,
  plannedMealsId,
}) => {
  const { actions } = useContext(DataContext);
  const { t } = useTranslation();

  useEffect(() => {
    const isNormalizationNeeded = scheduleDetails.some(day => 
        day.recipes.some(recipe => !recipe.recipeId)
    );

    if (isNormalizationNeeded) {
        const normalizedDetails = scheduleDetails.map(day => ({
            ...day,
            recipes: day.recipes.map(recipe => ({
                ...recipe,
                recipeId: recipe.recipeId || recipe.id
            }))
        }));
        setScheduleDetails(normalizedDetails);
    }
}, []);


  const normalizeRecipeIds = () => {

    const normalizedDetails = scheduleDetails.map(day => ({
        ...day,
        recipes: day.recipes.map(recipe => ({
            ...recipe,
            recipeId: recipe.recipeId || recipe.id
        }))
    }));


    if (JSON.stringify(scheduleDetails) !== JSON.stringify(normalizedDetails)) {
        setScheduleDetails(normalizedDetails);
    } 
};

useEffect(() => {
    normalizeRecipeIds();
}, []); 


  const addRecipeToDay = (dayIndex) => {
    const newScheduleDetails = [...scheduleDetails];
    
    newScheduleDetails[dayIndex].recipes.push({
        recipeId: "",  
        name: "Select a recipe",
        author: ""
    });
    setScheduleDetails(newScheduleDetails);
};



  const removeRecipe = (dayIndex, recipeIndex) => {
    const newScheduleDetails = [...scheduleDetails];
    newScheduleDetails[dayIndex].recipes.splice(recipeIndex, 1);
    setScheduleDetails(newScheduleDetails);
  };
  const handleRecipeSelection = (dayIndex, recipeIndex, recipeId) => {
    normalizeRecipeIds(); 
    const newScheduleDetails = [...scheduleDetails];
    const selectedRecipe = plannedRecipes.find(recipe => recipe.id === recipeId);
    if (selectedRecipe) {
        newScheduleDetails[dayIndex].recipes[recipeIndex] = {
            ...selectedRecipe,
            recipeId: selectedRecipe.id
        };
        setScheduleDetails(newScheduleDetails);
    } else {
        console.error("Failed to find the recipe with id:", recipeId);
    }
};
;



const finalizeMealPlan = async () => {
  try {
    const allRecipesHaveIds = scheduleDetails.every(detail =>
      detail.recipes.every(recipe => recipe.recipeId)
    );

    if (!allRecipesHaveIds) {
      Swal.fire({
        title: t("planmeals.error"),
        text: t("planmeals.ensureallrecipes"),
        icon: "error",
        confirmButtonText: t("planmeals.ok"),
      });
      return;
    }

    const { error: deleteError } = await supabase
      .from("planned_meals")
      .delete()
      .match({ id: plannedMealsId });

    if (deleteError) {
      console.error("Error deleting the meal plan:", deleteError.message);
      return;
    }

    const { data: mealPlan, error: mealPlanError } = await supabase
      .from("planned_meals")
      .insert([{ user_id: user.id, plan_name: eventName }])
      .select("id");

    if (mealPlanError) {
      console.error("Error inserting new meal plan:", mealPlanError.message);
      return;
    }

    let mealPlanId;
    if (!mealPlan || mealPlan.length === 0) {
      console.error("No meal plan data returned after insertion.");
      return;
    } else {
      mealPlanId = mealPlan[0].id;
    }

    const mealRecipesData = scheduleDetails.flatMap((detail) =>
      detail.recipes.map((recipe) => ({
        planned_meals_id: mealPlanId,
        recipe_id: recipe.recipeId,
        user_id: user.id,
        plan_date_time: `${detail.datePlanned}T${detail.timePlanned}:00`,
        plan_description: detail.eventDescription,
      }))
    );

    if (mealRecipesData.length > 0) {
      const { error: recipesError } = await supabase
        .from("planned_meal_recipes")
        .insert(mealRecipesData);

      if (recipesError) {
        console.error("Error inserting meal recipes:", recipesError.message);
        return;
      }

      Swal.fire({
        title: t("planmeals.success"),
        text: t("planmeals.mealplansuccess"),
        icon: "success",
        confirmButtonText: t("planmeals.ok"),
      }).then((result) => {
        if (result.isConfirmed) {
          setScheduleDetails([]);
          setPlannedRecipes([]);
          onClose();
          actions.refreshMeals();
        }
      });
    } else {
      console.error("No meal recipes data to insert.");
    }
  } catch (error) {
    console.error("Error finalizing meal plan:", error.message);
    Swal.fire({
      title: t("planmeals.error"),
      text: t("planmeals.failedtosave") `${error.message || error}`,
      icon: "error",
      confirmButtonText: t("planmeals.ok"),
    });
  }
};

  

  return (
    <div
      className={`modal ${isOpen ? "modal-open" : ""} fixed inset-0 flex items-center justify-center`}>
      <div
        className="modal-overlay absolute inset-0 bg-gray-900 opacity-50"
        onClick={onClose}
      ></div>
      <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded-xl z-50 shadow-lg overflow-hidden">
        <div className="p-5">
          <h2 className="text-2xl font-bold text-center mb-4">
          {t("planmeals.finalizeedit")} {eventName}
          </h2>

          <div className="modal-content overflow-y-auto max-h-[calc(100vh-200px)] px-4">
            {scheduleDetails.map((detail, detailIndex) => (
              <div key={detailIndex} className="space-y-4">
                <h3 className="text-xl font-bold">
                  {detail.eventDescription} {t("planmeals.on")} {detail.datePlanned} {t("planmeals.at")}{" "}
                  {detail.timePlanned}
                </h3>
                {detail.recipes.map((recipe, recipeIndex) => (
                  <div
                    key={recipeIndex}
                    className="flex items-center justify-between gap-6 mb-4"
                  >
                    <select
                      className="form-select block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                      value={recipe.id || ""}
                      onChange={(e) =>
                        handleRecipeSelection(
                          detailIndex,
                          recipeIndex,
                          e.target.value
                        )
                      }
                    >
                      <option value="">{t("planmeals.select")}</option>
                      {plannedRecipes.map((addedRecipe) => (
                        <option key={addedRecipe.id} value={addedRecipe.id}>
                          {addedRecipe.name}
                        </option>
                      ))}
                    </select>
                    <button
                      onClick={() => removeRecipe(detailIndex, recipeIndex)}
                      className="ml-4 text-red-600 hover:text-red-800 transition-colors duration-150"
                    >
                      <MdOutlineRemove className="h-8 w-8" />
                    </button>
                  </div>
                ))}
                <div className="text-center">
                  <button
                    onClick={() => addRecipeToDay(detailIndex)}
                    className="btn btn-outline inline-block px-6 py-2.5 text-stone-800 font-medium text-xs leading-tight uppercase rounded hover:bg-blue-700 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                  >
                    {detail.recipes.length > 0
                      ? t("planmeals.addanother")
                      : t("planmeals.addanotherdate")}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-end p-4">
          <button
          onClick={finalizeMealPlan}
            className="btn bg-green-600 text-white"
          >
            {t("planmeals.finalize")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditFinalizeMealModal;
