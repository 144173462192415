import React, { useState, useRef, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import { useTranslation } from 'react-i18next';

const PhotoUploaderRecipe = ({ onChangeImage }) => {
  const { t } = useTranslation();  
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    setFileName(t("photouprec.select"));
  }, []);

  const fileInputRef = useRef(null);

  const handlePhotoSelect = async (event) => {
    if (event.target.files && event.target.files[0]) {
      const selectedPhoto = event.target.files[0];
      setFileName(selectedPhoto.name);

      const extension = selectedPhoto.name.split('.').pop();
      const { data: dataUpload, error } = await supabase.storage
        .from('recipe-files')
        .upload(
          `private/recipes/banner-${Date.now()}${extension ? `.${extension}` : ''}`,
          selectedPhoto
        );

      if (error) {
        throw error;
      }
      if (typeof onChangeImage === 'function') {
        onChangeImage(dataUpload.path);
      }
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <div className="mt-3">
        <label className="block text-sm font-medium text-gray-900 dark:text-gray-900 mb-2">
          {t("photouprec.cover")}
        </label>
        <input
          type="file"
          accept="image/*"
          onChange={handlePhotoSelect}
          ref={fileInputRef}
          className="hidden"
        />
        <button
          type="button"
          onClick={handleClick}
          className="btn btn-outline border border-stone-800 hover:bg-blue-400 w-full text-left"
        >
          {fileName}
        </button>
      </div>
    </>
  );
};

export default PhotoUploaderRecipe;
