import { useTranslation } from 'react-i18next';

export const usePluralizeMeasure = () => {
  const { t } = useTranslation();

  const parseFraction = (input) => {
    const [whole, fraction] = input.split(' ');
    if (!fraction) return parseFloat(whole);
    const [numerator, denominator] = fraction.split('/');
    return parseFloat(whole) + (parseFloat(numerator) / parseFloat(denominator));
  };

  const pluralizeMeasure = (quantity, measure) => {
    if (!measure) return "";

    const numericQuantity = parseFraction(quantity.toString());
    if (isNaN(numericQuantity)) return t(`measurements.${measure}`);

    const key = numericQuantity === 1 ? `measurements.${measure}` : `measurements.${measure}s`;

    return t(key, "", { defaultValue: t(`measurements.${measure}`) });
  };

  return pluralizeMeasure;
};

export default usePluralizeMeasure;
