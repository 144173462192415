import React, { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { supabase } from "../supabaseClient";
import { Helmet } from "react-helmet-async";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useTranslation } from "react-i18next";
import {
  MdArrowBackIosNew,
  MdHourglassEmpty,
  MdCheck,
  MdCancel,
} from "react-icons/md";
import { DataContext } from "../context/DataContext";
import DisplayComments from "../components/common/DisplayCommentsMeal";
import WriteComment from "../components/common/WriteCommentsMeal";
import DeleteGroupMeal from "../components/utilities/DeleteGroupMeal";
import LeaveGroupMeal from "../components/utilities/LeaveGroupMeal";
async function fetchMealData(mealId) {
  let { data: groupMeal, error } = await supabase
    .from("other_group_meals")
    .select(
      `
      id,
      event_name,
      owner_id,
      other_group_meal_assignments:other_group_meal_assignments!other_group_meals_id (
        id,
        recipe_id,
        event_date_time,
        event_description,
        user_id,
        email,
        accepted,
        user_profile:user_id (first_name, last_name),
        recipes:recipe_id (id, name, url)
      )
    `
    )
    .eq("id", mealId)
    .single();

  if (error) throw new Error(error.message);
  return groupMeal;
}

function GroupMeals() {
  const { mealId } = useParams();
  const [mealData, setMealData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [groupedAssignments, setGroupedAssignments] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  dayjs.extend(utc);
  const { state } = useContext(DataContext);

  useEffect(() => {
    fetchMealData(mealId)
      .then((data) => {
        setMealData(data);
        groupAssignments(data.other_group_meal_assignments);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching meal data:", error);
        setLoading(false);
      });
  }, [mealId]);

  const groupAssignments = (assignments) => {
    const grouped = assignments.reduce((acc, item) => {
      const date = item.event_date_time.split("T")[0];
      if (!acc[date]) {
        acc[date] = {
          event_date_time: item.event_date_time,
          event_description: item.event_description,
          recipes: [],
        };
      }
      acc[date].recipes.push({
        name: item.recipes.name,
        url: item.recipes.url,
        email: item.email,
        user_profile: item.user_profile,
        accepted: item.accepted,
      });
      return acc;
    }, {});

    setGroupedAssignments(
      Object.values(grouped).sort(
        (a, b) => new Date(a.event_date_time) - new Date(b.event_date_time)
      )
    );
  };
  const formatDate = (utcDate) => {
    const dayjsDate = dayjs.utc(utcDate);
    const day = t(`days.${dayjsDate.format("dddd")}`);
    const month = t(`months.${dayjsDate.format("MMMM")}`);
    const atSymbol = t("misc.atSymbol") || "[at]";
    return `${day}, ${month} ${dayjsDate.format("D")}, ${dayjsDate.format(
      "YYYY"
    )} ${atSymbol} ${dayjsDate.format("h:mm A")}`;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  }
  if (!mealData) return <div>{t("groupmeals.noplan")}</div>;

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8">
      <Helmet>
        <title>
          RecipeCircle - {mealData ? mealData.event_name : "Group Meal Plan"}
        </title>
        <meta
          name="description"
          content="A group meal plan created on RecipeCircle."
        />
        <meta
          property="og:title"
          content={`RecipeCircle - ${
            mealData ? mealData.event_name : "Group Meal Plan"
          }`}
        />
        <meta
          property="og:description"
          content="A group meal plan created on RecipeCircle."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://recipeCircle.com/group_meals/${mealId}`}
        />
      </Helmet>

      <div className="w-100 container mx-auto">
        <div className="w-full overflow-x-hidden">
          <div className="text-center">
            <h1 className="text-4xl font-bold py-4 text-[#384E79]">
              {mealData.event_name}
            </h1>
            <div className="breadcrumbs flex justify-center mb-1">
              <ul>
                <li>
                  <a
                    onClick={() => {
                      navigate("/");
                    }}
                    className="text-[#384E79] hover:text-blue-400"
                  >
                    {t("planmeals.home")}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => {
                      navigate("/plan-meals");
                    }}
                    className="text-[#384E79] hover:text-blue-400"
                  >
                    {t("dashboard.planmeals")}
                  </a>
                </li>
                <li className="text-stone-700">{mealData.event_name}</li>
              </ul>
            </div>
          </div>

          <div className="flex justify-between items-center mb-4">
            <button className="btn btn-outline" onClick={() => navigate("/")}>
              <MdArrowBackIosNew />
            </button>

            {state.user?.id ? (
              state.user?.id === mealData.owner_id ? (
                <DeleteGroupMeal groupId={mealData.id} />
              ) : (
                <LeaveGroupMeal groupId={mealData.id} userId={state.user?.id} />
              )
            ) : null}
          </div>

          <div className="divider"></div>
        </div>
        <div className="grid grid-cols-1 gap-6">
          {groupedAssignments.map((group, index) => (
            <div
              key={index}
              className="bg-gradient-to-br from-blue-100 to-gray-300 p-6 rounded-xl shadow-lg transform transition duration-500 hover:shadow-xl"
            >
              <h2 className="text-2xl font-black mb-4 text-blue-600">
                {formatDate(group.event_date_time)} - {group.event_description}
              </h2>
              <ul>
                {group.recipes.map((recipe, idx) => (
                  <li
                    key={idx}
                    className="mt-4 border-b border-gray-400 pb-4 last:border-b-0"
                  >
                    <a
                      href={recipe.url}
                      className="text-2xl text-blue-500 hover:text-blue-600 font-bold transition duration-300"
                    >
                      {recipe.name}
                    </a>
                    <p className="text-md text-gray-600">
                      {t("viewcircle.assigned")}{" "}
                      {recipe.user_profile
                        ? recipe.user_profile.first_name &&
                          recipe.user_profile.last_name
                          ? `${recipe.user_profile.first_name} ${recipe.user_profile.last_name}`
                          : recipe.email
                        : recipe.email}
                      <br />
                      {state.user?.id === mealData.owner_id && (
                        <span>
                          {t("groupmeals.status")}{" "}
                          {recipe.accepted === 0 && (
                            <>
                              <MdHourglassEmpty className="inline text-indigo-600" />{" "}
                              <span className="text-indigo-600">
                                {t("groupmeals.sent")}
                              </span>
                            </>
                          )}
                          {recipe.accepted === 1 && (
                            <>
                              <MdCheck className="inline text-green-500" />{" "}
                              <span className="text-green-500">
                                {t("groupmeals.accepted")}
                              </span>
                            </>
                          )}
                          {recipe.accepted === 2 && (
                            <>
                              <MdCancel className="inline text-red-500" />{" "}
                              <span className="text-red-500">
                                {t("groupmeals.declined")}
                              </span>
                            </>
                          )}
                        </span>
                      )}
                      {state.user?.id !== mealData.owner_id &&
                        recipe.accepted === 1 && (
                          <span>
                            <MdCheck className="inline text-green-500" />{" "}
                            <span className="text-green-500">
                              {t("groupmeals.accepted")}
                            </span>
                          </span>
                        )}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <>
          <section className="bg-gray-200 py-8 lg:py-16 antialiased shadow-2xl rounded-2xl mx-auto my-8 w-full flex justify-center">
            <div className="px-4 sm:px-6 lg:px-8 md:w-2/3 lg:w-2/3">
              <h2 className="text-gray-800 mb-6 font-semibold text-2xl border-b border-gray-300 pb-4 text-center">
                {mealData.event_name} {t("viewcircle.discussion")}
              </h2>
              <div className="mb-6 w-full sm:w-full md:w-full lg:w-full">
                {state && state.user && (
                  <WriteComment
                    userId={state?.user?.id}
                    groupMealId={mealData.id}
                    onCancel={() => {}}
                  />
                )}
                {!state.user && (
                  <p
                    className="text-center text-blue-500 hover:cursor-pointer"
                    onClick={() => navigate("/sign-up")}
                  >
                    {t("groupmeals.create")} {mealData.event_name}{" "}
                    {t("groupmeals.discussion")}
                  </p>
                )}
              </div>
              <div className="space-y-6">
                <DisplayComments
                  groupMealId={mealData.id}
                  userId={state?.user?.id}
                  isUserLoggedIn={!!state.user}
                  groupMealOwner={mealData.owner_id}
                />
              </div>
            </div>
          </section>
        </>
      </div>
    </div>
  );
}
export default GroupMeals;
