import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import { DataContext } from "../../context/DataContext";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { MdAdd, MdNavigateNext, MdOutlineMoreVert } from "react-icons/md";
import DeletePlannedMeal from "./DeletePlannedMeal";
import { fetchPastPlannedMeals } from "./FetchPastPlannedMeals";
import { useTranslation } from "react-i18next";

const PastPlannedMealsDisplay = ({
  user,
  isOpen,
  onOpenModal,
  onEditClick,
}) => {
  const { state, actions } = useContext(DataContext);
  const { lastMealUpdate } = state;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [plannedMeals, setPlannedMeals] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  dayjs.extend(utc);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  const handleView = (url) => {
    navigate(`/recipes-view/${url}`);
  };

  useEffect(() => {
    if (state.user && state.user.id) {
      setIsLoading(true);
      fetchPastPlannedMeals(state.user.id, setPlannedMeals, () =>
        setIsLoading(false)
      );
    }
  }, [state.user, lastMealUpdate]);

  const groupByPlanDateTime = (meals) => {
    return meals.reduce((acc, meal) => {
      const dateTimeKey = dayjs(meal.plan_date_time).format("YYYY-MM-DDTHH:mm");
      if (!acc[dateTimeKey]) {
        acc[dateTimeKey] = {
          plan_date_time: meal.plan_date_time,
          recipes: [],
          plan_description: meal.plan_description,
        };
      }
      acc[dateTimeKey].recipes.push(meal);
      return acc;
    }, {});
  };

  const formatDate = (utcDate) => {
    const dayjsDate = dayjs.utc(utcDate);
    const day = t(`days.${dayjsDate.format("dddd")}`);
    const month = t(`months.${dayjsDate.format("MMMM")}`);
    const atSymbol = t("misc.atSymbol") || "[at]"; 
    return `${day}, ${month} ${dayjsDate.format("D")}, ${dayjsDate.format("YYYY")} ${atSymbol} ${dayjsDate.format("h:mm A")}`;
  };

  return (
    <div>
      {Object.keys(plannedMeals).length > 0 && (
        <>
          <h2 className="text-xl font-semibold mb-4 mt-10">
            {t("planmeals.past")}
          </h2>
          <div className="divider"></div>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 3, 900: 4, 1200: 5 }}
          >
            <Masonry gutter="20px">
              {Object.entries(plannedMeals).map(
                ([plannedMealsId, { plan_name, recipes }]) => {
                  const groupedByDateTime = groupByPlanDateTime(recipes);
                  return (
                    <div
                      key={plannedMealsId}
                      className="bg-white rounded-lg shadow-sm hover:shadow-lg transition-shadow border border-gray-200 overflow-hidden flex flex-col relative"
                    >
                      <div className="px-5 pt-5 pb-3 relative">
                        <h3 className="text-xl font-semibold text-blue-600 mb-1">
                          {plan_name}
                        </h3>
                        <div className="absolute top-3 right-4 z-10">
                          <div className="dropdown dropdown-end">
                            <button
                              tabIndex={0}
                              className="btn btn-ghost btn-circle hover:bg-blue-100"
                              onClick={toggleDropdown}
                            >
                              <MdOutlineMoreVert size={24} />
                            </button>
                            {isDropdownOpen && (
                              <ul
                                tabIndex={0}
                                className="dropdown-content menu p-2 shadow-md bg-base-100 rounded-md w-52 border border-gray-200"
                                style={{ position: "absolute" }}
                              >
                               
                                <DeletePlannedMeal
                                  plannedMealsId={plannedMealsId}
                                />
                              </ul>
                            )}
                          </div>
                        </div>
                        <div className="divider"></div>
                        {Object.entries(groupedByDateTime).map(
                          (
                            [
                              dateTime,
                              { plan_date_time, recipes, plan_description },
                            ],
                            index
                          ) => (
                            <div key={dateTime} className="mb-4">
                              <div className="text-xs text-gray-500 mb-2">
                                <strong>{t("planmeals.date")}</strong>:{" "}
                                {formatDate(plan_date_time)}
                                <br />
                                <strong>
                                  {t("planmeals.description")}
                                </strong>: {plan_description}
                              </div>
                              {recipes.map(({ id, recipe }) => (
                                <div
                                  key={id}
                                  className="border-b last:border-b-0 p-5 mb-1 hover:bg-blue-100 rounded-lg transition-colors flex justify-between items-center cursor-pointer group"
                                  onClick={() => handleView(recipe.url)}
                                >
                                  <div>
                                    <h4 className="text-lg font-semibold text-blue-500 mb-1">
                                      {recipe.name}
                                    </h4>
                                  </div>
                                  <MdNavigateNext className="text-2xl transition-opacity duration-150 ease-in-out opacity-0 group-hover:opacity-100 md:opacity-100" />
                                </div>
                              ))}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  );
                }
              )}
            </Masonry>
          </ResponsiveMasonry>
        </>
      )}
    </div>
  );
};

export default PastPlannedMealsDisplay;
