import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  MdOutlineAccountCircle,
  MdMenu,
  MdOutlineLanguage,
  MdOutlineLogin,
  MdOutlineManageAccounts,
} from "react-icons/md";
import LanguageModal from "../utilities/languagemodal";
import mainLogo from "../../assets/recipecircle_alt.png";
import { useTranslation } from "react-i18next";

const MobileNavbar = () => {
  const navigate = useNavigate();
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const drawerCheckboxRef = useRef(null);
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const { t } = useTranslation();

  const toggleLanguageModal = () =>
    setIsLanguageModalOpen(!isLanguageModalOpen);

  const navigateAndCloseDrawer = (path) => {
    navigate(path);
    closeDrawer();
  };

  const closeDrawer = () => {
    if (drawerCheckboxRef.current) {
      drawerCheckboxRef.current.checked = false;
    }
    setProfileMenuOpen(false);
  };

  return (
    <>
      <LanguageModal
        isOpen={isLanguageModalOpen}
        closeModal={() => setIsLanguageModalOpen(false)}
      />
      <div className="drawer drawer-mobile" style={{ zIndex: 50 }}>
        <input
          id="my-drawer"
          type="checkbox"
          className="drawer-toggle"
          ref={drawerCheckboxRef}
        />
        <div className="drawer-content flex flex-col">
          {/* Navbar */}
          <div
            className="w-full navbar"
            style={{ backgroundColor: "#384E79", height: "5rem" }}
          >
            <div className="flex-none text-3xl">
              <label
                htmlFor="my-drawer"
                className="btn btn-square btn-ghost drawer-button text-zinc-200"
              >
                <MdMenu className="h-8 w-8" />
              </label>
            </div>
            <div className="flex-1 justify-center text-xl">
              <a
                href="/"
                className="btn btn-ghost normal-case"
                onClick={() => navigateAndCloseDrawer("/")}
              >
                <img src={mainLogo} alt="Logo" className="h-16 -mt-3" />
              </a>
            </div>
            <div className="flex-none">
              <div className="dropdown dropdown-end" style={{ zIndex: 25 }}>
                <label
                  tabIndex={0}
                  className="btn btn-ghost btn-circle"
                  onClick={() => setProfileMenuOpen(!profileMenuOpen)}
                >
                  <MdOutlineAccountCircle className="h-8 w-8 text-zinc-200" />
                </label>
                {profileMenuOpen && (
                  <ul
                    tabIndex={0}
                    className="dropdown-content menu p-2 shadow bg-indigo-100 rounded-box w-52"
                  >
                    <li>
                      <a
                        onClick={() => {
                          navigateAndCloseDrawer("/log-in");
                        }}
                      >
                        <MdOutlineLogin /> {t("navbar.signin")}
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          navigateAndCloseDrawer("/sign-up");
                        }}
                      >
                        <MdOutlineManageAccounts /> {t("navbar.register")}
                      </a>
                    </li>
                    <li>
                      <a
                        onClick={() => {
                          toggleLanguageModal();
                          setProfileMenuOpen(false);
                        }}
                      >
                       <MdOutlineLanguage />  {t("navbar.selectlang")}
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="drawer-side" style={{ zIndex: 50 }}>
          <label htmlFor="my-drawer" className="drawer-overlay"></label>
          <ul className="menu p-4 overflow-y-auto w-80 bg-base-200 text-base-content font-medium text-2xl h-screen">
            <li className="border-b border-stone-800">
              <a
                className="block py-4 hover:bg-gray-500 hover:bg-opacity-25 w-full text-center mb-3"
                onClick={() => navigateAndCloseDrawer("/")}
              >
                {t("navbar.landing")}
              </a>
            </li>

            <li className="">
              <a
                className="block py-4 hover:bg-gray-500 hover:bg-opacity-25 w-full text-center my-3"
                onClick={() => navigateAndCloseDrawer("/public-recipes")}
              >
                {t("navbar.publicrec")}
              </a>
            </li>
            
          </ul>
        </div>
      </div>
    </>
  );
};

export default MobileNavbar;
