import { Outlet } from "react-router-dom";
import Layout from "./Layout";
import Footer from "../common/footer";

import { DataContextProvider } from "../../context/DataContext";
import CookieConsent from "../utilities/cookieconsent";

const MainLayout = (props) => {
  return (
    <>
      <div>
        <DataContextProvider>
          <Layout>
            <div className="flex flex-col min-h-screen">
              <main className="flex-grow">
              <Outlet />
              </main>
            </div>
          </Layout>
          <Footer />
          <CookieConsent />

        </DataContextProvider>
      </div>
    </>
  );
};

export default MainLayout;
