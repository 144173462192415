const ParseQuantity = (quantity) => {
  if (!quantity) return 0;

  const parts = quantity.split(' ');
  let total = 0;

  parts.forEach(part => {
    if (part.includes('/')) {
      const fractionParts = part.split('/');
      if (fractionParts.length === 2) {
        const numerator = Number(fractionParts[0]);
        const denominator = Number(fractionParts[1]);
        if (!isNaN(numerator) && !isNaN(denominator) && denominator !== 0) {
          total += numerator / denominator;
        }
      }
    } else {
      const number = Number(part);
      if (!isNaN(number)) {
        total += number;
      }
    }
  });

  return total;
};
export default ParseQuantity;