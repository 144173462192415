import React, { useState, useRef, useEffect } from "react";
import { IoMdCompass } from "react-icons/io";
import { useTranslation } from "react-i18next";

const MobileMenu = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const [fabClicked, setFabClicked] = useState(false);
  const { t } = useTranslation();

  const toggleMenu = (event) => {
    event.stopPropagation();
    setMenuOpen(!isMenuOpen);
    setFabClicked(true);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="fixed bottom-8 right-8" ref={menuRef}>
      <button
        type="button"
        id="menu-toggle"
        style={{ backgroundColor: "#384E79" }}
        className="flex items-center justify-center w-12 h-12 text-white rounded-full shadow-lg focus:outline-none "
        onClick={(event) => {
          event.preventDefault();
          toggleMenu(event);
        }}
      >
        <IoMdCompass size={24} />
      </button>
      {isMenuOpen && (
        <div
          className="absolute bottom-16 right-8 w-48 max-h-64 overflow-y-auto bg-white border border-gray-400 shadow-lg z-20"
          id="menu-content"
        >
          <ul className="list-reset py-2">
            <li className="py-1 my-2 border-b">
              <a
                href="#overview"
                style={{ scrollBehavior: "smooth" }}
                className="block px-4 py-2 text-gray-700 no-underline"
                onClick={closeMenu}
              >
                {t("menus.overview")}
              </a>
            </li>
            <li className="py-1 my-2 border-b">
              <a
                href="#links"
                className="block px-4 py-2 text-gray-700 no-underline"
                onClick={closeMenu}
              >
                {t("menus.links")}
              </a>
            </li>
            <li className="py-1 my-2 border-b">
              <a
                href="#categories"
                className="block px-4 py-2 text-gray-700 no-underline"
                onClick={closeMenu}
              >
                {t("menus.categories")}
              </a>
            </li>
            <li className="py-1 my-2">
              <a
                href="#circles"
                className="block px-4 py-2 text-gray-700 no-underline"
                onClick={closeMenu}
              >
                {t("menus.circles")}
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default MobileMenu;
