import React, { useState, useContext } from "react";
import { supabase } from "../../supabaseClient";
import { DataContext } from "../../context/DataContext";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const WriteComment = ({ groupMealId, userId, parentId = null, onCancel }) => {
  const [comment, setComment] = useState("");
  const { t } = useTranslation();
  const { actions } = useContext(DataContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!comment) return;

    const { data, error } = await supabase.from("comments").insert([
      {
        group_meal_id: groupMealId,
        user_id: userId,
        text: comment,
        parent_id: parentId,
      },
    ]);

    if (error) {
      Swal.fire({
        icon: "error",
        title: t("comments.oops"),
        text: t("comments.failure"),
      });
    } else {
      Swal.fire({
        title: t("comments.posted"),
        icon: "success",
      }).then((result) => {
        if (result.value) {
          actions.refreshComments();
          setComment("");
          onCancel();
        }
      });
    }
  };

  return (
    <form className="mb-8 mt-4" onSubmit={handleSubmit}>
      <div className="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border focus-within:border-blue-400">
        <label htmlFor="comment" className="sr-only">
          {t("comments.yourcomment")}
        </label>
        <textarea
          id="comment"
          rows="6"
          className="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:placeholder-gray-600 bg-white"
          placeholder={t("comments.commentplc")}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          required
        ></textarea>
      </div>

      <button
        type="submit"
        className="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-blue-600 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800"
      >
        {t("comments.postc")}
      </button>
      {parentId && (
        <button
          type="button"
          className="ml-3 inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-red-600 rounded-lg focus:ring-4 focus:ring-primary-200 dark:focus:ring-primary-900 hover:bg-primary-800"
          onClick={onCancel}
        >
          {t("comments.canceltext")}
        </button>
      )}
    </form>
  );
};

export default WriteComment;
