import React, { useState } from "react";
// import emailjs from "@emailjs/browser";
import { supabase } from "../../supabaseClient";
import { useForm } from "react-hook-form";
import PageTitle from "../../components/layout/PageTitle";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import PhotoUploaderCircle from "../../components/common/PhotoUploaderCircle";
import { useNavigate } from "react-router-dom";


const NewCircle = (props) => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const processSubmit = async (dataInput) => {
    setIsLoading(true);
    setData({ ...data, ...dataInput });
    const {
      data: { user },
    } = await supabase.auth.getUser();
    const insert = {
      owner_id: user.id,
      name: dataInput.name,
      description: dataInput.description,
      image_url: data?.image_url,
    };

    let {
      data: dataInsert,
      error,
      status,
    } = await supabase.from("circles").insert(insert).select();

    if (error && status !== 406) {
      throw error;
    }

    setSuccess(true);
    setTimeout(() => {
      navigate(`/circles/${dataInsert[0].id}`);
    }, 3000);
  };

  const onChangeImage = (image) => {
    setData({ ...data, image_url: image });
  };

  return (
    <div>
      <PageTitle title="New Circle" />
      <Breadcrumbs
        data={[
          { name: "Home", link: "/" },
          { name: "Circles", link: "/circles" },
          { name: "New Circle" },
        ]}
      />
      <div className="flex justify-space-between mb-1">
        <div className="flex-1">
          <button
            className="btn btn-secondary btn-square btn-outline"
            onClick={() => {
              navigate("/circles");
            }}
          >
            ❮
          </button>
        </div>
      </div>
      <div className="flex justify-center ">
        <div className="card w-full md:w-2/3 lg:w-2/3 bg-base-200 shadow-xl mb-4">
          <PhotoUploaderCircle onChangeImage={onChangeImage} />
          <div className="card-body items-center text-center p-4 md:p-8 lg:p-8">
            <form
              className="form-control w-full"
              onSubmit={handleSubmit((data) => processSubmit(data))}
            >
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Name</span>
                </label>
                <input
                  {...register("name", { required: true })}
                  className="input input-bordered"
                />
              </div>
              <div className="form-control w-full">
                <label className="label">
                  <span className="label-text">Description</span>
                </label>
                <textarea
                  {...register("description")}
                  className="input input-bordered h-32"
                />
              </div>
              <div className="divider divider-horizontal"></div>

              {/* <label className="label">
                <span className="label-text">Members</span>
              </label>
              <div className="grid card bg-base-300 rounded-box p-4">
                {dataMembers.map((member, index) => (
                  <div
                    className="form-control w-full"
                    key={`member-${member.id}`}
                  >
                    <div className="input-group">
                      <button
                        className="btn btn-primary"
                        onClick={() => handleMemberRemove(member.id)}
                      >
                        <DeleteIconSm />
                      </button>
                      <input
                        className="input input-bordered mb-1 w-full"
                        placeholder="email"
                        onChange={(e) =>
                          handleChangeMember(member.id, e.target.value)
                        }
                      />
                    </div>
                  </div>
                ))}
                <div className="w-full">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    onClick={() =>
                      setDataMembers([
                        ...dataMembers,
                        { id: Date.now(), email: "" },
                      ])
                    }
                  >
                    Add Member
                  </button>
                </div>
              </div> */}
              <div className="mt-4">
                <input
                  type="submit"
                  className="btn btn-primary w-full"
                  disabled={isLoading || success}
                  value="Save"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      {success ? (
        <div className="toast toast-top toast-end">
          <div className="alert alert-success">
            <div>
              <span>Circle created successfully.</span>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default NewCircle;
