/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const SearchBox = ({ onChange }) => {
  const handleChange = (value) => {
    onChange(value);
  };

  return (
    <div className="form-control mr-2 mb-2 w-full md:w-auto lg:w-auto">
      <div className="input-group">
        <input
          type="text"
          placeholder="Search..."
          className="input input-bordered w-full"
          onChange={(e) => handleChange(e.target.value)}
        />
        <button className="btn btn-square">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default SearchBox;
